import {Component, Input} from '@angular/core';
import {CampusEvent} from '@rz-gud/models';
import {GDColors} from '@conception/ng-gud-ui';

@Component({
  selector: 'events-container',
  templateUrl: './events-container.component.html',
  styleUrls: ['./events-container.component.scss']
})
export class EventsContainerComponent {
  @Input() items: Array<CampusEvent>;
  readonly gdColors = GDColors;
}
