import {ChangeDetectionStrategy, Component, Inject, PLATFORM_ID} from '@angular/core';
import {ProductHighlightsModel} from './product-highlights.model';
import {ContentElementModel} from '@conception/ngx-pimcore-connect';
import {TranslateService} from '@ngx-translate/core';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {PageSelectors} from '@rz-gud/store';
import {isPlatformBrowser} from '@angular/common';
import {ButtonSizes, GDColors} from '@conception/ng-gud-ui';

@Component({
  selector: 'ce-product-highlights',
  templateUrl: './product-highlights.component.html',
  styleUrls: ['./product-highlights.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductHighlightsComponent {

  @ContentElementModel(ProductHighlightsModel) content: ProductHighlightsModel;
  readonly detailLink$: Observable<string> = this.store.select(PageSelectors.productDetail);
  readonly buttonSizes = ButtonSizes;
  readonly gdColor = GDColors;
  readonly isTouch: boolean = false;
  private readonly isBrowser: boolean = false;

  constructor(readonly translate: TranslateService,
              private readonly store: Store,
              @Inject(PLATFORM_ID) platformId: Record<string, unknown>) {
    this.isBrowser = isPlatformBrowser(platformId);

    if (this.isBrowser) {
      this.isTouch = matchMedia('(hover: none), (pointer: coarse)').matches;
    }
  }

}
