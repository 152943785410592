import { Component, OnInit } from '@angular/core';
import { ContentElementModel } from '@conception/ngx-pimcore-connect';
import { CampusMediaModel } from './campus-media.model';
import { Category, MediaLibraryItem } from '@rz-gud/models';
import { CampusService } from '@rz-gud/services';
import { ApiConfig } from '@rz-gud/interfaces';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ce-campus-media',
  templateUrl: './campus-media.component.html',
  styleUrls: ['./campus-media.component.scss'],
})
export class CampusMediaComponent implements OnInit {
  @ContentElementModel(CampusMediaModel) content: CampusMediaModel;
  items$: Observable<Array<MediaLibraryItem>>;

  sortDropdown: Array<{ title: string; id: string }>;

  categoryDropdown: Array<Category>;
  currentSortValue: { title: string; id: string };
  currentCategoryValue: Category;

  constructor(private readonly campusService: CampusService, private readonly translate: TranslateService) {}

  set currentSort(sort: { title: string; id: string }) {
    if (sort.id !== this.currentSortValue?.id) {
      this.currentSortValue = sort;
      this.loadData();
    }
  }

  set currentCategory(category: Category) {
    if (category.value !== this.currentCategoryValue?.value) {
      this.currentCategoryValue = category;
      this.loadData();
    }
  }

  ngOnInit(): void {
    this.items$ = this.campusService.getMediaLibraryItems({
      folder: this.content.folder?.path,
      sortBy: 'date',
      sortDirection: 'asc',
    });
    this.items$.pipe(take(1)).subscribe((result) => {
      this.categoryDropdown = this.campusService.getUniqueCategories(result, true, 'media');
      this.currentCategoryValue = this.categoryDropdown[0];
    });
    this.initSortDropdown();
    this.translate.onLangChange.subscribe(() => this.initSortDropdown());
  }

  initSortDropdown(): void {
    this.sortDropdown = [
      {
        title: this.translate.instant('sort.newest'),
        id: 'newest',
      },
      {
        title: this.translate.instant('sort.oldest'),
        id: 'oldest',
      },
      {
        title: this.translate.instant('sort.az'),
        id: 'az',
      },
      {
        title: this.translate.instant('sort.za'),
        id: 'za',
      },
    ];
    this.currentSortValue = this.sortDropdown[0];
  }

  private loadData(): void {
    const config: ApiConfig = {
      sortDirection: this.currentSortValue.id === 'oldest' || this.currentSortValue.id === 'za' ? 'asc' : 'desc',
      sortBy: this.currentSortValue.id === 'newest' || this.currentSortValue.id === 'oldest' ? 'date' : 'alphabetical',
      folder: this.content.folder?.path,
    };

    if (this.currentCategoryValue?.value && this.currentCategoryValue.value !== 'all') {
      config.category = this.currentCategoryValue.value;
    }

    this.items$ = this.campusService.getMediaLibraryItems(config);
  }
}
