import { ChangeDetectorRef, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApiService, PageObjectService, PimVideo } from '@conception/ngx-pimcore-connect';
import {
  ComparisonActions,
  ComparisonSelectors,
  DynamicsSelectors,
  HubspotSelectors,
  LanguageNavigationActions,
  LayoutActions,
  LayoutSelectors,
  NavigationActions,
  PageActions,
  PageMode,
  ProductFilterActions,
  SearchSelectors,
  VideoPopupSelectors,
} from '@rz-gud/store';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@rz-gud/environment';
import { Menu, SiteInfo } from '@rz-gud/interfaces';
import { filter, map, take, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { scaleInCenter, slideInOutBottom } from '@rz-gud/animations';
import { translationConfig } from '@rz-gud/config';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';
import { MetaService } from '@rz-gud/services/meta.service';

@Component({
  selector: 'guntermann-drunck-corporate-website',
  templateUrl: './app.component.html',
  animations: [slideInOutBottom, scaleInCenter],
})
export class AppComponent implements OnInit {
  readonly searchOverlay$: Observable<boolean> = this.store.select(SearchSelectors.isOverlayOpen);
  readonly languageSwitchOverlay$: Observable<boolean> = this.store.select(LayoutSelectors.languageSwitchOverlay);
  readonly formOverlay$: Observable<boolean> = this.store.select(HubspotSelectors.isOverlayOpen);
  readonly dynamicsFormOverlay$: Observable<boolean> = this.store.select(DynamicsSelectors.isOverlayOpen);
  readonly video$: Observable<PimVideo> = this.store.select(VideoPopupSelectors.video);
  showComparisonOverlay$: Observable<boolean>;
  env = environment;
  hostname = '';
  private readonly isBrowser: boolean = false;

  constructor(
    private readonly store: Store,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly state: TransferState,
    private readonly translate: TranslateService,
    private readonly apiService: ApiService,
    private readonly pageObjectService: PageObjectService,
    private cd: ChangeDetectorRef,
    @Inject(PLATFORM_ID) platformId: Record<string, unknown>,
    @Inject(DOCUMENT) private document: Document,
    private metaService: MetaService
  ) {
    // Retrieve preferred language from local storage
    if (typeof window !== 'undefined') {
      const possibleLanguages = ['de', 'en', 'en-us'];
      const preferredLanguage = localStorage.getItem('preferredLanguage');
      let userLang: string;
      if (possibleLanguages.includes(window.location.pathname.split('/')[1])) {
        // use the language from the path
        userLang = window.location.pathname.split('/')[1];
        if (userLang === 'en-us') {
          userLang = 'en-US';
        }
      } else if (possibleLanguages.some((possibleLang) => navigator.language.startsWith(possibleLang))) {
        // use browser language
        userLang = navigator.language;
      } else {
        // use fallback/ default language
        userLang = translationConfig.defaultLanguage;
      }

      // Set the language to the preferred language if it exists in local storage
      if (
        preferredLanguage &&
        !window.location.pathname.startsWith('/en') &&
        !window.location.pathname.startsWith('/de') &&
        !window.location.pathname.startsWith('/en-US')
      ) {
        this.store.dispatch(LayoutActions.dismissLanguageTutorial());
        this.router.navigate(['/en-us/start']).then();
        this.translate.use(preferredLanguage.replace('-', '_'));
      } else if (userLang) {
        const language = userLang.startsWith('de') ? 'de' : userLang !== 'en-US' ? 'en' : userLang;
        const translateLang =
          language === 'de' || language === 'en' || language === 'en-US' ? language : translationConfig.defaultLanguage;
        if (
          (!window.location.pathname.startsWith('/en') &&
          !window.location.pathname.startsWith('/de') &&
          !window.location.pathname.startsWith('/en-US')) ||
          (window.location.pathname.split('/').filter(el => el !== '').length === 1)
        ) {
          const path = translateLang === 'en-US' ? '/en-us/start' : '/' + language + '/start';
          this.router.navigate([path]).then();
        }
        this.translate.use(translateLang.replace('-', '_'));
      } else {
        this.translate.use(translationConfig.defaultLanguage);
      }
    }

    this.document.documentElement.lang = this.translate.currentLang;
    this.getInitialSiteInfoObject();
    this.updatePageObject();
    this.registerScrollHandler();

    this.getProductNames('de');
    this.translate.onLangChange.subscribe((currentLang) => this.getProductNames(currentLang.lang));

    this.isBrowser = isPlatformBrowser(platformId);

    if (this.isBrowser) {
      this.hostname = document.baseURI.slice(0, -1);
    }

    // Set initial language apart from page object
    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        if (!this.isBrowser) {
          const particle = this.router.url.split('/')[1];
          if (particle.length > 1 && particle.length < 4) {
            this.translate.use(particle);
          }
        }
        if (this.isBrowser) {
          this.setHrefLangTags();
        }
      }
    });
  }

  ngOnInit() {
    setTimeout(() => {
      this.showComparisonOverlay$ = this.store.select(ComparisonSelectors.showOverlay);
      this.store.dispatch(ComparisonActions.setPageLoadOverlay());
      this.cd.detectChanges();
    }, 1500);

    // set canonical url on navigation end
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.updateCanonicalUrl(this.hostname + this.router.url.split('?')[0]);
    });
  }

  private updateCanonicalUrl(url: string) {
    const head = this.document.getElementsByTagName('head')[0];

    const canonicalTag =
      this.document.querySelector(`link[rel='canonical']`) || (this.document.createElement('link') as HTMLLinkElement);

    canonicalTag.setAttribute('rel', 'canonical');
    canonicalTag.setAttribute('href', url);

    this.pageObjectService.currentPageObject.subscribe((page) => {
      if (page && page.language) {
        if (page.language.lang.toLowerCase() === 'en_us' && url) {
          const langInfo = page.language?.nav.find((el) => el.lang.toLowerCase() === 'en');
          canonicalTag.setAttribute('href', langInfo.url);
        }
      }

      head.appendChild(canonicalTag);
    });
  }

  private registerScrollHandler(): void {
    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationStart) {
        if (this.isBrowser) {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }
    });
  }

  private getInitialSiteInfoObject(): void {
    this.apiService
      .getData([this.env.pageKey, this.translate.currentLang, environment.endpoints.generalPageInfo].join('/'))
      .pipe(
        take(1),
        map((result: { page_info: SiteInfo }) => result.page_info),
        map((result: SiteInfo) => this.apiService.removeContentPath(result)),
        tap((result: SiteInfo) => {
          this.store.dispatch(PageActions.setSiteInfo({ siteInfo: result }));
        })
      )
      .subscribe();
  }

  private getNavigation(): void {
    const menuStateKey = makeStateKey<Array<Menu>>('menuEntry_' + this.translate.currentLang);

    if (this.state.hasKey(menuStateKey)) {
      this.store.dispatch(
        NavigationActions.setEntry({
          entry: this.state.get(menuStateKey, [] as Array<Menu>),
        })
      );
      this.state.remove(menuStateKey);
    } else {
      const path =
        this.env.pageKey +
        '_' +
        this.translate.currentLang +
        '/' +
        this.translate.currentLang +
        environment.endpoints.nav;

      this.apiService
        .getData(path)
        .pipe(
          take(1),
          tap((entry) => this.state.set(menuStateKey, entry)),
          tap((entry) => this.store.dispatch(NavigationActions.setEntry({ entry })))
        )
        .subscribe();
    }
  }

  private updatePageObject(): void {
    this.pageObjectService.currentPageObject.subscribe((page) => {
      if (page) {
        // Save Site Info Object to Store
        if (page.site_info_object) {
          this.store.dispatch(PageActions.setSiteInfo({ siteInfo: page.site_info_object }));
        }

        // Save breadcrumb to store or clear it on empty breadcrumb
        if (page.breadcrumb) {
          this.store.dispatch(PageActions.setBreadcrumb({ breadcrumb: page.breadcrumb }));
        } else {
          this.store.dispatch(PageActions.clearBreadcrumb());
        }

        // Set Expert Mode or Easy Mode
        this.store.dispatch(
          LayoutActions.setPageMode({
            mode: page.isExpertMode ? PageMode.EXPERT : PageMode.EASY,
          })
        );

        if (page.language) {
          this.store.dispatch(LanguageNavigationActions.set({ items: page.language?.nav }));
          this.store.dispatch(
            LanguageNavigationActions.setCurrent({
              current: {
                lang: page.language.lang,
                label: page.language.label,
              },
            })
          );
        }

        // Set language for frontend, based on API data
        this.translate
          .use(page.language?.lang ?? translationConfig.defaultLanguage)
          .pipe(
            take(1),
            tap(() => (this.document.documentElement.lang = this.translate.currentLang)),
            tap(() => this.getNavigation())
          )
          .subscribe();
      }
    });
  }

  private getProductNames(currentLang: string): void {
    this.store.dispatch(ProductFilterActions.getProductNames({ lang: currentLang }));
  }

  private setHrefLangTags() {
    this.pageObjectService.currentPageObject.subscribe((page) => {
      if (page) {
        this.metaService.updateTag({
          rel: 'alternate',
          hreflang: page.language?.lang,
          href: this.hostname + this.router.url.split('?')[0],
        });
        page.language?.nav.forEach((el) => {
          this.metaService.updateTag({
            rel: 'alternate',
            hreflang: el.lang,
            href: this.hostname + el.url,
          });
        });
      }
    });
  }
}
