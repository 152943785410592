import { createSelector } from '@ngrx/store';
import { DynamicsState, State } from '../reducers';

export const select = (state: State) => state.dynamics;

// product inquiry form Values
export const productInquiryFormWebsiteId = (state: State) => state.page.siteInfo.productInquiryDynamicsWebsiteId;
export const productInquiryFormHostName = (state: State) => state.page.siteInfo.productInquiryDynamicsHostname;
export const productInquiryFormBlockId = (state: State) => state.page.siteInfo.productInquiryDynamicsFormBlockId;

// product question form values
export const productQuestionFormWebsiteId = (state: State) => state.page.siteInfo.productQuestionDynamicsWebsiteId;
export const productQuestionFormHostName = (state: State) => state.page.siteInfo.productQuestionDynamicsHostname;
export const productQuestionFormBlockId = (state: State) => state.page.siteInfo.productQuestionDynamicsFormBlockId;

export const prefillProducts = (state: State) => state.dynamics.products;

// state of dynamics form overlay
export const currentDynamicsForm = (state: State) => state.dynamics.form;
export const isOverlayOpen = createSelector<State, [DynamicsState], boolean>(
  select,
  (dynamics: DynamicsState) => dynamics.form !== null
);
