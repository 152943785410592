import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  PLATFORM_ID,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { notificationBubbleAnimation, slideInOutLeft, slideInOutTop } from '@rz-gud/animations';
import { Store } from '@ngrx/store';
import {
  BookmarkSelectors,
  ComparisonActions,
  ComparisonSelectors,
  LanguageNavigationSelectors,
  LayoutActions,
  LayoutSelectors,
  NavigationSelectors,
  PageMode,
  PageSelectors,
  SearchActions,
} from '@rz-gud/store';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { LanguageNavigationItem, NavigationItem } from '@rz-gud/interfaces';
import { MenuItemComponent } from '@rz-gud/components/static';
import { MoreButtonComponent } from '@conception/ng-gud-ui';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '@rz-gud/environment';

@Component({
  selector: 'main-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [slideInOutLeft, notificationBubbleAnimation, slideInOutTop],
})
export class HeaderComponent {
  @ViewChildren(MenuItemComponent, { read: ElementRef })
  menuItems: QueryList<ElementRef>;
  @ViewChild('nav') navContainerRef: ElementRef;
  @ViewChild(MoreButtonComponent, { read: ElementRef })
  moreButtonRef: ElementRef;

  showMobileMenu: boolean = false;
  flyout: string = '';
  indicatorPosition: number = 0;
  indicatorWidth: number = 0;
  browserLang: string;
  env = environment;

  readonly showTutorial$: Observable<boolean> = this.store.select(LayoutSelectors.showLanguageTutorial);
  readonly currentLanguage$: Observable<LanguageNavigationItem> = this.store.select(
    LanguageNavigationSelectors.current
  );
  readonly headermode$: Observable<string> = this.store.select(LayoutSelectors.mode);
  readonly mainNav$: Observable<NavigationItem> = this.store.select(NavigationSelectors.navigationItem('main'));
  readonly moreNav$: Observable<NavigationItem> = this.store.select(
    NavigationSelectors.navigationItem('more-from-g-d')
  );
  readonly mobileNav$: Observable<NavigationItem> = this.store.select(NavigationSelectors.navigationItem('mobile'));
  readonly comparisonList$: Observable<
    Array<{
      id: number;
      title: string;
    }>
  > = this.store.select(ComparisonSelectors.items);
  readonly bookmarkCount$: Observable<number> = this.store.select(BookmarkSelectors.count);
  readonly bookmarksLink$: Observable<string> = this.store.select(PageSelectors.bookmarks);
  readonly startLink$: Observable<string> = this.store.select(PageSelectors.start);
  readonly productEntryLink$: Observable<string> = this.store.select(PageSelectors.productEntry);
  readonly alternativeBreadcrumbLine$: Observable<boolean> = this.store.select(
    LayoutSelectors.alternativeBreadcrumbLine
  );
  readonly breadcrumbVisible$: Observable<boolean> = this.store.select(PageSelectors.breadcrumbVisible);
  readonly pageMode$: Observable<PageMode> = this.store.select(LayoutSelectors.mode);
  private readonly isBrowser: boolean = false;

  constructor(
    private readonly store: Store,
    private readonly translate: TranslateService,
    private router: Router,
    @Inject(PLATFORM_ID) platformId: Record<string, unknown>
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    if (this.isBrowser) {
      this.browserLang = navigator.language;
    }
    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd && this.showMobileMenu) {
        this.showMobileMenu = false;
      }
    });
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.flyout = '';
  }

  @HostListener('click', ['$event']) onMouseClick(e) {
    if (e.target.tagName !== 'GD-MORE-BUTTON') {
      this.flyout = '';
    }
  }

  openSearch(): void {
    this.store.dispatch(SearchActions.openOverlay());
  }

  showLanguageSwitch(): void {
    this.store.dispatch(LayoutActions.showLanguageSwitchOverlay());
  }

  setFlyout(index: number): void {
    this.flyout = index.toString();
    const activeElement = this.menuItems.get(index).nativeElement as HTMLElement;

    this.indicatorWidth = activeElement.clientWidth;
    this.indicatorPosition = this.navContainerRef.nativeElement.offsetLeft + activeElement.offsetLeft;
  }

  setFlyoutMore(): void {
    this.flyout = this.flyout === 'more' ? '' : 'more';

    this.indicatorWidth = this.moreButtonRef.nativeElement.clientWidth;
    this.indicatorPosition =
      this.navContainerRef.nativeElement.offsetLeft + this.moreButtonRef.nativeElement.offsetLeft;
  }

  clearFlyout(): void {
    this.flyout = '';
    this.indicatorWidth = 0;
  }

  compare(): void {
    this.store.dispatch(ComparisonActions.compare());
    this.store.dispatch(ComparisonActions.buildList({ lang: this.translate.currentLang }));
  }

  dismissLanguage(): void {
    this.store.dispatch(LayoutActions.dismissLanguageTutorial());
    this.store.dispatch(LayoutActions.openPageModeTutorial());
  }
}
