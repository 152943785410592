import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  /**
   * Helper function to make a unique array of objects
   *
   * @param origin {Array} - Any array of objects
   * @param prop {string} - The property to use for distinction
   *
   */
  addUnique(origin: Array<any>, prop: string): Array<any> {
    const result: Array<any> = [];
    origin.forEach((el) => {
      if (el && result.findIndex((item) => item[prop] === el[prop]) === -1) {
        result.push(el);
      }
    });

    return result;
  }

  /**
   * Remove HTML Tags from string
   *
   * @param str {string} - original string
   */
  stripTags(str: string): string {
    if (!str || str.length === 0) {
      return str;
    }
    return str.replace(/(<([^>]+)>)/gi, '');
  }
}
