<ng-container *ngIf="isLoaded$ | async">
  <ng-container *ngIf="(products$ | async) as products">
    <div class="reset" [innerHTML]="'showAllProducts' | translate" (click)="resetFilters()"></div>
    <div class="amount" #amount
         [innerHTML]="'productsAmount' | translate:{'productAmount': count$ | async}"></div>
    <ng-container *ngFor="let product of products; index as i; trackBy: trackById">
      <product-teaser [product]="product"></product-teaser>
    </ng-container>
    <ng-container *ngIf="(count$ | async) as count">
      <div class="more" *ngIf="products.length < count">
        <gd-button
          [label]="'showAmountMore' | translate:{'amount': (count - products.length) > 50 ? 50 : (count - products.length)}"
          color="azure-outline"
          (click)="showMore()"></gd-button>
        <gd-button *ngIf="count < 300"
                   [label]="'showMoreALL' | translate:{'amount': count}"
                   color="azure"
                   (click)="showAll()"></gd-button>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

<ng-container *ngIf="(isLoaded$ | async) === false">
  <div class="amount">&nbsp;
    <div class="loader"></div>
  </div>
  <product-teaser [isSkeleton]="true" *ngFor="let element of [0, 1, 2, 3, 4, 5]"></product-teaser>
</ng-container>
