<ng-container [ngSwitch]="entry?.type">
  <ng-container *ngSwitchCase="'PageHit'">
    <a [href]="pageHit.pagePath">
      <hr>
      <h3 [innerHTML]="entry.title"></h3>
      <p class="copy" [innerHTML]="processTextHits(pageHit.textHits)"></p>
      <p class="link" [innerHTML]="processUrl(pageHit.pagePath)"></p>
    </a>
  </ng-container>
  <ng-container *ngSwitchCase="'ObjectHit'">
    <a *ngIf="objectHitUrl; else hasNoObjectHitUrl" [href]="objectHitUrl">
      <ng-container [ngTemplateOutlet]="entryContent"></ng-container>
    </a>
    <ng-template #hasNoObjectHitUrl>
      <ng-container [ngTemplateOutlet]="entryContent"></ng-container>
    </ng-template>
  </ng-container>
  <ng-template #entryContent>
    <hr>
    <h3 [innerHTML]="objectHit.class === 'Mediathek'
      ? objectHit.objectData.title
      : objectHit.class === 'Glossary'
      ? objectHit.title
      : objectHit.class === 'Event'
      ? objectHit.objectData.title
      : objectHit.class === 'FAQ'
      ? objectHit.objectData.headline
      : objectHit.objectData.title
      ? objectHit.class === 'Branch'
      : objectHit.title"></h3>
    <p class="copy"
       [innerHTML]="highlightedText(shortenString(removeHtmlTagFromString(objectHit.class === 'Mediathek'
      ? objectHit.objectData.description
      : objectHit.class === 'Glossary'
      ? objectHit.objectData.copy
      : objectHit.class === 'FAQ'
      ? objectHit.objectData.copy
      : objectHit.class === 'Event'
      ? objectHit.objectData.description
      : objectHit.class === 'Branch'
      ? ''
      : objectHit.objectData.copy)))"></p>
    <p class="link" [innerHTML]="processUrl(objectHitUrl)"></p>
  </ng-template>
</ng-container>
