import {Component} from '@angular/core';
import {ContentElementModel} from '@conception/ngx-pimcore-connect';
import {GDColors} from '@conception/ng-gud-ui';
import {FullsizeTeaserModel} from './fullsize-teaser.model';

@Component({
  selector: 'ce-fullsize-teaser',
  templateUrl: './fullsize-teaser.component.html',
  styleUrls: ['./fullsize-teaser.component.scss']
})
export class FullsizeTeaserComponent {
  @ContentElementModel(FullsizeTeaserModel) content: FullsizeTeaserModel;
  gdColors = GDColors;
}
