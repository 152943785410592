import {Component, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';
import {GDColors} from '@conception/ng-gud-ui';
import {slideInOutLeft} from '@rz-gud/animations';
import {LayoutActions} from '@rz-gud/store';
import {Store} from '@ngrx/store';

@Component({
  selector: 'mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
  animations: [slideInOutLeft]
})
export class MobileMenuComponent implements OnInit, OnDestroy {

  @Input() data: any;
  @HostBinding('class') @Input() color: string;

  gdColors = GDColors;
  currentList: number = null;
  currentTeaser: number = null;

  constructor(private readonly store: Store) {
  }

  ngOnInit(): void {
    this.store.dispatch(LayoutActions.setFixed());
  }

  ngOnDestroy(): void {
    this.store.dispatch(LayoutActions.setUnfixed());
  }
}
