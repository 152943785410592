import {createSelector} from '@ngrx/store';
import {EasyModeState, State} from '../reducers';
import {EasyModeSelectorAnswerModel} from '@rz-gud/content-elements';


export const select = (state: State) => state.easyMode;

export const answers = createSelector<State,[EasyModeState],Array<EasyModeSelectorAnswerModel>>(
  select,
  (state: EasyModeState) => state.answers
);

export const stage = createSelector<State,[EasyModeState],number>(
  select,
  (state: EasyModeState) => state.stage
);


export const dropdownLabel = (fallback: string) => createSelector<State,[EasyModeState],string>(
  select,
  (state: EasyModeState) => {
    if (state.stage > 0 && state.stage < 4) {
      return state.answers[state.stage - 1].label ? state.answers[state.stage - 1].label : fallback;
    } else {
      return '';
    }
  }
);
