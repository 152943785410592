<div class="header">
  <div class="header--inner">
    <gd-multi-dropdown
      *ngIf="categoryDropdown"
      [useAsUnique]="'value'"
      [useAsLabel]="'label'"
      [label]="'categories' | translate"
      [multi]="false"
      [items]="categoryDropdown"
      [activeItems]="currentCategoryValue ? [currentCategoryValue] : []"
      (activeItemsChange)="currentCategory = $event[0]"></gd-multi-dropdown>

    <gd-multi-dropdown
      *ngIf="sortDropdown"
      [items]="sortDropdown"
      [label]="('sortBy' | translate) + currentSortValue?.title"
      [activeItems]="[currentSortValue]"
      (activeItemsChange)="currentSort = $event[0]"
      [multi]="false"></gd-multi-dropdown>
  </div>
</div>

<div class="campus-media--inner">
  <media-library-container *ngIf="items$ | async as items" [items]="items"></media-library-container>
</div>
