import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import * as fromEasyMode from './easymode.reducer';
import { EasyModeState } from './easymode.reducer';
import * as fromBookmark from './bookmark.reducer';
import { BookmarkState } from './bookmark.reducer';
import * as fromPage from './page.reducer';
import { PageState } from './page.reducer';
import * as fromLanguageNavigation from './language-navigation.reducer';
import { LanguageNavigationState } from './language-navigation.reducer';
import * as fromProductFilter from './product-filter.reducer';
import { ProductFilterState } from './product-filter.reducer';
import * as fromVideoPopup from './video-popup.reducer';
import { VideoPopupState } from './video-popup.reducer';
import * as fromSearch from './search.reducer';
import { SearchState } from './search.reducer';
import * as fromComparison from './comparison.reducer';
import { ComparisonState } from './comparison.reducer';
import * as fromNavigation from './navigation.reducer';
import { NavigationState } from './navigation.reducer';
import * as fromLayout from './layout.reducer';
import { LayoutState, PageMode } from './layout.reducer';
import * as fromHubspot from './hubspot.reducer';
import { HubspotState } from './hubspot.reducer';
import * as fromDynamics from './dynamics.reducer';
import { DynamicsState } from '@rz-gud/store/reducers/dynamics.reducer';
import { localStorageSync } from 'ngrx-store-localstorage';

export interface State {
  easyMode: EasyModeState;
  bookmarks: BookmarkState;
  page: PageState;
  languageNavigation: LanguageNavigationState;
  productFilter: ProductFilterState;
  videoPopup: VideoPopupState;
  search: SearchState;
  comparison: ComparisonState;
  navigation: NavigationState;
  layout: LayoutState;
  hubspot: HubspotState;
  dynamics: DynamicsState;
}

export const reducers: ActionReducerMap<State> = {
  easyMode: fromEasyMode.easyModeReducer,
  bookmarks: fromBookmark.bookmarkReducer,
  page: fromPage.pageReducer,
  languageNavigation: fromLanguageNavigation.languageNavigationReducer,
  productFilter: fromProductFilter.productFilterReducer,
  videoPopup: fromVideoPopup.videoPopupReducer,
  search: fromSearch.searchReducer,
  comparison: fromComparison.comparisonReducer,
  navigation: fromNavigation.navigationReducer,
  layout: fromLayout.layoutReducer,
  hubspot: fromHubspot.hubspotReducer,
  dynamics: fromDynamics.dynamicsReducer,
};

const localStorageSyncReducer = (reducer: ActionReducer<State>) =>
  localStorageSync({
    keys: [
      { bookmarks: ['products'] },
      { comparison: ['showOverlay', 'resultList', 'comparisonListProducts'] },
      { layout: ['pageModeTutorial'] },
    ],
    rehydrate: true,
  })(reducer);

export const metaReducers: Array<MetaReducer<any, any>> = [];

// check if in SSR!
if (typeof window !== 'undefined') {
  metaReducers.push(localStorageSyncReducer);
}

export {
  EasyModeState,
  BookmarkState,
  PageState,
  SearchState,
  LayoutState,
  HubspotState,
  PageMode,
  LanguageNavigationState,
  ProductFilterState,
  VideoPopupState,
  ComparisonState,
  NavigationState,
  DynamicsState,
};
