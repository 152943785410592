<ng-container *ngIf="(showSearchTag$ | async)">
  <gd-tag
    *ngIf="searchTerm$ | async as searchTerm"
    [label]="searchTerm"
    color="maya"
    (click)="clearSearchTerm()"
  ></gd-tag>
</ng-container>
<ng-container *ngIf="tags$ | async as filters">
  <ng-container *ngFor="let set of filters">
    <gd-tag
      *ngFor="let tag of set.filterEntries"
      [label]="tag.label"
      color="silver-light"
      (click)="removeFilter(set.category, tag)"
    ></gd-tag>
  </ng-container>
</ng-container>
