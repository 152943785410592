<div class="teaser">
  <div class="teaser--left">
    <gd-icon-box
      *ngIf="content.icon"
      [color]="gdColors.YELLOW"
      [iconColor]="gdColors.AZURE"
      [icon]="content.icon.value"></gd-icon-box>
    <img
      *ngIf="content.image?.urls"
      [src]="content.image.urls['CmsImages']"
      [alt]="content.image.meta?.alt"
      [title]="content.image?.meta?.title" />
  </div>
  <div class="teaser--right">
    <h3 [innerHTML]="content.title"></h3>
    <p [innerHTML]="content.text"></p>
    <a
      *ngIf="content.link?.linktype === 'internal'; else externalLink"
      [routerLink]="content.link.path">
      <gd-button
        [color]="gdColors.AZURE"
        [label]="content.link.text"></gd-button>
    </a>
    <ng-template #externalLink>
      <a
        *ngIf="content.link?.linktype === 'direct'"
        [href]="content.link.direct">
        <gd-button
          [color]="gdColors.AZURE"
          [label]="content.link.text"></gd-button>
      </a>
    </ng-template>
  </div>
</div>
