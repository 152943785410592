import { Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap, withLatestFrom } from 'rxjs/operators';
import { ComparisonActions, LayoutActions } from '../actions';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ComparisonSelectors, LayoutSelectors, PageSelectors } from '../selectors';
import { ApiService } from '@conception/ngx-pimcore-connect';
import { TranslateService } from '@ngx-translate/core';
import { Comparison } from '@rz-gud/models';
import { GtagService } from '@rz-gud/services/gtag.service';
import { isPlatformBrowser } from '@angular/common';

interface ComparisonResponse {
  productComparison: Array<any>;
}

const overlayOpenClass = 'comparisonOverlayOpen';

@Injectable()
export class ComparisonEffects {
  setOverlayOnPageLoad$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ComparisonActions.setPageLoadOverlay),
        withLatestFrom(this.store.select(ComparisonSelectors.showOverlay)),
        tap(([action, isOverlayOpen]) => {
          if (this.isBrowser) {
            if (isOverlayOpen) {
              this.renderer.addClass(document.body, overlayOpenClass);
            } else {
              this.renderer.removeClass(document.body, overlayOpenClass);
            }
          }
        })
      ),
    { dispatch: false }
  );

  setOverlayOpen$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ComparisonActions.showOverlay),
        tap((action) => {
          if (this.isBrowser) {
            if (action.showOverlay) {
              this.renderer.addClass(document.body, overlayOpenClass);
            } else {
              this.renderer.removeClass(document.body, overlayOpenClass);
            }
          }
        })
      ),
    { dispatch: false }
  );

  compare$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ComparisonActions.compare),
        withLatestFrom(
          this.store.select(PageSelectors.productComparison),
          this.store.select(ComparisonSelectors.items)
        ),
        tap(([action, target, items]) => {
          this.gtag.pushEvent('product_comparison', [{ products: items.map((el) => el.title).join(', ') }]);
          this.router.navigate([target]);
        })
      ),
    { dispatch: false }
  );

  buildList$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ComparisonActions.buildList),
        withLatestFrom(this.store.select(ComparisonSelectors.forApi)),
        tap(([action, productsForApi]) => {
          this.store.dispatch(ComparisonActions.isLoading({ isLoading: true }));
          productsForApi.productIds = [...new Set(productsForApi.productIds)];
          if (productsForApi.productIds.length > 0) {
            this.apiService
              .postData([action.lang, 'product-comparison/'].join('/'), productsForApi)
              .pipe(
                tap((resp: ComparisonResponse) => {
                  const list = new Comparison();
                  list.read(resp.productComparison);
                  this.store.dispatch(
                    ComparisonActions.writeList({
                      resultList: list,
                    })
                  );
                  this.store.dispatch(ComparisonActions.isLoading({ isLoading: false }));
                  this.store.dispatch(ComparisonActions.initUpdateList());
                })
              )
              .subscribe();
          } else {
            this.store.dispatch(ComparisonActions.clearList());
            this.store.dispatch(ComparisonActions.isLoading({ isLoading: false }));
          }
        })
      ),
    { dispatch: false }
  );

  updateList$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ComparisonActions.initUpdateList),
        withLatestFrom(this.store.select(ComparisonSelectors.comparison)),
        tap(([action, resultList]) => {
          const newList = [];
          resultList.products.map((product) => {
            const newListItem = {
              id: product.id,
              title: product.title,
            };
            newList.push(newListItem);
          });

          this.store.dispatch(ComparisonActions.updateList({ comparisonListProducts: newList }));
        })
      ),
    { dispatch: false }
  );

  removeFromList$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ComparisonActions.removeFromList),
        withLatestFrom(this.store.select(ComparisonSelectors.comparison)),
        tap(([action, currentResultList]) => {
          const list = new Comparison();
          list.read(currentResultList.products.filter((e) => e.id !== action.id));
          this.store.dispatch(
            ComparisonActions.writeList({
              resultList: list,
            })
          );
        })
      ),
    { dispatch: false }
  );

  private readonly isBrowser: boolean = false;
  private readonly renderer: Renderer2;

  constructor(
    private readonly actions$: Actions,
    private readonly apiService: ApiService,
    private readonly translate: TranslateService,
    private readonly store: Store,
    private readonly gtag: GtagService,
    private readonly router: Router,
    private readonly rendererFactory: RendererFactory2,
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.renderer = rendererFactory.createRenderer(null, null);
  }
}
