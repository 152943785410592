import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {Observable} from 'rxjs';
import {ProductFilterActions, ProductFilterSelectors, SearchActions, SearchSelectors} from '@rz-gud/store';
import {Store} from '@ngrx/store';

@Component({
  selector: 'auto-suggest',
  templateUrl: './auto-suggest.component.html',
  styleUrls: ['./auto-suggest.component.scss']
})
export class AutoSuggestComponent {
  @ViewChild('suggestionList') suggestionList: ElementRef;
  @Input() productAutofill: boolean = false;
  @Input() productSearch: boolean = false;
  @Input() searchTerm: string = '';
  @Input() theme: 'light' | 'dark' = 'light';
  @Input() class: string = '';
  @Input() focus: number;
  @Output() closeAutoSuggest: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() search: EventEmitter<boolean> = new EventEmitter<boolean>();

  readonly suggestions$: Observable<Array<any>> = this.store.select(SearchSelectors.suggestions);
  readonly autofill$: Observable<Array<string>> = this.store.select(ProductFilterSelectors.autofillProductNames);

  constructor(private readonly store: Store) {
  }

  @HostBinding('attr.class') get hostClasses(): string {
    return [
      this.class,
      this.theme
    ].join(' ');
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter' && this.focus > 0) {
      const searchTerm = this.suggestionList.nativeElement.children[this.focus - 1].innerText;
      this.setSearchTerm(searchTerm);
    }
  }

  setSearchTerm(productName: string): void {
    if (this.productSearch) {
      this.store.dispatch(ProductFilterActions.setSearchTerm({term: productName}));
    } else {
      this.store.dispatch(SearchActions.setTerm({term: productName}));
    }
    this.closeAutoSuggest.emit(true);
    this.search.emit(true);
  }
}
