import {Component, OnInit} from '@angular/core';
import {StoryBlockModel} from './story-block.model';
import {Observable} from 'rxjs';
import {ContentElementModel} from '@conception/ngx-pimcore-connect';
import {Story} from '@rz-gud/models';
import {StoryService} from '@rz-gud/services';
import {Store} from '@ngrx/store';
import {PageSelectors} from '@rz-gud/store';

@Component({
  selector: 'ce-story-block',
  templateUrl: './story-block.component.html',
  styleUrls: ['./story-block.component.scss']
})
export class StoryBlockComponent implements OnInit {

  @ContentElementModel(StoryBlockModel) content: StoryBlockModel;

  stories$: Observable<Array<Story>>;
  storyOverview$: Observable<string> = this.store.select(PageSelectors.storyOverview);

  constructor(private readonly storyService: StoryService,
              private readonly store: Store) {
  }

  ngOnInit(): void {
    setTimeout(() => {
        this.stories$ = this.storyService.getStories({
          limit: 4,
          folder: this.content.folder?.path
        });
      }, 200
    );
  }

}
