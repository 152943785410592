<img *ngIf="image?.url"
     [src]="image?.url"
     [alt]="image?.meta?.alt"
     [title]="image?.meta?.title"
     class="know-how-teaser__image">

<div class="know-how-teaser__content">
  <p *ngIf="date" [innerHTML]="date | date: 'dd.MM.yyyy'" class="copy-2"></p>
  <h2 *ngIf="headline" [innerHTML]="headline" class="headline-3"></h2>
  <p *ngIf="copy" [innerHTML]="copy | truncatetext:130" class="copy"></p>
  <a *ngIf="link" [routerLink]="link.path" class="know-how-teaser__button">
    <gd-button [label]="link.title" color="azure"></gd-button>
  </a>
</div>
