import {animate, style, transition, trigger} from '@angular/animations';

export const scaleInCenter = trigger('scaleInCenter', [
  transition(':enter', [
    style({transform: 'scaleY(0)'}),
    animate('250ms cubic-bezier(.25, .46, .45, .94)', style({transform: 'scaleY(1)'}))
  ]),
  transition(':leave', [
    style({transform: 'scaleY(1)'}),
    animate('50ms cubic-bezier(.25, .46, .45, .94)', style({transform: 'scaleY(0)'}))
  ])
]);
