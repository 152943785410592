<h2 class="color--maya headline-3" [innerHTML]="'product-overview.headline' | translate"></h2>
<p class="color--maya" [innerHTML]="'product-overview.text' | translate"></p>

<div class="filter">
  <product-overview-property-filter></product-overview-property-filter>
  <product-overview-filter-tags></product-overview-filter-tags>
</div>

<div class="main">
  <product-overview-main-filter></product-overview-main-filter>
  <product-container></product-container>
</div>
