import { Deserializable, serialize, serializeArray } from '@conception/ts-class-deserializer';
import { PimImageCollection } from '@conception/ngx-pimcore-connect';

export class ProductDetailInterfaceTableItem extends Deserializable {
  @serialize() description: string;
  @serialize() name: string;
  @serialize() type: string;
}

export class ProductDetailInterfaceTab extends Deserializable {
  @serialize(PimImageCollection) image: PimImageCollection;
  @serializeArray(ProductDetailInterfaceTableItem) table: Array<ProductDetailInterfaceTableItem>;
}

class ProductDetailInterfaceDimensions extends Deserializable {
  @serialize() height: number;
  @serialize() width: number;
  @serialize() length: number;
}

export class ProductDetailInterfacesModel extends Deserializable {
  @serialize(ProductDetailInterfaceTab) back: ProductDetailInterfaceTab;
  @serialize(ProductDetailInterfaceTab) front: ProductDetailInterfaceTab;
  @serialize(ProductDetailInterfaceDimensions) deviceDimensions: ProductDetailInterfaceDimensions;

  showElement = (): boolean => this && ['front', 'back'].some(this.showTab);
  showTab = (tab: string): boolean =>
    this[tab] &&
    this[tab].image?.urls &&
    Object(this[tab].image?.urls).hasOwnProperty('default') &&
    Array.isArray(this[tab].table) &&
    this[tab].table.length > 0;
}
