import {Component, Input} from '@angular/core';
import {GDColors} from '@conception/ng-gud-ui';
import {Observable} from 'rxjs';
import {Breadcrumb} from '@rz-gud/interfaces';
import {Store} from '@ngrx/store';
import {LayoutSelectors, PageMode, PageSelectors} from '@rz-gud/store';
import {breadcrumbAnimation} from '@rz-gud/animations';

@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  animations: [breadcrumbAnimation]
})
export class BreadcrumbComponent {

  @Input() lineColor: 'silver-light' | 'silver-light-transparent' | 'dark' = 'silver-light';
  @Input() showBreadcrumb: boolean = true;
  @Input() showBreadcrumbMobile: boolean = true;

  readonly gdColors = GDColors;
  readonly breadcrumb$: Observable<Array<Breadcrumb>> = this.store.select(PageSelectors.breadcrumb);
  readonly breadcrumbColor$: Observable<'dark' | 'white'> = this.store.select(LayoutSelectors.breadcrumbColor);
  readonly startLink$: Observable<string> = this.store.select(PageSelectors.start);
  readonly productEntryLink$: Observable<string> = this.store.select(PageSelectors.productEntry);
  readonly productsLink$: Observable<string> = this.store.select(PageSelectors.productEntry);
  readonly pageMode$: Observable<PageMode> = this.store.select(LayoutSelectors.mode);

  constructor(private readonly store: Store) {
  }
}
