import {animate, style, transition, trigger} from '@angular/animations';

export const notificationBubbleAnimation = trigger('notificationBubble', [
  transition(':enter', [
    style({
      transform: 'translateY(30px) translateX(-10px) scale(0)'
    }),
    animate('400ms cubic-bezier(.25, .46, .45, .94)',
      style({
        transform: 'translate(0) scale(1)'
      }))
  ]),
  transition(':leave', [
    style({
      transform: 'scale(1)',
      filter: 'blur(0px)',
      opacity: 1
    }),
    animate('350ms cubic-bezier(.25, .46, .45, .94)',
      style({
        transform: 'scale(2)',
        filter: 'blur(4px)',
        opacity: 0
      }))
  ])
]);
