import { Component, OnInit } from '@angular/core';
import { StoryService } from '@rz-gud/services';
import { Story, StoryTag } from '@rz-gud/models';
import { ContentElementModel } from '@conception/ngx-pimcore-connect';
import { StoryOverviewModel } from './story-overview.model';

interface StoryFilter {
  technology: Array<StoryTag>;
  branch: Array<StoryTag>;
  region: Array<StoryTag>;
}

interface ActiveStoryFilter {
  technology: Array<any>;
  branch: Array<any>;
  region: Array<any>;
}

@Component({
  selector: 'ce-story-overview',
  templateUrl: './story-overview.component.html',
  styleUrls: ['./story-overview.component.scss'],
})
export class StoryOverviewComponent implements OnInit {
  @ContentElementModel(StoryOverviewModel) content: StoryOverviewModel;

  stories: Array<Story> = [];
  storiesToShow: Array<Story> = [];
  filters: StoryFilter = {
    technology: [],
    branch: [],
    region: [],
  };

  activeFilters: ActiveStoryFilter = {
    technology: [],
    branch: [],
    region: [],
  };

  constructor(private readonly storyService: StoryService) {}

  ngOnInit(): void {
    setTimeout(
      () =>
        this.storyService
          .getStories({ folder: this.content.folder.path })
          .subscribe((stories) => {
            this.stories = stories.map((story) =>
              new Story().deserialize(story)
            );
            this.storiesToShow = this.stories;
            this.buildFilter();
          }),
      200
    );
  }

  /**
   * Called after every user interaction to update the UI and filter the story list
   */
  setFilter(): void {
    this.storiesToShow = this.stories;
    if (
      this.activeFilters.technology.length ||
      this.activeFilters.branch.length ||
      this.activeFilters.region.length
    ) {
      Object.keys(this.filters).forEach((key) => {
        this.storiesToShow = this.filterForProperty(key);
      });
    }
  }

  /**
   * Called by dismissing a tag, updates the dropdowns and calls setFilter
   *
   * @param prop - the filter category
   * @param id - the id of the filter value
   */
  removeFilter(prop: string, id: number): void {
    this.activeFilters[prop] = this.activeFilters[prop].filter(
      (filter) => filter.id !== id
    );
    this.setFilter();
  }

  /**
   * ngFor-trackBy function for performance
   *
   * @param index
   * @param el
   */
  trackById(index: number, el: Story): number {
    return el.id;
  }

  /**
   * Initial function to get all filter-values from the {Story} objects
   *
   * @private
   */
  private buildFilter(): void {
    this.stories.forEach((story) => {
      Object.keys(this.filters).forEach(
        (key) => (this.filters[key] = this.filters[key].concat(story[key]))
      );
    });

    Object.keys(this.filters).forEach(
      (key) =>
        (this.filters[key] = [
          ...new Map(this.filters[key].map((item) => [item.id, item])).values(),
        ])
    );
  }

  /**
   * Filter the storiesToShow object regarding a filter category
   *
   * @param prop - the filter category
   * @private
   */
  private filterForProperty(prop: string): Array<any> {
    return this.activeFilters[prop].length
      ? this.storiesToShow.filter((story) =>
          story[prop].some((tag) =>
            this.activeFilters[prop].find((el) => el.id === tag.id)
          )
        )
      : this.storiesToShow;
  }
}
