import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HighlightTextService {
  processText(text: string, searchString: string, removeHtml: boolean = false): string {
    const initText = removeHtml ? this.removeHtmlTagFromString(text) : text;
    return this.highlightedText(initText, searchString);
  }

  private removeHtmlTagFromString(text: string): string {
    return text.replace(/<[^>]+>/g, '');
  }

  private highlightedText(text: string, searchString: string): string {
    const newSearchString = searchString
      .replace('(', '\\(').replace(')', '\\)')
      .replace('[', '\\[').replace(']', '\\]')
      .replace('{', '\\{').replace('}', '\\}')
      .replace('+', '\\+');
    return text.replace(new RegExp(newSearchString, 'gi'),
      (match) => '<mark>' + match + '</mark>');
  }
}
