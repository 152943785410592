import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { HubspotSelectors, PageSelectors } from '@rz-gud/store';
import { take, tap, withLatestFrom } from 'rxjs/operators';
import { SimpleType } from '@rz-gud/interfaces';

declare global {
  interface Window {
    jQuery: any;
  }
}

@Component({
  selector: 'hubspot-form',
  templateUrl: './hubspot-form.component.html',
  styleUrls: ['./hubspot-form.component.scss'],
})
export class HubspotFormComponent {
  elementId: string = '';

  constructor(private readonly store: Store) {
    this.elementId = 'f' + (Math.random() + 1).toString(36).substring(7);
  }

  @Input() set formId(id: string) {
    if (id && id !== '') {
      this.store
        .select(PageSelectors.hubspotPortalId)
        .pipe(
          withLatestFrom(this.store.select(HubspotSelectors.hiddenFields)),
          take(1),
          tap(([portalId, hiddenFields]) =>
            this.createForm(portalId, id, hiddenFields)
          )
        )
        .subscribe();
    }
  }

  private createForm(
    portalId: string,
    formId: string,
    hiddenFields: Array<SimpleType>
  ) {
    hbspt.forms.create({
      region: 'eu1',
      portalId,
      formId,
      target: '#' + this.elementId,
      onFormReady: (form: any) => {
        hiddenFields.forEach((field: SimpleType) => {
          window
            .jQuery(form[0].querySelector('input[name="' + field.label + '"]'))
            .val(field.value)
            .change();
        });
      },
    });
  }
}
