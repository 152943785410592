import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';
import {HeroTextModel} from './hero-text.model';
import {ContentElementModel} from '@conception/ngx-pimcore-connect';

@Component({
  selector: 'ce-hero-text',
  templateUrl: './hero-text.component.html',
  styleUrls: ['./hero-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeroTextComponent {

  @Input() @ContentElementModel(HeroTextModel) content: HeroTextModel | any;

  @Input() class: string = '';

  @HostBinding('class') get classes() {
    return [
      this.content.theme,
      this.content.alignment,
      this.class
    ].join(' ');
  }
}
