import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductFilterSet, SimpleFilter } from '@rz-gud/interfaces';
import { Store } from '@ngrx/store';
import { ProductFilterActions, ProductFilterSelectors } from '@rz-gud/store';

@Component({
  selector: 'product-overview-filter-tags',
  templateUrl: './filter-tags.component.html',
  styleUrls: ['./filter-tags.component.scss'],
})
export class FilterTagsComponent {
  readonly tags$: Observable<Array<ProductFilterSet>> = this.store.select(
    ProductFilterSelectors.filterObject
  );
  readonly searchTerm$: Observable<string> = this.store.select(
    ProductFilterSelectors.searchTerm
  );
  readonly showSearchTag$: Observable<boolean> = this.store.select(
    ProductFilterSelectors.productOverviewSearchHasFocus
  );

  constructor(private readonly store: Store) {}

  removeFilter(category: string, filter: SimpleFilter): void {
    this.store.dispatch(
      ProductFilterActions.removeFilter({ category, filter })
    );
  }

  clearSearchTerm(): void {
    this.store.dispatch(ProductFilterActions.clearSearchTerm());
    this.store.dispatch(ProductFilterActions.reloadAll());
  }
}
