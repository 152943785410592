import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '@conception/ngx-pimcore-connect';
import {map} from 'rxjs/operators';
import {environment} from '@rz-gud/environment';
import {ApiConfig} from '@rz-gud/interfaces';
import {Branch} from '@rz-gud/models';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class BranchService {

  constructor(private readonly apiService: ApiService,
              private readonly translate: TranslateService,
              private readonly router: Router) {
  }


  /**
   * Returns a shuffled array based on Fisher-Yates-Algorithm
   *
   * @param arr {Array<Branch>} - Array to be shuffled
   * @private
   */
  private static shuffle(arr: Array<Branch>): Array<Branch> {
    for (let i = arr.length -1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = arr[i];
      arr[i] = arr[j];
      arr[j] = temp;
    }
    return arr;
  }

  /**
   * Returns a list of branches
   *
   * @param config {ApiConfig} - Configuration object
   */
  getBranches(config: ApiConfig): Observable<Array<Branch>> {
    return this.apiService.postData([this.translate.currentLang, environment.endpoints.branches].join('/'), config).pipe(
      map(branches => branches['branches-overview']),
      map(branches => this.apiService.removeContentPath(branches)),
      map(branches => branches.map(branch => new Branch().deserialize(branch)))
    );
  }

  /**
   * Returns a randomized list of branches, excluding the current branch
   *
   * @param config {ApiConfig} - Configuration object
   * @param count {number} - The amount of random branches
   */
  getRandomBranches(config: ApiConfig, count: number): Observable<Array<Branch>> {
    return this.apiService.postData([this.translate.currentLang, environment.endpoints.branches].join('/'), config).pipe(
      map(branches => branches['branches-overview']),
      map(branches => this.apiService.removeContentPath(branches)),
      map(branches => branches.map(branch => new Branch().deserialize(branch))),
      map((branches: Array<Branch>) => branches.filter(branch => branch.detailPage.path !== this.router.url)),
      map((branches: Array<Branch>) => BranchService.shuffle(branches).slice(0, count))
    );
  }
}
