import {createAction, props} from '@ngrx/store';
import {Product, ObjectHit, PageHit} from '@rz-gud/models';

export const setTerm = createAction(
  '[Search Term] Set',
  props<{ term: string }>()
);

export const clearTerm = createAction(
  '[Search Term] Clear'
);

export const search = createAction(
  '[Search] Search now!'
);

export const setCmsTotal = createAction(
  '[Search] Set CMS total',
  props<{cmsTotal: number}>()
);

export const setCmsResults = createAction(
  '[Search] Set CMS results',
  props<{cmsResults: Array<ObjectHit | PageHit>}>()
);

export const triggerAppendCmsResults = createAction(
  '[Search] Triggering append CMS results'
);

export const triggerLoadAllCmsResults = createAction(
  '[Search] Triggering load all CMS results'
);

export const appendCmsResults = createAction(
  '[Search] Append CMS results',
  props<{cmsResults: Array<ObjectHit | PageHit>}>()
);

export const clearCmsResults = createAction(
  '[Search] Clear CMS results'
);

export const setProductTotal = createAction(
  '[Search] Set Product total',
  props<{productTotal: number}>()
);

export const setProductResults = createAction(
  '[Search] Set Product results',
  props<{productResults: Array<Product>}>()
);

export const triggerAppendProductResults = createAction(
  '[Search] Triggering append Product results'
);

export const triggerLoadAllProductResults = createAction(
  '[Search] Triggering load all Product results'
);

export const appendProductResults = createAction(
  '[Search] Append Product results',
  props<{productResults: Array<Product>}>()
);

export const clearProductResults = createAction(
  '[Search] Clear Product results'
);

export const toggleOverlay = createAction(
  '[Search Overlay] Toggle'
);

export const openOverlay = createAction(
  '[Search Overlay] Open'
);

export const closeOverlay = createAction(
  '[Search Overlay] Close'
);

export const setSuggestions = createAction(
  '[Auto Suggest] Set',
  props<{ hits: Array<Product | PageHit | ObjectHit> }>()
);

export const clearSuggestions = createAction(
  '[Auto Suggest] Clear'
);

export const setTab = createAction(
  '[Search] Set Tab',
  props<{ tab: 'cms' | 'products' }>()
);

export const exactSearch = createAction(
  '[Search] Exact Search',
  props<{ exactSearch: boolean }>()
);
