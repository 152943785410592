<overlay-container (closed)="close()">
  <div class="search slide-in-top">
    <div class="search__headline">{{'search.headline' | translate}}</div>
    <searchbar [productAutofill]="true"
               [setExactSearch]="exactSearch"
               [disableInText]="disableInText"
               (exact)="exactSearch = $event"
               autofillTheme="light" [placeholder]="'search.placeholder' | translate"></searchbar>
    <!--
    <gd-checkbox [label]="'search.exactSearch' | translate"
                 [checked]="exactSearch"
                 size="small"
                 [transparent]="true"
                 (isChecked)="exactSearch = $event; disableInText = $event;"></gd-checkbox>
                 -->
  </div>
  <gd-separator></gd-separator>
  <div class="popular-searches slide-in-bottom" *ngIf="(popularSearches$ | async) as popularSearches">
    <div class="popular-searches__headline">{{'search.popularSearches' | translate}}</div>

    <div class="popular-searches__container">
      <gd-pill-button [routerLink]='productOverviewLink$ | async'
                      (click)="close()"
                      color='maya-filled'
                      [label]="'search.showAllProducts' | translate"></gd-pill-button>
      <gd-pill-button
        *ngFor="let item of popularSearches"
        color="silver-light"
        [label]="item"
        (click)="search(item)"></gd-pill-button>
    </div>
  </div>
</overlay-container>
