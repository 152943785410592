<div class="filter">
  <ul class="horizontal-navigation">
    <li
      [class.horizontal-navigation__item--active]="filter === ''"
      [innerHTML]="'all' | translate"
      class="horizontal-navigation__item horizontal-navigation__item--first"
      (click)="changeFilter('')"></li>
    <li
      *ngFor="let letter of letters"
      [class.horizontal-navigation__item--active]="
        letter.toLowerCase() === filter
      "
      [class.horizontal-navigation__item--disabled]="
        !availableLetters.includes(letter)
      "
      [innerHTML]="letter"
      class="horizontal-navigation__item"
      (click)="changeFilter(letter)"></li>
  </ul>
</div>

<accordion-container>
  <gd-icon-box [color]="gdColors.GREEN" icon="campus-az"></gd-icon-box>
  <ng-container *ngFor="let entry of entriesToShow">
    <gd-accordion
      color="green"
      [id]="'c_' + entry.id"
      [active]="'c_' + entry.id === activeEntry"
      [title]="entry.title">
      <gd-accordion-entry
        [image]="entry.image"
        [copy]="entry.copy"
        [copyText]="entry.copyText"
        [video]="entry.video"></gd-accordion-entry>
    </gd-accordion>
  </ng-container>
</accordion-container>
