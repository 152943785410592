import {Component} from '@angular/core';
import {ContentElementModel} from '@conception/ngx-pimcore-connect';
import {ProductDetailRelatedProductsModel} from './product-detail-related-products.model';

@Component({
  selector: 'ce-product-detail-related-products',
  templateUrl: './product-detail-related-products.component.html',
  styleUrls: ['./product-detail-related-products.component.scss']
})
export class ProductDetailRelatedProductsComponent {
  @ContentElementModel(ProductDetailRelatedProductsModel) content: ProductDetailRelatedProductsModel;
}
