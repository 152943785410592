import {createAction, props} from '@ngrx/store';
import {SiteInfo, Breadcrumb} from '@rz-gud/interfaces';

export const setSiteInfo = createAction(
  '[Page] Set Site Info',
  props<{ siteInfo: SiteInfo }>()
);

export const clearSiteInfo = createAction(
  '[Page] Clear Site Info'
);

export const setBreadcrumb = createAction(
  '[Page] Set Breadcrumb',
  props<{ breadcrumb: Array<Breadcrumb> }>()
);

export const clearBreadcrumb = createAction(
  '[Page] Clear Breadcrumb'
);
