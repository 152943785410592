import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ContentElementModel } from '@conception/ngx-pimcore-connect';
import { CeHubspotFormModel } from '@rz-gud/content-elements/ce-hubspot-form/ce-hubspot-form.model';

@Component({
  selector: 'ce-hubspot-form',
  templateUrl: './ce-hubspot-form.component.html',
  styleUrls: ['./ce-hubspot-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CeHubspotFormComponent {
  @ContentElementModel(CeHubspotFormModel) content: CeHubspotFormModel;
}
