<h3 *ngIf="content.headline" [innerHTML]="content.headline"></h3>

<div class="teaser-blocks" *ngIf="content.blocks.length">
  <a *ngFor="let teaser of content.blocks" class="teaser" [routerLink]="teaser.link?.path">
    <img *ngIf="teaser.image?.url" [src]="teaser.image.url" [alt]="teaser.image.meta?.alt" [title]="teaser.image.meta?.title">

    <div class="teaser-content">
      <h4 *ngIf="teaser.headline"
          class="teaser-content__headline"
          [innerHTML]="teaser.headline"></h4>
      <div class="teaser-content__text" [innerHTML]="teaser.text"></div>
    </div>
  </a>
</div>
