import {Injectable} from '@angular/core';
import {HubspotActions} from '@rz-gud/store';
import {Store} from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class HubspotService {

  constructor(private readonly store: Store) {
  }

  inquiry(name: string, sku: string): void {
    this.setProduct(name, sku, 'produkt');
    this.store.dispatch(HubspotActions.openInquiryForm());
  }

  inquiryMultiple(products: Array<{ name: string; sku: string }>): void {
    this.setProducts(products, 'produkt');
    this.store.dispatch(HubspotActions.openInquiryForm());
  }


  productQuestion(name: string, sku: string): void {
    this.setProduct(name, sku, 'produkt');
    this.store.dispatch(HubspotActions.openQuestionForm());
  }

  private setProduct(name: string, sku: string, label: string): void {
    this.store.dispatch(HubspotActions.setHiddenFields({
      hiddenFields: [{label, value: name.trim() + ' (' + sku + ')'}]
    }));
  }

  private setProducts(products: Array<{ name: string; sku: string }>, label: string): void {
    this.store.dispatch(HubspotActions.setHiddenFields({
      hiddenFields: [{label, value: products.map(product => product.name.trim() + ' (' + product.sku + ')').join(', ')}]
    }));
  }

}
