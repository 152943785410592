<div class="know-how__header">
  <gd-simple-headline *ngIf="content.simpleHeadline" [content]="content.simpleHeadline"></gd-simple-headline>
</div>

<div class="know-how__container">
  <gd-icon-box [color]="gdColors.GREEN" [icon]="content.icon"></gd-icon-box>
  <div class="know-how__amount"
       [innerHTML]="'knowHowAmount' | translate:{'knowHowAmount': content.knowHows.length}"></div>
  <ng-container *ngFor="let knowHow of content.knowHows; index as i;">
    <campus-knowhow-teaser *ngIf="(i + 1) <= shownItems"
                  [image]="knowHow.image"
                  [date]="knowHow.date"
                  [headline]="knowHow.headline"
                  [copy]="knowHow.copy"
                  [link]="knowHow.link"></campus-knowhow-teaser>
  </ng-container>
</div>

<div class="know-how__more" *ngIf="showLoadMore">
  <gd-button (click)="ingresShownItems(12)"
             [label]="'showAmountMore' | translate:{'amount': 12}" color="azure-outline"></gd-button>
  <gd-button (click)="ingresShownItems(content.knowHows.length - currentlyShownAmount)"
             [label]="'showMoreALL' | translate:{'amount': content.knowHows.length}" color="azure"></gd-button>
</div>
