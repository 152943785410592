import { ExtraOptions, Routes } from '@angular/router';
import { MissingTranslationHandler, TranslateLoader, TranslateModuleConfig } from '@ngx-translate/core';
import { CustomMissingTranslationHandler, httpLoaderFactory } from '@rz-gud/classes';
import { HttpClient } from '@angular/common/http';
import { RootStoreConfig } from '@ngrx/store';
import { StoreDevtoolsOptions } from '@ngrx/store-devtools/src/config';
import { environment } from '@rz-gud/environment';
import { metaReducers } from '@rz-gud/store';
import { NgxPimcoreConnectConfig, PageBuilderComponent } from '@conception/ngx-pimcore-connect';

import {
  CopyComponent,
  FullscreenVideoComponent,
  ImageTextColumnsComponent,
  ImageTextComboComponent,
  IntroComponent,
  NumbersComponent,
  QuoteComponent,
  SeparatorComponent,
  WideBlockComponent,
} from '@conception/ng-gud-ui';
import { StartupComponent } from '@rz-gud/components/static';
import {
  AccordionComponent,
  BookmarksComponent,
  BranchBlockComponent,
  BranchOverviewComponent,
  BranchRelatedComponent,
  BranchTabpanelComponent,
  CampusBlockComponent,
  CampusEventDetailHeaderComponent,
  CampusEventDetailIcsComponent,
  CampusEventDetailImageComponent,
  CampusEventDetailLinkComponent,
  CampusEventDetailTextbodyComponent,
  CampusEventsComponent,
  CampusFaqComponent,
  CampusGlossaryComponent,
  CampusMediaComponent,
  CeDynamicsFormComponent,
  CeHubspotFormComponent,
  EasyModeResultComponent,
  EasyModeSelectorComponent,
  FactSliderComponent,
  FocusSliderComponent,
  HeroTextComponent,
  ImageComponent,
  ImageSliderComponent,
  ImageTextSliderComponent,
  InsertionComponent,
  LargeHeadlineComponent,
  PageNotFoundComponent,
  PreloaderComponent,
  ProductCategoriesComponent,
  ProductComparisonComponent,
  ProductDetailDownloadComponent,
  ProductDetailInterfacesComponent,
  ProductDetailRelatedProductsComponent,
  ProductDetailSliderComponent,
  ProductDetailTabpanelComponent,
  ProductDetailTextBlockComponent,
  ProductDisplayComponent,
  ProductFamiliesComponent,
  ProductHighlightSliderCEComponent,
  ProductHighlightsComponent,
  ProductOverviewComponent,
  ProductSearchComponent,
  SearchResultsComponent,
  SimpleHeadlineComponent,
  SimpleTilesComponent,
  SliderComponent,
  StoryBlockComponent,
  StoryOverviewComponent,
  StoryRelatedComponent,
  TeaserBlocksComponent,
  TextBlocksComponent,
  WideImageComponent,
} from '@rz-gud/content-elements';

export const pimcoreConnectConfig: NgxPimcoreConnectConfig = {
  apiUrl: environment.apiUrl,
  ssrUrl: environment.apiUrl,
  contentPath: environment.contentPath,
  pageEndpoint: environment.endpoints.pages,
  contentElements: {
    pageNotFound: PageNotFoundComponent,
    preloader: PreloaderComponent,
    contentArray: 'content_elements',
    content: {
      'accordion': AccordionComponent,
      'bookmarks': BookmarksComponent,
      'branch-block': BranchBlockComponent,
      'branch-overview': BranchOverviewComponent,
      'branch-related': BranchRelatedComponent,
      'branch-tabpanel': BranchTabpanelComponent,
      'campus-block': CampusBlockComponent,
      'campus-event-detail-header': CampusEventDetailHeaderComponent,
      'campus-event-detail-ics': CampusEventDetailIcsComponent,
      'campus-event-detail-image': CampusEventDetailImageComponent,
      'campus-event-detail-link': CampusEventDetailLinkComponent,
      'campus-event-detail-textbody': CampusEventDetailTextbodyComponent,
      'campus-events': CampusEventsComponent,
      'campus-faq': CampusFaqComponent,
      'campus-glossary': CampusGlossaryComponent,
      'campus-media-library': CampusMediaComponent,
      'copy': CopyComponent,
      'divider': SeparatorComponent,
      'dynamics-form': CeDynamicsFormComponent,
      'easy-mode-result': EasyModeResultComponent,
      'fact-slider': FactSliderComponent,
      'focus-slider': FocusSliderComponent,
      'hero-text': HeroTextComponent,
      'hubspot-form': CeHubspotFormComponent,
      'image': ImageComponent,
      'image-slider': ImageSliderComponent,
      'image-text-columns': ImageTextColumnsComponent,
      'image-text-combo': ImageTextComboComponent,
      'image-text-slider': ImageTextSliderComponent,
      'insertion': InsertionComponent,
      'interview': EasyModeSelectorComponent,
      'intro': IntroComponent,
      'large-headline': LargeHeadlineComponent,
      'numbers': NumbersComponent,
      'product-categories': ProductCategoriesComponent,
      'product-comparison': ProductComparisonComponent,
      'product-detail-downloads': ProductDetailDownloadComponent,
      'product-detail-header': ProductDetailSliderComponent,
      'product-detail-interface-tab-element': ProductDetailInterfacesComponent,
      'product-detail-related-products': ProductDetailRelatedProductsComponent,
      'product-detail-tab-element': ProductDetailTabpanelComponent,
      'product-detail-text-block': ProductDetailTextBlockComponent,
      'product-display': ProductDisplayComponent,
      'product-families': ProductFamiliesComponent,
      'product-highlight-slider': ProductHighlightSliderCEComponent,
      'product-highlights': ProductHighlightsComponent,
      'product-overview': ProductOverviewComponent,
      'product-search': ProductSearchComponent,
      'quote': QuoteComponent,
      'search-result': SearchResultsComponent,
      'simple-headline': SimpleHeadlineComponent,
      'simple-tiles': SimpleTilesComponent,
      'slider': SliderComponent,
      'story-block': StoryBlockComponent,
      'story-overview': StoryOverviewComponent,
      'story-related': StoryRelatedComponent,
      'teaser-blocks': TeaserBlocksComponent,
      'text-blocks': TextBlocksComponent,
      'video': FullscreenVideoComponent,
      'wide-block': WideBlockComponent,
      'wide-image': WideImageComponent,
    },
  },
};

export const appId = {
  appId: 'serverApp',
};

export const routes: Routes = [
  {
    path: '',
    component: StartupComponent,
  },
  {
    path: '**',
    component: PageBuilderComponent,
  },
];

export const routerConfig: ExtraOptions = {
  initialNavigation: 'enabled',
  useHash: false,
  anchorScrolling: 'enabled',
  onSameUrlNavigation: 'ignore',
};

export const translationConfig: TranslateModuleConfig = {
  defaultLanguage: 'en',
  useDefaultLang: true,
  missingTranslationHandler: {
    provide: MissingTranslationHandler,
    useClass: CustomMissingTranslationHandler,
  },
  loader: {
    provide: TranslateLoader,
    useFactory: httpLoaderFactory,
    deps: [HttpClient],
  },
};

export const storeConfig: RootStoreConfig<any> = {
  runtimeChecks: {
    strictStateImmutability: true,
    strictActionImmutability: true,
  },
  metaReducers,
};

export const storeDevToolsConfig: StoreDevtoolsOptions = {
  maxAge: 25,
  logOnly: environment.production,
};

export const searchConfig = {
  cmsResults: 20,
  productResults: 12,
};
