<div class="tile" *ngFor="let tile of content.tiles">
  <a *ngIf="tile.logoExternalLink?.path; else logo" [href]="tile.logoExternalLink?.path" target="_blank" rel="noopener">
    <ng-container [ngTemplateOutlet]="logo"></ng-container>
  </a>
  <ng-template #logo>
    <div class="tile__image-container" *ngIf="tile.logo?.url"
         [class.tile__image-container--right]="tile.logoPosition === position.RIGHT"
         [class.tile__image-container--left]="tile.logoPosition === position.LEFT"
    >
      <img [src]="tile.logo.url"
           [alt]="tile.logo.meta?.alt"
           [title]="tile.logo.meta?.title">
    </div>
  </ng-template>
  <div class="tile__text--container" *ngIf="tile.logo?.url">
    <div class="tile__text" *ngIf="tile.logoText" [innerHTML]="tile.logoText"></div>
  </div>
  <div class="tile__headline" [innerHTML]="tile.headline"></div>
  <div class="tile__content" [innerHTML]="tile.content"></div>
</div>
