import {Deserializable, serialize, serializeArray} from '@conception/ts-class-deserializer';
import {PimAsset, PimLink} from '@conception/ngx-pimcore-connect';

export class TeaserBlock extends Deserializable {
  @serialize() headline: string;
  @serialize() text: string;
  @serialize(PimLink) link: PimLink;
  @serialize(PimAsset) image: PimAsset;
}

export class TeaserBlocksModel extends Deserializable {
  @serialize() headline: string;
  @serializeArray(TeaserBlock) blocks: Array<TeaserBlock>;
}
