import {Component, ElementRef, Inject, Input, OnChanges, PLATFORM_ID, SimpleChanges} from '@angular/core';
import {GDColors} from '@conception/ng-gud-ui';
import SwiperCore, {Grid, SwiperOptions} from 'swiper';
import {isPlatformBrowser} from '@angular/common';
import {Branch} from '@rz-gud/models';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {PageSelectors} from '@rz-gud/store';

SwiperCore.use([Grid]);

@Component({
  selector: 'branch-slider',
  templateUrl: './branch-slider.component.html',
  styleUrls: ['./branch-slider.component.scss']
})
export class BranchSliderComponent implements OnChanges {

  @Input() sliderConfig: SwiperOptions = {
    spaceBetween: 6,
    slidesPerView: 2,
    grid: {
      rows: 1
    },
    breakpoints: {
      1020: {
        spaceBetween: 8,
        slidesPerView: 4,
        grid: {
          rows: 1
        }
      },
      1580: {
        spaceBetween: 13,
        slidesPerView: 4,
        grid: {
          rows: 1
        }
      }
    }
  };

  @Input() showOverviewButton: boolean = true;
  @Input() maxSlides: any = 0;

  items: Array<Branch | any> = [];
  currentSlide: number = 1;
  showNavigation: boolean = true;
  gdColors = GDColors;
  branchesOverview$: Observable<string> = this.store.select(PageSelectors.branchesOverview);

  private readonly isBrowser: boolean = false;

  constructor(private readonly el: ElementRef,
              private readonly store: Store,
              @Inject(PLATFORM_ID) private platformId: Record<string, unknown>) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  @Input() set branches(items: Array<Branch | any>) {
    if (items) {
      this.items = items;
      this.setup();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setup();
  }

  setCurrentSlide(event): void {
    this.currentSlide = +event.realIndex + 1;
  }

  setup(): void {
    if (this.isBrowser) {
      this.showNavigation = this.maxSlides > 1;
    }
  }
}
