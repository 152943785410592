import {Deserializable, serialize, serializeArray} from '@conception/ts-class-deserializer';
import {PimAsset, PimLink} from '@conception/ngx-pimcore-connect';

export class HighlightSlide extends Deserializable {
  @serialize() headline: string;
  @serialize() icon: string;
  @serialize() tag: string;
  @serialize(PimLink) link: PimLink;
  @serialize(PimAsset) image: PimAsset;
}

export class HighlightSliderModel extends Deserializable {
  @serialize() headline: string;
  @serializeArray(HighlightSlide) slides: Array<HighlightSlide>;
}

