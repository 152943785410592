import {createSelector} from '@ngrx/store';
import {NavigationState, State} from '../reducers';
import {NavigationItem} from '@rz-gud/interfaces';

export const select = (state: State) => state.navigation;

export const navigationItem = (key: string) => createSelector<State,[NavigationState],NavigationItem>(
  select,
  (navigation: NavigationState) => navigation.items[key]
);
