import {Component, ElementRef, Inject, Input, OnChanges, PLATFORM_ID} from '@angular/core';
import {Product} from '@rz-gud/models';
import SwiperCore, {Grid, SwiperOptions} from 'swiper';
import {GDColors} from '@conception/ng-gud-ui';
import {Store} from '@ngrx/store';
import {isPlatformBrowser} from '@angular/common';

SwiperCore.use([Grid]);

@Component({
  selector: 'product-slider',
  templateUrl: './product-slider.component.html',
  styleUrls: ['./product-slider.component.scss']
})
export class ProductSliderComponent implements OnChanges {

  @Input() sliderConfig: SwiperOptions = {
    spaceBetween: 6,
    slidesPerView: 2,
    grid: {
      rows: 1
    },
    centerInsufficientSlides: true,
    breakpoints: {
      767: {
        spaceBetween: 8,
        slidesPerView: 3,
        grid: {
          rows: 1
        },
        centerInsufficientSlides: true
      },
      1580: {
        spaceBetween: 13,
        slidesPerView: 3,
        grid: {
          rows: 1
        },
        centerInsufficientSlides: true
      }
    }
  };

  @Input() maxSlides: any = 0;

  items: Array<Product>;
  currentSlide: number = 1;
  showNavigation: boolean = true;
  gdColors = GDColors;

  private readonly isBrowser: boolean = false;

  constructor(private readonly el: ElementRef,
              private readonly store: Store,
              @Inject(PLATFORM_ID) private platformId: Record<string, unknown>) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  @Input() set products(items: Array<Product>) {
    if (items) {
      this.items = items;
      this.maxSlides = items.length;
      setTimeout(() => this.setup(), 500);
    }
  }

  ngOnChanges(): void {
    this.setup();
  }

  setCurrentSlide(event): void {
    this.currentSlide = +event.realIndex + 1;
  }

  setup(): void {
    if (this.isBrowser) {
      this.showNavigation = this.maxSlides > 1;
    }
  }
}
