import {animate, animateChild, query, style, transition, trigger} from '@angular/animations';

export const ngIfAnimation = trigger('ngIfAnimation', [
  transition(':enter, :leave', [
    query('@*', animateChild())
  ])
]);

export const breadcrumbAnimation = trigger('breadcrumbAnimation', [
  transition(':enter', [
    style({display: 'none'}),
    animate('0ms 150ms ease', style({display: 'flex'}))
  ])
]);
