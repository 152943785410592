import { Component, HostListener, Input } from '@angular/core';
import { GDColors } from '@conception/ng-gud-ui';
import { fadeInOut } from '@rz-gud/animations';

@Component({
  selector: 'how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.scss'],
  animations: [fadeInOut],
})
export class HowItWorksComponent {
  @Input() content: any;
  readonly gdColors = GDColors;
  activeHotspot: number = -1;

  @HostListener('click', ['$event']) onClick(e: PointerEvent) {
    if (this.activeHotspot > -1) {
      setTimeout(() => (this.activeHotspot = -1), 10);
    }
  }

  setActiveHotspot(i: number): void {
    if (this.activeHotspot !== i) {
      setTimeout(
        () =>
          this.activeHotspot === i
            ? (this.activeHotspot = -1)
            : (this.activeHotspot = i),
        20
      );
    }
  }
}
