import { Component, Input, OnInit } from '@angular/core';
import { Product } from '@rz-gud/models';
import { GDColors } from '@conception/ng-gud-ui';
import { Store } from '@ngrx/store';
import {
  BookmarkActions,
  BookmarkSelectors,
  ComparisonActions,
  ComparisonSelectors,
  PageSelectors,
  DynamicsSelectors,
} from '@rz-gud/store';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { HubspotService, DynamicsService } from '@rz-gud/services';
import { environment } from '@rz-gud/environment';
import { take } from 'rxjs/operators';

@Component({
  selector: 'product-teaser',
  templateUrl: './product-teaser.component.html',
  styleUrls: ['./product-teaser.component.scss'],
})
export class ProductTeaserComponent implements OnInit {
  @Input() product: Product;
  @Input() isSkeleton: boolean = false;
  gdColors = GDColors;
  amIBookmarked$: Observable<boolean>;
  amIOnComparisonList$: Observable<boolean>;
  comparisonListFilled$: Observable<boolean>;
  isLoading$: Observable<boolean>;
  detailLink$: Observable<string>;
  env = environment;
  productInquiryFormHostName$: Observable<string>;
  productInquiryFormWebsiteId$: Observable<string>;
  productInquiryFormBlockId$: Observable<string>;
  productQuestionFormHostName$: Observable<string>;
  productQuestionFormWebsiteId$: Observable<string>;
  productQuestionFormBlockId$: Observable<string>;

  constructor(
    private readonly store: Store,
    private readonly hubspotService: HubspotService,
    private readonly dynamicsService: DynamicsService,
    readonly translate: TranslateService
  ) {}

  ngOnInit(): void {
    if (this.product) {
      this.amIBookmarked$ = this.store.select(BookmarkSelectors.amIBookmarked(+this.product.id));
      this.amIOnComparisonList$ = this.store.select(ComparisonSelectors.amIOnComparisonList(+this.product.id));
      this.comparisonListFilled$ = this.store.select(ComparisonSelectors.filled);

      this.productInquiryFormHostName$ = this.store.select(DynamicsSelectors.productInquiryFormHostName);
      this.productInquiryFormWebsiteId$ = this.store.select(DynamicsSelectors.productInquiryFormWebsiteId);
      this.productInquiryFormBlockId$ = this.store.select(DynamicsSelectors.productInquiryFormBlockId);

      this.productQuestionFormHostName$ = this.store.select(DynamicsSelectors.productQuestionFormHostName);
      this.productQuestionFormWebsiteId$ = this.store.select(DynamicsSelectors.productQuestionFormWebsiteId);
      this.productQuestionFormBlockId$ = this.store.select(DynamicsSelectors.productQuestionFormBlockId);

      this.detailLink$ = this.store.select(PageSelectors.productDetail);
      this.isLoading$ = this.store.select(ComparisonSelectors.isLoading);
    }
  }

  handleBookmark(): void {
    this.store.dispatch(BookmarkActions.toggleProduct({ id: +this.product.id }));
  }

  putToComparisonList(): void {
    this.store.dispatch(
      ComparisonActions.addToList({
        id: +this.product.id,
        title: this.product.headline(),
      })
    );
    this.store.dispatch(ComparisonActions.showOverlay({ showOverlay: true }));
  }

  removeFromComparisonList(): void {
    this.store.dispatch(ComparisonActions.removeFromList({ id: +this.product.id }));
  }

  question(): void {
    this.productQuestionFormWebsiteId$.pipe(take(1)).subscribe((websiteId) => {
      this.productQuestionFormHostName$.pipe(take(1)).subscribe((hostName) => {
        this.productQuestionFormBlockId$.pipe(take(1)).subscribe((blockId) => {
          if (websiteId && hostName && blockId) {
            this.dynamicsQuestion([{ name: this.product.headline(), sku: this.product.artikelnummer }]);
          } else {
            this.hubspotService.productQuestion(this.product.headline(), this.product.artikelnummer);
          }
        });
      });
    });
  }

  request(): void {
    this.productInquiryFormWebsiteId$.pipe(take(1)).subscribe((websiteId) => {
      this.productInquiryFormHostName$.pipe(take(1)).subscribe((hostName) => {
        this.productInquiryFormBlockId$.pipe(take(1)).subscribe((blockId) => {
          if (websiteId && hostName && blockId) {
            this.dynamicsInquiry([{ name: this.product.headline(), sku: this.product.artikelnummer }]);
          } else {
            this.hubspotService.inquiry(this.product.headline(), this.product.artikelnummer);
          }
        });
      });
    });
  }

  dynamicsQuestion(products: Array<{ name: string; sku: string }>): void {
    this.dynamicsService.productQuestion(products);
  }

  dynamicsInquiry(products: Array<{ name: string; sku: string }>): void {
    this.dynamicsService.inquiry(products);
  }
}
