import {Component, Input} from '@angular/core';
import {PimAsset, PimLink} from '@conception/ngx-pimcore-connect';

@Component({
  selector: 'campus-knowhow-teaser',
  templateUrl: './campus-knowhow-teaser.component.html',
  styleUrls: ['./campus-knowhow-teaser.component.scss']
})
export class CampusKnowhowTeaserComponent {

  /**
   * Teaser Image
   */
  @Input() image: PimAsset | any;

  /**
   * Teaser keyword
   */
  @Input() date: Date | any;

  /**
   * Teaser headline
   */
  @Input() headline: string = '';

  /**
   * Teaser description
   */
  @Input() copy: string = '';

  /**
   * Teaser Button
   */
  @Input() link: PimLink | any;
}
