import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {ContentElementModel, ScrollToService} from '@conception/ngx-pimcore-connect';
import {FAQModel} from './campus-faq.model';
import {Category, FAQ} from '@rz-gud/models';
import {Subscription} from 'rxjs';
import {GDColors} from '@conception/ng-gud-ui';
import {CampusService} from '@rz-gud/services';
import {ActivatedRoute} from '@angular/router';
import {isPlatformBrowser} from '@angular/common';
import {take} from 'rxjs/operators';

@Component({
  selector: 'ce-campus-faq',
  templateUrl: './campus-faq.component.html',
  styleUrls: ['./campus-faq.component.scss']
})
export class CampusFaqComponent implements OnInit, OnDestroy {

  @ContentElementModel(FAQModel) content: FAQModel;

  gdColors = GDColors;
  entries: Array<FAQ> = [];
  categories: Array<Category> = [];
  activeCategory: Category | undefined;
  activeEntry: string = null;

  private sub: Subscription;
  private readonly isBrowser: boolean = true;

  constructor(private readonly campusService: CampusService,
              private activatedRoute: ActivatedRoute,
              private scrollToService: ScrollToService,
              @Inject(PLATFORM_ID) private platformId: Record<string, unknown>) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    this.getData();
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  isActive(entry: FAQ): boolean {
    return entry.category.filter(el => el.value === this.activeCategory?.value).length > 0 || this.activeCategory?.value === 'all';
  }

  getData(): void {
    this.sub = this.campusService.getFAQEntries({folder: this.content.campusFAQ.path}).subscribe(entries => {
      this.entries = entries;
      this.categories = this.campusService.getUniqueCategories(this.entries, true, 'faq');
      this.activeCategory = this.categories[0];

      this.activatedRoute.queryParams.pipe(take(1)).subscribe(params => {
        if (this.isBrowser && params['scroll-to']) {
          setTimeout(() => this.scrollToService.scrollTo(document.getElementById(params['scroll-to'])), 1000);
          this.activeEntry = params['scroll-to'];
        }
      });
    });
  }

  changeCategory(categories: Array<Category>): void {
    this.activeCategory = categories.length ? categories[0] : undefined;
  }


  trackByHeadline(index: number, el: FAQ): string {
    return el.headline;
  }



}
