import {Component, OnDestroy, OnInit} from '@angular/core';
import {GDColors} from '@conception/ng-gud-ui';
import {ContentElementModel} from '@conception/ngx-pimcore-connect';
import {EasyModeResultModel} from './easy-mode-result.model';
import {Story, CampusEvent, MediaLibraryItem} from '@rz-gud/models';
import {ProductCategoriesModel} from '../product-categories/product-categories.model';
import {WideImageModel} from '../wide-image/wide-image.model';
import {SimpleHeadlineModel} from '../simple-headline/simple-headline.model';
import {Store} from '@ngrx/store';
import {EasymodeService} from '@rz-gud/services';
import {EasyModeActions, LayoutActions} from '@rz-gud/store';
import {take, tap} from 'rxjs/operators';

@Component({
  selector: 'ce-easy-mode-result',
  templateUrl: './easy-mode-result.component.html',
  styleUrls: ['./easy-mode-result.component.scss']
})
export class EasyModeResultComponent implements OnInit, OnDestroy {

  @ContentElementModel(EasyModeResultModel) content: EasyModeResultModel;

  gdColors = GDColors;
  answers: Array<any> = [];
  stories: Array<Story> = [];
  mediaLibraryItems: Array<MediaLibraryItem> = [];
  events: Array<CampusEvent> = [];
  productCategories: ProductCategoriesModel;
  wideImage: WideImageModel;
  simpleHeadline: SimpleHeadlineModel;

  constructor(private readonly store: Store,
              private readonly easymodeService: EasymodeService) {
    this.store.dispatch(EasyModeActions.setStage({stage: 0}));
    this.store.dispatch(LayoutActions.setBreadcrumbColor({breadcrumbColor: 'white'}));
  }

  ngOnDestroy(): void {
    this.store.dispatch(LayoutActions.setBreadcrumbColor({breadcrumbColor: 'dark'}));
  }

  ngOnInit(): void {
    this.easymodeService.getResults().pipe(
      take(1),
      tap(result => this.answers = this.easymodeService.getAnswerBlock(result)),
      tap(result => this.wideImage = this.easymodeService.getImage(result)),
      tap(result => this.simpleHeadline = this.easymodeService.getHeadline(result)),
      tap(result => this.stories = this.easymodeService.getStories(result)),
      tap(result => this.mediaLibraryItems = this.easymodeService.getMediaItems(result)),
      tap(result => this.productCategories = this.easymodeService.getProductCategories(result)),
      tap(result => this.events = this.easymodeService.getEvents(result)),
      tap(() => this.store.dispatch(EasyModeActions.clear()))
    ).subscribe();
  }
}
