import { createSelector } from '@ngrx/store';
import { SearchState, State } from '../reducers';
import { ObjectHit, PageHit, Product } from '@rz-gud/models';
import { searchConfig } from '@rz-gud/config';

export const select = (state: State) => state.search;

export const term = createSelector<State, [SearchState], string>(
  select,
  (search: SearchState) => search.term
);

export const isOverlayOpen = createSelector<State, [SearchState], boolean>(
  select,
  (search: SearchState) => search.overlay
);

export const suggestions = createSelector<
  State,
  [SearchState],
  Array<PageHit | ObjectHit | Product>
>(select, (search: SearchState) =>
  search?.suggestions.map((el) => {
    if (el?.urlSlug) {
      return new Product().deserialize(el);
    } else if (el?.type === 'PageHit') {
      return new PageHit().deserialize(el);
    } else {
      return new ObjectHit().deserialize(el);
    }
  })
);

export const cmsResults = createSelector<
  State,
  [SearchState],
  Array<PageHit | ObjectHit>
>(select, (search: SearchState) =>
  search?.cmsResults
    .map((el) => {
      if (el?.type === 'PageHit') {
        return new PageHit().deserialize(el);
      } else {
        return new ObjectHit().deserialize(el);
      }
    })
    .filter((el, index, array) => {
      if (el instanceof PageHit) {
        return (
          array.findIndex((t) => {
            if (t instanceof PageHit) {
              return t.pagePath === el.pagePath;
            }
            return false;
          }) === index
        );
      }
      if (el instanceof ObjectHit) {
        return (
          array.findIndex((t) => {
            if (t instanceof ObjectHit) {
              return t.id === el.id;
            }
            return false;
          }) === index
        );
      }
      return true;
    })
);

export const cmsTotal = createSelector<State, [SearchState], number>(
  select,
  (search: SearchState) => search.cmsTotal
);

export const cmsOffset = createSelector<State, [SearchState], number>(
  select,
  (search: SearchState) => search.cmsResults.length / searchConfig.cmsResults
);

export const productResults = createSelector<
  State,
  [SearchState],
  Array<Product>
>(select, (search: SearchState) =>
  search.productResults.map((el) => new Product().deserialize(el))
);

export const productTotal = createSelector<State, [SearchState], number>(
  select,
  (search: SearchState) => search.productTotal
);

export const productOffset = createSelector<State, [SearchState], number>(
  select,
  (search: SearchState) =>
    search.cmsResults.length / searchConfig.productResults + 1
);

export const activeTab = createSelector<
  State,
  [SearchState],
  'cms' | 'products'
>(select, (search: SearchState) => search.tab);

export const exactSearch = createSelector<State, [SearchState], boolean>(
  select,
  (search: SearchState) => search.exactSearch
);
