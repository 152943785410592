import { NgModule } from '@angular/core';
import {
  BrowserModule,
  BrowserTransferStateModule,
} from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from './shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import {
  ComparisonOverlayComponent,
  ContactBarComponent,
  ContactComponent,
  FooterComponent,
  HeaderComponent,
  LanguageSwitchOverlayComponent,
  SearchOverlayComponent,
  StartupComponent,
  ToTopComponent,
} from '@rz-gud/components/static';
import { effects, reducers } from '@rz-gud/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { FormsModule } from '@angular/forms';
import { NgxPimcoreConnectModule } from '@conception/ngx-pimcore-connect';
import {
  appId,
  pimcoreConnectConfig,
  routerConfig,
  routes,
  storeConfig,
  storeDevToolsConfig,
  translationConfig,
} from '@rz-gud/config';
import { EffectsModule } from '@ngrx/effects';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { FormOverlayComponent } from './components/static/overlays/form-overlay/form-overlay.component';
import { DynamicsFormOverlayComponent } from './components/static/overlays/dynamics-form-overlay/dynamics-form-overlay.component';
import { PimInlineLinkInterceptor } from './interceptors/pim-inline-link.interceptor';
import { PimCmsTreeInterceptor } from './interceptors/pim-cms-tree.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    ContactComponent,
    StartupComponent,
    SearchOverlayComponent,
    ContactBarComponent,
    ComparisonOverlayComponent,
    LanguageSwitchOverlayComponent,
    ToTopComponent,
    FormOverlayComponent,
    DynamicsFormOverlayComponent,
  ],
  imports: [
    BrowserModule.withServerTransition(appId),
    CommonModule,
    SharedModule,
    BrowserTransferStateModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    TranslateModule.forRoot(translationConfig),
    RouterModule.forRoot(routes, routerConfig),
    StoreModule.forRoot(reducers, storeConfig),
    EffectsModule.forRoot(effects),
    TransferHttpCacheModule,
    StoreDevtoolsModule.instrument(storeDevToolsConfig),
    NgxPimcoreConnectModule.forRoot(pimcoreConnectConfig),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PimInlineLinkInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PimCmsTreeInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
