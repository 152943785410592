import {Injectable} from '@angular/core';
import {ApiService, PimAsset} from '@conception/ngx-pimcore-connect';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {EasyModeSelectorAnswerModel} from '@rz-gud/content-elements/easy-mode-selector/easy-mode-selector.model';
import {EasyModeSelectors} from '@rz-gud/store';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '@rz-gud/environment';
import {map, take, tap} from 'rxjs/operators';
import {WideImageModel} from '@rz-gud/content-elements/wide-image/wide-image.model';
import {SimpleHeadlineModel} from '@rz-gud/content-elements/simple-headline/simple-headline.model';
import {CampusEvent, MediaLibraryItem, ProductCategory, Story} from '@rz-gud/models';
import {ProductCategoriesModel} from '@rz-gud/content-elements/product-categories/product-categories.model';
import {HelperService} from './helper.service';
import {GtagService} from '@rz-gud/services/gtag.service';

@Injectable({
  providedIn: 'root'
})
export class EasymodeService {

  private readonly answers$: Observable<Array<EasyModeSelectorAnswerModel>>;

  constructor(private readonly apiService: ApiService,
              private readonly translate: TranslateService,
              private readonly helper: HelperService,
              private readonly store: Store,
              private readonly gtag: GtagService) {
    this.answers$ = this.store.select(EasyModeSelectors.answers);
  }

  getResults(): Observable<any> {
    const currentAnswers: { answerIds: Array<number> } = {
      answerIds: []
    };
    this.answers$.pipe(
      take(1),
      tap(answers => this.gtag.pushEvent(
        'easymode-interview',
        answers.map((answer: EasyModeSelectorAnswerModel, index) => ({['answer' + (index + 1)]: answer.label}))
      )),
      map(answers => answers.map(answer => answer.id)),
      tap(answers => currentAnswers.answerIds = answers)
    ).subscribe();

    return this.apiService.postData([
      this.translate.currentLang,
      environment.endpoints.interview
    ].join('/'), currentAnswers);
  }

  getAnswerBlock(raw: Array<any>): Array<any> {
    if (raw.length) {
      return raw.map(el => ({answer: el.answer, icon: el.icon.value}));
    } else {
      return [];
    }
  }

  getImage(raw: Array<any>): WideImageModel {
    if (raw.length) {
      return new WideImageModel().deserialize({
        image: new PimAsset().deserialize({url: raw[0].branches[0].teaserImage?.urls.BranchTeaserLandscape}),
        icon: raw[0].branches[0].icon.value
      });
    } else {
      return undefined;
    }
  }

  getHeadline(raw: Array<any>): SimpleHeadlineModel {
    if (raw.length) {
      return new SimpleHeadlineModel().deserialize({
        headline: raw[0].branches[0].title.replace(raw[0].branches[0].title.split(' ', 2)[0], ''),
        headlineHighlight: raw[0].branches[0].title.split(' ', 2)[0],
        intro: raw[0].branches[0].content[0].headline + raw[0].branches[0].content[0].text
      });
    } else {
      return undefined;
    }
  }

  getStories(raw: Array<any>): Array<Story> {
    if (raw.length) {
      return this.helper.addUnique([].concat(...raw.map(el => el.stories)), 'id').map(
        story => new Story().deserialize(story)
      );
    } else {
      return [];
    }
  }

  getHighlightProducts(raw: Array<any>): void {
    // highlightProduct
  }

  getMediaItems(raw: Array<any>): Array<MediaLibraryItem> {
    if (raw.length) {
      return this.helper.addUnique([].concat(...raw.map(el => el.mediathek)), 'id').map(
        item => new MediaLibraryItem().deserialize(item)
      );
    } else {
      return [];
    }
  }

  getProductCategories(raw: Array<any>): ProductCategoriesModel {
    if (raw.length) {
      const elements: Array<ProductCategory> = this.helper.addUnique([].concat(...raw.map(el => el.productCategories)), 'id').map(
        item => {
          const temp = new ProductCategory().deserialize(item);
          temp.backgroundColor = 'silver-light';
          return temp;
        }
      );

      if (elements.length) {
        return new ProductCategoriesModel().deserialize({
          headline: this.translate.instant('easymode.product-categories'),
          elements
        });
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  getEvents(raw: Array<any>): Array<CampusEvent> {
    if (raw.length) {
      return this.helper.addUnique([].concat(...raw.map(el => el.events)), 'id').map(
        item => new CampusEvent().deserialize(item)
      );
    } else {
      return [];
    }
  }
}
