import {Component, Input} from '@angular/core';
import {MediaLibraryItem} from '@rz-gud/models';
import {GDColors} from '@conception/ng-gud-ui';

@Component({
  selector: 'media-library-container',
  templateUrl: './media-library-container.component.html',
  styleUrls: ['./media-library-container.component.scss']
})
export class MediaLibraryContainerComponent {
  @Input() items: Array<MediaLibraryItem> = [];
  readonly gdColors = GDColors;

  trackByTitle(index: number, el: MediaLibraryItem): string {
    return el.title;
  }
}
