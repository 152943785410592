import {Deserializable, serialize, serializeArray} from '@conception/ts-class-deserializer';
import {PimLink} from '@conception/ngx-pimcore-connect';

export class CampusTopic extends Deserializable {
  @serialize() category: string;
  @serializeArray() detailReference: Array<any>;
  @serialize() detailReferenceLabel: string;
  @serialize() link: PimLink;
  @serialize() title: string;
  @serialize() icon: string;
}
