import {Injectable} from '@angular/core';
import slugify from 'slugify';
import {ApiService} from '@conception/ngx-pimcore-connect';
import {TranslateService} from '@ngx-translate/core';
import {take, tap, withLatestFrom} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {SearchActions} from '@rz-gud/store';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private readonly apiService: ApiService,
              private readonly store: Store,
              private readonly translate: TranslateService) {
  }

  autoSuggest(input: string, productSearchOnly: boolean): void {
    // this.getCmsData(input, 0, 3).pipe(
    //   withLatestFrom(this.getProductData(input, 1, 3)),
    //   tap(([products, cms]) => {
    //     this.store.dispatch(SearchActions.setSuggestions({
    //       hits: [
    //         ...products.entries.map(el => el.toPlainObject()),
    //         ...cms.entries.map(el => el.toPlainObject())
    //       ]
    //     }));
    //   })
    // ).subscribe();
  }

  getProductData(searchEntry: string, offset: number, limit: number, exactSearch: boolean) {
    const tempSearchEntry: string = searchEntry.startsWith('"') && searchEntry.endsWith('"')
      ? searchEntry.slice(1, -1)
      : searchEntry;
    this.apiService.getData(
      this.translate.currentLang
      + '/find/product?term=' + tempSearchEntry
      + '&perPage=' + limit
      + '&page=' + offset
      + '&exactSearch=' + exactSearch
    ).pipe(
      take(1),
      tap(result => {
        if (offset !== 1) {
          this.store.dispatch(SearchActions.appendProductResults({productResults: result.products}));
        } else {
          this.store.dispatch(SearchActions.setProductResults({productResults: result.products}));
          this.store.dispatch(SearchActions.setProductTotal({productTotal: result.totalCount}));
        }
      })
    ).subscribe();
  }

  getCmsData(query: string, offset: number, limit: number, exactSearch: boolean): void {
    const tempQuery: string = query.startsWith('"') && query.endsWith('"') ? query.slice(1, -1) : query;
    this.apiService.postData(
      this.translate.currentLang + '/find/content',
      {query: tempQuery, offset, limit, exactSearch}).pipe(
      take(1),
      tap(result => {
        result.searchHits.map(entry => {
          if (entry.branchPage && entry.pagePath.split('/').length > (entry.pagePath.split('/').length - 1)) {
            const pagePathParts = entry.pagePath.split('/');
            entry.pagePath = entry.pagePath.replace('/' + pagePathParts[pagePathParts.length - 1],
              '?scroll-to=' + slugify(entry.title, {lower: true}));
          }
        });
      }),
      tap(result => {
        if (offset !== 0) {
          this.store.dispatch(SearchActions.appendCmsResults({cmsResults: result.searchHits}));
        } else {
          this.store.dispatch(SearchActions.setCmsTotal({cmsTotal: result.totalCount}));
          this.store.dispatch(SearchActions.setCmsResults({cmsResults: result.searchHits}));
        }
      })
    ).subscribe();
  }
}
