<h3 [innerHTML]="'product.downloads' | translate"></h3>

<ng-container *ngFor="let category of content.downloadBlocks">
  <gd-accordion *ngIf="category.downloads.length > 0"
                [icon]="category.icon"
                [title]="category.downloadBlockTitle"
                [color]="'yellow'">
    <ul class="download-list">
      <li *ngFor="let download of category.downloads">
        <a class="download" [href]="download.url" target="_blank">
          <gd-icon class="download__icon" color="azure" icon="simple-arrow"></gd-icon>
          <span class="download__label" [innerHTML]="download.title"></span>
        </a>
      </li>
    </ul>
  </gd-accordion>
</ng-container>
