import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {PageSelectors} from '@rz-gud/store';
import {takeWhile, tap} from 'rxjs/operators';

@Component({
  selector: 'view-startup',
  template: '<preloader></preloader>'
})
export class StartupComponent {
  constructor(private readonly router: Router,
              private readonly store: Store) {
    this.store.select(PageSelectors.start).pipe(
      takeWhile(result => result === undefined, true),
      tap(result => {
        if (result) {
          this.router.navigate([result]);
        }
      })
    ).subscribe();
  }
}
