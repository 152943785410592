import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ComparisonActions, ComparisonSelectors } from '@rz-gud/store';
import { ButtonSizes, GDColors } from '@conception/ng-gud-ui';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'comparison-overlay',
  templateUrl: './comparison-overlay.component.html',
  styleUrls: ['./comparison-overlay.component.scss'],
})
export class ComparisonOverlayComponent {
  buttonSizes = ButtonSizes;
  gdColors = GDColors;

  comparisonList$: Observable<Array<{ id: number; title: string }>> = this.store.select(ComparisonSelectors.items);

  constructor(private readonly store: Store, private readonly translate: TranslateService) {}

  remove(id: number): void {
    this.store.dispatch(ComparisonActions.removeFromList({ id }));
  }

  hideOverlay(): void {
    this.store.dispatch(ComparisonActions.showOverlay({ showOverlay: false }));
    this.store.dispatch(ComparisonActions.clearList());
  }

  compare(): void {
    this.store.dispatch(ComparisonActions.compare());
    this.store.dispatch(ComparisonActions.buildList({ lang: this.translate.currentLang }));
  }
}
