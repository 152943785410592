import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, PLATFORM_ID, ViewChild } from '@angular/core';
import { ContentElementModel } from '@conception/ngx-pimcore-connect';
import { BookmarksModel } from '@rz-gud/content-elements/bookmarks/bookmarks.model';
import { Product } from '@rz-gud/models';
import { GDColors } from '@conception/ng-gud-ui';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { DynamicsService, HubspotService, ProductService } from '@rz-gud/services';
import { isPlatformBrowser } from '@angular/common';
import { BookmarkActions, BookmarkSelectors, DynamicsSelectors, PageActions, PageSelectors } from '@rz-gud/store';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { GtagService } from '@rz-gud/services/gtag.service';

@Component({
  selector: 'ce-bookmarks',
  templateUrl: './bookmarks.component.html',
  styleUrls: ['./bookmarks.component.scss'],
})
export class BookmarksComponent implements AfterViewInit, OnDestroy {
  @ContentElementModel(BookmarksModel) content: BookmarksModel;
  @ViewChild('shareInput') shareInputRef: ElementRef;

  productInquiryFormHostName$: Observable<string>;
  productInquiryFormWebsiteId$: Observable<string>;
  productInquiryFormBlockId$: Observable<string>;

  preFetchProducts: Array<Product> = [];
  products: Array<Product> = [];
  idList: Array<number> = [];
  gdColors = GDColors;
  allProducts$: Observable<string> = this.store.select(PageSelectors.productEntry);
  shareLinkPrefix: string = '';
  shareLinkParams: string = '';
  shareMode: boolean = false;
  triggeredCopy: boolean = false;
  private readonly isBrowser: boolean = false;
  private sub: Subscription;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private readonly store: Store,
    private readonly gtag: GtagService,
    private readonly translate: TranslateService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly hubspotService: HubspotService,
    private readonly productService: ProductService,
    private readonly dynamicsService: DynamicsService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.setup();
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  ngAfterViewInit() {
    this.productInquiryFormHostName$ = this.store.select(DynamicsSelectors.productInquiryFormHostName);
    this.productInquiryFormWebsiteId$ = this.store.select(DynamicsSelectors.productInquiryFormWebsiteId);
    this.productInquiryFormBlockId$ = this.store.select(DynamicsSelectors.productInquiryFormBlockId);

    this.store.dispatch(
      PageActions.setBreadcrumb({
        breadcrumb: [
          { url: '/', title: '' },
          { url: '/', title: this.translate.instant('watchlist') },
        ],
      })
    );
  }

  setup(): void {
    if (this.activatedRoute.snapshot.queryParamMap.has('share')) {
      this.shareMode = true;
      this.gtag.pushEvent('bookmark_share_link_request', [
        { link: this.activatedRoute.snapshot.queryParamMap.get('share') },
      ]);
      this.idList = atob(this.activatedRoute.snapshot.queryParamMap.get('share'))
        .split(',')
        .map((el) => parseInt(el, 10));
      this.buildProducts();
      if (this.isBrowser) {
        this.shareLinkPrefix = window.location.pathname;
      }
    } else {
      this.shareMode = false;
      this.sub = this.store.select(BookmarkSelectors.select).subscribe((result) => {
        this.idList = result.products;
        this.buildProducts();
        if (this.isBrowser) {
          this.shareLinkPrefix = document.location.toString() + '?share=';
        }
      });
    }
  }

  buildProducts(): void {
    this.preFetchProducts = [];
    this.shareLinkParams = btoa(this.idList.join(','));

    setTimeout(
      () =>
        this.idList.forEach((id) => {
          this.productService
            .getProduct(id)
            .pipe(take(1))
            .subscribe((product) => this.preFetchProducts.push(product));
        }),
      200
    );

    this.products = this.preFetchProducts;
  }

  copyLink(): void {
    this.shareInputRef.nativeElement.select();
    document.execCommand('copy');
    this.shareInputRef.nativeElement.setSelectionRange(0, 0);
    this.triggeredCopy = true;

    this.gtag.pushEvent('bookmark_share_link_copied', [{ link: this.shareInputRef.nativeElement.value }]);
    setTimeout(() => (this.triggeredCopy = false), 5000);
  }

  requestProducts(): void {
    this.productInquiryFormWebsiteId$.pipe(take(1)).subscribe((websiteId) => {
      this.productInquiryFormHostName$.pipe(take(1)).subscribe((hostName) => {
        this.productInquiryFormBlockId$.pipe(take(1)).subscribe((blockId) => {
          if (websiteId && hostName && blockId) {
            this.dynamicsService.inquiry(
              this.products.map((product: Product) => ({
                name: product.headline(),
                sku: product.artikelnummer,
              }))
            );
          } else {
            this.hubspotService.inquiryMultiple(
              this.products.map((product) => ({
                name: product.headline(),
                sku: product.artikelnummer,
              }))
            );
          }
        });
      });
    });
  }

  resetList(): void {
    this.store.dispatch(BookmarkActions.clear());
  }

  /**
   * ngFor-trackBy function for performance
   *
   * @param index
   * @param el
   */
  trackById(index: number, el: Product): number {
    return el.id;
  }

  select(e: EventTarget): void {
    (e as HTMLInputElement).select();
  }
}
