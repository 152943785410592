import {Component, HostListener} from '@angular/core';

@Component({
  selector: 'to-top',
  templateUrl: './to-top.component.html',
  styleUrls: ['./to-top.component.scss']
})
export class ToTopComponent {

  visible: boolean = false;

  @HostListener('window:scroll', ['$event']) scroll(e) {
    this.visible = window.scrollY > (window.innerHeight * (window.innerWidth >= 1921 ? 1 : 2));
  }

  scrollTop(): void {
    window?.scrollTo(0, 0);
  }
}
