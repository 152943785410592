import {createSelector} from '@ngrx/store';
import {LanguageNavigationState, State} from '../reducers';
import {LanguageNavigationItem} from '@rz-gud/interfaces';

export const select = (state: State) => state.languageNavigation;

export const items = createSelector<State,[LanguageNavigationState],Array<LanguageNavigationItem>>(
  select,
  (state: LanguageNavigationState) => state.items
);

export const current = createSelector<State,[LanguageNavigationState],LanguageNavigationItem>(
  select,
  (state: LanguageNavigationState) => state.current
);

export const all = createSelector<State,[LanguageNavigationState],Array<LanguageNavigationItem>>(
  select,
  (state: LanguageNavigationState) => [state.current, ...state.items]
);

