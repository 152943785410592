import {
  Deserializable,
  serialize,
  serializeArray,
} from '@conception/ts-class-deserializer';
import { PimImageCollection, PimLink } from '@conception/ngx-pimcore-connect';
import { SimpleType } from '@rz-gud/interfaces';

export class ProductCategory extends Deserializable {
  @serialize() backgroundColor: string;
  @serialize() buttonLabel: string;
  @serialize() id: string;
  @serialize() displayName: string;
  @serialize() excerpt: string;
  @serialize() overviewLink: PimLink | any;
  @serialize() filterID: string;
  @serialize(PimImageCollection) teaserImage: PimImageCollection;
  @serializeArray() icons: Array<SimpleType>;
  @serialize() icon: SimpleType;
}
