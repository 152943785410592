import {Component, OnInit} from '@angular/core';
import {ContentElementModel} from '@conception/ngx-pimcore-connect';
import {ProductFamiliesModel} from './product-families.model';
import {GDColors} from '@conception/ng-gud-ui';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {PageSelectors} from '@rz-gud/store';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'ce-product-families',
  templateUrl: './product-families.component.html',
  styleUrls: ['./product-families.component.scss'],
})
export class ProductFamiliesComponent implements OnInit {
  @ContentElementModel(ProductFamiliesModel) content: ProductFamiliesModel;

  readonly productOverviewLink$: Observable<string> = this.store.select(PageSelectors.productOverview);
  readonly gdColors = GDColors;
  categoryHeadline: string = '';

  constructor(private readonly store: Store,
              private readonly translate: TranslateService) {
  }

  ngOnInit(): void {
    const suffix =
      this.content.families.length === 1
        ? this.translate.instant('category')
        : this.translate.instant('categories');

    this.categoryHeadline = this.content.families.length + ' ' + suffix;
  }
}
