import {createReducer, on} from '@ngrx/store';
import {BookmarkActions} from '../actions';

export interface BookmarkState {
  products: Array<number>;
}

export const initialState: BookmarkState = {
  products: []
};

export const bookmarkReducer = createReducer(
  initialState,
  on(BookmarkActions.bookmarkProduct, (state, {id}) => ({
      ...state, products: array_unique([...state.products, id])
    })
  ),
  on(BookmarkActions.bookmarkProducts, (state, {ids}) => ({
      ...state, products: array_unique(state.products.concat(ids))
    })
  ),
  on(BookmarkActions.removeProduct, (state, {id}) => ({
      ...state, products: array_unique(state.products.filter(el => el !== id))
    })
  ),
  on(BookmarkActions.removeProducts, (state, {ids}) => ({
      ...state, products: array_unique(state.products.filter(el => !ids.includes(el)))
    })
  ),
  on(BookmarkActions.toggleProduct, (state, {id}) => {
    const tempId: number = +id;
      const newBookmarks: Array<number> = state.products.includes(tempId)
        ? state.products.filter(el => el !== tempId)
        : [...state.products, tempId];

      return {
        ...state, products: array_unique(newBookmarks)
      };
    }
  ),
  on(BookmarkActions.clear, () => initialState)
);

const array_unique = (array: Array<any>) => [...new Set(array)];

