<h3 class="product-category__headline" [innerHTML]="content.headline"></h3>
<div
  class="product-category__container"
  [class.product-category__container--centered]="content.elements.length < 4">
  <product-teaser-ui
    *ngFor="let category of content.elements"
    [headline]="category.displayName"
    [copy]="category.excerpt"
    [image]="category.teaserImage.getImage('ProductTeaser')"
    [color]="category.backgroundColor"
    [icon]="category.icon"
    [showBookmark]="false">
    <div class="buttons">
      <a
        *ngIf="category.filterID"
        [routerLink]="productOverviewLink$ | async"
        [queryParams]="{ filter: category.filterID }">
        <gd-button
          [label]="'filterProducts' | translate"
          color="azure"></gd-button>
      </a>

      <a
        *ngIf="category.overviewLink?.overviewLink?.url"
        [routerLink]="category.overviewLink.overviewLink.url">
        <gd-button
          [label]="'toOverview' | translate"
          [color]="category.backgroundColor === 'yellow' ? 'azure' : 'white'"></gd-button>
      </a>
    </div>
  </product-teaser-ui>
</div>
