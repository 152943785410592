import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ContentElementModel} from '@conception/ngx-pimcore-connect';
import {ImageModel} from '@rz-gud/content-elements/image/image.model';

@Component({
  selector: 'ce-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageComponent {
  @ContentElementModel(ImageModel) content: ImageModel;
}
