<ng-container *ngIf="(easymode$ | async) as easymode">
  <div class="step"
       *ngFor="let step of qaBlock; let i = index"
       [class.step--active]="i === easymode.stage - 1"
       [class.step--done]="easymode.answers[i]?.label"
       (click)="jumpToStage(i + 1)">
    {{i + 1 | number: '2.0-0'}}
    &nbsp;&nbsp;&ndash;&nbsp;&nbsp;
    {{step.questionShortHand}}
    <gd-icon icon="checkmark" color="maya" *ngIf="easymode.answers[i]?.label"></gd-icon>
  </div>

  <div [class]="'helper helper--position-' + (easymode.stage - 1)"></div>

  <div class="answers">
    <div class="answer">{{easymode.answers[0]?.label}}</div>
    <div class="answer">{{easymode.answers[1]?.label}}</div>
    <div class="answer">{{easymode.answers[2]?.label}}</div>
  </div>
</ng-container>
