import {Component, OnInit} from '@angular/core';
import {ContentElementModel} from '@conception/ngx-pimcore-connect';
import {ProductDetailTextBlockModel} from './product-detail-text-block.model';
import {GDColors, IntroModel} from '@conception/ng-gud-ui';

@Component({
  selector: 'ce-product-detail-text-block',
  templateUrl: './product-detail-text-block.component.html',
  styleUrls: ['./product-detail-text-block.component.scss']
})
export class ProductDetailTextBlockComponent implements OnInit {

  @ContentElementModel(ProductDetailTextBlockModel) content: ProductDetailTextBlockModel;
  intro: IntroModel;
  readonly gdColors = GDColors;

  ngOnInit() {
    if (this.content?.text) {
      this.intro = this.content.getIntro();
    }
  }
}
