import {Component, Input} from '@angular/core';
import {Branch} from '@rz-gud/models';
import {GDColors} from '@conception/ng-gud-ui';

@Component({
  selector: 'branch-teaser',
  templateUrl: './branch-teaser.component.html',
  styleUrls: ['./branch-teaser.component.scss']
})
export class BranchTeaserComponent {
  @Input() branch: Branch;
  @Input() layout: 'portrait' | 'landscape' = 'portrait';
  readonly gdColors = GDColors;
}
