import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {ContentElementModel, ScrollToService,} from '@conception/ngx-pimcore-connect';
import {GDColors} from '@conception/ng-gud-ui';
import {GlossaryModel} from './campus-glossary.model';
import {Glossary} from '@rz-gud/models';
import {CampusService} from '@rz-gud/services';
import {ActivatedRoute} from '@angular/router';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'ce-campus-glossary',
  templateUrl: './campus-glossary.component.html',
  styleUrls: ['./campus-glossary.component.scss'],
})
export class CampusGlossaryComponent implements OnInit {
  @ContentElementModel(GlossaryModel) content: GlossaryModel;
  entriesToShow: Array<Glossary> = [];

  gdColors = GDColors;
  letters: Array<string> = [...Array(26).keys()].map((i) =>
    String.fromCharCode(i + 97)
  );
  availableLetters: Array<string> = [];
  filter: string = '';
  activeEntry: string = null;

  private entries: Array<Glossary> = [];
  private readonly isBrowser: boolean = true;

  constructor(
    private readonly camupsService: CampusService,
    private activatedRoute: ActivatedRoute,
    private scrollToService: ScrollToService,
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    if (this.isBrowser) {
      setTimeout(() => this.getData(), 200);
    }
  }

  changeFilter(item: string): void {
    this.filter = item;
    this.entriesToShow =
      this.filter === ''
        ? this.entries
        : this.entries.filter(
          (entry) => entry.getFirstLetter() === this.filter
        );
  }

  private getData(): void {
    this.camupsService
      .getGlossaryEntries({folder: this.content.campusGlossary.path, sortBy: 'title'})
      .subscribe((entries) => {
        this.entries = this.entriesToShow = entries;
        this.availableLetters = [
          ...new Set(this.entries.map((entry) => entry.getFirstLetter())),
        ];

        this.activatedRoute.queryParams.subscribe((params) => {
          if (this.isBrowser && (params['scroll-to'] || params.filter)) {
            if (this.availableLetters.includes(params.filter)) {
              this.changeFilter(params.filter);
            }
            setTimeout(() => {
              if (document.getElementById(params['scroll-to'])) {
                this.scrollToService.scrollTo(
                  document.getElementById(params['scroll-to'])
                );
              }
            }, 1000);
            this.activeEntry = params['scroll-to'];
          }
        });
      });
  }
}
