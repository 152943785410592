import {Deserializable, serialize} from '@conception/ts-class-deserializer';
import {PimImageCollection, PimVideo} from '@conception/ngx-pimcore-connect';
import {Category} from './category.model';

export class MediaLibraryItem extends Deserializable {
  @serialize() id: number;
  @serialize() title: string;
  @serialize() description: string;
  @serialize(PimVideo) video: PimVideo;
  @serialize(PimImageCollection) teaserImage: PimImageCollection;
  @serialize(Category) category: Category;
}
