import {createAction, props} from '@ngrx/store';
import {PimVideo} from '@conception/ngx-pimcore-connect';

export const setVideoPopup = createAction(
  '[VideoPopup] Set Video Popup',
  props<{ video: PimVideo }>()
);

export const clearVideoPopup = createAction(
  '[VideoPopup] Clear Video Popup'
);
