import {createReducer, on} from '@ngrx/store';
import {NavigationActions} from '../actions';
import {Menu, NavigationItems} from '@rz-gud/interfaces';

export interface NavigationState {
  entry: Array<Menu>;
  items: NavigationItems;
}

export const initialState: NavigationState = {
  entry: [],
  items: {}
};

export const navigationReducer = createReducer(
  initialState,
  on(NavigationActions.setEntry, (state, {entry}) => ({...state, entry})),
  on(NavigationActions.setItem, (state, {key, item}) => ({
    ...state,
    items: {
      ...state.items,
      [key]: item
    }
  })),
);

