import {Component, HostListener, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {ContentElementModel} from '@conception/ngx-pimcore-connect';
import {BranchRelatedModel} from './branch-related.model';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {PageSelectors} from '@rz-gud/store';
import {GDColors} from '@conception/ng-gud-ui';
import {Branch} from '@rz-gud/models';
import {BranchService} from '@rz-gud/services';
import {SwiperOptions} from 'swiper';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'ce-branch-related',
  templateUrl: './branch-related.component.html',
  styleUrls: ['./branch-related.component.scss']
})
export class BranchRelatedComponent implements OnInit {

  @ContentElementModel(BranchRelatedModel) content: BranchRelatedModel;
  branchesOverview$: Observable<string>;
  branches$: Observable<Array<Branch>>;
  readonly gdColors = GDColors;

  sliderConfig: SwiperOptions = {
    spaceBetween: 8,
    slidesPerView: 2,
    breakpoints: {
      767: {
        spaceBetween: 8,
        slidesPerView: 3
      }
    }
  };
  maxSlides: number = 0;

  private readonly isBrowser: boolean = false;

  constructor(private readonly store: Store,
              private readonly branchService: BranchService,
              @Inject(PLATFORM_ID) private platformId: Record<string, unknown>) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.getMaxSlides();
  }

  @HostListener('window:resize')
  onWindowResize() {
    this.getMaxSlides();
  }

  ngOnInit(): void {
    this.branches$ = this.branchService.getRandomBranches({folder: this.content.folder.path}, 3);
    this.branchesOverview$ = this.store.select(PageSelectors.branchesOverview);
  }

  getMaxSlides(): void {
    if (this.isBrowser) {
      this.maxSlides = window.innerWidth >= 767 ? 1 : 2;
    }
  }
}
