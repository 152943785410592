import { Component, OnInit } from '@angular/core';
import { ContentElementModel } from '@conception/ngx-pimcore-connect';
import { ProductDetailInterfacesModel } from './product-detail-interfaces.model';
import { TranslateService } from '@ngx-translate/core';

interface tab {
  id: 'front' | 'back';
  label: string;
}

@Component({
  selector: 'ce-product-detail-interfaces',
  templateUrl: './product-detail-interfaces.component.html',
  styleUrls: ['./product-detail-interfaces.component.scss'],
})
export class ProductDetailInterfacesComponent implements OnInit {
  @ContentElementModel(ProductDetailInterfacesModel) content: ProductDetailInterfacesModel;

  public width: string = '100%';

  public tabs: Array<tab> = [
    { id: 'front', label: this.translate.instant('product.interfaces.front') },
    { id: 'back', label: this.translate.instant('product.interfaces.back') },
  ];

  public activeTab: number;
  public activeTabId: 'front' | 'back';

  constructor(private translate: TranslateService) {}

  ngOnInit() {
    this.tabs = this.tabs.filter((tab) => this.content.showTab(tab.id));
    this.activeTab = this.tabs.findIndex((tab) => !!tab.id);
    this.activeTabId = this.getActiveTabId();

    if (this.content?.deviceDimensions?.width) {
      this.width = `${this.content.deviceDimensions.width * 0.5}%`;
    }
  }

  onActiveTabChange() {
    this.activeTabId = this.getActiveTabId();
  }

  private getActiveTabId() {
    return this.tabs[this.activeTab].id;
  }
}
