<swiper (indexChange)="setIndex($event)" [effect]="'fade'" [fadeEffect]="{crossFade: true}">
  <ng-template swiperSlide *ngFor="let slide of slides">
    <div class="inner-slide" *ngIf="staticLayout" [style.backgroundImage]="'url(' + slide.image.url + ')'"></div>

    <div class="inner-slide" *ngIf="!staticLayout" [style.backgroundImage]="'url(' + slide.image.url + ')'">
      <h3 class="inner-slide__headline" *ngIf="!staticLayout" [innerHTML]="slide.headline"></h3>
      <p class="inner-slide__subline" *ngIf="!staticLayout" [innerHTML]="slide.subline"></p>
      <a *ngIf="slide.link?.path && !staticLayout" [routerLink]="slide.link.path">
        <gd-simple-button [color]="gdColors.SILVERLIGHT"
                          [label]="slide.link?.text ? slide.link.text : 'viewNow' | translate"></gd-simple-button>
      </a>
    </div>
  </ng-template>
</swiper>

<div class="static" *ngIf="staticLayout">
  <ng-content></ng-content>
</div>

<gd-note *ngIf="note"
      [color]="noteLayout === 2 ? gdColors.AZURE : gdColors.SILVERLIGHT"
      [class.position-2]="noteLayout === 2"
      [textFlip]="noteLayout === 2"
      [reverseLine]="noteLayout === 2"
      [label]="note"></gd-note>

<gd-circle-indicator *ngIf="slides.length > 1"
                          [progress]="progress"
                          [currentIndex]="currentIndex"></gd-circle-indicator>
