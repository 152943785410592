import {animate, AnimationAnimateMetadata, state, style, transition, trigger} from '@angular/animations';

const timing: string = '120ms';
const toWidth: AnimationAnimateMetadata = animate(`${timing} cubic-bezier(.23,.09,.94,.33)`, style({width: '100%'}));
const toEnd: AnimationAnimateMetadata = animate(`${timing} cubic-bezier(.23,.09,.94,.33)`);

export const modeSwitchAnimation = trigger('modeSwitch', [
  state('easy', style({
    width: '50%',
    left: '-1px',
    right: 'auto'
  })),
  state('expert', style({
    width: '50%',
    left: 'auto',
    right: '-1px'
  })),
  transition('easy => expert', [
    toWidth,
    style({right: 0, left: 'auto'}),
    toEnd
  ]),
  transition('expert => easy', [
    toWidth,
    style({left: 0, right: 'auto'}),
    toEnd
  ])
]);
