import {Component, HostListener, Input, OnInit} from '@angular/core';
import {GDColors} from '@conception/ng-gud-ui';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {PageSelectors, ProductFilterActions} from '@rz-gud/store';

@Component({
  selector: 'flyout-item',
  templateUrl: './flyout-item.component.html',
  styleUrls: ['./flyout-item.component.scss']
})
export class FlyoutItemComponent {

  @Input() data: any;
  @Input() category: any;
  @Input() color: string;
  @Input() showLink: boolean = true;

  isHovered: boolean = false;

  readonly productOverviewLink$: Observable<string> = this.store.select(PageSelectors.productOverview);
  readonly gdColor = GDColors;

  constructor(private readonly store: Store) {
  }


  @HostListener('mouseenter') mouseEnter() {
    this.isHovered = true;
  }

  @HostListener('mouseleave') mouseLeave() {
    this.isHovered = false;
  }

  clearSearchTerm(): void {
    this.store.dispatch(ProductFilterActions.clearSearchTerm());
  }
}
