import {Component, Input} from '@angular/core';
import {ContentElementModel} from '@conception/ngx-pimcore-connect';
import {GDColors} from '@conception/ng-gud-ui';
import {HighlightSliderModel} from './campus-highlight-slider.model';

@Component({
  selector: 'ce-campus-highlight-slider',
  templateUrl: './campus-highlight-slider.component.html',
  styleUrls: ['./campus-highlight-slider.component.scss']
})
export class CampusHighlightSliderComponent {
  @Input() @ContentElementModel(HighlightSliderModel) content: HighlightSliderModel;
  gdColors = GDColors;
}
