import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ContentElementModel} from '@conception/ngx-pimcore-connect';
import {CampusEventDetailTextbodyModel} from './campus-event-detail-textbody.model';

@Component({
  selector: 'ce-campus-event-detail-textbody',
  templateUrl: './campus-event-detail-textbody.component.html',
  styleUrls: ['./campus-event-detail-textbody.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CampusEventDetailTextbodyComponent {
  @ContentElementModel(CampusEventDetailTextbodyModel) content: CampusEventDetailTextbodyModel;

}
