import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { LayoutActions, LayoutSelectors, PageMode, PageSelectors } from '@rz-gud/store';
import { Observable } from 'rxjs';
import { modeSwitchAnimation } from '@rz-gud/animations';
import { Router } from '@angular/router';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'mode-switch',
  templateUrl: './mode-switch.component.html',
  styleUrls: ['./mode-switch.component.scss'],
  animations: [modeSwitchAnimation]
})
export class ModeSwitchComponent {
  pageMode$: Observable<PageMode> = this.store.select(LayoutSelectors.mode);
  showTutorial$: Observable<boolean> = this.store.select(LayoutSelectors.showPageModeTutorial);
  pageModes = PageMode;

  constructor(private readonly store: Store,
              private readonly router: Router) {
  }

  setPageMode(mode: PageMode): void {
    this.store.select((mode === PageMode.EASY) ? PageSelectors.start : PageSelectors.productEntry).pipe(
      take(1),
      tap(link => this.router.navigate([link]))
    ).subscribe();
  }

  dismissPageMode(): void {
    this.store.dispatch(LayoutActions.dismissPageModeTutorial());
  }
}
