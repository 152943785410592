<div class="content">
  <mode-switch></mode-switch>
  <ng-container *ngFor="let link of data;let i = index">
    <flyout-item *ngIf="link.pages?.length <= 0; else showFlyout"
                 [data]="link.linkBrick"
                 [color]="color"
                 [showLink]="true"></flyout-item>
    <ng-template #showFlyout>
      <flyout-item [data]="link.linkBrick"
                   [color]="color"
                   [showLink]="false"
                   (click)="currentList = i"></flyout-item>
    </ng-template>
  </ng-container>
    <div *ngIf="currentList >= 0 && data[currentList]?.pages"
         class="flyout"
         [ngClass]="color"
         @slideInOutLeft>
      <div class="flyout__content">
        <gd-simple-button [label]="'back' | translate"
                          icon="simple-arrow"
                          [color]="color === 'silver-light' ? gdColors.AZURE : gdColors.SILVERLIGHT"
                          (click)="currentList = null"></gd-simple-button>
        <div class="flyout__title" [innerHTML]="data[currentList].title"></div>
        <ng-container *ngFor="let link of data[currentList].pages; let i = index">
          <flyout-item *ngIf="!(link?.linkBrick?.headline && link?.linkBrick?.copy); else showTeaser"
                       [data]="link.linkBrick"
                       [category]="link.productCategory?.length ? link.productCategory[0] : undefined"
                       [color]="color"
                       [showLink]="true"></flyout-item>
          <ng-template #showTeaser>
            <flyout-item [data]="link.linkBrick"
                         [color]="color"
                         [showLink]="false"
                         (click)="currentTeaser = i"></flyout-item>
          </ng-template>
        </ng-container>
        <a *ngIf="data[currentList].linkBrick.brick_type === 'navigationLinkInternal' || data[currentList].linkBrick.brick_type === 'NavigationLinkInternal'; else externalLink"
           [routerLink]="data[currentList].linkBrick.pageLink.pageLink.url">
          <gd-button [label]="data[currentList].linkBrick.buttonLabel" color="maya"></gd-button>
        </a>
        <ng-template #externalLink>
          <a [href]="data[currentList].externalLink.direct" [target]="data[currentList].externalLink.target">
            <gd-button [label]="data[currentList].linkBrick.buttonLabel" color="maya"></gd-button>
          </a>
        </ng-template>
      </div>
    </div>

    <div *ngIf="currentTeaser >= 0 && data[currentList]?.pages[currentTeaser]?.linkBrick"
         class="teaser"
         [ngClass]="color"
         @slideInOutLeft>
      <gd-simple-button [label]="'back' | translate"
                        icon="simple-arrow"
                        [color]="color === 'silver-light' ? gdColors.AZURE : gdColors.SILVERLIGHT"
                        (click)="currentTeaser = null"></gd-simple-button>
      <div class="teaser__title" [innerHTML]="data[currentList]?.pages[currentTeaser]?.linkBrick.pageLink.pageLink.name"></div>
      <flyout-teaser [data]="data[currentList]?.pages[currentTeaser]?.linkBrick" [color]="color"></flyout-teaser>
    </div>
</div>
