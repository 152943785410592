import {Deserializable, serialize, serializeArray} from '@conception/ts-class-deserializer';
import {SimpleType} from '@rz-gud/interfaces';

const masterSort = [
  'video',
  'signale',
  'uebertragung',
  'geraet',
  'sicherheitsfeatures',
  'bedienfeatures',
  'bedienung',
  'steuerungsmoeglichkeiten',
  'systemerweiterung',
  'systemupdate'
];

export class ProductDetailTabElement extends Deserializable {
  @serialize() id: number;
  @serialize() type?: string;
  @serialize() headline: string;
  @serialize() text: string;
  @serialize() shortText?: string;
  @serialize() category?: SimpleType;
  @serialize() subCategory?: SimpleType;
}

export class ProductDetailTabpanel extends Deserializable {
  @serialize() tabElement: any;
  @serialize() tabTitle: string;
  @serialize() tabType?: 'table' | 'text';

  deserialize(input: any): this {
    const temp: this = super.deserialize(input);

    return (this.tabType && this.tabType === 'text') ? this.sortText(temp) : temp;
  }

  sortText(tab: this): any {
    const elementMap: Record<string, any> = {};

    // remove duplicates
    const elements = tab.tabElement.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);


    // cluster elements by subCategory
    elements.forEach(el => {
      if (el.subCategory) {
        if (el.subCategory.value in elementMap) {
          elementMap[el.subCategory.value] =
            {
              ...elementMap[el.subCategory.value],
              text: elementMap[el.subCategory.value].text + el.text
            };
        } else {
          elementMap[el.subCategory.value] = {...el, headline: el.subCategory.label};
        }
      }
    });

    const arrayFromRecord = [];
    const resultArray = [];

    Object.keys(elementMap).map((key) => {
      arrayFromRecord.push(elementMap[key]);
      return arrayFromRecord;
    });

    arrayFromRecord.forEach(el => {
      let index = masterSort.findIndex(comp => comp === el.subCategory.value);
      if (index === -1) {
        index = masterSort.length;
      }
      if (!resultArray[index]) {
        resultArray[index] = el;
      } else {
        resultArray.push(el);
      }

    });

    return {...tab, tabElement: resultArray.filter(el => el)};
  }
}

export class ProductDetailTabpanelModel extends Deserializable {
  @serializeArray(ProductDetailTabpanel) tabs: Array<ProductDetailTabpanel>;
}
