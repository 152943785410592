import {Pipe, PipeTransform} from '@angular/core';
import {HighlightTextService} from '@rz-gud/services/highlight-text.service';

@Pipe({
  name: 'highlightText'
})
export class HighlightTextPipe implements PipeTransform {

  constructor(private highlightTextService: HighlightTextService) {
  }

  transform(value: string, searchString: string, removeHtml: boolean = false): string {
    return this.highlightTextService.processText(value, searchString, removeHtml);
  }
}
