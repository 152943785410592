import {Deserializable, serialize, serializeArray} from '@conception/ts-class-deserializer';

export class PageHit extends Deserializable {
  @serialize() pagePath: string;
  @serializeArray() textHits: Array<string>;
  @serialize() title: string;
  @serialize() type: string;
}

export class ObjectHitData extends Deserializable {
  @serialize() description: string;
  @serialize() headline: string;
  @serialize() copy: string;
  @serialize() id: number;
  @serialize() title: string;
}

export class ObjectHit extends Deserializable {
  @serialize() class: string;
  @serialize() id: number;
  @serialize(ObjectHitData) objectData: ObjectHitData;
  @serialize() title: string;
  @serialize() type: string;
}
