import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ContentElementModel } from '@conception/ngx-pimcore-connect';
import { ProductDetailTabpanel, ProductDetailTabpanelModel } from './product-detail-tabpanel.model';
import { isArray } from 'rxjs/internal-compatibility';

@Component({
  selector: 'ce-product-detail-tabpanel',
  templateUrl: './product-detail-tabpanel.component.html',
  styleUrls: ['./product-detail-tabpanel.component.scss'],
})
export class ProductDetailTabpanelComponent implements OnInit {
  @ContentElementModel(ProductDetailTabpanelModel) content: ProductDetailTabpanelModel;
  @ViewChild('tabContent') tabContent: ElementRef;
  activeTab: number = 0;

  tabs: Array<ProductDetailTabpanel> = [];

  constructor() {}

  ngOnInit(): void {
    this.tabs = this.content.tabs
      .filter(
        (tab) =>
          (isArray(tab.tabElement) && (tab.tabElement[0]?.text || tab.tabElement[0]?.headline)) ||
          tab.tabElement?.type === 'table'
      )
      .map((el) =>
        new ProductDetailTabpanel().deserialize({
          ...el,
          tabType: isArray(el.tabElement) ? 'text' : 'table',
        })
      );
  }

  scrollTop(): void {
    if (this.tabContent) {
      this.tabContent.nativeElement.scrollIntoView(true);
    }
  }
}
