import {Component} from '@angular/core';
import {ContentElementModel} from '@conception/ngx-pimcore-connect';
import {ProductHighlightSliderModel} from './product-highlight-slider.model';

@Component({
  selector: 'ce-product-highlight-slider',
  templateUrl: './product-highlight-slider-c-e.component.html',
  styleUrls: ['./product-highlight-slider-c-e.component.scss']
})
export class ProductHighlightSliderCEComponent {
  @ContentElementModel(ProductHighlightSliderModel) content: ProductHighlightSliderModel;
}
