<h3 [innerHTML]="categoryHeadline"></h3>

<gd-note
  [label]="categoryHeadline"
  [textFlip]="true"
  [color]="gdColors.AZURE"
  [unicolor]="true"></gd-note>

<product-teaser-ui
  *ngFor="let teaser of content.families"
  [headline]="teaser.displayName"
  [copy]="teaser.excerpt"
  [showBookmark]="false"
  [image]="teaser.teaserImage.getImage('ProductTeaser')">
  <div class="icons" *ngIf="teaser.icons.length">
    <gd-product-icon
      *ngFor="let icon of teaser.icons"
      color="azure"
      [icon]="icon.value"
      [tooltip]="'producticon.' + icon.value | translate"></gd-product-icon>
  </div>

  <div
    class="buttons"
    *ngIf="teaser.filterID || teaser.overviewLink?.overviewLink?.url">
    <a
      *ngIf="teaser.filterID"
      [routerLink]="productOverviewLink$ | async"
      [queryParams]="{ filter: teaser.filterID }">
      <gd-button [label]="'showAll' | translate"></gd-button>
    </a>

    <a
      *ngIf="teaser.overviewLink?.overviewLink?.url"
      [routerLink]="teaser.overviewLink.overviewLink.url">
      <gd-button [label]="teaser.buttonLabel ? teaser.buttonLabel : teaser.overviewLink.overviewLink.name"></gd-button>
    </a>
  </div>
</product-teaser-ui>
