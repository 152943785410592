import {Component, Input, OnInit} from '@angular/core';
import {ContentElementModel} from '@conception/ngx-pimcore-connect';
import {CampusKnowHowModel} from './campus-know-how.model';
import {GDColors} from '@conception/ng-gud-ui';

@Component({
  selector: 'ce-campus-know-how',
  templateUrl: './campus-know-how.component.html',
  styleUrls: ['./campus-know-how.component.scss']
})
export class CampusKnowHowComponent implements OnInit {

  @Input() @ContentElementModel(CampusKnowHowModel) content: CampusKnowHowModel;

  gdColors = GDColors;

  shownItems: number = 18;
  maxItems: number = 0;
  currentlyShownAmount: number;
  currentMaxItems: number = 0;
  showLoadMore: boolean = true;
  changeValue: boolean = true;

  ngOnInit(): void {
    this.setMaxItems(this.content.knowHows.length);
  }

  setMaxItems(items: number): void {
    this.maxItems = items;
    this.currentMaxItems = items;
    this.currentlyShownAmount = 24;
    this.showLoadMore = this.currentlyShownAmount < this.currentMaxItems;
  }

  ingresShownItems(ingresBy: number): void {
    this.currentlyShownAmount =
      this.currentlyShownAmount + ingresBy >= this.maxItems ?
        this.maxItems :
        this.currentlyShownAmount + ingresBy;

    if (this.changeValue) {
      this.shownItems = this.currentlyShownAmount;
    }

    this.changeValue = this.currentlyShownAmount !== this.maxItems;
    this.showLoadMore = this.currentlyShownAmount < this.currentMaxItems;
  }

}
