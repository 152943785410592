import {Deserializable, serialize, serializeArray} from '@conception/ts-class-deserializer';
import {Product} from '@rz-gud/models';
import {PimAsset} from '@conception/ngx-pimcore-connect';

export class ProductHighlightsSlide extends Deserializable {
  @serialize() headline: string;
  @serialize() copy: string;
  @serialize(PimAsset) image: PimAsset;
  @serializeArray(Product) product: Array<Product>;
}

export class ProductHighlightsModel extends Deserializable {
  @serialize() headline: string;
  @serializeArray(ProductHighlightsSlide) products: Array<ProductHighlightsSlide>;
}

