import {createSelector} from '@ngrx/store';
import {LayoutState, PageMode, State} from '../reducers';

export const select = (state: State) => state.layout;

export const fixed = createSelector<State,[LayoutState],boolean>(
  select,
  (state: LayoutState) => state.fixed
);

export const contactbarLock = createSelector<State,[LayoutState],boolean>(
  select,
  (state: LayoutState) => state.contactbarLock
);

export const languageSwitchOverlay = createSelector<State,[LayoutState],boolean>(
  select,
  (state: LayoutState) => state.languageSwitchOverlay
);

export const alternativeBreadcrumbLine = createSelector<State,[LayoutState],boolean>(
  select,
  (state: LayoutState) => state.alternativeBreadcrumbLine
);

export const breadcrumbColor = createSelector<State,[LayoutState],'dark' | 'white'>(
  select,
  (state: LayoutState) => state.breadcrumbColor
);

export const mode = createSelector<State,[LayoutState],PageMode>(
  select,
  (state: LayoutState) => state.mode
);

export const showPageModeTutorial = createSelector<State,[LayoutState],boolean>(
  select,
  (state: LayoutState) => state.pageModeTutorial
);

export const showLanguageTutorial = createSelector<State,[LayoutState],boolean>(
  select,
  (state: LayoutState) => state.languageTutorial
);
