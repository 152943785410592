import {createSelector} from '@ngrx/store';
import {BookmarkState, State} from '../reducers';

export const select = (state: State) => state.bookmarks;

export const amIBookmarked = (productId: number) => createSelector<State, [BookmarkState], boolean>(
  select,
  (bookmarks: BookmarkState) => bookmarks.products.includes(productId)
);

export const count = createSelector<State, [BookmarkState], number>(
  select,
  (bookmarks: BookmarkState) => bookmarks.products.length
);
