import { createSelector } from '@ngrx/store';
import { ProductFilterState, State } from '../reducers';
import {
  MainFilterGroup,
  ProductFilter,
  ProductFilterDefinition,
  ProductFilterSet,
  SimpleFilter,
} from '@rz-gud/interfaces';
import { Product } from '@rz-gud/models';

export const select = (state: State) => state.productFilter;

export const searchTerm = createSelector<State, [ProductFilterState], string>(
  select,
  (state: ProductFilterState) => state.search
);

export const filterObject = createSelector<State, [ProductFilterState], Array<ProductFilterSet>>(
  select,
  (state: ProductFilterState) => state.filter
);

export const isLoaded = createSelector<State, [ProductFilterState], boolean>(
  select,
  (state: ProductFilterState) => state.isLoaded
);

export const filterId = createSelector<State, [ProductFilterState], string>(
  select,
  (state: ProductFilterState) => state.filterId
);

export const products = createSelector<State, [ProductFilterState], Array<Product>>(
  select,
  (state: ProductFilterState) => filterIcons(state.products)
);

export const totalCount = createSelector<State, [ProductFilterState], number>(
  select,
  (state: ProductFilterState) => state.count
);

export const presets = createSelector<State, [ProductFilterState], Array<ProductFilterDefinition>>(
  select,
  (state: ProductFilterState) => state.presets
);

export const propertyFilter = createSelector<State, [ProductFilterState], Array<ProductFilter>>(
  select,
  (state: ProductFilterState) => state.propertyFilter
);

export const mainFilter = createSelector<State, [ProductFilterState], Array<MainFilterGroup>>(
  select,
  (state: ProductFilterState) => state.mainFilter
);

export const setFiltersAsMap = createSelector<State, [ProductFilterState], Map<string, Array<SimpleFilter>>>(
  select,
  (state: ProductFilterState) => {
    const setFilters: Map<string, Array<SimpleFilter>> = new Map();

    state.filter.forEach((el) => setFilters.set(el.category, el.filterEntries));

    return setFilters;
  }
);

export const currentPreset = createSelector<State, [ProductFilterState], Array<any>>(
  select,
  (state: ProductFilterState) => []
);

export const getFilterDefinition = (id: number) =>
  createSelector<State, [ProductFilterState], ProductFilterDefinition>(select, (state: ProductFilterState) => {
    const def = state.filterDefinitions.filter((el) => el.filterId === id);

    return def.length > 0 ? def[0] : null;
  });

const filterIcons = (productsToFilter: Array<any>): Array<Product> => {
  const iconsToRemove: Array<string> = [];

  productsToFilter = productsToFilter.map((product: Product) => {
    if (product?.productIcons?.some((icon) => icon.iconId === 'compression_compressed')) {
      return { ...product, bluedec: true };
    } else {
      return { ...product, bluedec: false };
    }
  });

  productsToFilter[0]?.productIcons?.forEach((icon) => {
    if (
      productsToFilter
        .map((product) => product.productIcons)
        .every((productIcons) => productIcons.some((productIcon) => productIcon.iconId === icon.iconId))
    ) {
      iconsToRemove.push(icon.iconId);
    }
  });

  if (iconsToRemove.length) {
    productsToFilter = productsToFilter.map((product) => ({
      ...product,
      productIcons: [...product.productIcons.filter((icon) => !iconsToRemove.includes(icon.iconId))],
    }));
  }

  return productsToFilter.map((el) => new Product().deserialize(el));
};

export const productNames = createSelector<State, [ProductFilterState], Array<string>>(
  select,
  (search: ProductFilterState) => search.productNames
);

export const autofillProductNames = createSelector<State, [ProductFilterState], Array<string>>(
  select,
  (search: ProductFilterState) => search.autofillProductNames
);

export const exactSearch = createSelector<State, [ProductFilterState], boolean>(
  select,
  (search: ProductFilterState) => search.exactSearch
);

export const productOverviewSearchHasFocus = createSelector<State, [ProductFilterState], boolean>(
  select,
  (search: ProductFilterState) => search.productOverviewSearchHasFocus
);
