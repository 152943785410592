import { Deserializable, serialize, serializeArray } from '@conception/ts-class-deserializer';
import { PimImageCollection } from '@conception/ngx-pimcore-connect';

export class ComparisonSubRow extends Deserializable {
  @serialize() label: string;
  @serializeArray() fields: Array<Array<string | number | boolean>> = [];
}

export class ComparisonRow extends Deserializable {
  @serialize() title: string;
  @serializeArray() subRows: Array<ComparisonSubRow> = [];
}

export class ComparisonField extends Deserializable {
  @serialize() label: string;
  @serializeArray() values: Array<string>;
}

export class ComparisonTableSection extends Deserializable {
  @serialize() title: string;
  @serializeArray(ComparisonField) fields: ComparisonField[] = [];
}

export class ComparisonProduct extends Deserializable {
  @serialize() title: string;
  @serialize() sku: string;
  @serialize() slug: string;
  @serialize() id: number;
  @serialize() image: PimImageCollection;
  @serializeArray(ComparisonTableSection)
  tableSections: ComparisonTableSection[] = [];
}

export class Comparison extends Deserializable {
  @serializeArray(ComparisonProduct) products: ComparisonProduct[] = [];
  @serialize() rows: ComparisonRow[] = [];

  areFieldsIdentical(fieldsA: any[], fieldsB: any[]): boolean {
    if (fieldsA.length !== fieldsB.length) {
      return false;
    }

    for (let i = 0; i < fieldsA.length; i++) {
      if (
        fieldsA[i].label !== fieldsB[i].label ||
        JSON.stringify(fieldsA[i].values) !== JSON.stringify(fieldsB[i].values)
      ) {
        return false;
      }
    }

    return true;
  }

  public read(list: any[]): void {
    this.products = list.map((el) =>
      new ComparisonProduct().deserialize({
        title: el.title,
        slug: el.slug,
        sku: el.artikelnummer || el.sku,
        image: new PimImageCollection().deserialize(el.image),
        id: el.id,
        tableSections: el.tableSections,
      })
    );

    const rows: ComparisonRow[] = [];
    const referenceProduct = this.products[0];
    const processedSections: string[] = [];

    referenceProduct?.tableSections.forEach((tableSection, sectionIndex) => {
      const newRow: ComparisonRow = new ComparisonRow().deserialize({
        title: tableSection.title,
        subRows: [],
      });

      tableSection.fields.forEach((field, fieldIndex) => {
        const newSubRow: ComparisonSubRow = new ComparisonSubRow().deserialize({
          label: field.label,
          fields: [],
        });

        for (const item of this.products) {
          let correspondingSection = item.tableSections.find(
            (ts) => ts.title === tableSection.title && !processedSections.includes(ts.title)
          );

          // If exact section title doesn't match, try to find a section with identical fields and values.
          if (!correspondingSection) {
            correspondingSection = item.tableSections.find(
              (ts) => this.areFieldsIdentical(ts.fields, tableSection.fields) && !processedSections.includes(ts.title)
            );
          }

          const correspondingField = correspondingSection?.fields.find((f) => f.label === field.label);

          if (correspondingField) {
            newSubRow.fields.push([...correspondingField.values]);
          } else {
            newSubRow.fields.push([]);
          }
        }

        newRow.subRows.push(newSubRow);
      });

      rows.push(newRow);
      if (newRow.title) {
        processedSections.push(newRow.title);
      }
    });

    this.rows = rows;
  }
}
