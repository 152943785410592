<h2 [innerHTML]="content.headline"></h2>
<div class="hotspot__image--container">
  <img
    class="hotspot__image"
    [src]="content.image.url"
    [alt]="content.image.meta?.alt"
    [title]="content.image.meta?.title" />
  <div
    class="hotspot"
    *ngFor="let hotspot of content.image.hotspots; index as index"
    [class.active]="activeHotspot === index"
    [class.left]="activeHotspot === index && hotspot.left <= 50"
    [class.right]="activeHotspot === index && hotspot.left > 50"
    [style.top]="hotspot.top + '%'"
    [style.left]="hotspot.left + '%'">
    <div class="hotspot__inner">
      <div
        class="hotspot__dot"
        [class.active]="activeHotspot === index"
        [ngClass]="
          hotspot.data[0].color
            ? 'hotspot__dot--' + hotspot.data[0].color?.value
            : 'hotspot__dot--yellow'
        "
        (click)="setActiveHotspot(index)">
        <gd-icon
          icon="plus"
          [color]="
            hotspot.data[0].color?.value === 'azure'
              ? gdColors.SILVERLIGHT
              : gdColors.AZURE
          "></gd-icon>
      </div>
      <hotspot-teaser
        *ngIf="activeHotspot === index"
        [class.left]="hotspot.left <= 50"
        [class.right]="hotspot.left > 50"
        [content]="hotspot.data[0]"
        @fadeInOut></hotspot-teaser>
    </div>
  </div>
</div>
<div *ngIf="false" class="hotspot-category--container">
  <div class="hotspot-category" *ngFor="let category of content.categories">
    <div
      class="hotspot-category__dot"
      [ngClass]="'hotspot-category__dot--' + category.color"></div>
    <div class="hotspot-category__title" [innerHTML]="category.title"></div>
  </div>
</div>

<p [innerHTML]="content.copy" class="copy"></p>
