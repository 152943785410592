import { createReducer, on } from '@ngrx/store';
import {
  MainFilterGroup,
  ProductFilter,
  ProductFilterDefinition,
  ProductFilterSet,
} from '@rz-gud/interfaces';
import { ProductFilterActions } from '../actions';
import { Product } from '@rz-gud/models';

export interface ProductFilterState {
  filter: Array<ProductFilterSet>;
  search: string;
  isLoaded: boolean;
  filterId: string;
  products: Array<Product>;
  count: number;
  propertyFilter: Array<ProductFilter>;
  mainFilter: Array<MainFilterGroup>;
  presets: Array<ProductFilterDefinition>;
  presetId: number;
  filterDefinitions: Array<ProductFilterDefinition>;
  productNames: Array<string>;
  autofillProductNames: Array<string>;
  exactSearch: boolean;
  productOverviewSearchHasFocus: boolean;
}

export const initialState: ProductFilterState = {
  filter: [],
  search: '',
  isLoaded: false,
  filterId: '',
  products: [],
  count: 0,
  propertyFilter: [],
  mainFilter: [],
  presets: [],
  presetId: 0,
  filterDefinitions: [],
  productNames: [],
  autofillProductNames: [],
  exactSearch: false,
  productOverviewSearchHasFocus: true,
};

export const productFilterReducer = createReducer(
  initialState,
  on(ProductFilterActions.setFilterObject, (state, { filter }) => ({
    ...state,
    filter,
  })),
  on(ProductFilterActions.clearFilterObject, (state) => ({
    ...state,
    filter: initialState.filter,
  })),
  on(ProductFilterActions.setFilter, (state, { category, object }) => {
    const filterObject: Array<ProductFilterSet> = [
      ...state.filter.filter((el) => el.category !== category),
    ];
    if (object.length) {
      filterObject.push({ category, filterEntries: object });
    }

    return {
      ...state,
      filter: filterObject,
    };
  }),
  on(ProductFilterActions.clearFilter, (state, { category }) => ({
    ...state,
    filter: [...state.filter.filter((el) => el.category !== category)],
  })),
  on(ProductFilterActions.addFilter, (state, { category, filter }) => {
    const currentFilterIndex = state.filter.findIndex(
      (el) => el.category === category
    );
    const currentFilterSet: Array<ProductFilterSet> = state.filter;
    if (currentFilterIndex !== -1) {
      currentFilterSet[currentFilterIndex].filterEntries.push(filter);
    } else {
      currentFilterSet.push({ category, filterEntries: [filter] });
    }

    return { ...state, filter: currentFilterSet };
  }),
  on(ProductFilterActions.removeFilter, (state, { category, filter }) => {
    let currentFilterSet: Array<ProductFilterSet> = state.filter.map((el) => {
      if (el.category === category) {
        return {
          ...el,
          filterEntries: el.filterEntries.filter((f) => f.key !== filter.key),
        };
      } else {
        return el;
      }
    });

    currentFilterSet = currentFilterSet.filter((el) => el.filterEntries.length);

    return { ...state, filter: currentFilterSet };
  }),
  on(ProductFilterActions.setPropertyFilter, (state, { propertyFilter }) => ({
    ...state,
    propertyFilter,
  })),
  on(ProductFilterActions.clearPropertyFilter, (state) => ({
    ...state,
    propertyFilter: initialState.propertyFilter,
  })),
  on(ProductFilterActions.setMainFilter, (state, { mainFilter }) => ({
    ...state,
    mainFilter,
  })),
  on(ProductFilterActions.clearMainFilter, (state) => ({
    ...state,
    mainFilter: initialState.mainFilter,
  })),
  on(ProductFilterActions.setPresets, (state, { presets }) => ({
    ...state,
    presets,
  })),
  on(ProductFilterActions.clearPresets, (state) => ({
    ...state,
    presets: initialState.presets,
  })),
  on(ProductFilterActions.setPresetId, (state, { presetId }) => ({
    ...state,
    presetId,
  })),
  on(ProductFilterActions.clearPresetId, (state) => ({
    ...state,
    presetId: initialState.presetId,
  })),
  on(
    ProductFilterActions.setFilterDefinitions,
    (state, { filterDefinitions }) => ({ ...state, filterDefinitions })
  ),
  on(ProductFilterActions.clearFilterDefinitions, (state) => ({
    ...state,
    filterDefinitions: initialState.filterDefinitions,
  })),
  on(ProductFilterActions.setFilterId, (state, { filterId }) => ({
    ...state,
    filterId,
  })),
  on(ProductFilterActions.clearFilterId, (state) => ({
    ...state,
    filterId: initialState.filterId,
  })),
  on(ProductFilterActions.setLoadingState, (state, { currentState }) => ({
    ...state,
    isLoaded: currentState,
  })),
  on(ProductFilterActions.setSearchTerm, (state, { term }) => ({
    ...state,
    search: term,
  })),
  on(ProductFilterActions.clearSearchTerm, (state) => ({
    ...state,
    search: initialState.search,
  })),
  on(ProductFilterActions.setProducts, (state, { products }) => ({
    ...state,
    products,
  })),
  on(ProductFilterActions.appendProducts, (state, { products }) => ({
    ...state,
    products: [...state.products, ...products],
  })),
  on(ProductFilterActions.clearProducts, (state) => ({
    ...state,
    products: initialState.products,
  })),
  on(ProductFilterActions.setCount, (state, { count }) => ({
    ...state,
    count,
  })),
  on(
    ProductFilterActions.getProductNamesSuccess,
    (state, { productNames }) => ({ ...state, productNames })
  ),
  on(
    ProductFilterActions.setAutoFillProductNames,
    (state, { autofillProductNames }) => ({ ...state, autofillProductNames })
  ),
  on(ProductFilterActions.exactSearch, (state, { exactSearch }) => ({
    ...state,
    exactSearch,
  })),
  on(
    ProductFilterActions.setProductOverviewSearchFocus,
    (state, { productOverviewSearchHasFocus }) => ({
      ...state,
      productOverviewSearchHasFocus,
    })
  )
);
