import { Deserializable, serialize, serializeArray } from '@conception/ts-class-deserializer';
import { SearchTerm } from '@rz-gud/interfaces';

export class ProductSearchModel extends Deserializable {
  @serialize() headline: string;
  @serialize() theme: 'main' | 'inline';
  @serializeArray() popularSearches: Array<SearchTerm>;
  @serializeArray() filterDefinitions: Array<any>;
}

