import { createReducer, on } from '@ngrx/store';
import { SearchActions } from '../actions';
import { Product, ObjectHit, PageHit } from '@rz-gud/models';

export interface SearchState {
  term: string;
  overlay: boolean;
  suggestions: Array<any>;
  productResults: Array<Product>;
  productTotal: number;
  cmsResults: Array<PageHit | ObjectHit>;
  cmsTotal: number;
  tab: 'cms' | 'products';
  exactSearch: boolean;
}

export const initialState: SearchState = {
  term: '',
  overlay: false,
  suggestions: [],
  productResults: [],
  productTotal: 0,
  cmsResults: [],
  cmsTotal: 0,
  tab: 'products',
  exactSearch: false,
};

export const searchReducer = createReducer(
  initialState,
  on(SearchActions.setTerm, (state, { term }) => ({ ...state, term })),
  on(SearchActions.clearTerm, (state) => ({
    ...state,
    term: initialState.term,
  })),
  on(SearchActions.toggleOverlay, (state) => ({
    ...state,
    overlay: !state.overlay,
  })),
  on(SearchActions.openOverlay, (state) => ({ ...state, overlay: true })),
  on(SearchActions.closeOverlay, (state) => ({ ...state, overlay: false })),
  on(SearchActions.setCmsTotal, (state, { cmsTotal }) => ({
    ...state,
    cmsTotal,
  })),
  on(SearchActions.setCmsResults, (state, { cmsResults }) => ({
    ...state,
    cmsResults,
  })),
  on(SearchActions.appendCmsResults, (state, { cmsResults }) => ({
    ...state,
    cmsResults: [...state.cmsResults, ...cmsResults],
  })),
  on(SearchActions.clearCmsResults, (state) => ({
    ...state,
    cmsResults: initialState.cmsResults,
  })),
  on(SearchActions.setProductTotal, (state, { productTotal }) => ({
    ...state,
    productTotal,
  })),
  on(SearchActions.setProductResults, (state, { productResults }) => ({
    ...state,
    productResults,
  })),
  on(SearchActions.appendProductResults, (state, { productResults }) => ({
    ...state,
    productResults: [...state.productResults, ...productResults],
  })),
  on(SearchActions.clearProductResults, (state) => ({
    ...state,
    productResults: initialState.productResults,
  })),
  on(SearchActions.setSuggestions, (state, { hits }) => ({
    ...state,
    suggestions: hits,
  })),
  on(SearchActions.clearSuggestions, (state) => ({
    ...state,
    suggestions: initialState.suggestions,
  })),
  on(SearchActions.setTab, (state, { tab }) => ({ ...state, tab })),
  on(SearchActions.exactSearch, (state, { exactSearch }) => ({
    ...state,
    exactSearch,
  }))
);
