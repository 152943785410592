<div class="upper">
  <a routerLink="/" class="logo-container">
    <gd-logo [border]="true"></gd-logo>
    <gd-line color="silver-light-transparent"></gd-line>
  </a>
  <div class="menu" *ngIf="footerNav$ | async as footerNav">
    <ng-container *ngFor="let nav of footerNav?.pages">
      <a
        *ngIf="
          nav.linkBrick?.brick_type === 'navigationLinkInternal' ||
            nav.linkBrick?.brick_type === 'NavigationLinkInternal';
          else externalLegal
        "
        class="menu__links"
        [routerLink]="nav.linkBrick?.pageLink.pageLink.url"
        [innerHTML]="nav.linkBrick?.pageLink.pageLink.name"
      ></a>
      <ng-template #externalLegal>
        <a
          class="menu__links"
          [href]="nav.linkBrick?.externalUrl"
          [target]="nav.linkBrick?.target.value"
          [innerHTML]="nav.linkBrick?.label"
        ></a>
      </ng-template>
    </ng-container>
  </div>
  <div class="social" *ngIf="socialNav$ | async as socialNav">
    <ng-container *ngFor="let nav of socialNav?.pages">
      <a
        *ngIf="
          nav.linkBrick?.brick_type === 'navigationLinkInternal' ||
            nav.linkBrick?.brick_type === 'NavigationLinkInternal';
          else externalSocial
        "
        class="social__link"
        [title]="nav.linkBrick.pageLink.name"
        [routerLink]="nav.linkBrick.pageLink.pageLink.url"
      >
        <gd-icon
          [icon]="nav.linkBrick?.icon?.value"
          [color]="gdColors.SILVERLIGHT"
        ></gd-icon>
      </a>
      <ng-template #externalSocial>
        <a
          class="social__link"
          [href]="nav.linkBrick?.externalUrl"
          [title]="nav.linkBrick?.label"
          rel="noreferrer"
          [target]="nav.linkBrick?.target.value"
        >
          <gd-icon
            [icon]="nav.linkBrick?.icon?.value"
            [color]="gdColors.SILVERLIGHT"
          ></gd-icon>
        </a>
      </ng-template>
    </ng-container>
  </div>
</div>

<div class="middle">
  <div class="middle__contact">
    <div class="contact">
      <h3 [innerHTML]="'footer.contact.hq.headline' | translate"></h3>
      <p [innerHTML]="'footer.contact.hq.subline' | translate"></p>
      <a [href]="eMailHQ">
        <gd-simple-button
          [label]="'footer.contact' | translate"
          [color]="gdColors.SILVERLIGHT"
        ></gd-simple-button>
      </a>
    </div>
    <div class="contact">
      <h3 [innerHTML]="'footer.contact.na.headline' | translate"></h3>
      <p [innerHTML]="'footer.contact.na.subline' | translate"></p>
      <a [href]="eMailNA">
        <gd-simple-button
          [label]="'footer.contact' | translate"
          [color]="gdColors.SILVERLIGHT"
        ></gd-simple-button>
      </a>
    </div>
  </div>

  <div class="support__container">
    <div class="divider"></div>
    <div class="support">
      <p [innerHTML]="'footer.support' | translate"></p>
      <a
        *ngIf="supportLink$ | async as supportLink"
        [routerLink]="supportLink"
        class="button-link"
      >
        <gd-button
          [color]="gdColors.MAYA"
          [label]="'footer.support.button' | translate"
        ></gd-button>
      </a>
    </div>
  </div>
</div>

<div class="bottom">
  <div class="bottom--inner">
    <div class="legal" *ngIf="legalNav$ | async as legalNav">
      <ng-container *ngFor="let nav of legalNav?.pages">
        <a
          *ngIf="
            nav?.linkBrick?.brick_type === 'navigationLinkInternal' ||
              nav?.linkBrick?.brick_type === 'NavigationLinkInternal';
            else externalLegal
          "
          class="legal__link"
          [routerLink]="nav?.linkBrick?.pageLink?.pageLink?.url"
          [innerHTML]="nav?.linkBrick?.pageLink?.pageLink?.name"
        ></a>
        <ng-template #externalLegal>
          <a
            class="legal__link"
            [href]="nav?.linkBrick?.externalUrl"
            [target]="nav?.linkBrick?.target?.value"
            [innerHTML]="nav?.linkBrick?.label"
          ></a>
        </ng-template>
      </ng-container>
      <div class="legal__link" (click)="changeConsent()">{{'cookie.consent.change' | translate}}</div>
      <div class="legal__link" (click)="widthdrawConsent()">{{'cookie.consent.withdraw' | translate}}</div>
    </div>
    <div class="copyright">
      {{ 'footer.copyright' | translate: { 'date': date | date: 'YYYY' } }}
    </div>
  </div>
</div>
