import {createSelector} from '@ngrx/store';
import {State, VideoPopupState} from '../reducers';
import {PimVideo} from '@conception/ngx-pimcore-connect';

export const select = (state: State) => state.videoPopup;

export const video = createSelector<State,[VideoPopupState],PimVideo>(
  select,
  (state: VideoPopupState) => state.video
);
