import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Product } from '@rz-gud/models';
import { ProductFilterActions, ProductFilterSelectors } from '@rz-gud/store';
import { Store } from '@ngrx/store';
import { ProductService } from '@rz-gud/services';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'product-container',
  templateUrl: './product-container.component.html',
  styleUrls: ['./product-container.component.scss'],
})
export class ProductContainerComponent implements OnInit {
  @ViewChild('amount') amountRef: ElementRef;
  readonly products$: Observable<Array<Product>> = this.store.select(
    ProductFilterSelectors.products
  );
  readonly isLoaded$: Observable<boolean> = this.store.select(
    ProductFilterSelectors.isLoaded
  );
  readonly count$: Observable<number> = this.store.select(
    ProductFilterSelectors.totalCount
  );
  subscriptions = new Subscription();

  constructor(
    private readonly store: Store,
    private readonly productService: ProductService,
    private readonly activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.loadProducts();
    this.subscriptions.add(
      this.products$.subscribe((product) => { })
    );
  }

  resetFilters(): void {
    this.store.dispatch(ProductFilterActions.clearFilterObject());
  }

  showMore(): void {
    this.store.dispatch(ProductFilterActions.loadMore());
  }

  showAll(): void {
    this.store.dispatch(ProductFilterActions.loadAll());
    this.amountRef.nativeElement.scrollIntoView(true);
  }

  trackById(index: number, el: Product): number {
    return el.id;
  }

  /**
   * First check, if a preset is set via GET param ('?filter=1234')
   * If so, we use this preset FilterDefinition (via store effect) to build up a filter object regarding on its preselected values.
   *
   * When there is no active preset, we clear the filter-object to trigger the
   * ProductFilter effects for loading product data via the API
   *
   * @private
   */
  private loadProducts(): void {
    if (this.activatedRoute.snapshot.queryParamMap.has('filter')) {
      this.store.dispatch(
        ProductFilterActions.useFilterDefinitionById({
          id: parseInt(
            this.activatedRoute.snapshot.queryParamMap.get('filter'),
            10
          ),
        })
      );
    } else {
      this.store.dispatch(ProductFilterActions.clearFilterObject());
    }
  }
}
