import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProductFilterActions, ProductFilterSelectors } from '@rz-gud/store';
import { Observable } from 'rxjs';
import {
  MainFilterGroup,
  ProductFilterDefinition,
  ProductFilterSet,
} from '@rz-gud/interfaces';
import { ProductService } from '@rz-gud/services';
import { isPlatformBrowser } from '@angular/common';
import { GtagService } from '@rz-gud/services/gtag.service';

@Component({
  selector: 'product-overview-main-filter',
  templateUrl: './main-filter.component.html',
  styleUrls: ['./main-filter.component.scss'],
})
export class MainFilterComponent {
  exactSearch: boolean = false;
  disableInText: boolean = false;
  readonly setPresetId$: Observable<Array<{ filterId: number }>> =
    this.store.select(ProductFilterSelectors.currentPreset);
  readonly filters$: Observable<Array<ProductFilterSet>> = this.store.select(
    ProductFilterSelectors.filterObject
  );
  readonly presets$: Observable<Array<ProductFilterDefinition>> =
    this.store.select(ProductFilterSelectors.presets);
  readonly mainFilter$: Observable<Array<MainFilterGroup>> = this.store.select(
    ProductFilterSelectors.mainFilter
  );

  private readonly isBrowser: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) platformId: Record<string, unknown>,
    private readonly store: Store,
    private readonly productService: ProductService,
    private readonly gtag: GtagService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  resetFilters(): void {
    this.store.dispatch(ProductFilterActions.clearFilterObject());
  }

  setFilter(def: ProductFilterDefinition): void {
    this.productService.setPreset(def);
    if (this.isBrowser) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
  search(term: string): void {
    this.store.dispatch(ProductFilterActions.setSearchTerm({ term }));
    this.store.dispatch(ProductFilterActions.search());
    this.gtag.pushEvent('product_search_via_popular', [{ term }]);
  }
}
