<div class="categories">
  <div class="categories--inner">
    <gd-multi-dropdown
      *ngIf="categories.length"
      [label]="'categories' | translate"
      [useAsUnique]="'value'"
      [useAsLabel]="'label'"
      [multi]="false"
      [items]="categories"
      [activeItems]="activeCategory ? [activeCategory] : []"
      (activeItemsChange)="changeCategory($event)">
    </gd-multi-dropdown>
  </div>
</div>

<div class="campus-faq--inner">
  <accordion-container>
    <gd-icon-box [color]="gdColors.GREEN" icon="campus-faq"></gd-icon-box>
    <ng-container *ngFor="let entry of entries; trackBy: trackByHeadline">
      <gd-accordion
        color="green"
        *ngIf="isActive(entry)"
        [id]="'c_' + entry.id"
        [active]="'c_' + entry.id === activeEntry"
        [title]="entry.headline">
        <gd-accordion-entry
          [video]="entry.video"
          [copy]="entry.copy"></gd-accordion-entry>
      </gd-accordion>
    </ng-container>
  </accordion-container>
  <!--*ngIf="(entry.category.value === activeCategory?.value) || activeCategory?.value === 'all'"-->
</div>
