import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'truncatetext'
})
export class TruncateTextPipe implements PipeTransform {

  transform(value: string, length: number): string {

    const biggestWord: number = 50;
    const ellipses: string = ' ...';

    if (typeof value !== 'string') {
      return value;
    }

    value = value.replace(/(<([^>]+)>)/gi, '');

    if (value.length <= length) {
      return value;
    }

    let truncatedText = value.slice(0, length + biggestWord);

    while (truncatedText.length > length - ellipses.length) {
      const lastSpace = truncatedText.lastIndexOf(' ');

      if (lastSpace === -1) {
        break;
      }

      truncatedText = truncatedText.slice(0, lastSpace).replace(/[!,.?]$/, '');

    }

    return truncatedText + ellipses;
  }
}
