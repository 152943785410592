import {Deserializable, serialize} from '@conception/ts-class-deserializer';

export class CampusEventDetailHeaderModel extends Deserializable {
  @serialize() startDate: string;
  @serialize() endDate: string;
  @serialize() startTime: string;
  @serialize() endTime: string;
  @serialize() timezone: string;
  @serialize() id: number;
  @serialize() location: string;
  @serialize() name: string;
  @serialize() shortDescripion: string;

  @serialize() title: string;
}
