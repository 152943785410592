import {AfterViewInit, Component, ElementRef, Inject, Input, PLATFORM_ID, ViewChild} from '@angular/core';
import {PimVideo, ScrollToService} from '@conception/ngx-pimcore-connect';
import {ButtonSizes, GDColors} from '@conception/ng-gud-ui';
import {Store} from '@ngrx/store';
import {VideoPopupActions, VideoPopupSelectors, VideoPopupState} from '@rz-gud/store';
import {Observable} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {isPlatformBrowser} from '@angular/common';
import {MediaLibraryItem} from '@rz-gud/models';

@Component({
  selector: 'media-teaser',
  templateUrl: './media-teaser.component.html',
  styleUrls: ['./media-teaser.component.scss']
})
export class MediaTeaserComponent implements AfterViewInit {

  @ViewChild('teaserVideo') teaserVideo: ElementRef;
  @Input() item: MediaLibraryItem;

  active: boolean = false;
  gdColors = GDColors;
  buttonSizes = ButtonSizes;

  readonly video$: Observable<PimVideo> = this.store.select(VideoPopupSelectors.video);
  private readonly isBrowser: boolean = false;

  constructor(private readonly store: Store<{ videoPopup: VideoPopupState }>,
              private activatedRoute: ActivatedRoute,
              private scrollToService: ScrollToService,
              @Inject(PLATFORM_ID) private platformId: Record<string, unknown>) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.video$.subscribe(video => {
      if (video) {
        this.active = video.id === this.item?.video?.id;
      } else {
        this.active = false;
      }
    });
  }

  ngAfterViewInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (this.isBrowser && params['scroll-to'] && this.item?.id === params['scroll-to']) {
        this.scrollToService.scrollTo(this.teaserVideo.nativeElement);
      }
    });
  }

  toggleVideoPopup(video: PimVideo): void {
    if (this.active) {
      this.store.dispatch(VideoPopupActions.clearVideoPopup());
      this.active = false;
    } else {
      this.store.dispatch(VideoPopupActions.setVideoPopup({video}));
      this.active = true;
    }
  }

}
