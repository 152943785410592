<gdcb-hero-text [headline]="content.headline" [subline]="content.subline"></gdcb-hero-text>
<div class="content">
  <div class="bodytext" [innerHTML]="content.text"></div>
  <a *ngIf="content.link?.path" [routerLink]="content.link.path">
    <gd-button [label]="content.link.text"></gd-button>
  </a>

  <gd-round-button *ngIf="content.overlay?.url"
                   (click)="overlay = true"
                   [color]="gdColors.YELLOW"
                   [size]="buttonSizes.LARGE"
                   [label]="'click to explore'"></gd-round-button>
</div>

<img [src]="content.image?.url" class="image" [alt]="content.image?.meta?.alt" [title]="content.image?.meta?.title">

<overlay *ngIf="overlay"
         [overlay]="content.overlay"
         [headline]="content.headline"
         @slideInOutBottom
         (closed)="overlay = false">
</overlay>
