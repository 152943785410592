import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { GDColors } from '@conception/ng-gud-ui';
import { LayoutActions } from '@rz-gud/store';
import { Store } from '@ngrx/store';

@Component({
  selector: 'tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TutorialComponent {
  @Input() isLanguage: boolean = false;
  @Input() class: string = '';
  @Input() link: { url: string; label: string; color?: any };
  @Output() closed = new EventEmitter<boolean>();
  gdColors = GDColors;

  constructor(private readonly store: Store) {}

  @HostBinding('class') get hostClasses(): string {
    return [this.class, this.isLanguage ? 'is-language' : ''].join(' ');
  }

  dismiss(): void {
    this.closed.emit(true);
  }

  persistPreferredLanguage(locale: string): void {
    localStorage.setItem('preferredLanguage', locale);
  }

  handleTutorialClick(): void {
    if (this.link.url.includes('/en-us/')) {
      if (typeof window !== 'undefined') {
        this.persistPreferredLanguage('en_US');
      }
    } else {
      this.showPageModeTutorial();
    }
  }

  showPageModeTutorial(): void {
    this.store.dispatch(LayoutActions.openPageModeTutorial());
  }
}
