import { Component, OnInit } from '@angular/core';
import { ContentElementModel } from '@conception/ngx-pimcore-connect';
import { BranchOverviewModel } from '@rz-gud/content-elements/branch-overview/branch-overview.model';
import { GDColors } from '@conception/ng-gud-ui';
import { BranchService } from '@rz-gud/services';
import { Observable } from 'rxjs';
import { Branch } from '@rz-gud/models';

@Component({
  selector: 'ce-branch-teaser-list',
  templateUrl: './branch-overview.component.html',
  styleUrls: ['./branch-overview.component.scss'],
})
export class BranchOverviewComponent implements OnInit {
  @ContentElementModel(BranchOverviewModel) content: BranchOverviewModel;

  gdColors = GDColors;
  branches$: Observable<Array<Branch>>;

  constructor(private readonly branchService: BranchService) {}

  ngOnInit(): void {
    setTimeout(
      () =>
        (this.branches$ = this.branchService.getBranches({
          folder: this.content.folder?.path,
        })),
      200
    );
  }
}
