import {animate, style, transition, trigger} from '@angular/animations';

export const fadeInOut = trigger('fadeInOut', [
  transition(':enter', [
    style({opacity: 0}),
    animate('150ms cubic-bezier(.25, .46, .45, .94)', style({opacity: 1}))
  ]),
  transition(':leave', [
    style({opacity: 1}),
    animate('150ms cubic-bezier(.25, .46, .45, .94)', style({opacity: 0}))
  ])
]);
