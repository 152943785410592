<div class='search-header'>
  <searchbar [prefill]='true'
             [productAutofill]="true"
             [setExactSearch]="exactSearch"
             [disableInText]="disableInText"
             (exact)="exactSearch = $event"
             autofillTheme="light"
             [placeholder]="'search.placeholder' | translate"></searchbar>
  <!--
  <gd-checkbox [label]="'search.exactSearch' | translate"
               [checked]="exactSearch"
               size="small"
               (isChecked)="exactSearch = $event; disableInText = $event;"></gd-checkbox>
               -->
</div>

<ng-container
  *ngIf='((cmsResults$ | async).length || (productResults$ | async).length) && searchTab$ | async as searchTab'>
  <div class='tabs'
       *ngIf='((cmsResults$ | async).length || (productResults$ | async).length) && searchTab$ | async as searchTab'>
    <div class='tab'
         [class.tab--active]="searchTab === 'cms' || (productTotal$ | async) === 0"
         [class.tab--inactive]='(cmsTotal$ | async) === 0'
         (click)="setTab('cms')">
      <div class='tab__count' [innerHTML]="'(' + (cmsTotal$ | async) + ')'"></div>
      <div class='tab__label' [innerHTML]="'search.cms' | translate"></div>
    </div>
    <div class='tab'
         [class.tab--active]="searchTab === 'products' && (productTotal$ | async) !== 0"
         [class.tab--inactive]='(productTotal$ | async) === 0'
         (click)="setTab('products')">
      <div class='tab__count' [innerHTML]="'(' + (productTotal$ | async) + ')'"></div>
      <div class='tab__label' [innerHTML]="'search.products' | translate"></div>
    </div>

    <gd-feels-right-badge></gd-feels-right-badge>
  </div>

  <div class='results'>
    <h1 class='results__headline' [innerHTML]='content.headline'></h1>

    <div class='cms-results' *ngIf="searchTab === 'cms' || (productTotal$ | async) === 0">
      <search-teaser *ngFor='let entry of cmsResults$ | async'
                     [entry]='entry'></search-teaser>
      <div class='load-more' *ngIf='(cmsResults$ | async).length < (cmsTotal$ | async)'>
        <gd-button (click)='loadMoreCms()'
                   [label]="'showAmountMore' | translate:{'amount': cmsPerPage}" color='azure-outline'></gd-button>
        <gd-button (click)='loadAllCms()'
                   [label]="'showAll' | translate"
                   color='azure'></gd-button>
      </div>
    </div>
    <div class='product-results' *ngIf="searchTab === 'products' && (productTotal$ | async) !== 0">
      <div class='product-teaser__container'>
        <product-teaser *ngFor='let product of productResults$ | async; trackBy: trackById'
                        [product]='product'></product-teaser>
      </div>
      <div class='load-more' *ngIf='(productResults$ | async).length < (productTotal$ | async)'>
        <gd-button (click)='loadMoreProducts()'
                   [label]="'showAmountMore' | translate:{'amount': productsPerPage}" color='azure-outline'></gd-button>
        <gd-button (click)='loadAllProducts()'
                   [label]="'showAll' | translate"
                   color='azure'></gd-button>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf='((cmsResults$ | async).length === 0 && (productResults$ | async).length) === 0'>
  <div class='tabs'>

    <div class='tab tab--inactive'>
      <div class='tab__count'>(0)</div>
      <div class='tab__label' [innerHTML]="'search.cms' | translate"></div>
    </div>
    <div class='tab tab--inactive'>
      <div class='tab__count'>(0)</div>
      <div class='tab__label' [innerHTML]="'search.products' | translate"></div>
    </div>

    <gd-feels-right-badge></gd-feels-right-badge>
  </div>

  <div class='results'>
    <h1 class='results__headline' [innerHTML]="'search.no-results.headline' | translate"></h1>
    <p>{{'search.no-results.copy' | translate}}</p>

    <div class='popular-searches__container'>
      <gd-pill-button [routerLink]='productOverviewLink$ | async'
                      color='maya-filled'
                      [label]="'search.showAllProducts' | translate"></gd-pill-button>
      <gd-pill-button
        *ngFor='let item of popularSearches$ | async'
        color='azure'
        [label]='item'
        (click)='search(item)'></gd-pill-button>
    </div>
  </div>
</ng-container>
