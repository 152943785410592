<ng-container *ngIf="showLink; else toggleMobileMenu">

  <ng-container [ngSwitch]="data?.brick_type">

    <a *ngSwitchCase="'NavigationLinkInternal'"
       [routerLink]="data.pageLink.pageLink?.url" (click)="clearSearchTerm()">
      <div class="label" [ngClass]="color" [innerHTML]="data.pageLink.pageLink?.name"></div>
      <div class="icon__container" [class.hide-icon]="data?.headline && data?.copy">
        <gd-icon [icon]="'simple-arrow'" [color]="color === 'azure' ? gdColor.SILVERLIGHT : gdColor.AZURE"></gd-icon>
      </div>
      <div *ngIf="data?.headline && data?.copy" class="line__container">
        <div class="line"></div>
      </div>
    </a>

    <a *ngSwitchCase="'navigationLinkInternal'"
       [routerLink]="data.pageLink.pageLink?.url" (click)="clearSearchTerm()">
      <div class="label" [ngClass]="color" [innerHTML]="data.pageLink.pageLink?.name"></div>

      <div class="icon__container"
           [class.hide-icon]="data?.headline && data?.copy"
           [class.arrow-animation]="(data?.headline || category?.headline) && (data?.copy || category?.excerpt)">
        <gd-icon [icon]="'simple-arrow'" [color]="isHovered ? 'maya' : color === 'azure' ? gdColor.SILVERLIGHT : gdColor.AZURE"></gd-icon>
      </div>
      <div class="line__container" *ngIf="(data?.headline || category?.headline) && (data?.copy || category?.excerpt)">
        <div class="line"></div>
      </div>
    </a>


    <a *ngSwitchCase="'navigationLinkExternal'" [href]="data.externalLink?.direct" [target]="data.externalLink?.target">
      <div class="label" [ngClass]="color" [innerHTML]="data.externalLink.text"></div>
      <div class="icon__container" [class.hide-icon]="data?.headline && data?.copy">
        <gd-icon [icon]="'simple-arrow'" [color]="isHovered ? 'maya' : color === 'azure' ? gdColor.SILVERLIGHT : gdColor.AZURE"></gd-icon>
      </div>
      <div *ngIf="data?.headline && data?.copy" class="line__container">
        <div class="line"></div>
      </div>
    </a>

    <a *ngSwitchCase="'navigationLinkFilter'"
       [routerLink]="productOverviewLink$ | async"
       [queryParams]="{filter: category ? category.filterID : data.filterId}" (click)="clearSearchTerm()">
      <div class="label" [ngClass]="color" [innerHTML]="data.buttonLabel"></div>

      <div class="icon__container"
           [class.arrow-animation]="(data?.headline || category?.headline) && (data?.copy || category?.excerpt)">
        <gd-icon [icon]="'simple-arrow'" [color]="isHovered ? 'maya' : color === 'azure' ? gdColor.SILVERLIGHT : gdColor.AZURE"></gd-icon>
      </div>


      <div *ngIf="(data?.headline || category?.headline) && (data?.copy || category?.excerpt)" class="line__container">
        <div class="line"></div>
      </div>
    </a>
  </ng-container>

  <flyout-teaser *ngIf="(data?.headline || category?.headline) && (data?.copy || category?.excerpt)"
                 [data]="data"
                 [brickType]="data?.brick_type"
                 [category]="category"
                 [color]="color"></flyout-teaser>
</ng-container>

<ng-template #toggleMobileMenu>
  <div class="label" [ngClass]="color" [innerHTML]="data.pageLink.pageLink?.name"></div>
  <div class="icon__container" [class.hide-icon]="data?.headline && data?.copy">
    <gd-icon [icon]="'simple-arrow'" [color]="color === 'azure' ? gdColor.SILVERLIGHT : gdColor.AZURE"></gd-icon>
  </div>
</ng-template>
