<div *ngIf="(filters$ | async).length"
     class="reset"
     [innerHTML]="'showAllProducts' | translate"
     (click)="resetFilters()"></div>
<div *ngIf="(filters$ | async).length === 0" class="reset">&nbsp;</div>

<searchbar
  [productSearch]="true"
  [productAutofill]="true"
  [setExactSearch]="exactSearch"
  [disableInText]="disableInText"
  (exact)="exactSearch = $event"
  [isProductOverview]="false"
  autofillTheme="dark"
  [placeholder]="'expertSearch.placeholder' | translate"></searchbar>

<gd-filter-tab *ngIf="(presets$ | async) as presets"
               [items]="presets"
               [icon]="'slide-control'"
               [color]="'maya'"
               [useAsLabel]="'title'"
               [useAsUnique]="'filterId'"
               [label]="'products.presets' | translate"
               [multi]="false"
               (activeItemsChange)="setFilter($event[0])"></gd-filter-tab>

<gd-filter-tab *ngFor="let filterGroup of (mainFilter$ | async)"
               [items]="filterGroup.entries"
               [icon]="filterGroup.group?.icon?.value"
               [useAsLabel]="'title'"
               [useAsUnique]="'filterId'"
               [color]="filterGroup.group?.color?.value"
               [label]="filterGroup.group.name"
               [multi]="true"
               (activeItemsChange)="setFilter($event[0])"
               [activeItems]="setPresetId$ | async"></gd-filter-tab>


