import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '@conception/ngx-pimcore-connect';
import {map} from 'rxjs/operators';
import {environment} from '@rz-gud/environment';
import {ApiConfig} from '@rz-gud/interfaces';
import {TranslateService} from '@ngx-translate/core';
import {CampusEvent, Category, FAQ, Glossary, MediaLibraryItem,} from '@rz-gud/models';
import {HelperService} from './helper.service';

@Injectable({
  providedIn: 'root',
})
export class CampusService {
  constructor(
    private readonly apiService: ApiService,
    private readonly helper: HelperService,
    private readonly translate: TranslateService
  ) {
  }

  /**
   * Returns a list of media library items
   *
   * @param config {ApiConfig} - Configuration object
   */
  getMediaLibraryItems(config: ApiConfig): Observable<Array<MediaLibraryItem>> {
    return this.apiService
      .postData(
        [this.translate.currentLang, environment.endpoints.mediaLibrary].join(
          '/'
        ),
        config
      )
      .pipe(
        map((items) => items['media-library']),
        map((items) => this.apiService.removeContentPath(items)),
        map((items) =>
          items.map((item) => new MediaLibraryItem().deserialize(item))
        )
      );
  }

  /**
   * Returns a list of FAQ entries
   *
   * @param config {ApiConfig} - Configuration object
   */
  getFAQEntries(config: ApiConfig): Observable<Array<FAQ>> {
    return this.apiService
      .postData(
        [this.translate.currentLang, environment.endpoints.faq].join('/'),
        config
      )
      .pipe(
        map((entries) => entries['campus-faq']),
        map((entries) => this.apiService.removeContentPath(entries)),
        map((entries) => entries.map((entry) => new FAQ().deserialize(entry)))
      );
  }

  /**
   * Returns a list of glossary entries
   *
   * @param config {ApiConfig} - Configuration object
   */
  getGlossaryEntries(config: ApiConfig): Observable<Array<Glossary>> {
    return this.apiService
      .postData(
        [this.translate.currentLang, environment.endpoints.glossary].join('/'),
        config
      )
      .pipe(
        map((entries) => entries['campus-glossary']),
        map((entries) => this.apiService.removeContentPath(entries)),
        map((entries) => entries.sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase())
          ? 1
          : ((b.title.toLowerCase() > a.title.toLowerCase()) ? -1 : 0))),
        map((entries) => entries.map((entry) => new Glossary().deserialize(entry)))
      );
  }

  /**
   * Returns a list of events
   *
   * @param config {ApiConfig} - Configuration object
   */
  getEvents(config: ApiConfig): Observable<Array<CampusEvent>> {
    return this.apiService
      .postData(
        [this.translate.currentLang, environment.endpoints.events].join('/'),
        config
      )
      .pipe(
        map((entries) => entries['campus-events']),
        map((entries) => this.apiService.removeContentPath(entries)),
        map((entries) =>
          entries.map((entry) => new CampusEvent().deserialize(entry))
        ),
        map((entries) => entries.sort(this.sortByDate('startDate')))
      );
  }

  /**
   * Returns a list of unique categories
   *
   * @param entries {Array<FAQ | MediaLibraryItem>} - Entries to search for categories
   * @param showAll {boolean} - Whether to add a "show all" option
   * @param type {'media' | 'faq'}
   */
  getUniqueCategories(
    entries: Array<FAQ | MediaLibraryItem>,
    showAll: boolean,
    type: 'media' | 'faq'
  ): Array<Category> {
    let items: Array<Category> = [];

    if (type === 'media') {
      items = this.helper.addUnique(
        entries.map((el) => el.category),
        'value'
      );
    } else if (type === 'faq') {
      entries.forEach((entry: FAQ) => {
        items.push(...entry.category);
      });
      items = this.helper.addUnique(items, 'value');
    }

    items = items.map((el: Category) => (new Category().deserialize({
      ...el,
      label: this.translate.instant(el.value)
    })));

    if (showAll) {
      return [
        new Category().deserialize({
          label: this.translate.instant('showAll'),
          value: 'all',
        }),
        ...items,
      ];
    } else {
      return items;
    }
  }

  private sortByDate(property: any) {
    let sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return (a, b) => {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */

      const timeA = new Date(a[property]).getTime();
      const timeB = new Date(b[property]).getTime();

      const result = timeA < timeB ? -1 : timeA > timeB ? 1 : 0;
      return result * sortOrder;
    };
  }
}
