<div class="product-detail-slider--inner">
  <gd-simple-button
    icon="long-arrow-back"
    class="history-back"
    [small]="true"
    [label]="'backToOverview' | translate"
    (click)="historyBack()"></gd-simple-button>

  <div class="images" id="top">
    <swiper
      #swiper
      [slidesPerView]="1"
      [navigation]="false"
      dragButtonHover=""
      [dragButtonHoverSize]="buttonSizes.SMALL"
      [dragButtonHoverIcon]="'zoom'"
      (activeIndexChange)="setCurrentSlide($event)">
      <ng-template swiperSlide *ngIf="content?.images?.bildFrontansicht?.urls">
        <div class="inner-slide" (click)="toggleOverlay('bildFrontansicht')">
          <img
            [src]="content?.images?.bildFrontansicht.getImage('ProductStage')"
            [alt]="content?.images?.bildFrontansicht?.meta?.alt"
            [title]="content?.images?.bildFrontansicht?.meta?.title" />

          <div class="note" *ngIf="content?.images?.bildFrontAehnlich">
            {{ 'similarImage' | translate }}
          </div>
        </div>
      </ng-template>
      <ng-template swiperSlide *ngIf="content?.images?.bildRueckansicht?.urls">
        <div class="inner-slide" (click)="toggleOverlay('bildRueckansicht')">
          <img
            [src]="content?.images?.bildRueckansicht.getImage('ProductStage')"
            [alt]="content?.images?.bildRueckansicht?.meta?.alt"
            [title]="content?.images?.bildRueckansicht?.meta?.title" />
          <div class="note" *ngIf="content?.images?.bildRueckAehnlich">
            {{ 'similarImage' | translate }}
          </div>
        </div>
      </ng-template>
    </swiper>
    <gd-slider-navigation
      *ngIf="content?.images?.bildRueckansicht?.urls && content?.images?.bildFrontansicht?.urls"
      [maxSlides]="maxSlides"
      [currentSlide]="currentSlide"
      (jumpTo)="swiper.swiperRef.slideTo($event)"
      (navigation)="
        $event === 'next' ? swiper.swiperRef.slideNext() : swiper.swiperRef.slidePrev()
      "></gd-slider-navigation>
    <gd-round-button *ngIf="isTouch" [size]="buttonSizes.SMALL" icon="zoom" [color]="gdColor.YELLOW"></gd-round-button>
  </div>
  <div class="content">
    <div class="content__top">
      <h1 [innerHTML]="title"></h1>
      <p class="copy" [innerHTML]="'productNumber' | translate: { 'productNumber': content.sku }"></p>
    </div>
    <div class="content__bottom">
      <p class="headline-3" [innerHTML]="this.content.getText()"></p>
      <div
        class="content__buttons"
        [class.content__buttons--spacing]="!!productDetailDownloadsIdHash || !!productDetailInterfacesIdHash">
        <gd-button
          [color]="(amIBookmarked$ | async) ? 'maya' : 'azure'"
          [label]="(amIBookmarked$ | async) ? ('removeFromWatchlist' | translate) : ('addToWatchlist' | translate)"
          (click)="bookmark()"></gd-button>
        <gd-button color="white-maya" [label]="'requestProductNow' | translate" (click)="request()"></gd-button>
      </div>

      <ng-container *ngIf="env.features.comparisonList">
        <gd-simple-button
          *ngIf="(amIOnComparisonList$ | async) === false"
          [inactive]="comparisonListFilled$ | async"
          class="add-to-list"
          [label]="'compareProduct' | translate"
          icon="simple-arrow"
          [small]="true"
          (click)="putToComparisonList()"></gd-simple-button>
        <gd-simple-button
          *ngIf="amIOnComparisonList$ | async"
          class="remove-from-list"
          [label]="'compareProductRemove' | translate"
          icon="simple-arrow"
          [small]="true"
          (click)="removeFromComparisonList()"></gd-simple-button>
      </ng-container>
      <br />
      <ng-container *ngIf="productDetailInterfacesIdHash">
        <gd-simple-button
          icon="simple-arrow"
          [label]="'viewInterfaces' | translate"
          [small]="true"
          (click)="scrollTo(productDetailInterfacesIdHash)">
        </gd-simple-button>
        <br />
      </ng-container>
      <ng-container *ngIf="productDetailDownloadsIdHash">
        <gd-simple-button
          icon="simple-arrow"
          [label]="'viewDownloads' | translate"
          [small]="true"
          (click)="scrollTo(productDetailDownloadsIdHash)"></gd-simple-button>
        <br />
      </ng-container>
    </div>
  </div>
  <div
    class="image-overlay"
    @slideInOutBottom
    *ngIf="
      showOverlay &&
      (content?.images?.bildFrontansicht.urls['ProductStage'] || content?.images?.bildRueckansicht.urls['ProductStage'])
    ">
    <div class="image-overlay__inner">
      <gd-round-button
        *ngIf="isTouch"
        [color]="gdColor.YELLOW"
        icon="close"
        [size]="buttonSizes.SMALL"
        (click)="toggleOverlay('')"></gd-round-button>
      <img
        class="image-overlay__image"
        dragButtonHover=""
        [dragButtonHoverSize]="buttonSizes.SMALL"
        [dragButtonHoverIcon]="'close'"
        (click)="toggleOverlay('')"
        [src]="content?.images[activeImage]?.getImage('ProductStage')"
        [alt]="content?.images[activeImage]?.meta?.alt"
        [title]="content?.images[activeImage]?.meta?.title" />
      <div class="image-overlay__teaser--container">
        <div
          class="image-overlay__teaser"
          *ngIf="content?.images?.bildFrontansicht?.urls"
          (click)="activeImage = 'bildFrontansicht'"
          [class.active]="activeImage === 'bildFrontansicht'">
          <img
            [src]="content?.images?.bildFrontansicht.getImage('ProductStage')"
            [alt]="content?.images?.bildFrontansicht.meta?.alt"
            [title]="content?.images?.bildFrontansicht.meta?.title" />
        </div>
        <div
          class="image-overlay__teaser"
          *ngIf="content?.images?.bildRueckansicht?.urls"
          (click)="activeImage = 'bildRueckansicht'"
          [class.active]="activeImage === 'bildRueckansicht'">
          <img
            [src]="content?.images?.bildRueckansicht.getImage('ProductStage')"
            [alt]="content?.images?.bildRueckansicht.meta?.alt"
            [title]="content?.images?.bildRueckansicht.meta?.title" />
        </div>
      </div>
    </div>
  </div>
</div>
