<gd-arrow-indicator [isOpen]="isOpen" *ngIf="isToggable"></gd-arrow-indicator>
<div class="label" [innerHTML]="label" *ngIf="label"></div>

<div class="answers__container" [class.answers__container--fixed]="!isToggable" *ngIf="isOpen && items.length">
  <div class="answers" @scaleInCenter>
    <ng-scrollbar>
    <div class="answers__label">{{description1}}</div>
      <div *ngFor="let option of items | filter: 'lang': 'en_US': true,"
           class="option"
           (click)="setActive(option)"
           [class.option--is-selected]="isActive(option)">
        {{getLabel(option)}}
        <gd-icon icon="checkmark" color="maya"></gd-icon>
      </div>
    <ng-container *ngIf="(items | filter: 'lang': 'en_US') as filteredItems">
    <div class="answers__label">{{description2}}</div>
      <div *ngFor="let option of filteredItems"
           class="option"
           (click)="setActive(option)"
           [class.option--is-selected]="isActive(option)">
        {{getLabel(option).replace('(United States)', '')}}
        <gd-icon icon="checkmark" color="maya"></gd-icon>
      </div>
    </ng-container>
    </ng-scrollbar>
  </div>
</div>
