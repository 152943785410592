import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Story } from '@rz-gud/models';
import { ApiService } from '@conception/ngx-pimcore-connect';
import { map, mergeMap } from 'rxjs/operators';
import { environment } from '@rz-gud/environment';
import { ApiConfig } from '@rz-gud/interfaces';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class StoryService {
  constructor(
    private readonly apiService: ApiService,
    private readonly translate: TranslateService,
    private readonly router: Router
  ) {}

  /**
   * Returns a list of stories
   *
   * @param config {ApiConfig} - Configuration object
   */
  getStories(config: ApiConfig): Observable<Array<Story>> {
    return this.apiService
      .postData(
        [this.translate.currentLang, environment.endpoints.stories].join('/'),
        config
      )
      .pipe(
        map((stories) => stories['stories-overview']),
        map((stories) => this.apiService.removeContentPath(stories)),
        map((stories) => stories.map((story) => new Story().deserialize(story)))
      );
  }

  /**
   * Returns a random story
   *
   * @param config {ApiConfig} - Configuration object
   */
  getRandomStory(config: ApiConfig): Observable<Story> {
    return this.getStories(config).pipe(
      map((stories) =>
        stories.filter((el) => el.detailPage?.path !== this.router.url)
      ),
      map((stories) => stories[Math.floor(Math.random() * stories.length)])
    );
  }

  /**
   * Returns the next story
   *
   * @param config {ApiConfig} - Configuration object
   */
  getNextStory(config: ApiConfig): Observable<Story> {
    return this.getStories(config).pipe(
      mergeMap((stories) =>
        this.findIndex(this.router.url, stories).pipe(
          map((index) => ({ index, stories }))
        )
      ),
      map(({ stories, index }) => stories[index])
    );
  }

  /**
   * Finds the index of the subsequent element
   *
   * @param notSlug {string} - The id of the current story
   * @param stories {Array<Story>} - The full set of all stories
   * @private
   */
  private findIndex(
    notSlug: string,
    stories: Array<Story>
  ): Observable<number> {
    const currentIndex =
      stories.findIndex((el) => el.detailPage.path === notSlug) + 1;
    return of(currentIndex + 1 >= stories.length ? 0 : currentIndex + 1);
  }
}
