import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { DynamicsActions, DynamicsSelectors, PageSelectors } from '@rz-gud/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { SimpleType } from '@rz-gud/interfaces';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'dynamics-form-overlay',
  templateUrl: './dynamics-form-overlay.component.html',
  styleUrls: ['./dynamics-form-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicsFormOverlayComponent implements OnInit {
  readonly currentDynamicsForm$: Observable<string> = this.store.select(DynamicsSelectors.currentDynamicsForm);

  dynamicsHostName: string;
  dynamicsWebsiteId: string;
  dynamicsFormBlockId: string;
  dynamicsPrefillFieldId: string;

  productInquiryDynamicsWebsiteId$: Observable<string>;
  productInquiryDynamicsHostname$: Observable<string>;
  productInquiryDynamicsFormBlockId$: Observable<string>;
  productInquiryDynamicsPrefillFieldId$: Observable<string>;

  productQuestionDynamicsWebsiteId$: Observable<string>;
  productQuestionDynamicsHostname$: Observable<string>;
  productQuestionDynamicsFormBlockId$: Observable<string>;
  productQuestionDynamicsPrefillFieldId$: Observable<string>;

  prefillProducts$: Observable<Array<SimpleType>>;

  constructor(private readonly store: Store, private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
    const eventer = window[eventMethod];
    const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';

    eventer(
      messageEvent,
      (e) => {
        if (typeof e.data === 'string' && e.data.indexOf('documentHeight:') > -1) {
          const height = e.data.split('documentHeight:')[1];
          document.querySelector('iframe[srcdoc]')?.setAttribute('height', height);
        }
      },
      false
    );

    this.productInquiryDynamicsWebsiteId$ = this.store.select(PageSelectors.productInquiryDynamicsWebsiteId);
    this.productInquiryDynamicsHostname$ = this.store.select(PageSelectors.productInquiryDynamicsHostname);
    this.productInquiryDynamicsFormBlockId$ = this.store.select(PageSelectors.productInquiryDynamicsFormBlockId);
    this.productInquiryDynamicsPrefillFieldId$ = this.store.select(PageSelectors.productInquiryDynamicsPrefillFieldId);

    this.productQuestionDynamicsWebsiteId$ = this.store.select(PageSelectors.productQuestionDynamicsWebsiteId);
    this.productQuestionDynamicsHostname$ = this.store.select(PageSelectors.productQuestionDynamicsHostname);
    this.productQuestionDynamicsFormBlockId$ = this.store.select(PageSelectors.productQuestionDynamicsFormBlockId);
    this.productQuestionDynamicsPrefillFieldId$ = this.store.select(
      PageSelectors.productQuestionDynamicsPrefillFieldId
    );
    this.prefillProducts$ = this.store.select(DynamicsSelectors.prefillProducts);

    this.currentDynamicsForm$.pipe(take(1)).subscribe((currentFormName) => {
      if (currentFormName === 'question') {
        // question form values
        this.productQuestionDynamicsHostname$.pipe(take(1)).subscribe((hostname) => {
          this.dynamicsHostName = hostname;
        });
        this.productQuestionDynamicsWebsiteId$.pipe(take(1)).subscribe((websiteId) => {
          this.dynamicsWebsiteId = websiteId;
        });
        this.productQuestionDynamicsFormBlockId$.pipe(take(1)).subscribe((formBlockId) => {
          this.dynamicsFormBlockId = formBlockId;
        });
        this.productQuestionDynamicsPrefillFieldId$.pipe(take(1)).subscribe((prefillFieldId) => {
          this.dynamicsPrefillFieldId = prefillFieldId;
        });
      } else {
        // inquiry form values
        this.productInquiryDynamicsHostname$.pipe(take(1)).subscribe((hostname) => {
          this.dynamicsHostName = hostname;
        });
        this.productInquiryDynamicsWebsiteId$.pipe(take(1)).subscribe((websiteId) => {
          this.dynamicsWebsiteId = websiteId;
        });
        this.productInquiryDynamicsFormBlockId$.pipe(take(1)).subscribe((formBlockId) => {
          this.dynamicsFormBlockId = formBlockId;
        });
        this.productInquiryDynamicsPrefillFieldId$.pipe(take(1)).subscribe((prefillFieldId) => {
          this.dynamicsPrefillFieldId = prefillFieldId;
        });
      }
    });
  }

  close(): void {
    this.store.dispatch(DynamicsActions.closeForm());
  }

  generateFormHTMLCode(): SafeHtml {
    let prefillProductString = '';
    this.prefillProducts$.pipe(take(1)).subscribe((prefillProducts) => {
      prefillProductString = prefillProducts.length ? prefillProducts[0].value : '';
    });

    const htmlCode = `
    <html>
      <head>
        <title>Microsoft Dynamics 365 Form</title>

        <style>
          .dialogProviderContainerStyle {
            width: auto !important;
            height: auto !important;
            min-height: 500px;
          }
        </style>
        <script src='https://ajax.aspnetcdn.com/ajax/jQuery/jquery-3.7.1.min.js'></script>
      </head>

      <body>
        <div id='dynamics--form'>
          <div data-form-block-id='${this.dynamicsFormBlockId}'></div>
          <div id='${this.dynamicsWebsiteId}'></div>
          <div class='d365-mkt-config' style='display: none;'
          data-website-id='${this.dynamicsWebsiteId}' data-hostname='${this.dynamicsHostName}'></div>

            <script>
              const loadScript = (url) => {
                const script = document.createElement('script');
                script.src = url;
                document.body.appendChild(script);
              };

              loadScript('https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js?v=1.84.2007');
              loadScript('https://mktdplp102cdn.azureedge.net/public/latest/js/ws-tracking.js?v=1.84.2007');

              const onElementReady = (selector) =>
              new Promise((resolve, reject) => {
                const el = document.querySelector(selector);
                if (el) {
                  resolve(el);
                  return;
                }
                new MutationObserver((mutationRecords, observer) => {
                  // Query for elements matching the specified selector
                  Array.from(document.querySelectorAll(selector)).forEach((element) => {
                    resolve(element);
                    // we have resolved -> destroy observer
                    observer.disconnect();
                  });
                }).observe(document.documentElement, {
                  childList: true,
                  subtree: true,
                });
              });

            const postSizeToParent = () => {
              const newDocumentHeight = document.getElementById('dynamics--form').scrollHeight;
              const message = 'documentHeight:'+ newDocumentHeight;
              parent.postMessage(message,'*');
            }

            window.onresize = () => {
              postSizeToParent();
            }

            window.onload = () => {
              postSizeToParent();

              onElementReady("input[name='${this.dynamicsPrefillFieldId}']").then(() => {
                document.querySelector("input[name='${this.dynamicsPrefillFieldId}']").setAttribute(
                    'value',
                    '${prefillProductString}'
                  );
                });
              }
          </script>
        </div>
      </body>
    </html>
  `;

    return this.sanitizer.bypassSecurityTrustHtml(htmlCode);
  }
}
