<div class="container" [class.container--has-started]="(easymode$ | async).stage > 0">
  <ng-container *ngIf="(easymode$ | async).stage as stage; then questionBlock else startBlock"></ng-container>
</div>


<ng-template #startBlock>
  <div class="inner-container">
    <div class="headline" [innerHTML]="content.headline + '<br><hr><span>' + this.content.subline + '</span>'"
         @slideHorizontal></div>

    <div class="action-area" @slideHorizontal>
      <div class="action-area__label" [innerHTML]="content.cta"></div>

      <gd-round-button [icon]="'next'"
                       [color]="gdColors.MAYA"
                       [size]="buttonSizes.MEDIUM"
                       (click)="nextStage()"></gd-round-button>
    </div>
  </div>
</ng-template>


<ng-template #questionBlock let-stage>
  <ng-container *ngFor="let i of [0, 1, 2]">
    <div class="inner-container" *ngIf="stage === (i + 1)">
      <div class="subline" [innerHTML]="content.headline"></div>
      <div class="headline" [innerHTML]="content.qaBlock[i].question" @slideHorizontal></div>

      <div class="action-area" @slideHorizontal>
        <gd-round-button [icon]="'next'"
                         [color]="gdColors.MAYA"
                         [size]="buttonSizes.MEDIUM"
                         [inactive]="!(easymode$ | async).answers[i].label"
                         (click)="nextStage()"></gd-round-button>

        <easy-mode-dropdown [availableAnswers]="content.qaBlock[i].answers"></easy-mode-dropdown>
      </div>
      <div class="back" *ngIf="i > 0" (click)="previousStage()">
        <gd-icon class="back__icon" [icon]="'long-arrow-left'" [color]="'silver-light'"></gd-icon>
        <div class="back__label">{{'back' | translate}}</div>
      </div>
    </div>
  </ng-container>

  <easy-mode-indicator *ngIf="stage > 0"
                       [qaBlock]="content.qaBlock"
                       @slideInOutBottom></easy-mode-indicator>
</ng-template>
