<ng-container *ngIf="(stories$ | async) as stories">
  <div class="intro-block">
    <h2 [innerHTML]="content.headline" class="intro-block__headline"></h2>

    <div class="decorator intro-block__decorator">
      <gd-icon icon="extender-signet" color="maya" class="decorator__icon"></gd-icon>
      <div class="decorator__label" [innerHTML]="content.subline"></div>
    </div>
  </div>

  <div class="teaser-grid">
    <story-teaser
      *ngFor="let story of stories; let i = index"
      [story]="story"
      [layout]="(i === 1 || i === 2) ? 'vertical' : 'horizontal'"></story-teaser>
  </div>

  <div class="to-overview">
    <a [routerLink]="storyOverview$ | async">
      <gd-simple-button [label]="'toOverview' | translate"></gd-simple-button>
    </a>
  </div>
</ng-container>
