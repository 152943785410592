import {Component, HostListener, Inject, Input, PLATFORM_ID} from '@angular/core';
import SwiperCore, {Grid, SwiperOptions} from 'swiper';
import {GDColors} from '@conception/ng-gud-ui';
import {isPlatformBrowser} from '@angular/common';
import {Product} from '@rz-gud/models';
import {TranslateService} from '@ngx-translate/core';
import {PimLink} from '@conception/ngx-pimcore-connect';

SwiperCore.use([Grid]);

@Component({
  selector: 'product-highlight-slider',
  templateUrl: './product-highlight-slider.component.html',
  styleUrls: ['./product-highlight-slider.component.scss']
})
export class ProductHighlightSliderComponent {

  @Input() overviewButton: PimLink;


  config: SwiperOptions = {
    spaceBetween: 1,
    slidesPerView: 1,
    grid: {
      rows: 1
    },
    breakpoints: {
      767: {
        spaceBetween: 1,
        slidesPerView: 2,
        grid: {
          rows: 1
        }
      }

    }
  };

  items: Array<Product> = [];
  currentSlide: number = 1;
  maxSlides: number = 0;
  showNavigation: boolean = true;
  gdColors = GDColors;

  private readonly isBrowser: boolean = false;

  constructor(@Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
              readonly translate: TranslateService) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  @Input() set productHighlights(items: Array<Product>) {
    if (items) {
      this.items = items;
      this.setup();
    }
  }

  @HostListener('window:resize')
  onWindowResize() {
    this.setup();
  }

  setCurrentSlide(event): void {
    this.currentSlide = +event.realIndex + 1;
  }

  setup(): void {
    if (this.isBrowser) {
      if (this.items.length > 2) {
        this.maxSlides = window.innerWidth >= 1020 ? 2 : 1;
        this.showNavigation = this.maxSlides > 1;
      } else {
        this.showNavigation = false;
      }
    }
  }

}
