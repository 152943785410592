import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { ApiService, ContentElementModel } from '@conception/ngx-pimcore-connect';
import { ProductComparisonModel } from './product-comparison.model';
import { Observable, Subscription } from 'rxjs';
import { DynamicsService, HubspotService, ProductService } from '@rz-gud/services';
import { Store } from '@ngrx/store';
import {
  BookmarkActions,
  BookmarkSelectors,
  BookmarkState,
  ComparisonActions,
  ComparisonSelectors,
  DynamicsSelectors,
  PageSelectors,
} from '@rz-gud/store';
import { Comparison, ComparisonProduct, Product } from '@rz-gud/models';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { environment } from '@rz-gud/environment';

@Component({
  selector: 'ce-product-comparison',
  templateUrl: './product-comparison.component.html',
  styleUrls: ['./product-comparison.component.scss'],
})
export class ProductComparisonComponent implements OnInit, OnDestroy {
  @ContentElementModel(ProductComparisonModel) content: ProductComparisonModel;
  @ViewChild('shareInput') shareInputRef: ElementRef;
  @Output() bookmarkClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('pdfTemplate') pdfTemplate: ElementRef;

  productInquiryFormHostName$: Observable<string>;
  productInquiryFormWebsiteId$: Observable<string>;
  productInquiryFormBlockId$: Observable<string>;

  printing: boolean = false;
  markSame: boolean = false;
  hideSame: boolean = false;
  preFetchProducts: Array<Product> = [];
  products: Array<Product> = [];
  idList: Array<number> = [];
  baseUrl$: string = '';
  shareLinkPrefix: string = '';
  shareLinkParams: string = '';
  shareMode: boolean = false;
  triggeredCopy: boolean = false;
  isBookmarked$: Observable<boolean>;
  detailLink$: Observable<string>;
  productSlugs = [];
  readonly productOverviewLink$: Observable<string> = this.store.select(PageSelectors.productOverview);
  readonly comparison$: Observable<Comparison> = this.store.select(ComparisonSelectors.comparison);
  readonly bookmarks$: Observable<BookmarkState> = this.store.select(BookmarkSelectors.select);
  readonly isLoading$: Observable<boolean> = this.store.select(ComparisonSelectors.isLoading);
  private sub: Subscription;

  private readonly isBrowser: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private readonly productService: ProductService,
    private readonly hubspotService: HubspotService,
    private readonly dynamicsService: DynamicsService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly store: Store,
    private readonly apiService: ApiService,
    private readonly translate: TranslateService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.setup();
  }

  ngOnInit(): void {
    this.productInquiryFormHostName$ = this.store.select(DynamicsSelectors.productInquiryFormHostName);
    this.productInquiryFormWebsiteId$ = this.store.select(DynamicsSelectors.productInquiryFormWebsiteId);
    this.productInquiryFormBlockId$ = this.store.select(DynamicsSelectors.productInquiryFormBlockId);

    this.detailLink$ = this.store.select(PageSelectors.productDetail);
    this.baseUrl$ =
      window.location.protocol +
      '//' +
      window.location.hostname +
      (window.location.port ? `:${window.location.port}` : '');
    this.store.dispatch(ComparisonActions.onPage());
    this.comparison$.pipe(take(1)).subscribe((comparison) => {
      comparison.products.forEach((product: ComparisonProduct) => {
        this.isBookmarked$ = this.store.select(BookmarkSelectors.amIBookmarked(product.id));
      });
    });
  }

  ngOnDestroy(): void {
    this.store.dispatch(ComparisonActions.notOnPage());
    this.sub?.unsubscribe();
  }

  removeFromList(id: number): void {
    this.store.dispatch(ComparisonActions.removeFromList({ id }));
  }

  historyBack(): void {
    history.back();
  }

  setup(): void {
    if (this.activatedRoute.snapshot.queryParamMap.has('share')) {
      this.shareMode = true;
      this.idList = atob(this.activatedRoute.snapshot.queryParamMap.get('share'))
        .split(',')
        .map((el) => parseInt(el, 10));
      this.store.dispatch(ComparisonActions.clearList());
      this.idList.map((id) => this.store.dispatch(ComparisonActions.addToList({ id })));
      this.store.dispatch(ComparisonActions.buildList({ lang: this.translate.currentLang }));
      this.buildProducts();
      if (this.isBrowser) {
        this.getProductSlugs();
        this.shareLinkPrefix = window.location.pathname;
      }
    } else {
      this.shareMode = false;
      this.sub = this.store.select(ComparisonSelectors.select).subscribe((products) => {
        this.idList = products.resultList.products.map((product) => product.id);
        this.buildProducts();
        if (this.isBrowser) {
          this.getProductSlugs();
          this.shareLinkPrefix = document.location.toString() + '?share=';
        }
      });
    }
  }

  getProductSlugs() {
    this.idList.forEach((id) => {
      this.productService
        .getProduct(id)
        .pipe(take(1))
        .subscribe((product) => {
          this.productSlugs.push({ id: product.id, slug: product.urlSlug });
        });
    });
  }

  getProductSlug(id) {
    return this.productSlugs?.length && id ? this.productSlugs?.find((p) => p.id === id)?.slug : '';
  }

  buildProducts(): void {
    this.preFetchProducts = [];
    this.shareLinkParams = btoa(String(this.idList));
    setTimeout(
      () =>
        this.idList.forEach((id) => {
          this.productService
            .getProduct(id)
            .pipe(take(1))
            .subscribe((product) => {
              this.preFetchProducts.push(product);
            });
        }),
      200
    );

    this.products = this.preFetchProducts;
  }

  select(e: EventTarget): void {
    (e as HTMLInputElement).select();
  }

  copyLink(): void {
    this.shareInputRef.nativeElement.select();
    document.execCommand('copy');
    this.shareInputRef.nativeElement.setSelectionRange(0, 0);
    this.triggeredCopy = true;
  }

  request(name: string, sku: string): void {
    this.productInquiryFormWebsiteId$.pipe(take(1)).subscribe((websiteId) => {
      this.productInquiryFormHostName$.pipe(take(1)).subscribe((hostName) => {
        this.productInquiryFormBlockId$.pipe(take(1)).subscribe((blockId) => {
          if (websiteId && hostName && blockId) {
            this.dynamicsService.inquiry([
              {
                name,
                sku,
              },
            ]);
          } else {
            this.hubspotService.inquiry(name, sku);
          }
        });
      });
    });
  }

  hasDifferences(fields) {
    const firstArray = fields[0];
    return !fields?.every(
      (field) => field.length === firstArray.length && field.every((value, index) => value === firstArray[index])
    );
  }

  hideValuesNotAvailableInFields(fields) {
    return !fields?.every((field) =>
      field.every((value, index) => value === 'Value not available' || value === 'Wert nicht verfügbar')
    );
  }

  hideValuesNotAvailableInRow(row) {
    return row.subRows.some((subRow) => this.hideValuesNotAvailableInFields(subRow.fields));
  }

  hasRowDifferences(row) {
    return row.subRows.some((subRow) => this.hasDifferences(subRow.fields));
  }

  handleBookmark(id: number): void {
    this.store.dispatch(BookmarkActions.toggleProduct({ id }));
  }

  isBookmarked(product: ComparisonProduct, bookmarks: BookmarkState): boolean {
    return bookmarks.products.some((id) => id === product.id);
  }

  checkButtonWidth(bookmarkContainer) {
    const ctaContainer = bookmarkContainer.parentNode;
    const tooltipContainer = bookmarkContainer.querySelector('.tooltip');
    const btnWidth = ctaContainer.offsetWidth - bookmarkContainer.offsetWidth;
    tooltipContainer.style.width = btnWidth + 'px';
  }

  toggleTableValues() {
    this.hideSame = !this.hideSame;
  }

  toggleTableColor() {
    this.markSame = !this.markSame;
  }

  openPDF() {
    this.printing = true;
    setTimeout(() => {
      this.generateTableAsBlob();
    }, 500);
  }

  generateTableAsBlob(): void {
    const tableHtml = this.pdfTemplate.nativeElement.outerHTML;
    const styles = this.getStyles();
    const fullHtml = `<html><head><style>${styles}</style></head><body>${tableHtml}</body></html>`;
    const data = {
      tableHTML: fullHtml,
      shareId: this.shareLinkParams,
    };

    this.apiService
      .postData([this.translate.currentLang, environment.endpoints.comparisonPdf].join('/'), data)
      .subscribe((response) => {
        if (response.pdfContent) {
          const formattedDate = `_${new Date().toISOString().slice(0, 10)}_${new Date()
            .toLocaleTimeString('en-US', { hour12: false })
            .replace(/:/g, '-')}`;

          const pdfBlob = new Blob(
            [
              Uint8Array.from(
                atob(response.pdfContent)
                  .split('')
                  .map((char) => char.charCodeAt(0))
              ),
            ],
            { type: 'application/pdf' }
          );
          saveAs(pdfBlob, this.translate.instant('product.comparison.pdf.headline') + formattedDate + '.pdf');
        } else if (!response.ok) {
          console.log('Error while generating the PDF');
        }
        this.printing = false;
      });
  }

  getStyles(): string {
    const styles = [];
    const styleElements = Array.from(document.querySelectorAll('style'));
    for (const styleElement of styleElements) {
      styles.push(styleElement.innerHTML);
    }
    return styles.join('\n');
  }
}
