import {Component, ElementRef, ViewChild} from '@angular/core';
import {GDColors} from '@conception/ng-gud-ui';
import {ContentElementModel} from '@conception/ngx-pimcore-connect';
import {BranchTabpanelModel} from './branch-tabpanel.model';

@Component({
  selector: 'ce-content-tabs',
  templateUrl: './branch-tabpanel.component.html',
  styleUrls: ['./branch-tabpanel.component.scss']
})
export class BranchTabpanelComponent {

  @ContentElementModel(BranchTabpanelModel) content: BranchTabpanelModel;
  @ViewChild('tabContent') tabContent: ElementRef;

  gdColors = GDColors;
  activeTab: number = 0;

  constructor() {
  }

  scrollTop(): void {
    if (this.tabContent) {
      this.tabContent.nativeElement.scrollIntoView(true);
    }
  }
}
