import { Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap, withLatestFrom } from 'rxjs/operators';
import { LayoutActions } from '../actions';
import { Store } from '@ngrx/store';
import { LayoutSelectors } from '../selectors';
import { isPlatformBrowser } from '@angular/common';
import { PageMode } from '@rz-gud/store';

@Injectable()
export class LayoutEffects {

  setFixed$ = createEffect(() => this.actions$.pipe(
      ofType(LayoutActions.toggleFixed, LayoutActions.setUnfixed, LayoutActions.setFixed, LayoutActions.setFixedState),
      withLatestFrom(this.store.select(LayoutSelectors.fixed)),
      tap(([action, latest]) => {
        if (this.isBrowser) {
          if (latest) {
            this.renderer.addClass(document.body, 'fixed');
          } else {
            this.renderer.removeClass(document.body, 'fixed');
          }
        }
      })),
    {dispatch: false}
  );

  setExpert$ = createEffect(() => this.actions$.pipe(
      ofType(LayoutActions.setPageMode),
      tap((action) => {
        if(action.mode === PageMode.EXPERT) {
          this.store.dispatch(LayoutActions.dismissPageModeTutorial());
        }
      })),
    {dispatch: false}
  );

  private readonly isBrowser: boolean = false;
  private readonly renderer: Renderer2;

  constructor(private readonly actions$: Actions,
              private readonly store: Store,
              private readonly rendererFactory: RendererFactory2,
              @Inject(PLATFORM_ID) private platformId: Record<string, unknown>) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.renderer = rendererFactory.createRenderer(null, null);
  }
}
