import {Component} from '@angular/core';
import {FocusSliderModel} from './focus-slider.model';
import {GDColors} from '@conception/ng-gud-ui';
import {ContentElementModel} from '@conception/ngx-pimcore-connect';
import SwiperCore, {Pagination} from 'swiper';

SwiperCore.use([Pagination]);


@Component({
  selector: 'ce-focus-slider',
  templateUrl: './focus-slider.component.html',
  styleUrls: ['./focus-slider.component.scss']
})
export class FocusSliderComponent {
  @ContentElementModel(FocusSliderModel) content: FocusSliderModel;
  gdColors = GDColors;
}
