import {Component} from '@angular/core';
import {ContentElementModel} from '@conception/ngx-pimcore-connect';
import {TextBlocksModel} from './text-blocks.model';
import {GDColors} from '@conception/ng-gud-ui';

@Component({
  selector: 'ce-text-blocks',
  templateUrl: './text-blocks.component.html',
  styleUrls: ['./text-blocks.component.scss']
})
export class TextBlocksComponent {
  @ContentElementModel(TextBlocksModel) content: TextBlocksModel;
  readonly gdColors = GDColors;
}
