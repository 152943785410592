import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ObjectHit, PageHit } from '@rz-gud/models';
import { ApiService } from '@conception/ngx-pimcore-connect';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { PageSelectors, SearchSelectors } from '@rz-gud/store';
import { HighlightTextService } from '@rz-gud/services/highlight-text.service';

@Component({
  selector: 'search-teaser',
  templateUrl: './search-teaser.component.html',
  styleUrls: ['./search-teaser.component.scss'],
})
export class SearchTeaserComponent implements OnInit, OnDestroy {
  @Input() entry: any;
  pageHit: PageHit;
  objectHit: ObjectHit;
  objectHitUrl: string = '';
  searchString: string = '';
  hasPagePath: boolean = false;
  private sub: Subscription;
  private sub2: Subscription;

  constructor(
    private readonly apiService: ApiService,
    private readonly store: Store,
    private highlightTextService: HighlightTextService
  ) {}

  ngOnInit(): void {
    this.sub = this.store.select(PageSelectors.siteInfo).subscribe((infoObject) => {
      if (this.entry?.pagePath) {
        this.hasPagePath = true;
      }
      switch (this.entry?.type) {
        case 'PageHit':
          this.entry.pagePath = this.apiService.removeContentPath(this.entry.pagePath);
          this.pageHit = this.entry;
          break;
        case 'ObjectHit':
          this.objectHit = this.entry;
          if (typeof infoObject[this.objectHit.class.toLowerCase()] !== 'undefined') {
            this.objectHitUrl =
              infoObject[this.objectHit.class.toLowerCase() + 'Page'].path + '?scroll-to=c_' + this.objectHit.id;
          } else if (
            // set glossary link
            this.objectHit.class.toLowerCase() === 'glossary'
          ) {
            this.objectHit = this.entry;
            this.objectHitUrl = infoObject.glossaryPage.path;
          } else if (
            // set event link
            this.objectHit.class.toLowerCase() === 'event'
          ) {
            this.objectHit = this.entry;
            this.objectHitUrl = infoObject.eventPage.path;
          } else if (this.objectHit.class.toLowerCase() === 'faq') {
            // set faq link
            this.objectHit = this.entry;
            this.objectHitUrl = infoObject.faqPage.path;
          } else if (this.objectHit.class.toLowerCase() === 'mediathek') {
            // set mediathek link
            this.objectHit = this.entry;
            this.objectHitUrl = infoObject.mediathekPage.path;
          }
          break;
      }
    });

    this.sub2 = this.store.select(SearchSelectors.term).subscribe((term) => (this.searchString = term));
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
    this.sub2?.unsubscribe();
  }

  processUrl(urlPath: string): string {
    let result: string = '';
    const path = urlPath.split('/');
    path.splice(0, 2);
    path.forEach((url) => (result += '/' + url));
    return result;
  }

  processTextHits(textHits: Array<string>): string {
    let resultText: string = '';
    textHits.forEach((textHit) => {
      const highlightedText = this.highlightTextService.processText(textHit, this.searchString, true);
      const words = highlightedText.split(' ');
      const indexOfSearchTerm = words.map((word) => word.toLowerCase()).findIndex((i) => i.includes(this.searchString));
      const result = words
        .filter((word, index) => index > indexOfSearchTerm - 6 && index < indexOfSearchTerm + 6)
        .join(' ');
      resultText +=
        (indexOfSearchTerm > 6 ? '... ' : '') + result + (indexOfSearchTerm < words.length - 6 ? ' ... ' : '');
    });

    return resultText;
  }

  removeHtmlTagFromString(text: string): string {
    return text?.replace(/<[^>]+>/g, '');
  }

  highlightedText(text: string): string {
    return text?.replace(new RegExp(this.searchString, 'gi'), (match) => '<mark>' + match + '</mark>');
  }

  shortenString(text: string): string {
    if (this.hasPagePath) {
      return text.length > 300 ? text.slice(0, 300) + ' ...' : text;
    } else {
      return text;
    }
  }
}
