import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { HubspotActions, HubspotSelectors } from '@rz-gud/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'form-overlay',
  templateUrl: './form-overlay.component.html',
  styleUrls: ['./form-overlay.component.scss'],
})
export class FormOverlayComponent implements OnInit {
  formOverlayId$: Observable<string>;

  constructor(private readonly store: Store) {}

  ngOnInit(): void {
    this.formOverlayId$ = this.store.select(HubspotSelectors.overlayFormId);
  }

  close(): void {
    this.store.dispatch(HubspotActions.closeForm());
  }
}
