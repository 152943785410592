import {Component} from '@angular/core';
import {ImageSliderModel} from './image-slider.model';
import {ContentElementModel} from '@conception/ngx-pimcore-connect';

@Component({
  selector: 'ce-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.scss']
})
export class ImageSliderComponent {
  @ContentElementModel(ImageSliderModel) content: ImageSliderModel;
}
