import {Deserializable, serialize} from '@conception/ts-class-deserializer';
import {PimImageCollection, PimText, PimVideo} from '@conception/ngx-pimcore-connect';

/*
  Smashing through the boundaries
  Lunacy has found me
  Cannot stop the glossary
 */

export class Glossary extends Deserializable {
  @serialize() id: number;
  @serialize() title: string;
  @serialize() copy: string;
  @serialize() copyText: PimText;
  @serialize(PimImageCollection) image: PimImageCollection;
  @serialize(PimVideo) video: PimVideo;

  public getFirstLetter(): string {
    return this.title[0]?.toLowerCase();
  }
}
