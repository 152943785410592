import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {PimAsset} from '@conception/ngx-pimcore-connect';
import {Store} from '@ngrx/store';
import {LayoutActions} from '@rz-gud/store';

@Component({
  selector: 'overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss']
})
export class OverlayComponent implements OnInit, OnDestroy {

  @Input() overlay: PimAsset;
  @Input() headline: string;
  @Output() closed: EventEmitter<boolean> = new EventEmitter<boolean>();

  activeMarker: number = -1;

  constructor(private readonly store: Store,) {
  }

  ngOnInit(): void {
    this.store.dispatch(LayoutActions.setFixed());
  }

  ngOnDestroy(): void {
    this.store.dispatch(LayoutActions.setUnfixed());
  }

}
