<ng-container *ngIf="data.tableSections && data.tableSections.length">
  <ng-container *ngFor="let section of data.tableSections; let isLast = last">
    <div class="mobile__headline" [innerHTML]="section.title"></div>
    <table>
      <tr>
        <th class="mobile--hide" [attr.rowspan]="section.fields?.length">{{section.title}}</th>
        <td [innerHTML]="section.fields[0]?.label"></td>
        <td [innerHTML]="section.fields[0]?.values.join('<br>')"></td>
      </tr>
      <ng-container *ngFor="let field of section.fields; let isFirst = first">
        <tr *ngIf="!isFirst && field.values">
          <td [innerHTML]="field.label"></td>
          <td [innerHTML]="field.values?.join('<br>')"></td>
        </tr>
      </ng-container>
    </table>
    <div class="table-divider" *ngIf="!isLast"></div>
  </ng-container>
</ng-container>
