import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {ImageStorySliderModel} from './image-text-slider.model';
import {ContentElementModel} from '@conception/ngx-pimcore-connect';

@Component({
  selector: 'ce-image-text-slider',
  templateUrl: './image-text-slider.component.html',
  styleUrls: ['./image-text-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageTextSliderComponent implements OnInit {

  @Input() @ContentElementModel(ImageStorySliderModel) content: ImageStorySliderModel | any;

  maxSlides: number = 0;
  currentSlide: number = 1;

  ngOnInit(): void {
    this.maxSlides = this.content.images.length;
  }

  setCurrentSlide(event): void {
    this.currentSlide = +event.realIndex + 1;
  }

}
