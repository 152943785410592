import {Component} from '@angular/core';
import {ContentElementModel} from '@conception/ngx-pimcore-connect';
import {CampusEventDetailImageModel} from './campus-event-detail-image.model';

@Component({
  selector: 'ce-campus-event-detail-image',
  templateUrl: './campus-event-detail-image.component.html',
  styleUrls: ['./campus-event-detail-image.component.scss']
})
export class CampusEventDetailImageComponent {
  @ContentElementModel(CampusEventDetailImageModel) content: CampusEventDetailImageModel;
}
