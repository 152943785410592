import { createAction, props } from '@ngrx/store';
import { Comparison } from '@rz-gud/models';

export const addToList = createAction('[Comparison] Add to List', props<{ id: number; title?: string }>());

export const removeFromList = createAction('[Comparison] Remove from list', props<{ id: number }>());

export const initUpdateList = createAction('[Comparison] Initiate Update List');

export const updateList = createAction('[Comparison] Update List', props<{ comparisonListProducts }>());

export const buildList = createAction('[Comparison] Build List', props<{ lang?: string }>());

export const clearList = createAction('[Comparison] Clear List');

export const onPage = createAction('[Comparison] On Page');

export const notOnPage = createAction('[Comparison] Not on Page');

export const compare = createAction('[Comparison] Compare now');

export const writeList = createAction('[Comparison] Write', props<{ resultList: Comparison }>());

export const isLoading = createAction('[Comparison] Product List is Loading', props<{ isLoading: boolean }>());

export const showOverlay = createAction('[Comparison] Toggle Comparison Overlay', props<{ showOverlay: boolean }>());

export const setPageLoadOverlay = createAction('[Comparison] Set overlay state on page load');
