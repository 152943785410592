<div class="inner">
  <div class="products">
    <div class="product" *ngFor="let product of (comparisonList$ | async)" (click)="remove(product.id)">
      <div class="product__label">{{product.title}}</div>
      <gd-icon color="azure" icon="close" class="product__remove"></gd-icon>
    </div>
  </div>
  <div class="buttons">
    <gd-button color="silver-light"
               [label]="'cancel' | translate"
               (click)="hideOverlay()"></gd-button>
    <gd-button color="azure"
               [label]="'compareNow' | translate"
               (click)="compare()"
               [inactive]="(comparisonList$ | async).length < 2"></gd-button>
  </div>
</div>
