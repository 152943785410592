<tab-container [tabs]="content.tabs"
               useAsLabel="tabTitle"
               [(activeTab)]="activeTab"
               (activeTabChange)="scrollTop()"></tab-container>

<div class="tab-content" #tabContent>
<ng-container *ngFor="let item of content.tabs[activeTab].tabContent.content_elements">
  <ng-container [ngSwitch]="item?.type">
    <gdcb-image-text-columns *ngSwitchCase="'image-text-columns'"
                             [content]="item.content"></gdcb-image-text-columns>
    <ce-image-text-slider *ngSwitchCase="'image-text-slider'" [content]="item.content"></ce-image-text-slider>
    <gd-separator *ngSwitchCase="'divider'"></gd-separator>
    <gdcb-copy *ngSwitchCase="'copy'" [content]="item.content"></gdcb-copy>
    <ce-hero-text *ngSwitchCase="'hero-text'" [content]="item.content"></ce-hero-text>
    <gdcb-quote *ngSwitchCase="'quote'" [content]="item.content"></gdcb-quote>
    <how-it-works *ngSwitchCase="'image-hotspots'" [content]="item.content"></how-it-works>
    <gdcb-fullscreen-video *ngSwitchCase="'video'" [content]="item.content"></gdcb-fullscreen-video>
  </ng-container>
</ng-container>
</div>
