<div class="container" [class.container--reduced]="!showAllFilters">
  <gd-multi-dropdown
    *ngFor="let item of propertyFilter$ | async; index as i"
    [items]="item.values"
    [label]="item.label"
    [useAsUnique]="'key'"
    [useAsLabel]="'label'"
    [staticLabel]="true"
    [multi]="true"
    [activeItems]="
      (setFilters$ | async).has(item.key)
        ? (setFilters$ | async).get(item.key)
        : []
    "
    (activeItemsChange)="changeFilter(item.key, $event)"
  ></gd-multi-dropdown>
</div>

<gd-simple-button
  class="more"
  [class.more--active]="showAllFilters"
  [label]="
    showAllFilters ? ('lessFilters' | translate) : ('moreFilters' | translate)
  "
  (click)="showAllFilters = !showAllFilters"
></gd-simple-button>
