import {Deserializable, serialize, serializeArray} from '@conception/ts-class-deserializer';
import {PimImageCollection, PimLink} from '@conception/ngx-pimcore-connect';

export class StoryTag extends Deserializable {
  @serialize() id: number;
  @serialize() name: string;
}

export class Story extends Deserializable {
  @serialize() id: number;
  @serialize() customer: string;
  @serialize() title: string;
  @serialize(PimImageCollection) teaserImage: PimImageCollection;
  @serializeArray(StoryTag) branch: Array<StoryTag>;
  @serializeArray(StoryTag) region: Array<StoryTag>;
  @serializeArray(StoryTag) technology: Array<StoryTag>;
  @serialize(PimLink) detailPage: PimLink;

  public getTeaserImage(layout: string): string {
    if (this.teaserImage && this.teaserImage.urls) {
      switch (layout) {
        case 'horizontal':
          return this.teaserImage.getImage('StoryBlockLandscape');
        case 'vertical':
          return this.teaserImage.getImage('StoryBlockPortrait');
        default:
          return this.teaserImage.getImage('StoryTeaserBig');
      }
    } else {
      return '';
    }
  }
}
