<div class="inner" *ngIf="content && content.showElement()">
  <h3 [innerHTML]="'product.interfaces.headline' | translate"></h3>

  <tab-container
    *ngIf="tabs.length > 1"
    [tabs]="tabs"
    color="silver-light"
    useAsLabel="label"
    [(activeTab)]="activeTab"
    (activeTabChange)="onActiveTabChange()"></tab-container>

  <figure class="image">
    <img
      *ngIf="activeTabId && content[activeTabId]?.image?.urls"
      src="{{ content[activeTabId].image.getImage('default') }}"
      alt="{{ content[activeTabId].image?.meta?.alt || '' }}"
      width="{{ content[activeTabId].image.imageWidth }}"
      height="{{ content[activeTabId].image.imageHeight }}"
      [ngStyle]="{ width }" />
  </figure>

  <div class="table-container">
    <table cellspacing="0">
      <thead>
        <tr>
          <th>{{ 'product.interfaces.thead.name' | translate }}</th>
          <th>{{ 'product.interfaces.thead.type' | translate }}</th>
          <th>{{ 'product.interfaces.thead.description' | translate }}</th>
        </tr>
      </thead>

      <tbody>
        <ng-container *ngIf="content[activeTabId]?.table">
          <tr *ngFor="let row of content[activeTabId].table">
            <td>{{ row.name }}</td>
            <td>{{ row.type }}</td>
            <td>{{ row.description }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
