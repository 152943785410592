import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  PLATFORM_ID,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  ContentElementModel,
  ScrollToService,
} from '@conception/ngx-pimcore-connect';
import { AccordionModel } from '@rz-gud/content-elements/accordion/accordion.model';
import { ActivatedRoute, Params } from '@angular/router';
import { AccordionComponent as GdAccordionComponent } from '@conception/ng-gud-ui';
import { take, tap } from 'rxjs/operators';
import slugify from 'slugify';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'ce-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent implements OnInit, AfterViewInit {
  @ContentElementModel(AccordionModel) content: AccordionModel;
  @ViewChildren(GdAccordionComponent, { read: ElementRef })
  accordionBlocks: QueryList<ElementRef>;
  renderForms: Map<number, string> = new Map<number, string>();
  ids: Array<string> = [];
  activeAccordion = -1;
  private isBrowser: boolean = false;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private readonly scrollToService: ScrollToService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  toggle(index: number, active: boolean) {
    (window as any).MsCrmMkt = undefined;
    if (index !== -1 && active && this.content.blocks[index].hubspotFormId) {
      if (!this.renderForms.has(index)) {
        this.renderForms.set(index, this.content.blocks[index].hubspotFormId);
      }
    }

    this.activeAccordion = index;
  }

  ngOnInit(): void {
    this.content.blocks.forEach((el) =>
      this.ids.push(slugify(el.headline, { lower: true }))
    );
    if (this.content.openFirstElement) {
      this.toggle(0, true);
    }
  }

  ngAfterViewInit(): void {
    if (this.isBrowser) {
      this.activatedRoute.queryParams
        .pipe(
          take(1),
          tap((params: Params) => {
            if (params.form) {
              setTimeout(() => {
                const index: number = this.content.blocks.findIndex(
                  (el) => el.urlParameter === params.form
                );

                if (index !== -1) {
                  this.accordionBlocks
                    .get(index)
                    .nativeElement.scrollIntoView(true);
                  this.toggle(index, true);
                }
              }, 300);
            }

            if (params['scroll-to']) {
              setTimeout(() => {
                if (document.getElementById(params['scroll-to'])) {
                  this.accordionBlocks.forEach((item, index) => {
                    if (item.nativeElement.id === params['scroll-to']) {
                      this.activeAccordion = index;
                    }
                  });

                  this.scrollToService.scrollTo(
                    document.getElementById(params['scroll-to'])
                  );
                }
              }, 1000);
            }
          })
        )
        .subscribe();
    }
  }
}
