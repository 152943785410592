import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { GDColors } from '@conception/ng-gud-ui';
import { NavigationItem } from '@rz-gud/interfaces';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { NavigationSelectors, PageSelectors } from '@rz-gud/store';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';

declare global {
  interface Window {
    Cookiebot: any;
  }
}

@Component({
  selector: 'main-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  readonly gdColors = GDColors;
  readonly date = new Date();
  readonly footerNav$: Observable<NavigationItem> = this.store.select(NavigationSelectors.navigationItem('footer'));
  readonly socialNav$: Observable<NavigationItem> = this.store.select(NavigationSelectors.navigationItem('social'));
  readonly legalNav$: Observable<NavigationItem> = this.store.select(NavigationSelectors.navigationItem('legal'));
  readonly supportLink$: Observable<string> = this.store.select(PageSelectors.support);
  eMailHQ: SafeUrl;
  eMailNA: SafeUrl;

  private readonly isBrowser: boolean = true;

  constructor(
    @Inject(PLATFORM_ID) platformId: Record<string, unknown>,
    private readonly store: Store,
    private readonly sanitizer: DomSanitizer,
    readonly translate: TranslateService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    if (this.isBrowser) {
      setTimeout(() => this.translateEmailBypass(), 200);
    }
  }

  translateEmailBypass() {
    this.eMailHQ = this.sanitizer.bypassSecurityTrustUrl(
      'mailto:' + this.translate.instant('footer.contact.hq.email')
    );
    this.eMailNA = this.sanitizer.bypassSecurityTrustUrl(
      'mailto:' + this.translate.instant('footer.contact.na.email')
    );
  }

  changeConsent(): void {
    window?.scrollTo(0, 0);
    window?.Cookiebot.renew();
  }

  widthdrawConsent(): void {
    window?.scrollTo(0, 0);
    window?.Cookiebot.withdraw();
  }
}
