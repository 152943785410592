import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { GDColors, ProductFlag } from '@conception/ng-gud-ui';
import { SimpleType } from '@rz-gud/interfaces';

@Component({
  selector: 'product-teaser-ui',
  templateUrl: './product-teaser-ui.component.html',
  styleUrls: ['./product-teaser-ui.component.scss'],
})
export class ProductTeaserUiComponent implements OnInit {
  /**
   * Specify the icon, available: "silverlight" and "yellow"
   */
  @Input() color: GDColors | string = GDColors.SILVERLIGHT;

  /**
   * Specify the icon, available: "broadcast", "air-traffic", "industry", "maritime"
   */
  @Input() icon: SimpleType;

  /**
   * Teaser image
   */
  @Input() image: string;

  /**
   * Teaser subline
   */
  @Input() headline: string = '';

  /**
   * Teaser description
   */
  @Input() copy: string = '';

  /**
   * Teaser productNumber
   */
  @Input() productNumber: string | any;

  /**
   * Teaser tag
   */
  @Input() tag: ProductFlag;

  /**
   * Teaser Bookmark toggle
   */
  @Input() showBookmark: boolean = true;

  /**
   * Bookmark state
   */
  @Input() isBookmarked: boolean = false;

  /**
   * Skeleton version of the teaser, used for hydration
   */
  @Input() isSkeleton: boolean = false;

  /**
   * Is the image "only" a similar image
   */
  @Input() similarImage: boolean = false;

  /**
   * Optional link, set on image
   */
  @Input() mainLink: string = '';

  /**
   * Additional external classes based on context
   */
  @Input() class: string = '';

  @Output() bookmarkClicked: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @ViewChild('content', { read: ElementRef, static: true }) content:
    | ElementRef
    | undefined;

  boxColor: GDColors = GDColors.YELLOW;
  iconColor: GDColors = GDColors.AZURE;
  smallSpacing: boolean = false;

  @HostBinding('class') get hostClasses(): string {
    return [this.class, this.color, this.isSkeleton ? 'skeleton' : ''].join(
      ' '
    );
  }

  ngOnInit(): void {
    switch (this.color) {
      case GDColors.SILVERLIGHT:
        this.boxColor = GDColors.YELLOW;
        this.iconColor = GDColors.AZURE;
        break;
      case GDColors.YELLOW:
        this.boxColor = GDColors.WHITE_TRANSPARENT;
        this.iconColor = GDColors.AZURE;
        break;
      default:
        this.boxColor = GDColors.YELLOW;
        this.iconColor = GDColors.AZURE;
    }

    for (let i = 0; i < this.content?.nativeElement.childNodes.length; i++) {
      if (
        this.content?.nativeElement.childNodes[i].className === 'simple-buttons'
      ) {
        this.smallSpacing = true;
        break;
      }
    }
  }
}
