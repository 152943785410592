import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  constructor() {
  }

  transform(input: Array<any>, property: string, value: string, exclude: boolean = false): Array<any> {
    if (input.length) {
      if (exclude) {
        return input.filter((item) => item[property] !== value);
      } else {
        return input.filter((item) => item[property] === value);
      }
    }
    return [];
  }
}
