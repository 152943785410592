import { Component, Input, OnInit } from '@angular/core';
import { Product } from '@rz-gud/models';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { BookmarkActions, BookmarkSelectors, PageSelectors } from '@rz-gud/store';

@Component({
  selector: 'product-highlight-teaser',
  templateUrl: './product-highlight-teaser.component.html',
  styleUrls: ['./product-highlight-teaser.component.scss'],
})
export class ProductHighlightTeaserComponent implements OnInit {
  @Input() product: Product;

  amIBookmarked$: Observable<boolean>;
  detailLink$: Observable<string>;

  constructor(readonly translate: TranslateService, private readonly store: Store) {}

  ngOnInit(): void {
    if (this.product) {
      this.amIBookmarked$ = this.store.select(BookmarkSelectors.amIBookmarked(this.product.id));
      this.detailLink$ = this.store.select(PageSelectors.productDetail);
    }
  }

  handleBookmark(): void {
    this.store.dispatch(BookmarkActions.toggleProduct({ id: this.product.id }));
  }
}
