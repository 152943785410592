<div
  *ngIf="(icon || tag || showBookmark) && !isSkeleton"
  class="product-teaser__top">
  <gd-icon-box
    *ngIf="icon?.value"
    [color]="boxColor"
    [iconColor]="iconColor"
    [icon]="icon.value"></gd-icon-box>
  <gd-teaser-tag *ngIf="tag?.label" [backgroundColor]="tag.value">{{tag.label}}</gd-teaser-tag>
  <div
    *ngIf="showBookmark"
    class="bookmark"
    [class.active]="isBookmarked"
    (click)="bookmarkClicked.emit(true)">
    <gd-icon icon="bookmark" color="azure"></gd-icon>
    <div class="tooltip">
      {{
      isBookmarked
        ? ('removeFromWatchlist' | translate)
        : ('addToWatchlist' | translate)
      }}
    </div>
  </div>
</div>
<ng-container *ngIf="mainLink">
  <a [routerLink]="mainLink"
    class="product-teaser__image product-teaser__image--with-link"
    [class.product-teaser__image--skeleton]="isSkeleton">
    <img *ngIf="image && !isSkeleton" [src]="image" [alt]="headline"/>
    <div class="product-teaser__similar-image" *ngIf="similarImage">
      {{ 'similarImage' | translate }}
    </div>
  </a>
</ng-container>
<ng-container *ngIf="mainLink === ''">
  <div
    class="product-teaser__image"
    [class.product-teaser__image--skeleton]="isSkeleton">
    <img *ngIf="image && !isSkeleton" [src]="image" [alt]="headline"/>
    <div class="product-teaser__similar-image" *ngIf="similarImage">
      {{ 'similarImage' | translate }}
    </div>
  </div>
</ng-container>
<div
  #content
  class="product-teaser__content"
  *ngIf="!isSkeleton"
  [class.product-teaser__content--small-spacing]="smallSpacing">
  <ng-content select=".icons"></ng-content>
  <h2 *ngIf="headline" [innerHTML]="headline"></h2>
  <p *ngIf="copy" [class.no-spacing]="productNumber" [innerHTML]="copy"></p>
  <p
    class="item-number"
    *ngIf="productNumber"
    [innerHTML]="
      'productNumber' | translate: { 'productNumber': productNumber }
    "></p>
  <ng-content select=".link-button"></ng-content>
  <ng-content select="gd-button"></ng-content>
  <ng-content select=".select-button__container"></ng-content>
  <ng-content select=".buttons"></ng-content>
  <ng-content select=".simple-buttons"></ng-content>
</div>

<div class="product-teaser__content skeleton" *ngIf="isSkeleton">
  <h2 class="skeleton__headline">&nbsp;</h2>
  <p class="skeleton__copy">&nbsp;</p>
  <div class="skeleton__buttons"></div>
</div>
