<gd-icon-box [color]="gdColors.GREEN" icon="campus-media"></gd-icon-box>
<div class="amount" [innerHTML]="'videoAmount' | translate:{'videoAmount': items?.length}"></div>
<media-teaser *ngFor="let media of items; index as i; trackBy: trackByTitle"
              [item]="media"
              [id]="'c_' + media.id"></media-teaser>

<!--
<div class="media-library__more" *ngIf="false">
  <gd-button (click)="ingresShownItems(12)"
             [label]="'showAmountMore' | translate:{'amount': 12}" color="azure-outline"></gd-button>
  <gd-button (click)="ingresShownItems(content.videos.length - currentlyShownAmount)"
             [label]="'showMoreALL' | translate:{'amount': content.videos.length}" color="azure"></gd-button>
</div>
-->
