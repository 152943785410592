import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ContentElementModel } from '@conception/ngx-pimcore-connect';
import { EventsModel } from './campus-events.model';
import { CampusEvent } from '@rz-gud/models';
import { CampusService } from '@rz-gud/services';
import { Observable } from 'rxjs';
import { GDColors } from '@conception/ng-gud-ui';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'ce-campus-events',
  templateUrl: './campus-events.component.html',
  styleUrls: ['./campus-events.component.scss']
})
export class CampusEventsComponent implements OnInit {
  @ContentElementModel(EventsModel) content: EventsModel;
  gdColors = GDColors;
  events$: Observable<Array<CampusEvent>>;
  private readonly isBrowser: boolean = true;

  constructor(private readonly campusService: CampusService,
              @Inject(PLATFORM_ID) private platformId: Record<string, unknown>) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    if (this.isBrowser) {
      setTimeout(() => this.getData(), 200);
    }
  }

  getData() {
    this.events$ = this.campusService.getEvents({
      folder: this.content?.folder?.path
    });
  }
}
