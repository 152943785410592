import { Component, Input } from '@angular/core';
import { GDColors } from '@conception/ng-gud-ui';

@Component({
  selector: 'hotspot-teaser',
  templateUrl: './hotspot-teaser.component.html',
  styleUrls: ['./hotspot-teaser.component.scss'],
})
export class HotspotTeaserComponent {
  @Input() content: any;
  readonly gdColors = GDColors;
}
