import {createReducer, on} from '@ngrx/store';
import {VideoPopupActions} from '../actions';
import {PimVideo} from '@conception/ngx-pimcore-connect';

export interface VideoPopupState {
  video: PimVideo;
}

export const initialState: VideoPopupState = {
  video: null
};

export const videoPopupReducer = createReducer(
  initialState,
  on(VideoPopupActions.setVideoPopup, (state, {video}) => ({...state, video})),
  on(VideoPopupActions.clearVideoPopup, (state) => ({...state, video: initialState.video}))
);
