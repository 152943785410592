import {createReducer, on} from '@ngrx/store';
import {SimpleType} from '@rz-gud/interfaces';
import {HubspotActions} from '../actions';

export interface HubspotState {
  hiddenFields: Array<SimpleType>;
  form: string;
}

export const initialState: HubspotState = {
  hiddenFields: [],
  form: null
};

export const hubspotReducer = createReducer(
  initialState,
  on(HubspotActions.setHiddenFields, (state, {hiddenFields}) => ({...state, hiddenFields})),
  on(HubspotActions.openInquiryForm, (state) => ({...state, form: 'inquiry'})),
  on(HubspotActions.openQuestionForm, (state) => ({...state, form: 'question'})),
  on(HubspotActions.closeForm, (state) => ({...state, form: initialState.form}))
);
