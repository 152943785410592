import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, take, tap} from 'rxjs/operators';
import {NavigationActions} from '../actions';
import {Store} from '@ngrx/store';
import {HttpClient} from '@angular/common/http';
import {NavigationItem} from '@rz-gud/interfaces';
import {makeStateKey, TransferState} from '@angular/platform-browser';
import {ApiService} from '@conception/ngx-pimcore-connect';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class NavigationEffects {

  getEntry$ = createEffect(() => this.actions$.pipe(
    ofType(NavigationActions.setEntry),
    tap((action) => {
      const lang = this.translate.currentLang;
      const keys = {
        'main': makeStateKey<NavigationItem>('main' + lang),
        'more-from-g-d': makeStateKey<NavigationItem>('more' + lang),
        'mobile': makeStateKey<NavigationItem>('mobile' + lang),
        'footer': makeStateKey<NavigationItem>('footer' + lang),
        'legal': makeStateKey<NavigationItem>('legal' + lang),
        'social': makeStateKey<NavigationItem>('social' + lang),
      };

      action.entry.forEach(entry => {
        if (this.state.hasKey(keys[entry.key])) {
          this.store.dispatch(NavigationActions.setItem({
            key: entry.key,
            item: this.state.get(keys[entry.key], undefined as NavigationItem)
          }));
          this.state.remove(keys[entry.key]);
        } else {
          this.http.get<NavigationItem>(entry.link).pipe(
            take(1),
            map(item => this.apiService.removeContentPath(item)),
            tap(item => this.state.set(keys[entry.key], item)),
            tap(item => this.store.dispatch(NavigationActions.setItem({key: entry.key, item})))
          ).subscribe();
        }
      });
    })
    ),
    {dispatch: false}
  );

  constructor(private readonly actions$: Actions,
              private readonly translate: TranslateService,
              private readonly state: TransferState,
              private readonly store: Store,
              private readonly apiService: ApiService,
              private readonly http: HttpClient) {
  }
}
