import {Component} from '@angular/core';
import {ContentElementModel} from '@conception/ngx-pimcore-connect';
import {TeaserBlocksModel} from './teaser-blocks.model';

@Component({
  selector: 'ce-teaser-blocks',
  templateUrl: './teaser-blocks.component.html',
  styleUrls: ['./teaser-blocks.component.scss']
})
export class TeaserBlocksComponent {
  @ContentElementModel(TeaserBlocksModel) content: TeaserBlocksModel;
}
