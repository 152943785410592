<div *ngIf="(pageMode$ | async) as pageMode" [class]="'container ' + pageMode">
  <button class="button button--easy"
          [class.button--active]="pageMode === pageModes.EASY"
          (click)="setPageMode(pageModes.EASY)">
    <span class="label" [innerHTML]="'modeSwitch.easy' | translate"></span>
    <span class="label label--long" [innerHTML]="'modeSwitch.easy.long' | translate"></span>
  </button>
  <button class="button button--expert"
          [class.button--active]="pageMode === pageModes.EXPERT"
          (click)="setPageMode(pageModes.EXPERT)">
    <span class="label" [innerHTML]="'modeSwitch.expert' | translate"></span>
    <span class="label label--long" [innerHTML]="'modeSwitch.expert.long' | translate"></span>
  </button>
  <div class="mask"
       [@modeSwitch]="pageMode === pageModes.EASY ? 'easy' : 'expert'"></div>

  <tutorial *ngIf="(showTutorial$ | async) && pageMode === pageModes.EASY"
            (closed)="dismissPageMode()"></tutorial>

</div>

