<main-header></main-header>
<router-outlet></router-outlet>
<contact></contact>
<main-footer></main-footer>
<contact-bar></contact-bar>
<to-top></to-top>
<video-overlay
  *ngIf="video$ | async as video"
  [video]="video"
  @scaleInCenter
></video-overlay>
<search-overlay *ngIf="searchOverlay$ | async"></search-overlay>
<language-switch-overlay
  *ngIf="languageSwitchOverlay$ | async"
></language-switch-overlay>
<comparison-overlay
  @slideInOutBottom
  *ngIf="env.features.comparisonList && (showComparisonOverlay$ | async)"
></comparison-overlay>
<form-overlay *ngIf="formOverlay$ | async"></form-overlay>
<dynamics-form-overlay *ngIf="dynamicsFormOverlay$ | async"></dynamics-form-overlay>
