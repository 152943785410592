<div class="inner inner--locked" *ngIf="visible && (amILocked$ | async)" (click)="unlock()">
  <gd-icon icon="simple-arrow" color="silver-light"></gd-icon>
  <div class="label">{{'contactbar.label' | translate}}</div>
</div>

<div class="inner" [class.inner--active]="visible && (amILocked$ | async) === false">
  <gd-round-button *ngIf="visible && (amILocked$ | async) === false"
                   @scaleInCenter
                   [size]="buttonSizes.EXTRA_SMALL"
                   [color]="gdColors.MAYA"
                   (click)="lock()"
                   icon="close"></gd-round-button>

  <a [routerLink]="contactLink$ | async" class="button" (click)="trackEvent('E-Mail')">
    <gd-icon icon="email" color="silver-light" class="button__icon"></gd-icon>
    <div class="button__label">{{'contactbar.contact' | translate}}</div>
  </a>
  <a [routerLink]="supportLink$ | async" class="button" (click)="trackEvent('Support')">
    <gd-icon icon="support" color="silver-light" class="button__icon"></gd-icon>
    <div class="button__label">{{'contactbar.support' | translate}}</div>
  </a>
</div>
