<h1>404</h1>
<h2 [innerHTML]="'pageNotFound.headline' | translate"></h2>
<p [innerHTML]="'pageNotFound.subline' | translate"></p>
<gd-button [color]="gdColors.AZURE"
           [routerLink]="'/'"
           [label]="'pageNotFound.button' | translate"></gd-button>

<div class="search">
  <div class="search--inner">
    <p [innerHTML]="'pageNotFound.search.headline' | translate"></p>
    <searchbar [productAutofill]="true"
               [setExactSearch]="exactSearch"
               [disableInText]="disableInText"
               (exact)="exactSearch = $event"
               autofillTheme="light" [placeholder]="'search.placeholder' | translate"></searchbar>
    <!--
    <gd-checkbox [label]="'search.exactSearch' | translate"
                 [checked]="exactSearch"
                 size="small"
                 (isChecked)="exactSearch = $event; disableInText = $event;"></gd-checkbox>
                 -->
  </div>
</div>
