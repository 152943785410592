<product-teaser-ui
  *ngIf="!isSkeleton"
  [color]="gdColors.SILVERLIGHT"
  [image]="product.teaserImage()"
  [similarImage]="product.bildFrontAehnlich"
  [mainLink]="'/' + [detailLink$ | async, product.category, product.urlSlug].join('/')"
  [headline]="product.headline()"
  [copy]="
    product[
      'funktion' +
        (this.translate.currentLang.toLowerCase() === 'en_us' ? 'EN' : this.translate.currentLang.toUpperCase())
    ] | truncatetext: 90
  "
  [tag]="product.productFlags[0]"
  [isBookmarked]="amIBookmarked$ | async"
  (bookmarkClicked)="handleBookmark()">
  <div class="icons">
    <ng-container *ngFor="let icon of product.productIcons; let i = index">
      <gd-product-icon
        *ngIf="i < 4"
        [icon]="icon.iconId"
        color="azure"
        [tooltip]="'producticon.' + icon.iconId | translate"></gd-product-icon>
    </ng-container>
    <gd-product-icon
      *ngIf="product.bluedec"
      class="bluedec"
      icon="bluedec"
      color="azure"
      [tooltip]="'producticon.bluedec' | translate"></gd-product-icon>
  </div>
  <div class="buttons">
    <a class="full-width" [routerLink]="'/' + [detailLink$ | async, product.category, product.urlSlug].join('/')">
      <gd-button [label]="'toTheProduct' | translate" [color]="gdColors.AZURE"></gd-button>
    </a>

    <gd-button [label]="'requestNow' | translate" color="white-maya" (click)="request()"></gd-button>
  </div>
  <div class="simple-buttons">
    <ng-container *ngIf="env.features.comparisonList">
      <div class="comparison-buttons">
        <gd-simple-button
          *ngIf="(amIOnComparisonList$ | async) === false"
          [inactive]="comparisonListFilled$ | async"
          [label]="'compareProduct' | translate"
          icon="simple-arrow"
          (click)="putToComparisonList()"></gd-simple-button>
        <gd-simple-button
          *ngIf="amIOnComparisonList$ | async"
          class="remove-from-list"
          [label]="'compareProductRemove' | translate"
          icon="simple-arrow"
          (click)="removeFromComparisonList()"></gd-simple-button>
      </div>
    </ng-container>
    <gd-simple-button
      [label]="'questionsAboutTheProduct' | translate"
      (click)="question()"
      icon="simple-arrow"></gd-simple-button>
  </div>
</product-teaser-ui>

<product-teaser-ui *ngIf="isSkeleton" [isSkeleton]="true"></product-teaser-ui>
