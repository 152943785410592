import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { ComparisonActions, ComparisonSelectors, LanguageNavigationSelectors, LayoutActions } from '@rz-gud/store';
import { LanguageNavigationItem } from '@rz-gud/interfaces';
import { take, tap, withLatestFrom } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'language-switch-overlay',
  templateUrl: './language-switch-overlay.component.html',
  styleUrls: ['./language-switch-overlay.component.scss'],
})
export class LanguageSwitchOverlayComponent {
  items: Array<LanguageNavigationItem> = [];
  activeItem: LanguageNavigationItem;
  comparisonItems: Subscription;

  constructor(private readonly store: Store, private readonly router: Router) {
    this.setItems();
  }

  setItems(): void {
    this.store
      .select(LanguageNavigationSelectors.items)
      .pipe(
        withLatestFrom(this.store.select(LanguageNavigationSelectors.current)),
        take(1),
        tap(([items, current]) => {
          this.items = [current, ...items];
          this.activeItem = current;
        })
      )
      .subscribe();
  }

  set(lang: LanguageNavigationItem): void {
    if (lang.url) {
      if (lang.url !== '/en-us/start' && typeof window !== 'undefined') {
        localStorage.removeItem('preferredLanguage');
      } else {
        localStorage.setItem('preferredLanguage', 'en_US');
      }

      this.close();
      const params = this.router.routerState.snapshot.root.queryParams;

      if (Object.keys(params).length !== 0) {
        this.router.navigate([lang.url], { queryParams: params });
      } else {
        this.router.navigate([lang.url]);
      }

      this.comparisonItems = this.store
        .select(ComparisonSelectors.forApi)
        .pipe(take(1))
        .subscribe((items) => {
          if (items) {
            this.store.dispatch(ComparisonActions.buildList({ lang: lang.lang }));
          }
        });
      this.comparisonItems.unsubscribe();
    }
  }

  close(): void {
    this.store.dispatch(LayoutActions.hideLanguageSwitchOverlay());
  }
}
