import {Injectable} from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '@rz-gud/environment';

@Injectable()
export class PimCmsTreeInterceptor implements HttpInterceptor {
  private static modifyBody(body: any): any {

    let jsonBody = JSON.stringify(body);

    jsonBody = jsonBody.replace(
      new RegExp(environment.contentPath, 'g'),
      ''
    );

    environment.additionalContentPaths.forEach(path => {
      jsonBody = jsonBody.replace(
        new RegExp(path, 'g'),
        ''
      );
    });

    return JSON.parse(jsonBody);
  }

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (
          event instanceof HttpResponse &&
          req.url.startsWith(environment.apiUrl)
        ) {
          event = event.clone({
            body: PimCmsTreeInterceptor.modifyBody(event.body),
          });
        }
        return event;
      })
    );
  }
}
