import {createSelector} from '@ngrx/store';
import {ComparisonState, State} from '../reducers';
import {Comparison} from '@rz-gud/models';

export const select = (state: State) => state.comparison;

export const items = createSelector<State,[ComparisonState],Array<{ id: number; title: string }>>(
  select,
  (list: ComparisonState) => list.comparisonListProducts || []
);

export const comparison = createSelector<State,[ComparisonState],Comparison>(
  select,
  (list: ComparisonState) => list.resultList
);

export const count = createSelector<State,[ComparisonState],number>(
  select,
  (list: ComparisonState) => list.resultList?.products?.length
);

export const filled = createSelector<State,[ComparisonState],boolean>(
  select,
  (list: ComparisonState) => list.comparisonListProducts.length === 3
);

export const forApi = createSelector<State,[ComparisonState],Record<'productIds', Array<number>>>(
  select,
  (list: ComparisonState) => ({productIds: list.comparisonListProducts.map(el => el.id)})
);

export const showOverlay = createSelector<State,[ComparisonState],boolean>(
  select,
  (list: ComparisonState) => !list.onComparisonPage && list.showOverlay && list.comparisonListProducts.length > 0 && !list.isLoading
);

export const onPage = createSelector<State,[ComparisonState],boolean>(
  select,
  (list: ComparisonState) => list.onComparisonPage
);

export const amIOnComparisonList = (productId: number) => createSelector<State,[ComparisonState],boolean>(
  select,
  (list: ComparisonState) => list.comparisonListProducts.filter(el => el.id === productId).length > 0
);

export const isLoading = createSelector<State,[ComparisonState],boolean>(
  select,
  (list: ComparisonState) => list.isLoading
);

