<a [routerLink]="story.detailPage?.path">
  <img *ngIf="story.teaserImage.urls"
       [src]="story.getTeaserImage(layout)"
       [alt]="story.teaserImage?.meta?.alt"
       [title]="story.teaserImage?.meta?.title"
       class="background-image">

  <div class="box" [classList]="orientation" *ngIf="story">
    <h4 [innerHTML]="story.customer"></h4>
    <h3 [innerHTML]="story.title"></h3>
  </div>
</a>
