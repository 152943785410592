import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap, withLatestFrom } from 'rxjs/operators';
import { LayoutActions, ProductFilterActions, SearchActions } from '../actions';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  PageMode,
  PageSelectors,
  ProductFilterSelectors,
  SearchSelectors,
} from '@rz-gud/store';
import { SearchService } from '@rz-gud/services';
import { searchConfig } from '@rz-gud/config';
import { GtagService } from '@rz-gud/services/gtag.service';

@Injectable()
export class SearchEffects {
  openOverlay$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SearchActions.openOverlay),
        tap((action) => this.store.dispatch(LayoutActions.setFixed()))
      ),
    { dispatch: false }
  );

  closeOverlay$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SearchActions.closeOverlay),
        tap((action) => this.store.dispatch(LayoutActions.setUnfixed()))
      ),
    { dispatch: false }
  );

  searchTerm$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SearchActions.setTerm),
        tap((action) => {
          if (action.term.length > 1) {
            this.searchService.autoSuggest(action.term, false);
          } else {
            this.store.dispatch(SearchActions.clearSuggestions());
          }
        })
      ),
    { dispatch: false }
  );

  search$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SearchActions.search),
        withLatestFrom(
          this.store.select(PageSelectors.search),
          this.store.select(SearchSelectors.term),
          this.store.select(SearchSelectors.exactSearch)
        ),
        tap(() => this.store.dispatch(SearchActions.closeOverlay())),
        tap(([action, link, term, exactSearch]) =>
          this.gtag.pushEvent('search', [{ term }])
        ),
        tap(([action, link, term, exactSearch]) =>
          this.searchService.getCmsData(
            term,
            0,
            searchConfig.cmsResults,
            exactSearch
          )
        ),
        tap(([action, link, term, exactSearch]) =>
          this.searchService.getProductData(
            term,
            1,
            searchConfig.productResults,
            exactSearch
          )
        ),
        tap(([action, link, term, exactSearch]) => this.router.navigate([link]))
      ),
    { dispatch: false }
  );

  clear$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SearchActions.clearTerm),
        tap((action) => {
          this.store.dispatch(SearchActions.clearProductResults());
          this.store.dispatch(SearchActions.clearCmsResults());
          this.store.dispatch(SearchActions.setCmsTotal({ cmsTotal: 0 }));
          this.store.dispatch(
            SearchActions.setProductTotal({ productTotal: 0 })
          );
        })
      ),
    { dispatch: false }
  );

  appendCms$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SearchActions.triggerAppendCmsResults),
        withLatestFrom(
          this.store.select(SearchSelectors.term),
          this.store.select(SearchSelectors.cmsOffset)
        ),
        tap(([action, term, offset]) =>
          this.searchService.getCmsData(
            term,
            offset,
            searchConfig.cmsResults,
            false
          )
        )
      ),
    { dispatch: false }
  );

  loadAllCms$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SearchActions.triggerLoadAllCmsResults),
        withLatestFrom(
          this.store.select(SearchSelectors.term),
          this.store.select(SearchSelectors.cmsTotal)
        ),
        tap(([action, term, total]) =>
          this.searchService.getCmsData(term, 0, total, false)
        )
      ),
    { dispatch: false }
  );

  appendProducts$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SearchActions.triggerAppendProductResults),
        withLatestFrom(
          this.store.select(SearchSelectors.term),
          this.store.select(SearchSelectors.productOffset)
        ),
        tap(([action, term, offset]) =>
          this.searchService.getProductData(
            term,
            offset,
            searchConfig.productResults,
            false
          )
        )
      ),
    { dispatch: false }
  );

  loadAllProducts$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SearchActions.triggerLoadAllProductResults),
        withLatestFrom(
          this.store.select(SearchSelectors.term),
          this.store.select(SearchSelectors.productTotal)
        ),
        tap(([action, term, total]) =>
          this.searchService.getProductData(term, 1, total, false)
        )
      ),
    { dispatch: false }
  );

  changeActiveTab$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SearchActions.setProductTotal),
        tap((action) => {
          this.store.dispatch(
            SearchActions.setTab({
              tab: action.productTotal === 0 ? 'cms' : 'products',
            })
          );
        })
      ),
    { dispatch: false }
  );

  setAutofill$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SearchActions.setTerm, SearchActions.clearTerm),
        withLatestFrom(
          this.store.select(ProductFilterSelectors.productNames),
          this.store.select(SearchSelectors.term)
        ),
        tap(([action, productNames, searchTerm]) => {
          const autofillProductNames =
            searchTerm.length > 0
              ? this.sortInputFirst(searchTerm, productNames)
                  .filter((productName) =>
                    productName.toLowerCase().includes(searchTerm.toLowerCase())
                  )
                  .slice(0, 19)
              : [];

          this.store.dispatch(
            ProductFilterActions.setAutoFillProductNames({
              autofillProductNames: this.sortInputFirst(
                searchTerm,
                autofillProductNames
              ),
            })
          );
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly gtag: GtagService,
    private readonly searchService: SearchService,
    private readonly store: Store
  ) {}

  private sortInputFirst(input, productNames): Array<string> {
    const sortedProductNames = [...productNames];
    sortedProductNames.sort(
      (a, b) =>
        +/^[0-9]/.test(a) - +/^[0-9]/.test(b) ||
        a.localeCompare(b, undefined, {
          numeric: true,
        })
    );
    const inputTrue = sortedProductNames.filter(
      (productName) =>
        productName.substring(0, input.length).toLowerCase() ===
        input.toLowerCase()
    );
    const inputFalse = sortedProductNames.filter(
      (productName) =>
        productName.substring(0, input.length).toLowerCase() !==
        input.toLowerCase()
    );

    return [...inputTrue, ...inputFalse];
  }
}
