<div class="no-entries" *ngIf="idList.length === 0">
  {{content.noItems}}<br><br>

  <a [routerLink]="allProducts$ | async">
    <gd-button [color]="'azure'" [label]="'bookmarks.to_products' | translate"></gd-button>
  </a>
  <gd-separator></gd-separator>
</div>

<div class="bookmark-share" *ngIf="shareMode">
  {{'bookmarks.this_is_a_shared_list' | translate}}
  <a [routerLink]="shareLinkPrefix">
    <gd-simple-button [label]="'bookmarks.to_my_list' | translate" icon="simple-arrow"></gd-simple-button>
  </a>
</div>

<div class="bookmark-share" *ngIf="idList.length && !shareMode">
  <div>{{'bookmarks.share' | translate}}<br>
    <div class="cta">
      <div class="share">
        <div class="share__field--container">
          <gd-icon class="share__icon" icon="link" color="azure"></gd-icon>
          <input class="share__field" type="text"
                 [value]="shareLinkPrefix + shareLinkParams"
                 readonly
                 #shareInput
                 (click)="select($event.target)">
        </div>
        <gd-button [color]="triggeredCopy ? 'green' : 'silver-light'"
                   [label]="triggeredCopy ? ('bookmarks.link_copied' | translate) : ('bookmarks.copy_link' | translate)"
                   (click)="copyLink()"></gd-button>
      </div>
      <gd-button color="azure" [label]="'bookmarks.request_products' | translate"
                 (click)="requestProducts()"></gd-button>
    </div>
  </div>

  <div class="reset-list">
    <gd-button [color]="'silver-light'"
               [label]="'bookmarks.reset' | translate"
               (click)="resetList()"></gd-button>
  </div>
</div>

<div class="bookmark-container" *ngIf="idList.length">
  <h3 [innerHTML]="content.headline"></h3>
  <gd-note [color]="gdColors.AZURE"
           [textFlip]="true"
           [unicolor]="true"
           [reverseLine]="true"
           [label]="content.headline"></gd-note>
  <product-teaser *ngFor="let product of products; trackBy: trackById" [product]="product"></product-teaser>
</div>
