import { createAction, props } from '@ngrx/store';
import {
  MainFilterGroup,
  ProductFilter,
  ProductFilterDefinition,
  ProductFilterSet,
  SimpleFilter,
} from '@rz-gud/interfaces';
import { Product } from '@rz-gud/models';

export const setLoadingState = createAction(
  '[Product Filter] Set loading state',
  props<{ currentState: boolean }>()
);

export const setFilterObject = createAction(
  '[Product Filter] Set the whole object',
  props<{ filter: Array<ProductFilterSet> }>()
);

export const setFilter = createAction(
  '[Product Filter] Set one filter category',
  props<{ category: string; object: Array<SimpleFilter> }>()
);

export const addFilter = createAction(
  '[Product Filter] Add one value to one filter category',
  props<{ category: string; filter: SimpleFilter }>()
);

export const clearFilter = createAction(
  '[Product Filter] Clear one filter category',
  props<{ category: string }>()
);

export const removeFilter = createAction(
  '[Product Filter] Remove one value from one filter category',
  props<{ category: string; filter: SimpleFilter }>()
);

export const clearFilterObject = createAction(
  '[Product Filter] Clear the whole object'
);

export const setFilterId = createAction(
  '[Product Filter] Set filterId',
  props<{ filterId: string }>()
);

export const setupFilter = createAction(
  '[Product Filter] Setup all filter objects',
  props<{ filterDefinitions: Array<ProductFilterDefinition> }>()
);

export const setPropertyFilter = createAction(
  '[Product Filter] Set property-filter',
  props<{ propertyFilter: Array<ProductFilter> }>()
);

export const clearPropertyFilter = createAction(
  '[Product Filter] Clear property-filter'
);

export const setMainFilter = createAction(
  '[Product Filter] Set main-filter',
  props<{ mainFilter: Array<MainFilterGroup> }>()
);

export const clearMainFilter = createAction(
  '[Product Filter] Clear main-filter'
);

export const setPresets = createAction(
  '[Product Filter] Set presets',
  props<{ presets: Array<ProductFilterDefinition> }>()
);

export const clearPresets = createAction('[Product Filter] Clear presets');

export const setPresetId = createAction(
  '[Product Filter] Set preset id',
  props<{ presetId: number }>()
);

export const clearPresetId = createAction('[Product Filter] Clear preset id');

export const setFilterDefinitions = createAction(
  '[Product Filter] Set filter definitions',
  props<{ filterDefinitions: Array<ProductFilterDefinition> }>()
);

export const clearFilterDefinitions = createAction(
  '[Product Filter] Clear filter definitions'
);

export const useFilterDefinitionById = createAction(
  '[Product Filter] Use filter definition by id',
  props<{ id: number }>()
);

export const clearFilterId = createAction('[Product Filter] Clear FilterId');

export const search = createAction('[Product Search] Search!');

export const setSearchTerm = createAction(
  '[Product Search] Set a product search term',
  props<{ term: string }>()
);

export const clearSearchTerm = createAction(
  '[Product Search] Clear the product search'
);

export const clear = createAction('[Product Filter & Search] Clear');

export const setProducts = createAction(
  '[Products] Set',
  props<{ products: Array<Product> }>()
);

export const appendProducts = createAction(
  '[Products] Append',
  props<{ products: Array<Product> }>()
);

export const loadMore = createAction('[Products] Load More');

export const loadAll = createAction('[Products] Load All');

export const reloadAll = createAction('[Products] Reload All');

export const clearProducts = createAction('[Products] Clear');

export const setCount = createAction(
  '[Products] Set total count',
  props<{ count: number }>()
);

export const getProductNames = createAction(
  '[Product Search] Get productNames',
  props<{ lang: string }>()
);

export const getProductNamesSuccess = createAction(
  '[Product Search] Get productNames succeeded',
  props<{ productNames: Array<string> }>()
);

export const getProductNamesError = createAction(
  '[Product Search] Get productNames failed',
  props<{ error: string }>()
);

export const setAutoFillProductNames = createAction(
  '[Product Search] Set autofill productNames',
  props<{ autofillProductNames: Array<string> }>()
);

export const clearAutoFillProductNames = createAction(
  '[Product Search] Set autofill productNames'
);

export const exactSearch = createAction(
  '[Product Search] Exact Search',
  props<{ exactSearch: boolean }>()
);

export const setProductOverviewSearchFocus = createAction(
  '[Product Search] Set product overview search focus',
  props<{ productOverviewSearchHasFocus: boolean }>()
);
