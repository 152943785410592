import {Component, Input, OnInit} from '@angular/core';
import {GDColors} from '@conception/ng-gud-ui';
import {NavigationItem} from '@rz-gud/interfaces';

@Component({
  selector: 'menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent implements OnInit {
  @Input() data: NavigationItem;
  @Input() color: GDColors | string = GDColors.SILVERLIGHT;

  shortName: string = '';

  getShortName(): void {
    const name = this.data.linkBrick?.pageLink?.pageLink?.name;
    this.shortName = name?.split('-')[1] ? name?.split('-')[1] : name?.split('-')[0];
  }

  ngOnInit(): void {
    this.getShortName();
  }
}
