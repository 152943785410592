import {EasymodeEffects} from './easymode.effects';
import {SearchEffects} from './search.effects';
import {ProductFilterEffects} from './product-filter.effects';
import {NavigationEffects} from './navigation.effects';
import {LayoutEffects} from './layout.effects';
import {ComparisonEffects} from '@rz-gud/store/effects/comparison.effects';
import {BookmarkEffects} from '@rz-gud/store/effects/bookmark.effects';

export const effects = [
  ProductFilterEffects,
  EasymodeEffects,
  SearchEffects,
  NavigationEffects,
  LayoutEffects,
  ComparisonEffects,
  BookmarkEffects
];
