import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {tap, withLatestFrom} from 'rxjs/operators';
import {EasyModeActions} from '../actions';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {EasyModeSelectors, PageSelectors} from '../selectors';

@Injectable()
export class EasymodeEffects {

  setEasyModeStage$ = createEffect(() => this.actions$.pipe(
    ofType(EasyModeActions.nextStage),
    withLatestFrom(this.store.select(EasyModeSelectors.stage), this.store.select(PageSelectors.easyMode)),
    tap(([action, latest, target]) => {
      if (latest > 3) {
        this.router.navigate([target]);
      }
    })
    ),
    {dispatch: false}
  );

  constructor(private readonly actions$: Actions,
              private readonly store: Store,
              private readonly router: Router) {
  }
}
