import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {CampusEvent} from '@rz-gud/models';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {PageSelectors} from '@rz-gud/store';

@Component({
  selector: 'event-teaser',
  templateUrl: './event-teaser.component.html',
  styleUrls: ['./event-teaser.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventTeaserComponent {
  @Input() event: CampusEvent;
  readonly detailLink$: Observable<string> = this.store.select(PageSelectors.eventDetail);

  constructor(private readonly store: Store) {
  }
}
