import {Deserializable, serialize, serializeArray} from '@conception/ts-class-deserializer';
import {PimVideo} from '@conception/ngx-pimcore-connect';
import {Category} from './category.model';

export class FAQ extends Deserializable {
  @serialize() id: number;
  @serialize() headline: string;
  @serialize() copy: string;
  @serialize(PimVideo) video: PimVideo;
  @serializeArray(Category) category: Array<Category>;
}
