import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

@Injectable({
  providedIn: 'root'
})
export class GtagService {

  private readonly isBrowser: boolean = true;

  constructor(@Inject(PLATFORM_ID) platformId: Record<string, unknown>) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  pushEvent(name: string, variables: any[] = []): void {
    if (this.isBrowser) {
      const event = {
        event: name
      };

      variables.forEach((val) => {
        event[Object.keys(val)[0]] = val[Object.keys(val)[0]];
      });

      window.dataLayer.push(event);
    }
  }
}

