import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {tap} from 'rxjs/operators';
import {BookmarkActions} from '../actions';
import {Store} from '@ngrx/store';
import {GtagService} from '@rz-gud/services/gtag.service';


@Injectable()
export class BookmarkEffects {

  bookmark$ = createEffect(() => this.actions$.pipe(
      ofType(BookmarkActions.bookmarkProducts, BookmarkActions.bookmarkProduct, BookmarkActions.toggleProduct),
      tap(action => {
        if (action?.type === '[Bookmark] Bookmark Product' || action?.type === '[Bookmark] Toggle Product') {
          this.gtag.pushEvent('bookmark_product', [{product: action.id}]);
        } else {
          action.ids.forEach(id => this.gtag.pushEvent('bookmark_product', [{product: id}]));
        }
      })
    ),
    {dispatch: false}
  );

  constructor(private readonly actions$: Actions,
              private readonly store: Store,
              private readonly gtag: GtagService) {
  }
}
