<h3 *ngIf="content.headline" [innerHTML]="content.headline"></h3>
<swiper *ngIf="content.products.length > 0"
        [loop]="true"
        [spaceBetween]="20"
        [navigation]="false"
        [slidesPerView]="'auto'"
        [dragButtonHover]="'Drag'">
  <ng-template swiperSlide *ngFor="let slide of content.products">
    <div class="inner-slide">
      <h2 class="product-highlights__headline" [innerHTML]="slide.headline"></h2>
      <img *ngIf="slide.image?.url"
           [src]="slide.image.url"
           [alt]="slide.image.meta?.alt"
           [title]="slide.image.meta?.title">
      <p class="product-highlights__subline"
         [class.product-highlights__subline--no-image]="!slide.image?.url"
         [innerHTML]="slide.copy"></p>
      <a [routerLink]="[(detailLink$ | async), slide.product[0]?.category, slide.product[0]?.urlSlug].join('/')">
        <gd-button [label]="'toTheProduct' | translate"></gd-button>
      </a>
    </div>
  </ng-template>
</swiper>
<gd-round-button *ngIf="isTouch" [size]="buttonSizes.SMALL" icon="zoom" [color]="gdColor.YELLOW"></gd-round-button>
