<button
  class="tab"
  *ngFor="let tab of tabs; let i = index"
  [innerHTML]="useAsLabel ? tab[useAsLabel] : tab"
  [class.tab--active]="activeTab === i"
  [ngClass]="'tab--' + color"
  (click)="activeTabChange.emit(i)"></button>

<gd-multi-dropdown
  [items]="tabs"
  [useAsLabel]="useAsLabel"
  [useAsUnique]="useAsLabel"
  [activeItems]="[tabs[activeTab]]"
  [label]="tabs[activeTab][useAsLabel]"
  (activeItemsChange)="activeItemsChange($event)"></gd-multi-dropdown>
