<div class="buttons">
  <a [routerLink]="storyOverview$ | async">
    <gd-button [color]="gdColors.AZURE_OUTLINE"
               [icon]="'long-arrow-back'"
               [label]="'backToOverview' | translate"
               [iconPosition]="'left'"></gd-button>
  </a>
  <a *ngIf="(nextStory$ | async) as nextStory"
     [routerLink]="nextStory.detailPage?.path">
    <gd-button [color]="gdColors.AZURE"
               [iconPosition]="'right'"
               [icon]="'long-arrow-right'"
               [label]="'nextStory' | translate"></gd-button>
  </a>
</div>

<div class="next-story" *ngIf="(randomStory$ | async) as nextStory">
  <h2 class="herotext" [innerHTML]="'storyRelated' | translate"></h2>
  <div class="teaser-container">
    <story-teaser [story]="nextStory" layout="overview"></story-teaser>
  </div>
</div>
