import {AfterViewInit, Component, ElementRef, HostListener, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {BranchBlockModel} from '@rz-gud/content-elements/branch-block/branch-block.model';
import {ContentElementModel} from '@conception/ngx-pimcore-connect';
import {Observable} from 'rxjs';
import {Branch} from '@rz-gud/models';
import {BranchService} from '@rz-gud/services';
import {isPlatformBrowser} from '@angular/common';
import {take, tap} from 'rxjs/operators';

@Component({
  selector: 'ce-branch-block',
  templateUrl: './branch-block.component.html',
  styleUrls: ['./branch-block.component.scss']
})
export class BranchBlockComponent implements OnInit, AfterViewInit {

  @ContentElementModel(BranchBlockModel) content: BranchBlockModel;
  branches$: Observable<Array<Branch>>;
  maxSlides: number = 0;

  private readonly isBrowser: boolean = false;

  constructor(private readonly el: ElementRef,
              private readonly branchService: BranchService,
              @Inject(PLATFORM_ID) private platformId: Record<string, unknown>) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  @HostListener('window:resize')
  onWindowResize() {
    this.getMaxSlides();
  }

  ngOnInit(): void {
    setTimeout(() => {
        this.branches$ = this.branchService.getBranches({
          folder: this.content.folder?.path
        });
        this.getMaxSlides();
      }, 200
    );
  }

  ngAfterViewInit(): void {
    this.el.nativeElement.classList.add(this.content.theme);
  }

  getMaxSlides(): void {
    if (this.isBrowser && this.branches$) {
      this.branches$.pipe(
        take(1),
        tap(branches => {
          this.maxSlides = window.innerWidth >= 1020 ? branches.length - 3 : branches.length - 1;
        })
      ).subscribe();
    }
  }
}
