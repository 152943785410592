import { AfterViewInit, ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'dynamics-form',
  template: '<iframe [scrolling]="\'no\'" [srcdoc]="generateFormHTMLCode()"></iframe>',
  styleUrls: ['./dynamics-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicsFormComponent implements AfterViewInit {
  @Input() formBlockId: string = '';
  @Input() websiteId: string = '';
  @Input() hostName: string = '';

  constructor(private sanitizer: DomSanitizer) {}

  ngAfterViewInit() {
    const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
    const eventer = window[eventMethod];
    const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';

    eventer(
      messageEvent,
      (e) => {
        if (typeof e.data === 'string' && e.data.indexOf('documentHeight:') > -1) {
          const height = e.data.split('documentHeight:')[1];
          document.querySelector('iframe[srcdoc]')?.setAttribute('height', height);
        }
      },
      false
    );
  }

  generateFormHTMLCode(): SafeHtml {
    const dynamicsFormBlockId = this.formBlockId;
    const dynamicsWebsiteId = this.websiteId;
    const dynamicsHostname = this.hostName;

    const dynamicsInitialization = `
    const loadScript = (url) => {
      var script = document.createElement('script');
      script.src = url;
      document.body.appendChild(script);
    };

    loadScript('https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js?v=1.84.2007');
    loadScript('https://mktdplp102cdn.azureedge.net/public/latest/js/ws-tracking.js?v=1.84.2007');
  `;

    const htmlCode = `
    <html>
      <head>
        <style>
          .dialogProviderContainerStyle {
            width: auto !important;
            height: auto !important;
          }
        </style>
        <script src='https://ajax.aspnetcdn.com/ajax/jQuery/jquery-3.7.1.min.js'></script>
        <title>Microsoft Dynamics 365 Form</title>
      </head>
      <body>
        <div id='dynamics--form'>
          <div data-form-block-id='${dynamicsFormBlockId}'></div>
          <div id='${dynamicsWebsiteId}'></div>
          <div class='d365-mkt-config' style='display: none;'
          data-website-id='${dynamicsWebsiteId}' data-hostname='${dynamicsHostname}'></div>

          <script>${dynamicsInitialization}</script>
          <script>
            const postSizeToParent = () => {
              const newDocumentHeight = document.getElementById('dynamics--form').scrollHeight;
              const message = 'documentHeight:'+ newDocumentHeight;
              parent.postMessage(message,"*");
            }

            window.onresize = () => {
              postSizeToParent();
            }

            window.onload = () => {
              postSizeToParent();
            }
          </script>
        </div>
      </body>
    </html>
  `;

    return this.sanitizer.bypassSecurityTrustHtml(htmlCode);
  }
}
