<div class="media-teaser__video" #teaserVideo>
  <gd-round-button [color]="gdColors.GREEN"
                   [size]="buttonSizes.SMALL"
                   (click)="toggleVideoPopup(item.video)"
                   icon="video"
                   class="start"></gd-round-button>

  <img *ngIf="item.teaserImage"
       [src]="item.teaserImage.getImage('CmsImages')"
       [alt]="item.teaserImage?.meta?.alt"
       [title]="item.teaserImage?.meta?.title"
       role="presentation"
       class="poster">
</div>

<div class="media-teaser__content">
  <p *ngIf="item.category?.value" [innerHTML]="item.category?.value | translate" class="copy-2"></p>
  <h2 *ngIf="item.title" [innerHTML]="item.title" class="headline-3"></h2>
  <p *ngIf="item.description" [innerHTML]="item.description" class="copy"></p>
</div>
