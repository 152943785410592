import {Deserializable, serialize, serializeArray} from '@conception/ts-class-deserializer';

export class TechnicalDataItem extends Deserializable {
  @serialize() label: string;
  @serializeArray() values: Array<string>;
}

export class TechnicalDataTableSection extends Deserializable {
  @serialize() title: string;
  @serializeArray(TechnicalDataItem) fields: Array<TechnicalDataItem>;
}

export class TechnicalDataTable extends Deserializable {
  @serialize() id?: number;
  @serialize() type?: string;
  @serializeArray(TechnicalDataTableSection) tableSections: Array<TechnicalDataTableSection>;
}
