import {Deserializable, serialize, serializeArray} from '@conception/ts-class-deserializer';
import {PimAsset, PimLink} from '@conception/ngx-pimcore-connect';
import {SimpleHeadlineModel} from '../simple-headline/simple-headline.model';

export class knowHow extends Deserializable {
  @serialize(PimAsset) image: PimAsset;
  @serialize() date: Date;
  @serialize() headline: string;
  @serialize() copy: string;
  @serialize() link: PimLink;
}

export class CampusKnowHowModel extends Deserializable {
  @serialize(SimpleHeadlineModel) simpleHeadline: SimpleHeadlineModel;
  @serialize() icon: string;
  @serializeArray(knowHow) knowHows: Array<knowHow>;
}

