import { Component } from '@angular/core';
import { ContentElementModel } from '@conception/ngx-pimcore-connect';
import { LargeHeadlineModel } from '@rz-gud/content-elements/large-headline/large-headline.model';

@Component({
  selector: 'ce-large-headline',
  templateUrl: './large-headline.component.html',
  styleUrls: ['./large-headline.component.scss'],
})
export class LargeHeadlineComponent {
  @ContentElementModel(LargeHeadlineModel) content: LargeHeadlineModel;

  constructor() {}
}
