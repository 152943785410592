import {Deserializable, serialize} from '@conception/ts-class-deserializer';
import {PimImageCollection, PimLink} from '@conception/ngx-pimcore-connect';

export class CampusEvent extends Deserializable {
  @serialize() customSlug: boolean;
  @serialize() startDate: string;
  @serialize() endDate: string;
  @serialize() startTime: string;
  @serialize() endTime: string;
  @serialize() timezone: string;
  @serialize() description: string;
  @serialize() gdEvent: boolean;
  @serialize() id: number;
  @serialize(PimImageCollection) image: PimImageCollection;
  @serialize(PimLink) link: PimLink;
  @serialize() location: string;
  @serialize() title: string;
  @serialize() urlSlug: string;
}
