<a
  *ngIf="product.urlSlug"
  [routerLink]="'/' + [detailLink$ | async, product.category, product.urlSlug].join('/')"
  [title]="product.beschreibung"></a>
<img
  *ngIf="product.bildFrontansicht?.getImage('ProductHighlightSlider')"
  [src]="product.bildFrontansicht.getImage('ProductHighlightSlider')"
  [alt]="product.bildFrontansicht?.meta?.alt"
  [title]="product.bildFrontansicht?.meta?.title" />
<div class="no-image" *ngIf="!product.bildFrontansicht?.getImage('ProductHighlightSlider')"></div>
<div class="teaser__content">
  <div class="teaser__content--top">
    <div class="bookmark" (click)="handleBookmark()">
      <gd-icon icon="bookmark" color="azure" [filled]="amIBookmarked$ | async"></gd-icon>
      <div class="tooltip">
        {{ (amIBookmarked$ | async) ? ('removeFromWatchlist' | translate) : ('addToWatchlist' | translate) }}
      </div>
    </div>
    <gd-teaser-tag *ngIf="product.productFlags[0]?.label" [backgroundColor]="product.productFlags[0]?.value">{{
      product.productFlags[0]?.label
    }}</gd-teaser-tag>
  </div>
  <h3 [innerHTML]="product.headline()"></h3>
  <p>
    {{
      product[
        'funktion' +
          (this.translate.currentLang.toUpperCase() === 'EN-US' ? 'EN' : this.translate.currentLang.toUpperCase())
      ] | truncatetext: 60
    }}
  </p>
</div>
