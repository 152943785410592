import {Component, Input} from '@angular/core';
import {ContentElementModel} from '@conception/ngx-pimcore-connect';
import {WideImageModel} from './wide-image.model';

@Component({
  selector: 'ce-wide-image',
  templateUrl: './wide-image.component.html',
  styleUrls: ['./wide-image.component.scss']
})
export class WideImageComponent {
  @Input() @ContentElementModel(WideImageModel) content: WideImageModel;
}
