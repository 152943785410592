import {createReducer, on} from '@ngrx/store';
import {EasyModeSelectorAnswerModel} from '../../components/content-elements/easy-mode-selector/easy-mode-selector.model';
import {EasyModeActions} from '../actions';

export interface EasyModeState {
  answers: Array<EasyModeSelectorAnswerModel>;
  stage: number;
}

export const initialState: EasyModeState = {
  answers: [
    new EasyModeSelectorAnswerModel().toPlainObject(),
    new EasyModeSelectorAnswerModel().toPlainObject(),
    new EasyModeSelectorAnswerModel().toPlainObject()
  ],
  stage: 0
};

export const easyModeReducer = createReducer(
  initialState,
  on(EasyModeActions.clearAnswer, (state, {index}) => {
    const answers = [...state.answers];
    answers[index] = new EasyModeSelectorAnswerModel().toPlainObject();

    return {
      ...state,
      answers
    };
  }),
  on(EasyModeActions.setAnswer, (state, {answer}) => {
    const answers = [...state.answers];
    answers[state.stage - 1] = answer;

    return {
      ...state,
      answers
    };
  }),
  on(EasyModeActions.clear, () => initialState),
  on(EasyModeActions.setStage, (state, {stage}) => (
    {
      ...state,
      stage
    }
  )),
  on(EasyModeActions.nextStage, (state) => (
    {
      ...state,
      stage: state.stage + 1
    }
  )),
  on(EasyModeActions.previousStage, (state) => (
    {
      ...state,
      stage: state.stage - 1
    }
  ))
);
