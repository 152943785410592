import {Deserializable, serialize, serializeArray} from '@conception/ts-class-deserializer';
import {PimLink} from '@conception/ngx-pimcore-connect';

export class TextBlock extends Deserializable {
  @serialize() headline: string;
  @serialize() text: string;
  @serialize(PimLink) link: PimLink;
}

export class TextBlocksModel extends Deserializable {
  @serialize() headline: string;
  @serializeArray(TextBlock) blocks: Array<TextBlock>;
}
