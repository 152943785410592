import {ChangeDetectionStrategy, Component, HostBinding} from '@angular/core';
import {CampusBlockModel} from './campus-block.model';
import {GDColors} from '@conception/ng-gud-ui';
import {ContentElementModel} from '@conception/ngx-pimcore-connect';

@Component({
  selector: 'ce-campus-block',
  templateUrl: './campus-block.component.html',
  styleUrls: ['./campus-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CampusBlockComponent {

  @ContentElementModel(CampusBlockModel) content: CampusBlockModel;
  gdColors = GDColors;

  @HostBinding('class') get getHostClasses(): string {
    return [
      this.content.layout
    ].join(' ');
  }
}
