import {createAction, props} from '@ngrx/store';
import {PageMode} from '../reducers';

export const setPageMode = createAction(
  '[Page Mode] Set',
  props<{ mode: PageMode }>()
);

export const openPageModeTutorial = createAction(
  '[Page Mode] Open tutorial'
);

export const dismissPageModeTutorial = createAction(
  '[Page Mode] Dismiss tutorial'
);

export const openLanguageTutorial = createAction(
  '[Layout Language Tutorial] Open tutorial'
);

export const dismissLanguageTutorial = createAction(
  '[Layout Language Tutorial] Dismiss tutorial'
);

export const setFixed = createAction(
  '[Layout] Page fixed'
);

export const setUnfixed = createAction(
  '[Layout] Page unfixed'
);

export const toggleFixed = createAction(
  '[Layout] Page toggle fixed'
);

export const setFixedState = createAction(
  '[Layout] Set fixed state',
  props<{ fixed: boolean }>()
);

export const lockContactBar = createAction(
  '[Layout Contactbar] Lock'
);

export const unlockContactBar = createAction(
  '[Layout Contactbar] Unlock'
);

export const showLanguageSwitchOverlay = createAction(
  '[Layout Language Switch Overlay] Show'
);

export const hideLanguageSwitchOverlay = createAction(
  '[Layout Language Switch Overlay] Hide'
);

export const activateAlternativeBreadcrumb = createAction(
  '[Layout Alternative Breadcrumb] Active'
);

export const deactivateAlternativeBreadcrumb = createAction(
  '[Layout Alternative Breadcrumb] Inactive'
);

export const setBreadcrumbColor = createAction(
  '[Layout Breadcrumb Color] Set',
  props<{ breadcrumbColor: 'dark' | 'white' }>()
);
