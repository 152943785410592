<ng-container *ngIf="content.link?.linktype === 'direct' && content.link?.direct">
  <a [href]="content.link.direct" target="_blank" rel="noopener">
    <gd-button color="azure" [label]="content.link.text" class="external"></gd-button>
  </a>
</ng-container>

<a *ngIf="(eventsOverview$ | async) as eventsOverview" [routerLink]="eventsOverview">
  <gd-button icon="long-arrow-back"
             [label]="'backToOverview' | translate"
             iconPosition="left"
             color="azure-outline"></gd-button>
</a>
