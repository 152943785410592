<ng-container *ngIf="comparison$ | async as table">
  <gd-simple-button
    icon="long-arrow-back"
    class="history-back"
    [small]="true"
    [label]="'backToOverview' | translate"
    (click)="historyBack()"></gd-simple-button>
  <div class="heading">
    <h1 [innerHTML]="content.headline" *ngIf="content.headline"></h1>
    <h2 [innerHTML]="content.subline" *ngIf="content.subline"></h2>

    <ng-container *ngIf="isLoading$ | async; else showContent">
      <preloader></preloader>
    </ng-container>
  </div>

  <ng-template #showContent>
    <div class="no-entries" *ngIf="table.products.length === 0 && idList.length === 0">
      {{ 'product.comparison.noEntries' | translate }} <br /><br />
      <a [routerLink]="productOverviewLink$ | async">
        <gd-button [color]="'azure'" [label]="'bookmarks.to_products' | translate"></gd-button>
      </a>
      <gd-separator></gd-separator>
    </div>
    <div class="product-comparison-share" *ngIf="idList.length">
      <div
        class="product-comparison-share__headline"
        [innerHTML]="
          !shareMode ? ('product.comparison.share' | translate) : ('product.comparison.sharePdfText' | translate)
        "></div>
      <div class="cta">
        <div class="share" *ngIf="!shareMode">
          <div class="share__field--container">
            <gd-icon class="share__icon" icon="link" color="azure"></gd-icon>
            <input
              class="share__field"
              type="text"
              [value]="shareLinkPrefix + shareLinkParams"
              readonly
              #shareInput
              (click)="select($event.target)" />
          </div>
          <gd-button
            [color]="triggeredCopy ? 'green' : 'silver-light'"
            [label]="
              triggeredCopy
                ? ('product.comparison.link_copied' | translate)
                : ('product.comparison.copy_link' | translate)
            "
            (click)="copyLink()"></gd-button>
        </div>
        <div class="printing-button">
          <gd-button
            color="azure"
            [label]="
              printing
                ? ('product.comparison.pdf.loading' | translate)
                : ('product.comparison.download_pdf' | translate)
            "
            (click)="openPDF()"></gd-button>
          <preloader *ngIf="printing"></preloader>
        </div>
      </div>
    </div>
    <div class="table-button">
      <ng-container [ngTemplateOutlet]="comparisonTable"></ng-container>
      <div *ngIf="table.products.length === 2" class="to-overview">
        <a [routerLink]="productOverviewLink$ | async">
          <gd-button [color]="'azure'" [label]="'product.comparison.goToOverview' | translate"></gd-button>
        </a>
      </div>
      <div *ngIf="table.products.length === 1" class="to-overview">
        <a [routerLink]="productOverviewLink$ | async">
          <gd-button [color]="'azure'" [label]="'product.comparison.goToOverview' | translate"></gd-button>
        </a>
        <div>{{ 'product.comparison.hint' | translate }}</div>
      </div>
    </div>
  </ng-template>

  <ng-template #comparisonTable>
    <table *ngIf="table.products.length > 0">
      <thead>
        <tr class="table__header">
          <th class="table__header--differences" colspan="2">
            <div class="toggle">
              <input
                id="markSame"
                type="checkbox"
                [checked]="markSame"
                (click)="toggleTableColor()"
                [ngClass]="{ 'checked': this.markSame, 'unchecked': !this.markSame }" />

              <label for="markSame" class="toggle__trail">
                <span class="toggle__handler"></span>
              </label>
              <div class="label" [innerHTML]="'product.comparison.toggleLabel.markDifferences' | translate"></div>
            </div>

            <div class="toggle">
              <input
                id="hideSame"
                type="checkbox"
                [checked]="hideSame"
                (click)="toggleTableValues()"
                [ngClass]="{ 'checked': this.hideSame, 'unchecked': !this.hideSame }" />
              <label for="hideSame" class="toggle__trail">
                <span class="toggle__handler"></span>
              </label>
              <div class="label" [innerHTML]="'product.comparison.hideSameValues' | translate"></div>
            </div>
          </th>
          <th class="table__header--element" *ngFor="let product of table.products; let i = index">
            <div class="delete" (click)="removeFromList(product.id)">
              <gd-icon icon="trashcan" color="azure"></gd-icon>
            </div>
            <div class="wrap">
              <div class="table__header--content-top">
                <div class="image-container">
                  <a [href]="baseUrl$ + (detailLink$ | async) + '/' + getProductSlug(product.id)">
                    <img
                      *ngIf="product?.image?.urls['ProductTeaser']"
                      [src]="product.image.urls['ProductTeaser']"
                      [title]="product.image.meta?.title ? product.image.meta.title : product.title"
                      [alt]="product.image.meta?.alt ? product.image.meta.alt : product.title" />
                    <div class="img-placeholder" *ngIf="!product.image.urls['ProductTeaser']"></div>
                  </a>
                </div>
                <div class="title">
                  <a [href]="baseUrl$ + (detailLink$ | async) + '/' + getProductSlug(product.id)">{{ product.title }}</a>
                </div>
              </div>
              <div class="table__header--content-bottom">
                <div
                  class="article-number"
                  [innerHTML]="('product.comparison.articleNumber' | translate) + ' ' + product.sku"></div>
                <div class="cta cta__bookmark" *ngIf="bookmarks$ | async as bookmarks">
                  <gd-button
                    [label]="'requestNow' | translate"
                    color="white-maya"
                    (click)="request(product.title, product.sku)"></gd-button>
                  <div
                    class="bookmark-container"
                    (mouseleave)="tooltip.style.width = '0'"
                    (mouseover)="checkButtonWidth(bookmarkContainer)"
                    #bookmarkContainer>
                    <div
                      class="bookmark"
                      (click)="handleBookmark(product.id)"
                      [class.active]="isBookmarked(product, bookmarks)">
                      <gd-icon icon="bookmark" color="azure"></gd-icon>
                      <div class="tooltip" #tooltip>
                        {{
                          isBookmarked(product, bookmarks)
                            ? ('removeFromWatchlist' | translate)
                            : ('addToWatchlist' | translate)
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let row of table.rows; let isLastRow = last">
          <ng-container *ngFor="let subRow of row.subRows; let isFirstRow = first">
            <tr
              class="table__row"
              [class.divider]="isFirstRow && !isLastRow"
              [class.table__row--hide]="(hideSame && !hasRowDifferences(row)) || !hideValuesNotAvailableInRow(row)">
              <th *ngIf="isFirstRow" [attr.rowspan]="row.subRows?.length" [innerHTML]="row.title"></th>
              <td
                class="table__label"
                [class.table__row--hide]="
                  (hideSame && !hasDifferences(subRow.fields)) || !hideValuesNotAvailableInFields(subRow.fields)
                "
                [class.table__row--highlight]="hasDifferences(subRow.fields) && markSame"
                [innerHTML]="subRow.label"></td>
              <ng-container *ngFor="let field of subRow.fields">
                <td
                  class="table__value"
                  [innerHTML]="
                    field[0] === 'productComparison.notAvailable' || field.length === 0
                      ? ('product.comparison.notAvailable' | translate)
                      : field.join('<br>')
                  "
                  [class.table__row--hide]="
                    (hideSame && !hasDifferences(subRow.fields)) || !hideValuesNotAvailableInFields(subRow.fields)
                  "
                  [class.table__row--highlight]="hasDifferences(subRow.fields) && markSame"></td>
              </ng-container>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
  </ng-template>
  <div *ngIf="printing" class="pdf-template" id="pdfTemplate" #pdfTemplate>
    <ng-container [ngTemplateOutlet]="comparisonTable"></ng-container>
  </div>
</ng-container>
