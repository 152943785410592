import {Component, HostBinding, Input} from '@angular/core';

@Component({
  selector: 'flyout-teaser-text',
  templateUrl: './flyout-teaser-text.component.html',
  styleUrls: ['./flyout-teaser-text.component.scss']
})
export class FlyoutTeaserTextComponent {

  @Input() data: any;
  @Input() isCategory: boolean = false;
  @HostBinding('class') @Input() color: string;
}
