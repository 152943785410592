<tab-container *ngIf="tabs.length"
               [tabs]="tabs"
               color="silver-light"
               useAsLabel="tabTitle"
               [(activeTab)]="activeTab"
               (activeTabChange)="scrollTop()"></tab-container>

<div class="tab-content" #tabContent *ngIf="tabs.length">

  <ng-container *ngIf="tabs[activeTab].tabType === 'text'">
    <ng-container *ngFor="let item of tabs[activeTab].tabElement">
      <ng-container *ngIf="item['headline'] || item['text']">
        <h2 *ngIf="item['headline']" [innerHTML]="'subCategories.' + item['headline'] | translate"></h2>
        <div *ngIf="item['text']" class="tab-content__content" [innerHTML]="item['text']"></div>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="tabs[activeTab].tabType === 'table'">
    <technical-data-table
      *ngIf="tabs[activeTab].tabElement?.type && tabs[activeTab].tabElement?.type === 'table'"
      [data]="tabs[activeTab].tabElement">
    </technical-data-table>
  </ng-container>

</div>
