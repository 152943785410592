import {ChangeDetectionStrategy, Component} from '@angular/core';
import {SimpleTilesModel} from './simple-tiles.model';
import {ContentElementModel} from '@conception/ngx-pimcore-connect';

export enum LogoPosition {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center'
}

@Component({
  selector: 'ce-simple-tiles',
  templateUrl: './simple-tiles.component.html',
  styleUrls: ['./simple-tiles.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleTilesComponent {
  @ContentElementModel(SimpleTilesModel) content: SimpleTilesModel;
  position: typeof LogoPosition = LogoPosition;
}
