import { Component, OnInit } from '@angular/core';
import { ContentElementModel } from '@conception/ngx-pimcore-connect';
import { ProductOverviewModel } from './product-overview.model';
import { Store } from '@ngrx/store';
import { ProductFilterActions, SearchActions } from '@rz-gud/store';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ce-product-overview',
  templateUrl: './product-overview.component.html',
  styleUrls: ['./product-overview.component.scss']
})
export class ProductOverviewComponent implements OnInit {
  @ContentElementModel(ProductOverviewModel) content: ProductOverviewModel;

  constructor(private readonly activatedRoute: ActivatedRoute,
              private readonly store: Store) {
  }

  ngOnInit(): void {
    this.store.dispatch(ProductFilterActions.setupFilter({filterDefinitions: this.content.filterDefinitions}));
    this.store.dispatch(SearchActions.clearTerm());
  }
}
