import { createSelector } from '@ngrx/store';
import { PageState, State } from '../reducers';
import { SiteInfo, Breadcrumb } from '@rz-gud/interfaces';

export const select = (state: State) => state.page;

export const siteInfo = createSelector<State, [PageState], SiteInfo>(
  select,
  (state: PageState) => state.siteInfo
);

export const start = createSelector<State, [PageState], string>(
  select,
  (state: PageState) => state.siteInfo?.homepage?.path
);

export const storyOverview = createSelector<State, [PageState], string>(
  select,
  (state: PageState) => state.siteInfo?.stories?.path
);

export const productEntry = createSelector<State, [PageState], string>(
  select,
  (state: PageState) => state.siteInfo?.products?.path
);

export const productOverview = createSelector<State, [PageState], string>(
  select,
  (state: PageState) => state.siteInfo?.productsOverview?.path
);

export const productDetail = createSelector<State, [PageState], string>(
  select,
  (state: PageState) => state.siteInfo?.productsDetail?.path
);

export const eventOverview = createSelector<State, [PageState], string>(
  select,
  (state: PageState) => state.siteInfo?.eventPage?.path
);

export const eventDetail = createSelector<State, [PageState], string>(
  select,
  (state: PageState) => state.siteInfo?.eventDetail?.path
);

export const campus = createSelector<State, [PageState], string>(
  select,
  (state: PageState) => state.siteInfo?.campus?.path
);

export const faq = createSelector<State, [PageState], string>(
  select,
  (state: PageState) => state.siteInfo?.faqPage?.path
);

export const glossary = createSelector<State, [PageState], string>(
  select,
  (state: PageState) => state.siteInfo?.glossaryPage?.path
);

export const mediaLibrary = createSelector<State, [PageState], string>(
  select,
  (state: PageState) => state.siteInfo?.mediathekPage?.path
);

export const branchesOverview = createSelector<State, [PageState], string>(
  select,
  (state: PageState) => state.siteInfo?.industries?.path
);

export const contact = createSelector<State, [PageState], string>(
  select,
  (state: PageState) => state.siteInfo?.contact?.path
);

export const support = createSelector<State, [PageState], string>(
  select,
  (state: PageState) => state.siteInfo?.support?.path
);

export const bookmarks = createSelector<State, [PageState], string>(
  select,
  (state: PageState) => state.siteInfo?.bookmarks?.path
);

export const easyMode = createSelector<State, [PageState], string>(
  select,
  (state: PageState) => state.siteInfo?.easymodeResult?.path
);

export const search = createSelector<State, [PageState], string>(
  select,
  (state: PageState) => state.siteInfo?.search?.path
);

export const productComparison = createSelector<State, [PageState], string>(
  select,
  (state: PageState) => state.siteInfo?.productComparison?.path
);

export const breadcrumb = createSelector<State, [PageState], Array<Breadcrumb>>(
  select,
  (state: PageState) => state.breadcrumb
);

export const breadcrumbVisible = createSelector<State, [PageState], boolean>(
  select,
  (state: PageState) => state.breadcrumb.length > 1
);

export const popularSearches = createSelector<
  State,
  [PageState],
  Array<string>
>(select, (state: PageState) =>
  state.siteInfo?.popularSearches.map((el) => el.searchTerm)
);

export const hubspotPortalId = createSelector<State, [PageState], string>(
  select,
  (state: PageState) => state.siteInfo.hubspotPortalId
);

export const hubspotFormProductInquiry = createSelector<
  State,
  [PageState],
  string
>(select, (state: PageState) => state.siteInfo.hubspotFormProductInquiry);

export const hubspotFormProductQuestions = createSelector<
  State,
  [PageState],
  string
>(select, (state: PageState) => state.siteInfo.hubspotFormProductQuestions);

export const productInquiryDynamicsWebsiteId = createSelector<
  State,
  [PageState],
  string
>(select, (state: PageState) => state.siteInfo.productInquiryDynamicsWebsiteId);

export const productInquiryDynamicsHostname = createSelector<
  State,
  [PageState],
  string
>(select, (state: PageState) => state.siteInfo.productInquiryDynamicsHostname);

export const productInquiryDynamicsFormBlockId = createSelector<
  State,
  [PageState],
  string
>(
  select,
  (state: PageState) => state.siteInfo.productInquiryDynamicsFormBlockId
);

export const productInquiryDynamicsPrefillFieldId = createSelector<
  State,
  [PageState],
  string
>(
  select,
  (state: PageState) => state.siteInfo.productInquiryDynamicsPrefillFieldId
);

export const productQuestionDynamicsWebsiteId = createSelector<
  State,
  [PageState],
  string
>(
  select,
  (state: PageState) => state.siteInfo.productQuestionDynamicsWebsiteId
);

export const productQuestionDynamicsPrefillFieldId = createSelector<
  State,
  [PageState],
  string
>(
  select,
  (state: PageState) => state.siteInfo.productQuestionDynamicsPrefillFieldId
);

export const productQuestionDynamicsHostname = createSelector<
  State,
  [PageState],
  string
>(select, (state: PageState) => state.siteInfo.productQuestionDynamicsHostname);

export const productQuestionDynamicsFormBlockId = createSelector<
  State,
  [PageState],
  string
>(
  select,
  (state: PageState) => state.siteInfo.productQuestionDynamicsFormBlockId
);
