import {ChangeDetectionStrategy, Component} from '@angular/core';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {PageSelectors} from '@rz-gud/store';
import {GtagService} from '@rz-gud/services/gtag.service';

@Component({
  selector: 'contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactComponent {
  readonly contactLink$: Observable<string> = this.store.select(PageSelectors.contact);

  constructor(private readonly store: Store,
              private readonly gtag: GtagService) {
  }


  trackEvent(cta: string): void {
    this.gtag.pushEvent('cta', [{location: 'contact-footer'}, {cta}]);
  }

}
