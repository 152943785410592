import {Deserializable, serialize, serializeArray} from '@conception/ts-class-deserializer';
import {CampusTopic} from '@rz-gud/models';


export class CampusBlockModel extends Deserializable {
  @serialize() headline: string;
  @serialize() headlineHighlight: string;
  @serialize() backgroundColor: string;
  @serialize() layout: string;
  @serialize() backToOverview: boolean;
  @serializeArray(CampusTopic) campusBlock: Array<CampusTopic>;
}

