import {Deserializable, serialize, serializeArray} from '@conception/ts-class-deserializer';

export class Fact extends Deserializable {
  @serialize() headline: string;
  @serialize() text: string;
  @serialize() icon: string;
}

export class FactSliderModel extends Deserializable {
  @serializeArray(Fact) blocks: Array<Fact>;
  @serialize() theme: 'azure' | 'campus';
}
