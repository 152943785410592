import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@rz-gud/environment';

@Injectable()
export class PimInlineLinkInterceptor implements HttpInterceptor {
  private static modifyBody(body: any): any {
    return JSON.parse(
      JSON.stringify(body).replace(
        environment.inlineLinkMarker,
        environment.apiUrl.replace('webapp/', '')
      )
    );
  }

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (
          event instanceof HttpResponse &&
          req.url.endsWith(environment.endpoints.pages) &&
          req.method === 'POST'
        ) {
          event = event.clone({
            body: PimInlineLinkInterceptor.modifyBody(event.body),
          });
        }
        return event;
      })
    );
  }
}
