<h2 class='herotext' *ngIf='content.headline' [innerHTML]='content.headline'></h2>
<p *ngIf='content.copy' [innerHTML]='content.copy'></p>


<accordion-container *ngIf='content.blocks.length > 0'>
  <ng-container *ngFor='let item of content.blocks; let i = index'>
    <gd-accordion *ngIf='item.headline' [title]='item.headline'
                  [id]='ids[i]'
                  [active]='activeAccordion === i'
                  [color]='content.theme'
                  (toggle)='toggle(i, $event)'>

      <gd-accordion-entry [copy]='item.copy'
                          [video]='item.video'
                          [image]='item.image'></gd-accordion-entry>

      <dynamics-form *ngIf='item.dynamicsFormBlockId && item.dynamicsWebsiteId && item.dynamicsHostName'
                     [formBlockId]='item.dynamicsFormBlockId'
                     [websiteId]='item.dynamicsWebsiteId'
                     [hostName]='item.dynamicsHostName'></dynamics-form>

      <hubspot-form
        *ngIf='item.hubspotFormId && !(item.dynamicsFormBlockId && item.dynamicsWebsiteId && item.dynamicsHostName)'
        [formId]='renderForms.get(i)'></hubspot-form>
    </gd-accordion>
  </ng-container>
</accordion-container>
