import {Deserializable, serialize, serializeArray} from '@conception/ts-class-deserializer';

export class Downloads extends Deserializable {
  @serialize() extension: string;
  @serialize() preview: string;
  @serialize() size: number;
  @serialize() title: string;
  @serialize() url: string;
}

export class DownloadBlockModel extends Deserializable {
  @serialize() downloadBlockTitle: string;
  @serialize() icon: string;
  @serializeArray(Downloads) downloads: Array<Downloads>;
}

export class ProductDetailDownloadModel extends Deserializable {
  @serializeArray(DownloadBlockModel) downloadBlocks: Array<DownloadBlockModel>;
}


