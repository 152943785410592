import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {Story} from '@rz-gud/models';

@Component({
  selector: 'story-teaser',
  templateUrl: './story-teaser.component.html',
  styleUrls: ['./story-teaser.component.scss']
})
export class StoryTeaserComponent implements OnInit {

  @Input() story: Story = null;
  @Input() layout: 'horizontal' | 'vertical' | 'overview' = 'horizontal';
  @Input() xOrientation: string = 'right';
  @Input() yOrientation: string = 'bottom';

  @Input() class: string = '';

  orientation: string;

  @HostBinding('class') get hostClasses(): string {
    return [
      this.class,
      this.layout,
    ].join(' ');
  }

  ngOnInit(): void {
    this.orientation = 'box box--' + this.yOrientation + ' ' + 'box--' + this.xOrientation;
  }

}
