import {Component} from '@angular/core';
import {Store} from '@ngrx/store';
import {PageSelectors, ProductFilterActions, SearchActions} from '@rz-gud/store';
import {Observable} from 'rxjs';
import {GtagService} from '@rz-gud/services/gtag.service';

@Component({
  selector: 'search-overlay',
  templateUrl: './search-overlay.component.html',
  styleUrls: ['./search-overlay.component.scss']
})
export class SearchOverlayComponent {
  popularSearches$: Observable<Array<string>> = this.store.select(PageSelectors.popularSearches);
  readonly productOverviewLink$: Observable<string> = this.store.select(PageSelectors.productOverview);

  exactSearch: boolean = false;
  disableInText: boolean = false;

  constructor(private readonly store: Store,
              private readonly gtag: GtagService) {
    this.store.dispatch(SearchActions.clearTerm());
    this.store.dispatch(ProductFilterActions.clearSearchTerm());
  }

  close(): void {
    this.store.dispatch(SearchActions.closeOverlay());
  }

  search(term: string): void {
    this.store.dispatch(SearchActions.setTerm({term}));
    this.store.dispatch(SearchActions.search());
    this.gtag.pushEvent('search_via_popular', [{term}]);
    this.close();
  }
}
