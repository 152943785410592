<ng-container *ngIf="items.length > 0">
  <swiper
    #swiper
    [config]="config"
    (activeIndexChange)="setCurrentSlide($event)"
  >
    <ng-template swiperSlide *ngFor="let productHighlight of items">
      <div class="inner-slide">
        <product-highlight-teaser
          [product]="productHighlight"
        ></product-highlight-teaser>
      </div>
    </ng-template>
  </swiper>
  <div class="bottom" *ngIf="showNavigation || overviewButton?.path">
    <gd-slider-navigation
      *ngIf="showNavigation"
      [maxSlides]="maxSlides"
      [class.full-width]="!overviewButton?.path"
      [currentSlide]="currentSlide"
      (jumpTo)="swiper.swiperRef.slideTo($event)"
      (navigation)="
        $event === 'next'
          ? swiper.swiperRef.slideNext()
          : swiper.swiperRef.slidePrev()
      "
    >
    </gd-slider-navigation>
    <div *ngIf="!showNavigation"></div>

    <a
      *ngIf="overviewButton?.path"
      class="to-overview"
      [routerLink]="overviewButton.path"
    >
      <gd-simple-button [label]="'toOverview' | translate"></gd-simple-button>
    </a>
  </div>
</ng-container>
