<header>
  <h3 class="copy" [innerHTML]="content.subline"></h3>
  <h2 class="herotext" [innerHTML]="content.headline"></h2>
</header>

<div class="branches" *ngIf="(branches$ | async) as branches">
  <branch-slider [branches]="branches" [sliderConfig]="sliderConfig" [maxSlides]="maxSlides" [showOverviewButton]="false"></branch-slider>
</div>

<a [routerLink]="branchesOverview$ | async">
  <gd-button [color]="gdColors.AZURE_OUTLINE"
             icon="long-arrow-back"
             [label]="'backToOverview' | translate"
             iconPosition="left"></gd-button>
</a>
