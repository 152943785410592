<ng-container *ngIf="answers.length">
  <header>
    <div class="header--inner">
      <h1 class="headline" [innerHTML]="content.headline"></h1>

      <div class="answers">
        <div class="answer">
          <gd-icon [icon]="answers[0].icon" [color]="gdColors.SILVERLIGHT"></gd-icon>
          <div class="label">{{ 'easymode.industry' | translate}}</div>
          <div class="value" [innerHTML]="answers[0].answer"></div>
        </div>
        <div class="answer">
          <gd-icon [icon]="answers[1].icon" [color]="gdColors.SILVERLIGHT"></gd-icon>
          <div class="label">{{ 'easymode.function' | translate}}</div>
          <div class="value" [innerHTML]="answers[1].answer"></div>
        </div>
        <div class="answer">
          <gd-icon [icon]="answers[2].icon" [color]="gdColors.SILVERLIGHT"></gd-icon>
          <div class="label">{{ 'easymode.kvm' | translate}}</div>
          <div class="value" [innerHTML]="answers[2].answer"></div>
        </div>
      </div>
    </div>
  </header>

  <div class="easy-mode-result--inner">
    <ce-wide-image *ngIf="wideImage" [content]="wideImage"></ce-wide-image>

    <div class="wrap">
      <gd-simple-headline *ngIf="simpleHeadline" [content]="simpleHeadline"></gd-simple-headline>
    </div>

    <div class="media" *ngIf="mediaLibraryItems.length">
      <h2 [innerHTML]="'easymode.medialibrary' | translate"></h2>
      <media-library-container [items]="mediaLibraryItems"></media-library-container>
    </div>

    <div class="events" *ngIf="events.length">
      <h2 [innerHTML]="'easymode.events' | translate"></h2>
      <events-container [items]="events"></events-container>
    </div>

    <ce-product-categories *ngIf="productCategories?.elements.length" [content]="productCategories">
    </ce-product-categories>

    <div class="wrap">
      <div class="stories" *ngIf="stories.length">
        <h2 [innerHTML]="'easymode.stories' | translate"></h2>
        <story-teaser *ngFor="let story of stories; let odd = odd"
                      [xOrientation]="odd ? 'left' : 'right'"
                      layout="overview"
                      [story]="story"></story-teaser>
      </div>
    </div>
  </div>
</ng-container>
