import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { ContentElementModel } from '@conception/ngx-pimcore-connect';
import { ProductSearchModel } from './product-search.model';
import { Store } from '@ngrx/store';
import {LayoutActions, PageActions, PageSelectors, ProductFilterActions, SearchActions} from '@rz-gud/store';
import { GtagService } from '@rz-gud/services/gtag.service';
import {Observable, Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'ce-product-search',
  templateUrl: './product-search.component.html',
  styleUrls: ['./product-search.component.scss']
})
export class ProductSearchComponent implements OnInit, OnDestroy {
  @ContentElementModel(ProductSearchModel) content: ProductSearchModel;

  @Input() class: string = '';
  productOverviewLink$: Observable<string> = this.store.select(PageSelectors.productOverview);

  exactSearch: boolean = false;
  disableInText: boolean = false;

  constructor(private readonly store: Store,
              private readonly gtag: GtagService) {
  }

  @HostBinding('attr.class') get hostClasses(): string {
    return [this.class, this.content.theme].join(' ');
  }

  ngOnInit() {
    this.store.dispatch(ProductFilterActions.clearSearchTerm());

    if (this.content.theme === 'main') {
      this.store.dispatch(PageActions.clearBreadcrumb());
      this.store.dispatch(LayoutActions.activateAlternativeBreadcrumb());
    }
  }

  ngOnDestroy() {
    this.store.dispatch(LayoutActions.deactivateAlternativeBreadcrumb());
  }

  search(term: string): void {
    this.store.dispatch(ProductFilterActions.setSearchTerm({ term }));
    this.store.dispatch(ProductFilterActions.search());
    this.gtag.pushEvent('product_search_via_popular', [{ term }]);
  }
}
