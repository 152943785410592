import {Deserializable, serialize} from '@conception/ts-class-deserializer';
import {IntroModel} from '@conception/ng-gud-ui';
import {PimText} from '@conception/ngx-pimcore-connect';

export class ProductDetailTextBlockModel extends Deserializable {
  @serialize() text: PimText | string;
  @serialize() headline: string;

  getIntro(): IntroModel {
    if (typeof this.text === 'string') {
      return new IntroModel().deserialize({
        copy: this.text
      });
    } else {
      return new IntroModel().deserialize({
        highlight: this.text?.headline,
        copy: this.text?.text
      });
    }
  }
}


