<div class="filter">
  <ng-container *ngFor="let item of filters | keyvalue">
    <gd-multi-dropdown *ngIf="filters[item.key].length"
                       [items]="filters[item.key]"
                       [useAsLabel]="'name'"
                       [useAsUnique]="'id'"
                       [label]="'storyOverview.filter.' + item.key | translate"
                       [multi]="true"
                       [(activeItems)]="activeFilters[item.key]"
                       (activeItemsChange)="setFilter()"></gd-multi-dropdown>
  </ng-container>

  <div class="filter__tags"
       *ngIf="activeFilters.technology?.length || activeFilters.region?.length ||activeFilters.branch?.length">
    <ng-container *ngFor="let item of activeFilters | keyvalue">
      <gd-tag *ngFor="let tag of activeFilters[item.key]"
              [label]="tag.name"
              (click)="removeFilter(item.key, tag.id)"></gd-tag>
    </ng-container>
  </div>
</div>

<story-teaser *ngFor="let story of storiesToShow; let odd = odd; trackBy: trackById"
              [xOrientation]="odd ? 'left' : 'right'"
              layout="overview"
              [story]="story"></story-teaser>
