import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { SwiperModule } from 'swiper/angular';
import { TranslateModule } from '@ngx-translate/core';
import { NgGudUiModule } from '@conception/ng-gud-ui';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { EasyModeIndicatorComponent } from '@rz-gud/content-elements/easy-mode-selector/easy-mode-indicator/easy-mode-indicator.component';
import { EasyModeDropdownComponent } from '@rz-gud/content-elements/easy-mode-selector/easy-mode-dropdown/easy-mode-dropdown.component';
import { DynamicScriptDirective } from './directives/dynamic-script.directive';
import {
  BreadcrumbComponent,
  FlyoutItemComponent,
  FlyoutMenuComponent,
  FlyoutTeaserComponent,
  FlyoutTeaserTextComponent,
  MenuItemComponent,
  MobileMenuComponent,
  VideoOverlayComponent,
} from '@rz-gud/components/static';
import { FilterTagsComponent } from '@rz-gud/content-elements/product-overview/filter-tags/filter-tags.component';
import { PropertyFilterComponent } from '@rz-gud/content-elements/product-overview/property-filter/property-filter.component';
import { MainFilterComponent } from '@rz-gud/content-elements/product-overview/main-filter/main-filter.component';
import { HighlightTextPipe, TruncateTextPipe, FilterPipe } from '@rz-gud/pipes';
import {
  AccordionComponent,
  BookmarksComponent,
  BranchBlockComponent,
  BranchOverviewComponent,
  BranchRelatedComponent,
  BranchTabpanelComponent,
  CampusBlockComponent,
  CampusEventDetailHeaderComponent,
  CampusEventDetailIcsComponent,
  CampusEventDetailImageComponent,
  CampusEventDetailLinkComponent,
  CampusEventDetailTextbodyComponent,
  CampusEventsComponent,
  CampusFaqComponent,
  CampusGlossaryComponent,
  CampusHighlightSliderComponent,
  CampusKnowHowComponent,
  CampusMediaComponent,
  CeHubspotFormComponent,
  CeDynamicsFormComponent,
  EasyModeResultComponent,
  EasyModeSelectorComponent,
  FactSliderComponent,
  FocusSliderComponent,
  FullsizeTeaserComponent,
  HeroTextComponent,
  ImageComponent,
  ImageSliderComponent,
  ImageTextSliderComponent,
  InsertionComponent,
  PageNotFoundComponent,
  PreloaderComponent,
  ProductCategoriesComponent,
  ProductComparisonComponent,
  ProductDetailDownloadComponent,
  ProductDetailRelatedProductsComponent,
  ProductDetailSliderComponent,
  ProductDetailTabpanelComponent,
  ProductDetailTextBlockComponent,
  ProductDisplayComponent,
  ProductFamiliesComponent,
  ProductHighlightsComponent,
  ProductHighlightSliderCEComponent,
  ProductOverviewComponent,
  ProductSearchComponent,
  SearchResultsComponent,
  SimpleHeadlineComponent,
  SimpleTilesComponent,
  SliderComponent,
  StoryBlockComponent,
  StoryOverviewComponent,
  StoryRelatedComponent,
  TeaserBlocksComponent,
  TextBlocksComponent,
  WideImageComponent,
  ProductDetailInterfacesComponent,
} from '@rz-gud/content-elements';
import {
  AccordionContainerComponent,
  EventsContainerComponent,
  MediaLibraryContainerComponent,
  OverlayContainerComponent,
  TabContainerComponent,
} from '@rz-gud/components/shared/container';
import {
  AutoSuggestComponent,
  HowItWorksComponent,
  HubspotFormComponent,
  DynamicsFormComponent,
  OverlayComponent,
  SearchbarComponent,
  TechnicalDataTableComponent,
} from '@rz-gud/components/shared/content-blocks';
import { ModeSwitchComponent, OptionDropdownComponent, TutorialComponent } from '@rz-gud/components/shared/interaction';
import {
  BranchSliderComponent,
  FadeSliderComponent,
  ProductHighlightSliderComponent,
  ProductSliderComponent,
} from '@rz-gud/components/shared/slider';
import {
  BranchTeaserComponent,
  CampusKnowhowTeaserComponent,
  EventTeaserComponent,
  HotspotTeaserComponent,
  MediaTeaserComponent,
  ProductHighlightTeaserComponent,
  ProductTeaserComponent,
  ProductTeaserUiComponent,
  SearchTeaserComponent,
  StoryTeaserComponent,
} from '@rz-gud/components/shared/teaser';
import { ProductContainerComponent } from '@rz-gud/content-elements/product-overview/product-container/product-container.component';
import { LargeHeadlineComponent } from '@rz-gud/content-elements/large-headline/large-headline.component';

@NgModule({
  declarations: [
    AccordionComponent,
    AccordionContainerComponent,
    AutoSuggestComponent,
    BookmarksComponent,
    BranchBlockComponent,
    BranchOverviewComponent,
    BranchRelatedComponent,
    BranchSliderComponent,
    BranchTabpanelComponent,
    BranchTeaserComponent,
    BreadcrumbComponent,
    CampusBlockComponent,
    CampusEventDetailHeaderComponent,
    CampusEventDetailIcsComponent,
    CampusEventDetailImageComponent,
    CampusEventDetailLinkComponent,
    CampusEventDetailTextbodyComponent,
    CampusEventsComponent,
    CampusFaqComponent,
    CampusGlossaryComponent,
    CampusHighlightSliderComponent,
    CampusKnowHowComponent,
    CampusKnowhowTeaserComponent,
    CampusMediaComponent,
    CeDynamicsFormComponent,
    CeHubspotFormComponent,
    DynamicScriptDirective,
    DynamicsFormComponent,
    EasyModeDropdownComponent,
    EasyModeIndicatorComponent,
    EasyModeResultComponent,
    EasyModeSelectorComponent,
    EventTeaserComponent,
    EventsContainerComponent,
    FactSliderComponent,
    FadeSliderComponent,
    FilterPipe,
    FilterTagsComponent,
    FlyoutItemComponent,
    FlyoutMenuComponent,
    FlyoutTeaserComponent,
    FlyoutTeaserTextComponent,
    FocusSliderComponent,
    FullsizeTeaserComponent,
    HeroTextComponent,
    HighlightTextPipe,
    HotspotTeaserComponent,
    HowItWorksComponent,
    HubspotFormComponent,
    ImageComponent,
    ImageSliderComponent,
    ImageTextSliderComponent,
    InsertionComponent,
    LargeHeadlineComponent,
    MainFilterComponent,
    MediaLibraryContainerComponent,
    MediaTeaserComponent,
    MenuItemComponent,
    MobileMenuComponent,
    ModeSwitchComponent,
    OptionDropdownComponent,
    OverlayComponent,
    OverlayContainerComponent,
    PageNotFoundComponent,
    PreloaderComponent,
    ProductCategoriesComponent,
    ProductComparisonComponent,
    ProductContainerComponent,
    ProductDetailDownloadComponent,
    ProductDetailInterfacesComponent,
    ProductDetailRelatedProductsComponent,
    ProductDetailSliderComponent,
    ProductDetailTabpanelComponent,
    ProductDetailTextBlockComponent,
    ProductDisplayComponent,
    ProductFamiliesComponent,
    ProductHighlightSliderCEComponent,
    ProductHighlightSliderCEComponent,
    ProductHighlightSliderComponent,
    ProductHighlightTeaserComponent,
    ProductHighlightsComponent,
    ProductOverviewComponent,
    ProductSearchComponent,
    ProductSliderComponent,
    ProductTeaserComponent,
    ProductTeaserUiComponent,
    PropertyFilterComponent,
    SearchResultsComponent,
    SearchTeaserComponent,
    SearchbarComponent,
    SimpleHeadlineComponent,
    SimpleTilesComponent,
    SliderComponent,
    StoryBlockComponent,
    StoryOverviewComponent,
    StoryRelatedComponent,
    StoryTeaserComponent,
    TabContainerComponent,
    TeaserBlocksComponent,
    TechnicalDataTableComponent,
    TextBlocksComponent,
    TruncateTextPipe,
    TutorialComponent,
    VideoOverlayComponent,
    WideImageComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SwiperModule,
    NgScrollbarModule,
    TranslateModule.forChild(),
    NgGudUiModule,
    FormsModule,
  ],
  exports: [
    AccordionContainerComponent,
    BranchBlockComponent,
    BranchOverviewComponent,
    BranchSliderComponent,
    BranchTabpanelComponent,
    BranchTeaserComponent,
    BreadcrumbComponent,
    CampusBlockComponent,
    CampusGlossaryComponent,
    CampusHighlightSliderComponent,
    CampusKnowHowComponent,
    CampusMediaComponent,
    CeHubspotFormComponent,
    CeDynamicsFormComponent,
    CommonModule,
    EasyModeDropdownComponent,
    EasyModeIndicatorComponent,
    EasyModeResultComponent,
    EasyModeResultComponent,
    EasyModeSelectorComponent,
    EventTeaserComponent,
    EventsContainerComponent,
    FadeSliderComponent,
    FlyoutMenuComponent,
    FocusSliderComponent,
    FullsizeTeaserComponent,
    HeroTextComponent,
    HowItWorksComponent,
    HubspotFormComponent,
    DynamicsFormComponent,
    ImageComponent,
    ImageSliderComponent,
    ImageTextSliderComponent,
    MediaLibraryContainerComponent,
    MediaTeaserComponent,
    MenuItemComponent,
    MobileMenuComponent,
    ModeSwitchComponent,
    NgGudUiModule,
    NgScrollbarModule,
    OptionDropdownComponent,
    DynamicScriptDirective,
    OverlayComponent,
    OverlayContainerComponent,
    PageNotFoundComponent,
    PreloaderComponent,
    ProductCategoriesComponent,
    ProductDetailDownloadComponent,
    ProductDetailSliderComponent,
    ProductDetailTabpanelComponent,
    ProductDetailTextBlockComponent,
    ProductDisplayComponent,
    ProductHighlightSliderCEComponent,
    ProductHighlightSliderComponent,
    ProductHighlightTeaserComponent,
    ProductHighlightsComponent,
    ProductOverviewComponent,
    ProductSearchComponent,
    ProductSliderComponent,
    ProductTeaserComponent,
    ProductTeaserUiComponent,
    RouterModule,
    SearchResultsComponent,
    SearchTeaserComponent,
    SearchbarComponent,
    SimpleHeadlineComponent,
    SimpleTilesComponent,
    SliderComponent,
    StoryBlockComponent,
    StoryOverviewComponent,
    StoryRelatedComponent,
    StoryTeaserComponent,
    SwiperModule,
    TabContainerComponent,
    TechnicalDataTableComponent,
    TranslateModule,
    TruncateTextPipe,
    VideoOverlayComponent,
    WideImageComponent,
    HighlightTextPipe,
    TutorialComponent,
  ],
})
export class SharedModule {}
