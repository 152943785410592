import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {Story} from '@rz-gud/models';
import {GDColors} from '@conception/ng-gud-ui';
import {ContentElementModel} from '@conception/ngx-pimcore-connect';
import {StoryRelatedModel} from './story-related.model';
import {StoryService} from '@rz-gud/services';
import {ApiConfig} from '@rz-gud/interfaces';
import {Store} from '@ngrx/store';
import {PageSelectors} from '@rz-gud/store';


@Component({
  selector: 'ce-story-detail',
  templateUrl: './story-related.component.html',
  styleUrls: ['./story-related.component.scss']
})
export class StoryRelatedComponent implements OnInit {

  @ContentElementModel(StoryRelatedModel) content: StoryRelatedModel;

  gdColors = GDColors;
  randomStory$: Observable<Story>;
  nextStory$: Observable<Story>;
  storyOverview$: Observable<string>;

  constructor(private readonly storyService: StoryService,
              private readonly store: Store) {
  }

  ngOnInit(): void {
    const config: ApiConfig = {
      folder: this.content.folder.path,
      sortBy: 'creation_date',
      sortDirection: 'DESC'
    };

    this.storyOverview$ = this.store.select(PageSelectors.storyOverview);
    this.randomStory$ = this.storyService.getRandomStory(config);
    this.nextStory$ = this.storyService.getNextStory(config);
  }
}
