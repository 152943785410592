import { ChangeDetectionStrategy, Component } from '@angular/core';
import { GDColors } from '@conception/ng-gud-ui';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageNotFoundComponent {
  gdColors = GDColors;
  exactSearch: boolean = false;
  disableInText: boolean = false;

  constructor() {}
}
