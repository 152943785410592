import {Deserializable, serialize, serializeArray} from '@conception/ts-class-deserializer';
import { PimAsset, PimLink } from '@conception/ngx-pimcore-connect';

export class SimpleTile extends Deserializable {
  @serialize(PimAsset) logo?: PimAsset;
  @serialize(PimLink) logoExternalLink?: PimLink;
  @serialize() logoText?: string;
  @serialize() logoPosition?: string;
  @serialize() headline: string;
  @serialize() content: string;
}

export class SimpleTilesModel extends Deserializable {
  @serializeArray() tiles: Array<SimpleTile>;
}

