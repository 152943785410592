import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { ProductFilterActions } from '../actions';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  PageSelectors,
  ProductFilterSelectors,
  SearchSelectors,
} from '../selectors';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from '@rz-gud/services';
import { MainFilterGroup, ProductFilterDefinition } from '@rz-gud/interfaces';
import { GtagService } from '@rz-gud/services/gtag.service';
import { of } from 'rxjs';
import { ApiService } from '@conception/ngx-pimcore-connect';

@Injectable()
export class ProductFilterEffects {
  search$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProductFilterActions.search),
        withLatestFrom(
          this.store.select(PageSelectors.productOverview),
          this.store.select(ProductFilterSelectors.searchTerm)
        ),
        tap(([action, link, term]) => {
          this.gtag.pushEvent('product_search', [{ term }]);
          this.store.dispatch(ProductFilterActions.clearFilterObject());
          this.router.navigate([link]);
        })
      ),
    { dispatch: false }
  );

  reloadProducts$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ProductFilterActions.clearFilter,
          ProductFilterActions.clearFilterObject,
          ProductFilterActions.setFilter,
          ProductFilterActions.setFilterObject,
          ProductFilterActions.addFilter,
          ProductFilterActions.removeFilter
        ),
        withLatestFrom(
          this.store.select(ProductFilterSelectors.filterObject),
          this.store.select(ProductFilterSelectors.searchTerm),
          this.store.select(SearchSelectors.exactSearch)
        ),
        tap(([action, filterObject, searchTerm, exactSearch]) =>
          this.productService.getProducts(filterObject, searchTerm, exactSearch)
        )
      ),
    { dispatch: false }
  );

  loadMore$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProductFilterActions.loadMore),
        withLatestFrom(
          this.store.select(ProductFilterSelectors.filterObject),
          this.store.select(ProductFilterSelectors.searchTerm),
          this.store.select(ProductFilterSelectors.products),
          this.store.select(SearchSelectors.exactSearch)
        ),
        tap(([action, filterObject, searchTerm, products, exactSearch]) => {
          const page = Math.floor(products.length / 50) + 1;
          this.productService.getProducts(
            filterObject,
            searchTerm,
            exactSearch,
            50,
            page
          );
        })
      ),
    { dispatch: false }
  );

  loadAll$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProductFilterActions.loadAll),
        withLatestFrom(
          this.store.select(ProductFilterSelectors.filterObject),
          this.store.select(ProductFilterSelectors.searchTerm),
          this.store.select(ProductFilterSelectors.totalCount),
          this.store.select(SearchSelectors.exactSearch)
        ),
        tap(([action, filterObject, searchTerm, count, exactSearch]) => {
          this.productService.getProducts(
            filterObject,
            searchTerm,
            exactSearch,
            count
          );
        })
      ),
    { dispatch: false }
  );

  reloadAll$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProductFilterActions.reloadAll),
        withLatestFrom(
          this.store.select(ProductFilterSelectors.filterObject),
          this.store.select(ProductFilterSelectors.searchTerm),
          this.store.select(SearchSelectors.exactSearch)
        ),
        tap(([action, filterObject, searchTerm, exactSearch]) =>
          this.productService.getProducts(filterObject, searchTerm, exactSearch)
        )
      ),
    { dispatch: false }
  );

  setupFilter$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProductFilterActions.setupFilter),
        tap((action) => {
          const main: ProductFilterDefinition = action.filterDefinitions.filter(
            (el) => el.isMain
          )[0];
          const presets: Array<ProductFilterDefinition> =
            action.filterDefinitions.filter((el) => !el.isMain && el.isPreset);
          const filter = action.filterDefinitions
            .filter((el) => el.isMain)[0]
            ?.filters.filter((el) => el.values.length && !el.hidden);
          const mainFilter = action.filterDefinitions.filter(
            (el) => el.filterGroup !== null && !el.isMain && !el.isPreset
          );
          const mainFilterGroup: Array<MainFilterGroup> = [];

          // eslint-disable-next-line @typescript-eslint/no-shadow
          mainFilter.forEach((filter) => {
            const groupIndex = mainFilterGroup.findIndex(
              (el) => el.group.id === filter.filterGroup.id
            );

            const translatedFilter: ProductFilterDefinition = {
              ...filter,
              title: this.translate.instant(filter.title),
            };

            if (groupIndex !== -1) {
              mainFilterGroup[groupIndex].entries.push(translatedFilter);
            } else {
              mainFilterGroup.push({
                group: translatedFilter.filterGroup,
                entries: [translatedFilter],
              });
            }
          });

          // eslint-disable-next-line @typescript-eslint/no-shadow
          const propertyFilter = filter.map((filter) => ({
            ...filter,
            values: filter.values.map((val) => ({
              key: val,
              label: this.translate.instant(val),
            })),
          }));

          const translatedPresets = presets.map((preset) => ({
            ...preset,
            title: this.translate.instant(preset.title),
            // eslint-disable-next-line @typescript-eslint/no-shadow
            filters: preset.filters.map((filter) => ({
              ...filter,
              values: filter.values.map((val) => ({
                key: val,
                label: this.translate.instant(val),
              })),
            })),
          }));

          this.store.dispatch(
            ProductFilterActions.setFilterDefinitions({
              filterDefinitions: action.filterDefinitions,
            })
          );
          this.store.dispatch(
            ProductFilterActions.setFilterId({
              filterId: main?.filterId.toString(),
            })
          );
          this.store.dispatch(
            ProductFilterActions.setPropertyFilter({ propertyFilter })
          );
          this.store.dispatch(
            ProductFilterActions.setMainFilter({ mainFilter: mainFilterGroup })
          );
          this.store.dispatch(
            ProductFilterActions.setPresets({ presets: translatedPresets })
          );
        })
      ),
    { dispatch: false }
  );

  useFilterDefinitionById$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProductFilterActions.useFilterDefinitionById),
        switchMap((action) =>
          this.store.select(
            ProductFilterSelectors.getFilterDefinition(action.id)
          )
        ),
        tap((def) => this.productService.setPreset(def))
      ),
    { dispatch: false }
  );

  getProductNames$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductFilterActions.getProductNames),
      switchMap((action) =>
        this.apiService$.getData([action.lang, 'productnames'].join('/')).pipe(
          map((productNames) =>
            ProductFilterActions.getProductNamesSuccess({ productNames })
          ),
          catchError((error) =>
            of(ProductFilterActions.getProductNamesError({ error }))
          )
        )
      )
    )
  );

  setAutofill$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ProductFilterActions.setSearchTerm,
          ProductFilterActions.clearSearchTerm
        ),
        withLatestFrom(
          this.store.select(ProductFilterSelectors.productNames),
          this.store.select(ProductFilterSelectors.searchTerm)
        ),
        tap(([action, productNames, searchTerm]) => {
          const autofillProductNames =
            searchTerm.length > 0
              ? this.sortInputFirst(searchTerm, productNames)
                  .filter((productName) =>
                    productName.toLowerCase().includes(searchTerm.toLowerCase())
                  )
                  .slice(0, 19)
              : [];

          this.store.dispatch(
            ProductFilterActions.setAutoFillProductNames({
              autofillProductNames: this.sortInputFirst(
                searchTerm,
                autofillProductNames
              ),
            })
          );
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly productService: ProductService,
    private readonly translate: TranslateService,
    private readonly store: Store,
    private readonly router: Router,
    private readonly gtag: GtagService,
    private readonly apiService$: ApiService
  ) {}

  private sortInputFirst(input, productNames): Array<string> {
    const sortedProductNames = [...productNames];
    sortedProductNames.sort(
      (a, b) =>
        +/^[0-9]/.test(a) - +/^[0-9]/.test(b) ||
        a.localeCompare(b, undefined, {
          numeric: true,
        })
    );
    const inputTrue = sortedProductNames.filter(
      (productName) =>
        productName.substring(0, input.length).toLowerCase() ===
        input.toLowerCase()
    );
    const inputFalse = sortedProductNames.filter(
      (productName) =>
        productName.substring(0, input.length).toLowerCase() !==
        input.toLowerCase()
    );

    return [...inputTrue, ...inputFalse];
  }
}
