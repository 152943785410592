import { Deserializable, serialize } from '@conception/ts-class-deserializer';
import { PimImageCollection, PimText } from '@conception/ngx-pimcore-connect';

export class ProductDetailSliderImagesModel extends Deserializable {
  @serialize(PimImageCollection) bildFrontansicht: PimImageCollection;
  @serialize() bildFrontAehnlich: boolean;
  @serialize(PimImageCollection) bildRueckansicht: PimImageCollection;
  @serialize() bildRueckAehnlich: boolean;
}

export class ProductDetailSliderModel extends Deserializable {
  @serialize() name: string;
  @serialize() highlightedProductName: string;
  @serialize() sku: string;
  @serialize() text: PimText | string;
  @serialize() id: number;
  @serialize() slugCategory: string;
  @serialize() category: string;
  @serialize() seoTitle: string;
  @serialize() seoDescription: string;
  @serialize() urlSlug: string;
  @serialize(ProductDetailSliderImagesModel) images: ProductDetailSliderImagesModel;

  getText(): string {
    return typeof this.text === 'string' ? this.text : this.text?.text;
  }
}
