import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { ContentElementModel, PageObjectService, MetaService } from '@conception/ngx-pimcore-connect';
import { MetaService as CanonicalService } from '@rz-gud/services/meta.service';
import { ProductDetailSliderModel } from './product-detail-slider.model';
import { Store } from '@ngrx/store';

import {
  BookmarkActions,
  BookmarkSelectors,
  ComparisonActions,
  ComparisonSelectors,
  DynamicsSelectors,
  LayoutActions,
  PageActions,
  PageSelectors,
} from '@rz-gud/store';
import { Observable, Subscription } from 'rxjs';
import { ButtonSizes, GDColors } from '@conception/ng-gud-ui';
import { slideInOutBottom } from '@rz-gud/animations';
import { take, tap } from 'rxjs/operators';
import { DynamicsService, HelperService, HubspotService } from '@rz-gud/services';
import { TranslateService } from '@ngx-translate/core';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '@rz-gud/environment';
import { ProductDetailInterfacesModel } from '@rz-gud/content-elements/product-detail-interfaces/product-detail-interfaces.model';

@Component({
  selector: 'ce-product-detail-slider',
  templateUrl: './product-detail-slider.component.html',
  styleUrls: ['./product-detail-slider.component.scss'],
  animations: [slideInOutBottom],
})
export class ProductDetailSliderComponent implements OnInit, OnDestroy {
  @ContentElementModel(ProductDetailSliderModel)
  content: ProductDetailSliderModel;

  title: string = '';
  maxSlides: number = 2;
  currentSlide: number = 1;
  showOverlay: boolean = false;
  productDetailDownloadsIdHash: string | null = null;
  productDetailInterfacesIdHash: string | null = null;
  activeImage: string = null;
  buttonSizes = ButtonSizes;
  amIBookmarked$: Observable<boolean>;
  amIOnComparisonList$: Observable<boolean>;
  comparisonListFilled$: Observable<boolean>;
  productInquiryFormHostName$: Observable<string>;
  productInquiryFormWebsiteId$: Observable<string>;
  productInquiryFormBlockId$: Observable<string>;
  detailLink$: Observable<string>;
  isTouch: boolean = false;
  baseUrl =
    window.location.protocol +
    '//' +
    window.location.hostname +
    (window.location.port ? `:${window.location.port}` : '');

  env = environment;

  readonly gdColor = GDColors;
  private readonly sub: Subscription;
  private readonly isBrowser: boolean = false;

  constructor(
    private readonly pageObjectService: PageObjectService,
    private readonly hubspotService: HubspotService,
    private readonly dynamicsService: DynamicsService,
    private readonly translate: TranslateService,
    private readonly helper: HelperService,
    private readonly store: Store,
    private readonly canonicalService: CanonicalService,
    private readonly metaService: MetaService,
    @Inject(PLATFORM_ID) platformId: Record<string, unknown>
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.sub = this.pageObjectService.currentPageObject.subscribe((pageObject) => {
      if (pageObject) {
        this.productDetailDownloadsIdHash =
          pageObject.content_elements.find((element) => element?.type === 'product-detail-downloads')?.id_hash || null;

        this.productDetailInterfacesIdHash =
          pageObject.content_elements.find(
            (element) =>
              element?.content &&
              element?.type === 'product-detail-interface-tab-element' &&
              new ProductDetailInterfacesModel().deserialize(element?.content)?.showElement()
          )?.id_hash || null;
      }
    });

    if (this.isBrowser) {
      this.isTouch = matchMedia('(hover: none), (pointer: coarse)').matches;
    }
  }

  ngOnInit(): void {
    this.productInquiryFormHostName$ = this.store.select(DynamicsSelectors.productInquiryFormHostName);
    this.productInquiryFormWebsiteId$ = this.store.select(DynamicsSelectors.productInquiryFormWebsiteId);
    this.productInquiryFormBlockId$ = this.store.select(DynamicsSelectors.productInquiryFormBlockId);

    this.amIBookmarked$ = this.store.select(BookmarkSelectors.amIBookmarked(this.content.id));
    this.amIOnComparisonList$ = this.store.select(ComparisonSelectors.amIOnComparisonList(this.content.id));
    this.comparisonListFilled$ = this.store.select(ComparisonSelectors.filled);
    this.detailLink$ = this.store.select(PageSelectors.productDetail);
    this.title = this.createTitle();

    this.setMetadata();
    this.setBreadcrumb();

    this.detailLink$.subscribe((detailLink) => {
      this.canonicalService.updateCanonicalUrl(
        `${this.baseUrl}${detailLink}/${this.content.slugCategory}/${this.content.urlSlug}`
      );
    });
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
    this.store.dispatch(LayoutActions.setFixedState({ fixed: false }));
  }

  setMetadata(): void {
    const maxDescriptionLength = 170;
    let croppedDescription =
      this.content.seoDescription ??
      `${this.content.category} | ${this.content.name} | ${this.content.sku} | ${this.content.text}`;

    if (croppedDescription.length > maxDescriptionLength) {
      const lastSpaceIndex = croppedDescription.lastIndexOf(' ', maxDescriptionLength);
      if (lastSpaceIndex !== -1) {
        croppedDescription = `${croppedDescription.slice(0, lastSpaceIndex)}…`;
      } else {
        croppedDescription = `${croppedDescription.slice(0, maxDescriptionLength - 1)}…`;
      }
    }

    this.metaService.setDescription(croppedDescription);

    if (this.content.seoTitle) {
      this.metaService.setTitle(this.content.seoTitle);
    }
  }

  createTitle(): string {
    return this.content.name?.replace(
      this.content.highlightedProductName,
      '<span class="color--maya">' + this.content.highlightedProductName + '</span>'
    );
  }

  setCurrentSlide(event): void {
    this.currentSlide = +event.realIndex + 1;
  }

  historyBack(): void {
    history.back();
  }

  scrollTo(targetIdHash: string): void {
    this.scrollToOffset(document.getElementById(targetIdHash).offsetTop);
  }

  scrollToOffset(offset: number): void {
    window.scrollTo({
      top: offset,
      behavior: 'smooth',
    });
  }

  putToComparisonList(): void {
    this.store.dispatch(
      ComparisonActions.addToList({
        id: this.content.id,
        title: this.content.name,
      })
    );
    this.store.dispatch(ComparisonActions.showOverlay({ showOverlay: true }));
  }

  removeFromComparisonList(): void {
    this.store.dispatch(ComparisonActions.removeFromList({ id: this.content.id }));
  }

  bookmark(): void {
    this.store.dispatch(BookmarkActions.toggleProduct({ id: this.content.id }));
  }

  request(): void {
    this.productInquiryFormWebsiteId$.pipe(take(1)).subscribe((websiteId) => {
      this.productInquiryFormHostName$.pipe(take(1)).subscribe((hostName) => {
        this.productInquiryFormBlockId$.pipe(take(1)).subscribe((blockId) => {
          if (websiteId && hostName && blockId) {
            this.dynamicsInquiry([{ name: this.content.name, sku: this.content.sku }]);
          } else {
            this.hubspotService.inquiry(this.content.name, this.content.sku);
          }
        });
      });
    });
  }

  dynamicsInquiry(products: Array<{ name: string; sku: string }>): void {
    this.dynamicsService.inquiry(products);
  }

  toggleOverlay(target: string): void {
    this.showOverlay = !this.showOverlay;
    this.activeImage = target;

    this.store.dispatch(LayoutActions.setFixedState({ fixed: this.showOverlay }));
    if (this.showOverlay) {
      this.scrollToOffset(0);
    }
  }

  setBreadcrumb(): void {
    this.store
      .select(PageSelectors.productOverview)
      .pipe(
        take(1),
        tap((result) => {
          setTimeout(
            () =>
              this.store.dispatch(
                PageActions.setBreadcrumb({
                  breadcrumb: [
                    { url: '/', title: '' },
                    { url: result, title: this.translate.instant('products') },
                    { url: '/', title: this.helper.stripTags(this.title) },
                  ],
                })
              ),
            200
          );
        })
      )
      .subscribe();
  }
}
