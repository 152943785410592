import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {scaleInCenter} from '@rz-gud/animations';
import {GDColors} from '@conception/ng-gud-ui';
import {Store} from '@ngrx/store';
import {LayoutActions} from '@rz-gud/store';

@Component({
  selector: 'overlay-container',
  templateUrl: './overlay-container.component.html',
  styleUrls: ['./overlay-container.component.scss'],
  animations: [scaleInCenter]
})
export class OverlayContainerComponent implements OnInit, OnDestroy {
  @Output() closed: EventEmitter<boolean> = new EventEmitter<boolean>();
  readonly gdColors = GDColors;

  constructor(private readonly store: Store) {
  }

  ngOnInit(): void {
    this.store.dispatch(LayoutActions.setFixed());
  }

  ngOnDestroy() {
    this.store.dispatch(LayoutActions.setUnfixed());
  }
}
