import {Component, OnDestroy, OnInit} from '@angular/core';
import {ContentElementModel} from '@conception/ngx-pimcore-connect';
import {SliderModel} from './slider.model';
import {Store} from '@ngrx/store';
import {LayoutActions} from '@rz-gud/store';

@Component({
  selector: 'ce-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit, OnDestroy {

  @ContentElementModel(SliderModel) content: SliderModel;

  constructor(private readonly store: Store) {
  }

  ngOnInit(): void {
    this.store.dispatch(LayoutActions.setBreadcrumbColor({breadcrumbColor: 'white'}));
  }

  ngOnDestroy(): void {
    this.store.dispatch(LayoutActions.setBreadcrumbColor({breadcrumbColor: 'dark'}));
  }

}
