import {Component, Input} from '@angular/core';
import {TechnicalDataTable} from '@rz-gud/models';

@Component({
  selector: 'technical-data-table',
  templateUrl: './technical-data-table.component.html',
  styleUrls: ['./technical-data-table.component.scss']
})
export class TechnicalDataTableComponent {
  @Input() data: TechnicalDataTable;
}
