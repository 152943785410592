import {Component, HostBinding, Input} from '@angular/core';
import {ContentElementModel} from '@conception/ngx-pimcore-connect';
import {ProductDisplayModel} from './product-display.model';
import {slideInOutBottom, slideInOutLeft} from '@rz-gud/animations';
import {ButtonSizes, GDColors} from '@conception/ng-gud-ui';

@Component({
  selector: 'ce-product-display',
  templateUrl: './product-display.component.html',
  styleUrls: ['./product-display.component.scss'],
  animations: [slideInOutLeft, slideInOutBottom]
})
export class ProductDisplayComponent {

  @ContentElementModel(ProductDisplayModel) content: ProductDisplayModel;

  /**
   * Additional external classes based on context
   */
  @Input() class: string = '';

  overlay: boolean = false;
  readonly buttonSizes = ButtonSizes;
  readonly gdColors = GDColors;

  @HostBinding('class') get hostClasses(): string {
    return [
      this.class,
      this.content.theme,
      this.content.reverse ? 'reverse' : ''
    ].join(' ');
  }
}
