import {Component, Input} from '@angular/core';
import {Store} from '@ngrx/store';
import {VideoPopupActions} from '@rz-gud/store';
import {PimVideo} from '@conception/ngx-pimcore-connect';

@Component({
  selector: 'video-overlay',
  templateUrl: './video-overlay.component.html',
  styleUrls: ['./video-overlay.component.scss']
})
export class VideoOverlayComponent {
  @Input() video: PimVideo;

  constructor(private readonly store: Store) {
  }

  close(): void {
    this.store.dispatch(VideoPopupActions.clearVideoPopup());
  }

}
