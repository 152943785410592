import {createAction, props} from '@ngrx/store';
import {LanguageNavigationItem} from '@rz-gud/interfaces';

export const set = createAction(
  '[Language Navigation] Set',
  props<{ items: Array<LanguageNavigationItem> }>()
);

export const setCurrent = createAction(
  '[Language Navigation] Set current',
  props<{ current: LanguageNavigationItem }>()
);
