import {Deserializable, serialize, serializeArray} from '@conception/ts-class-deserializer';
import {PimAsset, PimLink} from '@conception/ngx-pimcore-connect';

export class FocusSlide extends Deserializable {
  @serialize() headline: string;
  @serialize() copy: string;
  @serialize(PimLink) link: PimLink;
  @serialize(PimAsset) image: PimAsset;
}

export class FocusSliderModel extends Deserializable {
  @serialize() headline: string;
  @serialize() subline: string;
  @serializeArray(FocusSlide) slides: Array<FocusSlide>;
}

