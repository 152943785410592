import {Deserializable, serialize} from '@conception/ts-class-deserializer';
import {PimAsset, PimLink} from '@conception/ngx-pimcore-connect';

export class FadeSlide extends Deserializable {
  @serialize() headline?: string;
  @serialize() subline?: string;
  @serialize() icon?: string;
  @serialize(PimLink) link?: PimLink;
  @serialize(PimAsset) image: PimAsset;
}
