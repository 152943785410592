import {Component} from '@angular/core';
import {Observable} from 'rxjs';
import {ProductService} from '@rz-gud/services';
import {ProductFilter, SimpleFilter} from '@rz-gud/interfaces';
import {ProductFilterActions, ProductFilterSelectors,} from '@rz-gud/store';
import {Store} from '@ngrx/store';

@Component({
  selector: 'product-overview-property-filter',
  templateUrl: './property-filter.component.html',
  styleUrls: ['./property-filter.component.scss'],
})
export class PropertyFilterComponent {
  showAllFilters: boolean = false;
  readonly setFilters$: Observable<Map<string, Array<SimpleFilter>>> = this.store.select(ProductFilterSelectors.setFiltersAsMap);
  readonly propertyFilter$: Observable<Array<ProductFilter>> = this.store.select(ProductFilterSelectors.propertyFilter);

  constructor(private readonly productService: ProductService,
              private readonly store: Store) {
  }

  changeFilter(category: string, object: Array<any>): void {
    this.store.dispatch(ProductFilterActions.setFilter({category, object}));
  }
}
