import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ProductCategoriesModel} from './product-categories.model';
import {GDColors} from '@conception/ng-gud-ui';
import {ContentElementModel} from '@conception/ngx-pimcore-connect';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {PageSelectors} from '@rz-gud/store';

@Component({
  selector: 'ce-product-categories',
  templateUrl: './product-categories.component.html',
  styleUrls: ['./product-categories.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductCategoriesComponent {

  @Input() @ContentElementModel(ProductCategoriesModel) content: ProductCategoriesModel;

  readonly productOverviewLink$: Observable<string> = this.store.select(PageSelectors.productOverview);
  readonly gdColors = GDColors;

  constructor(private readonly store: Store) {
  }
}
