import { Injectable } from '@angular/core';
import { DynamicsActions, HubspotActions } from '@rz-gud/store';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class DynamicsService {
  constructor(private readonly store: Store) {}

  inquiry(products: Array<{ name: string; sku: string }>): void {
    this.setProducts(products, 'produkte');
    this.store.dispatch(DynamicsActions.openProductInquiryForm());
  }

  productQuestion(products: Array<{ name: string; sku: string }>): void {
    this.setProducts(products, 'produkte');
    this.store.dispatch(DynamicsActions.openProductQuestionForm());
  }

  setProducts(
    products: Array<{ name: string; sku: string }>,
    label: string
  ): void {
    this.store.dispatch(
      DynamicsActions.setProducts({
        products: [
          {
            label,
            value: products
              .map((product) => product.name.trim() + ' (' + product.sku + ')')
              .join(', '),
          },
        ],
      })
    );
  }
}
