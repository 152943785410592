import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {ContentElementModel} from '@conception/ngx-pimcore-connect';
import {CampusEventDetailHeaderModel} from './campus-event-detail-header.model';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {PageSelectors} from '@rz-gud/store';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '@rz-gud/environment';


@Component({
  selector: 'ce-campus-event-detail-header',
  templateUrl: './campus-event-detail-header.component.html',
  styleUrls: ['./campus-event-detail-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CampusEventDetailHeaderComponent implements OnInit {
  @ContentElementModel(CampusEventDetailHeaderModel) content: CampusEventDetailHeaderModel;
  eventsOverview$: Observable<string> = this.store.select(PageSelectors.eventOverview);
  icsDownload: string = '';

  constructor(private readonly store: Store,
              private readonly translate: TranslateService) {
  }

  ngOnInit() {
    this.icsDownload = this.buildIcsPath();
  }

  private buildIcsPath(): string {
    return [
      environment.apiUrl,
      this.translate.currentLang,
      '/',
      environment.endpoints.eventICS,
      this.content.id,
      '/ics'
    ].join('');
  }
}
