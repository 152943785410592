<div class="auto-suggest__inner">
  <ng-container *ngIf="productAutofill; else suggestions">
    <ng-container *ngIf="autofill$ | async as autofill">
      <ng-container *ngIf="autofill?.length > 0">
        <div class="auto-suggest auto-suggest__products" *ngIf="autofill?.length === 1
        ? autofill[0].toLowerCase() !== searchTerm.toLowerCase()
        : true">
          <ul #suggestionList>
            <ng-container *ngFor="let product of autofill; let last = last; let i = index">
              <li class="auto-suggest__content"
                  [ngClass]="{'auto-suggest__focus': focus === i + 1}"
                  [innerHTML]="(product | highlightText: searchTerm)"
                  (click)="setSearchTerm(product)"></li>
            </ng-container>
          </ul>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-template #suggestions>
    <ng-container *ngIf="suggestions$ | async as suggestions">
      <div class="auto-suggest" *ngIf="suggestions?.length > 0">
        <ul>
          <li *ngFor="let hit of suggestions">
            <ng-container *ngIf="hit?.urlSlug">
              {{hit?.headline()}}
            </ng-container>
            <ng-container *ngIf="hit?.type && hit?.type === 'PageHit'">
              {{hit?.title}}
            </ng-container>
            <ng-container *ngIf="hit?.type && hit?.type === 'ObjectHit'">
              {{hit?.title}}
            </ng-container>
          </li>
        </ul>
      </div>
    </ng-container>
  </ng-template>
</div>
