<ng-container *ngIf="headermode$ | async as headermode">
  <a
    [routerLink]="
      (pageMode$ | async) === 'easy'
        ? (startLink$ | async)
        : (productEntryLink$ | async)
    "
    class="logo-container"
    title="Start"
    (mouseenter)="clearFlyout()"
  >
    <gd-logo
      [border]="true"
      [borderColor]="headermode === 'easy' ? 'maya' : 'azure'"
      class="logo"
    ></gd-logo>
  </a>

  <nav #nav>
    <div class="main" *ngIf="mainNav$ | async as mainNav">
      <ng-container *ngFor="let nav of mainNav?.pages; let i = index">
        <ng-container
          *ngIf="
            nav?.linkBrick?.brick_type === 'navigationLinkInternal' ||
              nav?.linkBrick?.brick_type === 'NavigationLinkInternal';
            else external
          "
        >
          <a
            *ngIf="nav?.linkBrick?.pageLink?.pageLink?.url"
            class="menu__links"
            [routerLink]="nav?.linkBrick?.pageLink?.pageLink?.url"
          >
            <menu-item
              [color]="headermode === 'easy' ? 'silver-light' : 'azure'"
              [data]="nav"
              (mouseenter)="setFlyout(i)"
            ></menu-item>
          </a>
        </ng-container>
        <ng-template #external>
          <a
            *ngIf="nav?.linkBrick?.externalUrl"
            class="menu__links"
            [href]="nav?.linkBrick?.externalUrl"
            [target]="nav?.linkBrick?.target?.value"
          >
            <menu-item
              [color]="headermode === 'easy' ? 'silver-light' : 'azure'"
              [data]="nav"
              (mouseenter)="setFlyout(i)"
            ></menu-item>
          </a>
        </ng-template>
      </ng-container>
    </div>

    <ng-container *ngIf="moreNav$ | async as moreNav">
      <gd-more-button
        [onDark]="headermode === 'easy'"
        [label]="moreNav.name"
        (click)="setFlyoutMore()"
        (mouseenter)="clearFlyout()"
      ></gd-more-button>
    </ng-container>

    <div class="icons" (mouseenter)="clearFlyout()">
      <gd-icon-button (click)="openSearch()">
        <gd-icon
          icon="search"
          [color]="headermode === 'easy' ? 'silver-light' : 'azure'"
        ></gd-icon>
      </gd-icon-button>
      <!-- TODO: Login -->
      <gd-icon-button *ngIf="false">
        <gd-icon
          icon="user"
          [color]="headermode === 'easy' ? 'silver-light' : 'azure'"
        ></gd-icon>
      </gd-icon-button>
      <a *ngIf="env.features.comparisonList"
         (click)="compare()"
         [title]="'header.comparison.icon.title' | translate"
      >
        <gd-notification-bubble
          *ngIf="(comparisonList$ | async).length > 0"
          @notificationBubble
          [content]="(comparisonList$ | async).length"
          [color]="'yellow'"
        ></gd-notification-bubble>
        <gd-icon-button *ngIf="env.features.comparisonList">
          <gd-icon class="comparison"
                   icon="comparison"
                   [title]="'header.comparison.icon.title' | translate"
                   [color]="headermode === 'easy' ? 'silver-light' : 'azure'"
          ></gd-icon>
        </gd-icon-button>
      </a>
      <a [routerLink]="bookmarksLink$ | async"
         [title]="'header.watchlist.icon.title' | translate"
      >
        <gd-notification-bubble
          *ngIf="(bookmarkCount$ | async) > 0"
          @notificationBubble
          [content]="bookmarkCount$ | async"
          [color]="'yellow'"
        ></gd-notification-bubble>
        <gd-icon-button>
          <gd-icon
            icon="bookmark"
            [filled]="(bookmarkCount$ | async) > 0"
            [color]="headermode === 'easy' ? 'silver-light' : 'azure'"
          ></gd-icon>
        </gd-icon-button>
      </a>
    </div>

    <mode-switch (mouseenter)="clearFlyout()"></mode-switch>

    <div class="language-toggle" (click)="showLanguageSwitch()">
      <gd-icon-button class="globe" (mouseenter)="clearFlyout()">
        <gd-icon
          icon="globe"
          [color]="headermode === 'easy' ? 'silver-light' : 'azure'"
        ></gd-icon>
      </gd-icon-button>
    </div>

    <ng-container *ngIf="currentLanguage$ | async as currentLanguage">
      <tutorial [isLanguage]="true" [link]="{url: '/en-us/start', label: 'tutorial.language.url', color: 'azure-filled'}"
                *ngIf="(showTutorial$ | async) && browserLang === 'en-US' && currentLanguage.lang !== 'en_US'" (closed)="dismissLanguage()"></tutorial>
    </ng-container>

    <div class="toggle-menu__container">
      <div class="toggle-menu__divider"></div>
      <div
        class="toggle-menu"
        [ngClass]="headermode === 'easy' ? 'white' : 'azure'"
        [class.active]="showMobileMenu"
        (click)="showMobileMenu = !showMobileMenu"
      >
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </nav>

  <mobile-menu
    *ngIf="showMobileMenu && (mobileNav$ | async)?.pages as pages"
    [data]="pages"
    [color]="headermode === 'easy' ? 'azure' : 'silver-light'"
    @slideInOutLeft
  ></mobile-menu>

  <breadcrumb
    [lineColor]="
      (alternativeBreadcrumbLine$ | async)
        ? 'dark'
        : (breadcrumbVisible$ | async) && flyout === ''
        ? 'silver-light'
        : 'silver-light-transparent'
    "
    [showBreadcrumb]="flyout === ''"
    [showBreadcrumbMobile]="!showMobileMenu"
  ></breadcrumb>

  <div
    class="hover-indicator"
    [style.left]="indicatorPosition + 'px'"
    [style.width]="indicatorWidth + 'px'"
    [class.active]="flyout !== ''"
  ></div>

  <div class="expert-backdrop__container">
    <div
      class="expert-backdrop"
      [class.expert-backdrop--active]="headermode === 'expert'"
    ></div>
  </div>
  <flyout-menu
    *ngIf="flyout !== ''"
    @slideInOutTop
    [data]="
      flyout !== 'more'
        ? (mainNav$ | async).pages[this.flyout]
        : (moreNav$ | async).pages[0]
    "
    [color]="headermode === 'easy' ? 'azure' : 'silver-light'"
  ></flyout-menu>
</ng-container>
