import {createAction, props} from '@ngrx/store';
import {EasyModeSelectorAnswerModel} from '@rz-gud/content-elements/easy-mode-selector/easy-mode-selector.model';

export const setAnswer = createAction(
  '[Easy Mode] Set Answer',
  props<{ answer: EasyModeSelectorAnswerModel }>()
);

export const clearAnswer = createAction(
  '[Easy Mode] Clear Answer',
  props<{ index: number }>()
);

export const clear = createAction(
  '[Easy Mode] Clear'
);

export const nextStage = createAction(
  '[Easy Mode] Next Stage'
);

export const previousStage = createAction(
  '[Easy Mode] Previous Stage'
);

export const setStage = createAction(
  '[Easy Mode] Set Stage',
  props<{ stage: number }>()
);
