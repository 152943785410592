import {createAction, props} from '@ngrx/store';
import {Menu, NavigationItem} from '@rz-gud/interfaces';

export const setEntry = createAction(
  '[Navigation] Set entry',
  props<{ entry: Array<Menu> }>()
);

export const setItem = createAction(
  '[Navigation] Set navigation item',
  props<{ key: string; item: NavigationItem }>()
);
