import {Component} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import { PageSelectors, SearchActions, SearchSelectors } from '@rz-gud/store';
import {ContentElementModel} from '@conception/ngx-pimcore-connect';
import {ObjectHit, PageHit, Product} from '@rz-gud/models';
import {SearchResultsModel} from './search-results.model';
import {searchConfig} from '@rz-gud/config';
import { GtagService } from '@rz-gud/services/gtag.service';

@Component({
  selector: 'ce-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent {

  @ContentElementModel(SearchResultsModel) content: SearchResultsModel;
  readonly cmsPerPage: number = searchConfig.cmsResults;
  readonly productsPerPage: number = searchConfig.productResults;
  readonly searchTab$: Observable<'cms' | 'products'> = this.store.select(SearchSelectors.activeTab);
  readonly cmsResults$: Observable<Array<PageHit | ObjectHit>> = this.store.select(SearchSelectors.cmsResults);
  readonly cmsTotal$: Observable<number> = this.store.select(SearchSelectors.cmsTotal);
  readonly productResults$: Observable<Array<Product>> = this.store.select(SearchSelectors.productResults);
  readonly productTotal$: Observable<number> = this.store.select(SearchSelectors.productTotal);
  readonly popularSearches$: Observable<Array<string>> = this.store.select(PageSelectors.popularSearches);
  readonly productOverviewLink$: Observable<string> = this.store.select(PageSelectors.productOverview);

  exactSearch: boolean = false;
  disableInText: boolean = false;

  constructor(private readonly store: Store,
              private readonly gtag: GtagService) {
  }

  loadMoreCms(): void {
    this.store.dispatch(SearchActions.triggerAppendCmsResults());
  }

  loadAllCms(): void {
    this.store.dispatch(SearchActions.triggerLoadAllCmsResults());
  }

  loadMoreProducts(): void {
    this.store.dispatch(SearchActions.triggerAppendProductResults());
  }

  loadAllProducts(): void {
    this.store.dispatch(SearchActions.triggerLoadAllProductResults());
  }

  setTab(tab: 'cms' | 'products'): void {
    this.store.dispatch(SearchActions.setTab({tab}));
  }

  trackById(index: number, el: Product): number {
    return el.id;
  }

  search(term: string): void {
    this.store.dispatch(SearchActions.setTerm({term}));
    this.store.dispatch(SearchActions.search());
    this.gtag.pushEvent('search_via_popular', [{term}]);
  }

}
