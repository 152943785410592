import {Deserializable, serialize} from '@conception/ts-class-deserializer';
import {PimAsset, PimLink} from '@conception/ngx-pimcore-connect';

export class ProductDisplayModel extends Deserializable {
  @serialize() headline: string;
  @serialize() subline: string;
  @serialize() theme: 'gray' | 'white';
  @serialize() reverse: boolean;
  @serialize() text: string;
  @serialize(PimAsset) image: PimAsset;
  @serialize(PimLink) link: PimLink;
  @serialize(PimAsset) overlay: PimAsset;
}


