import {createReducer, on} from '@ngrx/store';
import {LayoutActions} from '../actions';

export enum PageMode {
  EASY = 'easy',
  EXPERT = 'expert',
  CUSTOMER = 'customer'
}


export interface LayoutState {
  mode: PageMode;
  pageModeTutorial: boolean;
  languageTutorial: boolean;
  fixed: boolean;
  contactbarLock: boolean;
  languageSwitchOverlay: boolean;
  alternativeBreadcrumbLine: boolean;
  breadcrumbColor: 'dark' | 'white';
}

export const initialState: LayoutState = {
  mode: PageMode.EASY,
  pageModeTutorial: false,
  languageTutorial: true,
  fixed: false,
  contactbarLock: false,
  languageSwitchOverlay: false,
  alternativeBreadcrumbLine: false,
  breadcrumbColor: 'dark'
};

export const layoutReducer = createReducer(
  initialState,
  on(LayoutActions.setPageMode, (state, {mode}) => ({...state, mode})),
  on(LayoutActions.openPageModeTutorial, (state) => ({...state, pageModeTutorial: true})),
  on(LayoutActions.dismissPageModeTutorial, (state) => ({...state, pageModeTutorial: false})),
  on(LayoutActions.openLanguageTutorial, (state) => ({...state, languageTutorial: true})),
  on(LayoutActions.dismissLanguageTutorial, (state) => ({...state, languageTutorial: false})),
  on(LayoutActions.setFixed, (state) => ({...state, fixed: true})),
  on(LayoutActions.setUnfixed, (state) => ({...state, fixed: false})),
  on(LayoutActions.setFixedState, (state, {fixed}) => ({...state, fixed})),
  on(LayoutActions.toggleFixed, (state) => ({...state, fixed: !state.fixed})),
  on(LayoutActions.lockContactBar, (state) => ({...state, contactbarLock: true})),
  on(LayoutActions.unlockContactBar, (state) => ({...state, contactbarLock: false})),
  on(LayoutActions.showLanguageSwitchOverlay, (state) => ({...state, languageSwitchOverlay: true})),
  on(LayoutActions.hideLanguageSwitchOverlay, (state) => ({...state, languageSwitchOverlay: false})),
  on(LayoutActions.activateAlternativeBreadcrumb, (state) => ({...state, alternativeBreadcrumbLine: true})),
  on(LayoutActions.deactivateAlternativeBreadcrumb, (state) => ({...state, alternativeBreadcrumbLine: false})),
  on(LayoutActions.setBreadcrumbColor, (state, {breadcrumbColor}) => ({...state, breadcrumbColor}))
);
