import {animate, style, transition, trigger} from '@angular/animations';

export const slideInOutLeft = trigger('slideInOutLeft', [
  transition(':enter', [
    style({transform: 'translateX(-100%)'}),
    animate('200ms ease-in', style({transform: 'translateX(0%)'}))
  ]),
  transition(':leave', [
    animate('200ms ease-in', style({transform: 'translateX(-100%)'}))
  ])
]);

export const slideInOutBottom = trigger('slideInOutBottom', [
  transition(':enter', [
    style({transform: 'translateY(100%)'}),
    animate('200ms ease-in', style({transform: 'translateY(0%)'}))
  ]),
  transition(':leave', [
    animate('200ms ease-in', style({transform: 'translateY(100%)'}))
  ])
]);

export const slideInOutTop = trigger('slideInOutTop', [
  transition(':enter', [
    style({transform: 'translateY(-10%)', opacity: 0}),
    animate('200ms ease-in', style({transform: 'translateY(0%)', opacity: 1}))
  ]),
  transition(':leave', [
    animate('200ms ease-in', style({transform: 'translateY(-10%)', opacity: 0}))
  ])
]);

export const slideHorizontal = trigger('slideHorizontal', [
  transition(':enter', [
    style({transform: 'translateX(100vw)'}),
    animate('500ms 400ms ease-out', style({transform: 'translateY(0)'}))
  ]),
  transition(':leave', [
    animate('500ms 200ms ease-out', style({transform: 'translateX(-100vw)'}))
  ])
]);
