import { Inject, Injectable, RendererFactory2, ViewEncapsulation } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  constructor(private readonly rendererFactory: RendererFactory2, @Inject(DOCUMENT) private document) {}

  /**
   * @param tag
   */
  addTag(tag: MetaLinkDefinition) {
    try {
      const renderer = this.rendererFactory.createRenderer(this.document, {
        id: '-1',
        encapsulation: ViewEncapsulation.None,
        styles: [],
        data: {},
      });

      const link = renderer.createElement('link');
      const head = this.document.head;

      if (head === null) {
        throw new Error('<head> not found within DOCUMENT.');
      }
      Object.keys(tag).forEach((prop: string) => renderer.setAttribute(link, prop, tag[prop]));
      renderer.appendChild(head, link);
    } catch (e) {}
  }

  /**
   * @param tag
   */
  removeTag(tag?: MetaLinkDefinition) {
    if (tag) {
      const selector = 'rel=' + tag.rel;
      const lang = 'hreflang=' + tag.hreflang;
      try {
        const renderer = this.rendererFactory.createRenderer(this.document, {
          id: '-1',
          encapsulation: ViewEncapsulation.None,
          styles: [],
          data: {},
        });
        const head = this.document.head;
        if (head === null) {
          throw new Error('<head> not found within DOCUMENT.');
        }
        const linkTags = this.document.querySelectorAll('link[' + selector + '][' + lang + ']');
        for (const link of linkTags) {
          renderer.removeChild(head, link);
        }
      } catch (e) {}
    }
  }

  /**
   * @param tag
   */
  updateTag(tag: MetaLinkDefinition) {
    this.removeTag(tag);
    this.addTag(tag);
  }

  /**
   * Updates current canonical URL
   *
   * @param url
   */
  public updateCanonicalUrl(url: string) {
    const head = this.document.getElementsByTagName('head')[0];

    const canonicalTag =
      this.document.querySelector(`link[rel='canonical']`) || (this.document.createElement('link') as HTMLLinkElement);

    canonicalTag.setAttribute('rel', 'canonical');
    canonicalTag.setAttribute('href', url);

    head.appendChild(canonicalTag);
  }
}

export declare type MetaLinkDefinition = {
  charset?: string;
  crossorigin?: string;
  href?: string;
  hreflang?: string;
  media?: string;
  rel?: string;
  rev?: string;
  sizes?: string;
  target?: string;
  type?: string;
} & {
  [prop: string]: string;
};
