import {Deserializable, serialize} from '@conception/ts-class-deserializer';
import {PimImageCollection, PimLink} from '@conception/ngx-pimcore-connect';
import {SimpleType} from '@rz-gud/interfaces';

export class Branch extends Deserializable {
  @serialize() id: string;
  @serialize() title: string;
  @serialize(PimImageCollection) teaserImage: PimImageCollection;
  @serialize() detailPage: PimLink;
  @serialize() icon: SimpleType;

  public getTeaserImage(layout: string): string {
    if (this.teaserImage && this.teaserImage.urls) {
      switch (layout) {
        case 'portrait':
          return this.teaserImage.getImage('BranchTeaserPortrait');
        case 'landscape':
          return this.teaserImage.getImage('BranchTeaserLandscape');
        default:
          return this.teaserImage.getImage('BranchTeaserLandscape');
      }
    } else {
      return '';
    }
  }
}
