import {createAction, props} from '@ngrx/store';

export const bookmarkProduct = createAction(
  '[Bookmark] Bookmark Product',
  props<{ id: number }>()
);

export const bookmarkProducts = createAction(
  '[Bookmark] Bookmark Products',
  props<{ ids: Array<number> }>()
);

export const removeProduct = createAction(
  '[Bookmark] Remove Product',
  props<{ id: number }>()
);

export const removeProducts = createAction(
  '[Bookmark] Remove Products',
  props<{ ids: Array<number> }>()
);

export const toggleProduct = createAction(
  '[Bookmark] Toggle Product',
  props<{ id: number }>()
);

export const clear = createAction(
  '[Bookmark] Clear'
);
