import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { SimpleHeadlineModel } from './simple-headline.model';
import { ContentElementModel } from '@conception/ngx-pimcore-connect';

@Component({
  selector: 'ce-simple-headline',
  templateUrl: './simple-headline.component.html',
  styleUrls: ['./simple-headline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleHeadlineComponent {
  @Input()
  @ContentElementModel(SimpleHeadlineModel)
  content: SimpleHeadlineModel;

  /**
   * Additional external classes based on context
   */
  @Input() class: string = '';

  @HostBinding('class') get hostClasses(): string {
    return [
      this.class,
      this.content.withBadge ? 'with-badge' : '',
      'background--' + this.content.backgroundColor,
    ].join(' ');
  }
}
