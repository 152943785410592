import { createReducer, on } from '@ngrx/store';
import { DynamicsActions } from '../actions';
import { SimpleType } from '@rz-gud/interfaces';

export interface DynamicsState {
  products: Array<SimpleType>;
  form: string;
}

export const initialState: DynamicsState = {
  products: [],
  form: null,
};

export const dynamicsReducer = createReducer(
  initialState,
  on(DynamicsActions.setProducts, (state, { products }) => ({
    ...state,
    products,
  })),
  on(DynamicsActions.openProductInquiryForm, (state) => ({
    ...state,
    form: 'inquiry',
  })),
  on(DynamicsActions.openProductQuestionForm, (state) => ({
    ...state,
    form: 'question',
  })),
  on(DynamicsActions.closeForm, (state) => ({
    ...state,
    form: initialState.form,
  }))
);
