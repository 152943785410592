import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { CampusService } from '@rz-gud/services';
import { ActivatedRoute } from '@angular/router';
import slugify from 'slugify';

@Component({
  selector: 'tab-container',
  templateUrl: './tab-container.component.html',
  styleUrls: ['./tab-container.component.scss'],
})
export class TabContainerComponent implements AfterViewInit {
  @Input() tabs: Array<any> = [];
  @Input() useAsLabel: string = '';
  @Input() color: string = 'yellow';
  @Input() activeTab: number = 0;
  @Output() activeTabChange: EventEmitter<number> = new EventEmitter<number>();

  constructor(private readonly campusService: CampusService, private activatedRoute: ActivatedRoute) {}

  ngAfterViewInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params['scroll-to']) {
        setTimeout(() => {
          this.activeTabChange.emit(
            this.tabs.findIndex(
              (tab) => slugify(tab[this.useAsLabel], { lower: true }) === params['scroll-to'].toLowerCase()
            )
          );
        }, 750);
      }
    });
  }

  activeItemsChange(event: any): void {
    this.activeTabChange.emit(
      this.tabs.findIndex(
        (tab) => slugify(tab[this.useAsLabel], { lower: true }) === slugify(event[0][this.useAsLabel], { lower: true })
      )
    );
  }
}
