<gd-round-button icon="close"
                 [color]="gdColors.WHITE_TRANSPARENT"
                 (click)="dismiss()"></gd-round-button>


<div class="tutorial__headline" [innerHTML]="isLanguage ? ('tutorial.language.headline' | translate) : ('tutorial.headline' | translate)"></div>
<div class="tutorial__copy" [innerHTML]="isLanguage ? ('tutorial.language.copy' | translate) : ('tutorial.copy' | translate)"></div>

<a *ngIf="link" [routerLink]="link.url" (click)="handleTutorialClick()">
  <gd-pill-button
    [label]="link.label | translate"
    [color]="link.color || 'azure-filled'"
  ></gd-pill-button>
</a>
