<gd-icon *ngIf="searchTerm && prefill" icon="close" color="azure" class="clear" (click)="clear()"></gd-icon>

<input
  class="input"
  type="text"
  inputmode="search"
  [placeholder]="placeholder"
  #searchInput
  (blur)="onBlur()"
  (focus)="toggleAutoSuggest()"
  (keyup)="autoSuggest($event)"
  (keyup.enter)="focusIndex === 0 && !isProductOverview ? search() : focusIndex === 0 && isProductOverview ? reloadAll() : null"
  (keyup.arrowUp)="focusAutoSuggest($event)"
  (keyup.arrowDown)="focusAutoSuggest($event)"
  [(ngModel)]="searchTerm">

<gd-icon icon="search" color="azure" class="loupe" (click)="isProductOverview ? reloadAll() : search();"></gd-icon>
<auto-suggest *ngIf="showAutoSuggest"
              [productAutofill]="productAutofill"
              [searchTerm]="searchTerm"
              [theme]="autofillTheme"
              [productSearch]="productSearch"
              [focus]="focusIndex"
              (search)="search()"
              (closeAutoSuggest)="showAutoSuggest = false"></auto-suggest>
