<h4 [innerHTML]="'contact.headline' | translate"></h4>
<gdcb-hero-text [headline]="'contact.sublineHighlight' | translate"
                [subline]="'contact.subline' | translate"
                highlight="suffix"></gdcb-hero-text>

<gd-separator></gd-separator>

<div class="cta-grid">
  <a [href]="'contact.newsletter.linkUrl' | translate"
     rel="noopener"
     target="_blank"
     class="cta"
     (click)="trackEvent('Subscribe to newsletter ')">
    <img src="./assets/svg/mail.svg" [alt]="'contact.newsletter' | translate" class="cta__icon">
    <div class="cta__label" [innerHTML]="'contact.newsletter' | translate"></div>
  </a>
  <a [href]="'contact.demo.linkUrl' | translate"
     rel="noopener"
     target="_blank"
     class="cta"
     (click)="trackEvent('Request Control Center demo')">
    <img src="./assets/images/controlcenter.png" [alt]="'contact.demo' | translate" class="cta__icon">
    <div class="cta__label" [innerHTML]="'contact.demo' | translate"></div>
  </a>
  <a *ngIf="contactLink$ | async as contactLink"
     [routerLink]="contactLink"
     (click)="trackEvent('Request live consultation')"
     class="cta">
    <img src="./assets/svg/appointment.svg" [alt]="'contact.liveConsultation' | translate" class="cta__icon">
    <div class="cta__label" [innerHTML]="'contact.liveConsultation' | translate"></div>
  </a>
</div>
