import { Deserializable, serialize, serializeArray } from '@conception/ts-class-deserializer';
import { PimImageCollection } from '@conception/ngx-pimcore-connect';
import { ProductFlag } from '@conception/ng-gud-ui';
import { Icon } from './icon.model';

export class Product extends Deserializable {
  @serialize() id: number;
  @serialize() beschreibung: string;
  @serialize() beschreibung2: string;
  @serialize() funktionDE: string;
  @serialize() funktionEN: string;
  @serialize() title: string;
  @serializeArray(ProductFlag) productFlags: Array<ProductFlag>;
  @serialize(PimImageCollection) bildFrontansicht: PimImageCollection;
  @serialize() bildFrontAehnlich: boolean;
  @serialize() urlSlug: string;
  @serialize() artikelnummer: string;
  @serializeArray(Icon) productIcons: Array<Icon>;
  @serialize() bluedec: boolean;
  @serialize() category: string;

  public headline(): string {
    return this.title ? this.title : [this.beschreibung, this.beschreibung2].join('');
  }

  public teaserImage(): string {
    return this.bildFrontansicht && this.bildFrontansicht.urls ? this.bildFrontansicht.getImage('ProductTeaser') : '';
  }
}
