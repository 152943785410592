import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '@conception/ngx-pimcore-connect';
import { map, take, tap } from 'rxjs/operators';
import { Product } from '@rz-gud/models';
import { TranslateService } from '@ngx-translate/core';
import { ProductFilterDefinition, ProductFilterSet } from '@rz-gud/interfaces';
import { Store } from '@ngrx/store';
import { ProductFilterActions, ProductFilterSelectors } from '@rz-gud/store';
import { HttpParams } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '@rz-gud/classes';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(
    private readonly apiService: ApiService,
    private readonly store: Store,
    private readonly translate: TranslateService
  ) {}

  /**
   * Gets a list of products and saves/appends it to the store
   *
   * @param sets {Array<ProductFilterSet>} - Set filters
   * @param searchTerm {string} - Set search term
   * @param exactSearch {boolean} - Exact search
   * @param limit {number} - Limit of items to load
   * @param page {number} - Offset
   */
  getProducts(
    sets: Array<ProductFilterSet>,
    searchTerm: string,
    exactSearch?: boolean,
    limit?: number,
    page?: number
  ): void {
    let params: HttpParams = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });

    sets.forEach((set) =>
      set.filterEntries.forEach((entry) => {
        params = params.append(set.category + '[]', entry.key);
      })
    );
    params = limit ? params.append('perPage', limit) : params;
    params = page ? params.append('page', page) : params;
    // params = searchTerm ? params.append('texts', searchTerm) : params;

    if (searchTerm) {
      const tempSearchEntry: string =
        searchTerm.startsWith('"') && searchTerm.endsWith('"')
          ? searchTerm.slice(1, -1)
          : searchTerm;

      // Check whether to append offset data (triggered by "load more" -> page is not 0) or to load a whole new list
      if (page) {
        this.apiService
          .getData(
            this.translate.currentLang +
              '/find/product?term=' +
              tempSearchEntry +
              '&perPage=' +
              limit +
              '&page=' +
              page +
              '&exactSearch=' +
              exactSearch
          )
          .pipe(
            take(1),
            map((response) => response.products),
            map((products) => this.apiService.removeContentPath(products)),
            tap((products) =>
              this.store.dispatch(
                ProductFilterActions.appendProducts({ products })
              )
            )
          )
          .subscribe();
      } else {
        this.store.dispatch(
          ProductFilterActions.setLoadingState({ currentState: false })
        );
        this.apiService
          .getData(
            this.translate.currentLang +
              '/find/product?term=' +
              tempSearchEntry +
              '&exactSearch=' +
              exactSearch
          )
          .pipe(
            take(1),
            tap((response) =>
              this.store.dispatch(
                ProductFilterActions.setCount({
                  count: response.totalCount,
                })
              )
            ),
            map((response) => response.products),
            map((products) => this.apiService.removeContentPath(products)),
            tap(() =>
              this.store.dispatch(ProductFilterActions.clearProducts())
            ),
            tap((products) =>
              this.store.dispatch(
                ProductFilterActions.setProducts({ products })
              )
            ),
            tap(() =>
              this.store.dispatch(
                ProductFilterActions.setLoadingState({ currentState: true })
              )
            )
          )
          .subscribe();
      }
    } else {
      this.store
        .select(ProductFilterSelectors.filterId)
        .pipe(
          take(1),
          tap((filterId) => {
            // Check whether to append offset data (triggered by "load more" -> page is not 0) or to load a whole new list
            if (page && filterId) {
              this.apiService
                .getData(
                  [this.translate.currentLang, filterId, 'products'].join('/'),
                  params
                )
                .pipe(
                  take(1),
                  map((response) => response.products),
                  map((products) =>
                    this.apiService.removeContentPath(products)
                  ),
                  tap((products) =>
                    this.store.dispatch(
                      ProductFilterActions.appendProducts({ products })
                    )
                  )
                )
                .subscribe();
            } else {
              this.store.dispatch(
                ProductFilterActions.setLoadingState({ currentState: false })
              );
              if (filterId) {
                this.apiService
                  .getData(
                    [this.translate.currentLang, filterId, 'products'].join(
                      '/'
                    ),
                    params
                  )
                  .pipe(
                    take(1),
                    tap((response) =>
                      this.store.dispatch(
                        ProductFilterActions.setCount({
                          count: response.totalCount,
                        })
                      )
                    ),
                    map((response) => response.products),
                    map((products) =>
                      this.apiService.removeContentPath(products)
                    ),
                    tap(() =>
                      this.store.dispatch(ProductFilterActions.clearProducts())
                    ),
                    tap((products) =>
                      this.store.dispatch(
                        ProductFilterActions.setProducts({ products })
                      )
                    ),
                    tap(() =>
                      this.store.dispatch(
                        ProductFilterActions.setLoadingState({
                          currentState: true,
                        })
                      )
                    )
                  )
                  .subscribe();
              }
            }
          })
        )
        .subscribe();
    }
  }

  /**
   * Get a single product by id
   *
   * @param id {number} - Product id
   */
  getProduct(id: number): Observable<Product> {
    return this.apiService
      .getData([this.translate.currentLang, 'products', id].join('/'))
      .pipe(
        map((product) => this.apiService.removeContentPath(product)),
        map((product) => {
          if (
            product.productIcons.some(
              (icon) => icon.iconId === 'compression_compressed'
            )
          ) {
            return { ...product, bluedec: true };
          } else {
            return { ...product, bluedec: false };
          }
        }),
        map((product) => new Product().deserialize(product))
      );
  }

  /**
   * Use a ProductFilterDefinition as preset
   *
   * @param definition {ProductFilterDefinition} - Product Filter Definition to use
   */
  setPreset(definition: ProductFilterDefinition): void {
    const preselects: Array<ProductFilterSet> = definition.filters
      .filter((el) => el.preSelect)
      .map((el) => ({
        category: el.key,
        filterEntries: [
          ...el.preSelect
            .split(',')
            .filter((entry) => entry !== ' ' && entry !== '')
            .map((filter) => ({
              key: filter,
              label: this.translate.instant(filter),
            })),
        ],
      }));

    this.store.dispatch(
      ProductFilterActions.setPresetId({ presetId: definition.filterId })
    );
    this.store.dispatch(
      ProductFilterActions.setFilterObject({ filter: preselects })
    );
  }
}
