import {Component} from '@angular/core';
import {ContentElementModel} from '@conception/ngx-pimcore-connect';
import {CampusEventDetailIcsModel} from './campus-event-detail-ics.model';

@Component({
  selector: 'ce-campus-event-detail-ics',
  templateUrl: './campus-event-detail-ics.component.html',
  styleUrls: ['./campus-event-detail-ics.component.scss']
})
export class CampusEventDetailIcsComponent {
  @ContentElementModel(CampusEventDetailIcsModel) content: CampusEventDetailIcsModel;
}
