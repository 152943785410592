<header>
  <h2 [innerHTML]="headline"></h2>
  <gd-icon icon="close" color="azure" (click)="closed.emit(true)"></gd-icon>
</header>

<div class="img-container" *ngIf="overlay?.url">
  <img class="background"
       [src]="overlay.url"
       [alt]="overlay.meta?.alt"
       [title]="overlay.meta?.title">

  <gd-marker *ngFor="let marker of overlay?.marker; let i = index;"
             [active]="activeMarker === i"
             (click)="activeMarker = activeMarker === i ? -1 : i"
             [style.top]="marker.top + '%'"
             [style.left]="marker.left + '%'">
    {{marker.data[0].value}}
  </gd-marker>
</div>





