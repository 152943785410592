import {Deserializable, serialize} from '@conception/ts-class-deserializer';
import {PimAsset} from '@conception/ngx-pimcore-connect';

export class ProductHighlightModel extends Deserializable {
  @serialize() id: string;
  @serialize(PimAsset) image: PimAsset;
  @serialize() tag: string;
  @serialize() headline: string;
  @serialize() subline: string;
  @serialize() copy: string;
}
