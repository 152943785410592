import { Component, HostBinding, Inject, Input, PLATFORM_ID } from '@angular/core';
import { ContentElementModel } from '@conception/ngx-pimcore-connect';
import { FactSliderModel } from './fact-slider.model';
import { SwiperOptions } from 'swiper';
import { GDColors } from '@conception/ng-gud-ui';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'ce-fact-slider',
  templateUrl: './fact-slider.component.html',
  styleUrls: ['./fact-slider.component.scss']
})
export class FactSliderComponent {

  @ContentElementModel(FactSliderModel) content: FactSliderModel;

  @Input() class: string = '';

  sliderConfig: SwiperOptions = {
    spaceBetween: 6,
    slidesPerView: 1,
    grid: {
      rows: 1
    },
    breakpoints: {
      766: {
        spaceBetween: 8,
        slidesPerView: 2,
        grid: {
          rows: 1
        }
      },
      1020: {
        spaceBetween: 8,
        slidesPerView: 4,
        grid: {
          rows: 1
        }
      },
      1580: {
        spaceBetween: 13,
        slidesPerView: 4,
        grid: {
          rows: 1
        }
      }
    }
  };

  currentSlide: number = 1;
  showNavigation: boolean = true;
  gdColors = GDColors;
  private readonly isBrowser: boolean = false;

  constructor(@Inject(PLATFORM_ID) private platformId: Record<string, unknown>) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  @HostBinding('class') get classes() {
    return [
      this.content.theme,
      this.class
    ].join(' ');
  }

  setCurrentSlide(event): void {
    this.currentSlide = +event.realIndex + 1;
  }

  checkAfterResize(event: any): void {
    const slidesPerView = event.currentBreakpoint === 'max'
      ? this.sliderConfig.slidesPerView
      : this.sliderConfig.breakpoints[event.currentBreakpoint].slidesPerView;
    this.showNavigation = slidesPerView < this.content.blocks.length;
  }
}
