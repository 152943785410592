import { createAction, props } from '@ngrx/store';
import { SimpleType } from '@rz-gud/interfaces';

export const openProductQuestionForm = createAction(
  '[Dynamics Form] Open product inquiry'
);

export const openProductInquiryForm = createAction(
  '[Dynamics Form] Open product question'
);

export const setProducts = createAction(
  '[Dynamics Form] Set Products',
  props<{ products: Array<SimpleType> }>()
);

export const closeForm = createAction('[Dynamics Form] Close form');
