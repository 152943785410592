import {Deserializable, serialize} from '@conception/ts-class-deserializer';

export class HeroTextModel extends Deserializable {
  @serialize() headline: string;
  @serialize() subline: string;
  @serialize() theme: string;
  @serialize() alignment: string;
}


