<div class='product-search--inner'>
  <h1
    *ngIf="content.theme === 'main'"
    class='headline-2'
    [innerHTML]='content.headline'
  ></h1>
  <h2
    *ngIf="content.theme === 'inline'"
    class='herotext'
    [innerHTML]='content.headline'
  ></h2>

  <searchbar
    [productSearch]='true'
    [productAutofill]="true"
    [setExactSearch]="exactSearch"
    [disableInText]="disableInText"
    (exact)="exactSearch = $event"
    autofillTheme="dark"
    [theme]="content.theme === 'inline' ? 'dark' : 'light'"
    [placeholder]="'expertSearch.placeholder' | translate"
  ></searchbar>
  <!--
  <gd-checkbox [label]="'search.exactSearch' | translate"
               [checked]="exactSearch"
               size="small"
               (isChecked)="exactSearch = $event; disableInText = $event;"></gd-checkbox>
               -->

  <gd-separator></gd-separator>

  <div class='popular-searches'>
    <div
      class='popular-searches__label'
      [innerHTML]="'search.popularSearches' | translate"
    ></div>
    <div class='popular-searches__container'>
      <a [routerLink]='productOverviewLink$ | async'>
        <gd-pill-button
          [label]="'search.showAllProducts' | translate"
          [color]="'azure-filled'"
        ></gd-pill-button>
      </a>
      <gd-pill-button
        *ngFor='let item of content.filterDefinitions'
        [label]='item.name | translate'
        [routerLink]='productOverviewLink$ | async'
        [queryParams]='{filter: item.id}'
      ></gd-pill-button>
      <gd-pill-button
        *ngFor='let item of content.popularSearches'
        [label]='item.searchTerm'
        (click)='search(item.searchTerm)'
      ></gd-pill-button>
    </div>
  </div>
</div>
