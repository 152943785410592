<gd-teaser-tag backgroundColor="yellow" *ngIf="event.gdEvent">G&D Event</gd-teaser-tag>

<div class="image">
  <img [src]="event.image.getImage('CmsImages')"
       [alt]="event.image?.meta?.alt ? event.image.meta.alt : event.title"
        [title]="event.image?.meta?.title">
</div>

<div class="text">
  <div class="upper">
    <div class="date">
      <span *ngIf="event.startDate">{{event.startDate | date:'dd.MM.YYYY'}}</span>
      <span *ngIf="event.endDate && (event.startDate | date:'dd.MM.YYYY') !== (event.endDate | date:'dd.MM.YYYY')">
        - {{event.endDate | date:'dd.MM.YYYY'}}
      </span>
    </div>
    <h2 class="title" [innerHTML]="event.title"></h2>
    <p class="copy description" [innerHTML]="event.description | truncatetext:130"></p>
  </div>
  <a *ngIf="(detailLink$ | async) as detailLink" [routerLink]="[detailLink, event.urlSlug].join('/')">
    <gd-button [color]="'azure'" [label]="'learnMore' | translate"></gd-button>
  </a>
</div>
