<h3 *ngIf="data?.headline" [innerHTML]="data?.headline"></h3>
<p *ngIf="data?.copy || data?.excerpt" [innerHTML]="isCategory ? data?.excerpt : data?.copy"></p>

<ng-container *ngIf="data?.alternativeLink?.path">
  <a [routerLink]="data.alternativeLink?.path" class="teaser__button">
    <gd-button [color]="'maya'" [label]="data?.buttonLabel"></gd-button>
  </a>
</ng-container>

<ng-container *ngIf="data?.pageLink?.pageLink?.url">
  <a [routerLink]="data.pageLink?.pageLink.url" class="teaser__button">
    <gd-button [color]="'maya'" [label]="data?.buttonLabel"></gd-button>
  </a>
</ng-container>

<ng-container *ngIf="data?.overviewLink?.overviewLink?.url">
  <a [routerLink]="data.overviewLink.overviewLink.url" class="teaser__button">
    <gd-button [color]="'maya'" [label]="data.overviewLink.overviewLink?.name"></gd-button>
  </a>
</ng-container>
