<h2 [innerHTML]="content.headline"></h2>
<swiper [loop]="true"
        [spaceBetween]="20"
        [navigation]="false"
        [pagination]="{clickable: true}"
        [slidesPerView]="'auto'">
  <ng-template swiperSlide *ngFor="let slide of content.slides">
    <a *ngIf="slide.link.internal" [routerLink]="slide.link.path"
       dragButtonHover="DRAG CLICK">
      <ng-container *ngTemplateOutlet="slideTemplate; context: { $implicit: slide }"></ng-container>
    </a>
    <a *ngIf="!slide.link.internal" [href]="slide.link.path" [target]="slide.link.target" rel="nofollow"
       dragButtonHover="DRAG CLICK">
      <ng-container *ngTemplateOutlet="slideTemplate; context: { $implicit: slide }"></ng-container>
    </a>
  </ng-template>
</swiper>


<ng-template #slideTemplate let-slide>
  <div class="inner-slide">
    <div class="focus-intro">
      <gd-feels-right-badge [color]="gdColors.YELLOW"></gd-feels-right-badge>
      <gd-icon icon="extender-signet" color="maya" class="decorator__icon"></gd-icon>
    </div>
    <div class="focus-content">
      <div class="focus-content__text">
        <h3 class="focus-content__headline" [innerHTML]="slide.headline"></h3>
        <p class="focus-content__copy" [innerHTML]="slide.copy"></p>
      </div>
      <gd-simple-button icon="plus" [label]="slide.link.text | uppercase"></gd-simple-button>
    </div>
    <div class="focus-image">
      <img [src]="slide.image.url" [alt]="slide.image?.meta?.alt" [title]="slide.image?.meta?.title">
    </div>
  </div>
</ng-template>
