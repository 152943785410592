import { createSelector } from '@ngrx/store';
import { HubspotState, State } from '../reducers';
import { SimpleType } from '@rz-gud/interfaces';

export const select = (state: State) => state.hubspot;
export const inquiryId = (state: State) =>
  state.page.siteInfo.hubspotFormProductInquiry;
export const questionsId = (state: State) =>
  state.page.siteInfo.hubspotFormProductQuestions;

export const hiddenFields = createSelector<
  State,
  [HubspotState],
  Array<SimpleType>
>(select, (hubspot: HubspotState) => hubspot.hiddenFields);

export const isOverlayOpen = createSelector<State, [HubspotState], boolean>(
  select,
  (hubspot: HubspotState) => hubspot.form !== null
);

export const overlayFormId = createSelector<
  State,
  [HubspotState, string, string],
  string
>(
  select,
  inquiryId,
  questionsId,
  (hubspot: HubspotState, inquiry: string, questions: string) =>
    hubspot.form === 'inquiry' ? inquiry : questions
);
