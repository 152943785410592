import {Component, HostBinding, Input} from '@angular/core';
import {ContentElementModel} from '@conception/ngx-pimcore-connect';
import {InsertionModel} from './insertion.model';

@Component({
  selector: 'ce-insertion',
  templateUrl: './insertion.component.html',
  styleUrls: ['./insertion.component.scss']
})
export class InsertionComponent {

  @ContentElementModel(InsertionModel) content: InsertionModel;

  @Input() class: string = '';

  @HostBinding('class') get classes() {
    return [
      this.content.theme,
      this.class
    ].join(' ');
  }
}
