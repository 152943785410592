<gd-note *ngIf="content.headline"
         [label]="content.headline"
         [color]="gdColors.AZURE"
         [unicolor]="true"
         [textFlip]="true"></gd-note>

<div class="blocks" *ngIf="content.blocks.length">
  <div class="block" *ngFor="let block of content.blocks">
    <h4 class="block__headline" [innerHTML]="block.headline"></h4>
    <div class="block__content" [innerHTML]="block.text"></div>

    <ng-container *ngIf="block.link">
      <ng-container [ngSwitch]="block.link.linktype">
        <a *ngSwitchCase="'internal'" [routerLink]="block.link.path">
          <gd-button [label]="block.link.text" color="maya"></gd-button>
        </a>
        <a *ngSwitchCase="'direct' || 'external'" [href]="block.link.path" target="_blank" rel="noreferrer">
          <gd-button [label]="block.link.text" color="maya"></gd-button>
        </a>
      </ng-container>
    </ng-container>
  </div>
</div>
