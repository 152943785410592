import {Component, HostListener, Inject, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {LayoutActions, LayoutSelectors, PageSelectors} from '@rz-gud/store';
import {ButtonSizes, GDColors} from '@conception/ng-gud-ui';
import {scaleInCenter} from '@rz-gud/animations';
import {GtagService} from '@rz-gud/services/gtag.service';

@Component({
  selector: 'contact-bar',
  templateUrl: './contact-bar.component.html',
  styleUrls: ['./contact-bar.component.scss'],
  animations: [scaleInCenter]
})
export class ContactBarComponent {
  visible: boolean = false;

  readonly buttonSizes = ButtonSizes;
  readonly gdColors = GDColors;
  readonly supportLink$: Observable<string> = this.store.select(PageSelectors.support);
  readonly contactLink$: Observable<string> = this.store.select(PageSelectors.contact);
  readonly amILocked$: Observable<boolean> = this.store.select(LayoutSelectors.contactbarLock);
  private readonly isBrowser: boolean = false;

  constructor(private readonly store: Store,
              private readonly gtag: GtagService,
              @Inject(PLATFORM_ID) private platformId: Record<string, unknown>) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  @HostListener('window:scroll', ['$event']) scroll(e) {
    if (this.isBrowser) {
      this.visible = window.scrollY > (window.innerHeight * .75);
    }
  }

  lock(): void {
    this.store.dispatch(LayoutActions.lockContactBar());
  }

  unlock(): void {
    this.store.dispatch(LayoutActions.unlockContactBar());
  }

  trackEvent(cta: string): void {
    this.gtag.pushEvent('cta', [{location: 'sidebar'}, {cta}]);
  }
}
