<ng-container *ngIf="(breadcrumb$ | async) as breadcrumb">
  <div class="breadcrumb__outer" *ngIf="showBreadcrumbMobile" @breadcrumbAnimation>
    <div class="breadcrumb--top" *ngIf="showBreadcrumb && breadcrumb.length &&
    ((breadcrumb[0]?.url !== (startLink$ | async)) || (breadcrumb[0]?.url !== (productsLink$ | async)))">
      <gd-icon icon="extender-signet" color="maya"></gd-icon>
      <div class="breadcrumb__container">
        <div class="breadcrumb__list"
             [class.breadcrumb__list--white]="(breadcrumbColor$ | async) === 'white'">
          <div class="breadcrumb breadcrumb__title" [innerHTML]="'youAreHere' | translate"></div>
          <a [routerLink]="(pageMode$ | async) === 'easy' ? (startLink$ | async) : (productEntryLink$ | async)"
             class="breadcrumb breadcrumb__link">
            Start
          </a>
          <span class="breadcrumb dash">&ndash;</span>
          <ng-container *ngFor="let item of breadcrumb; let last = last;">
            <a *ngIf="item.title && item.url && item.url !== '/'"
               [routerLink]="item.url"
               [class.breadcrumb__link--last]="last"
               class="breadcrumb breadcrumb__link">
              {{item.title}}
            </a>
            <span *ngIf="item.title && (item.url === '/' ||item.url === '')" class="breadcrumb">
            {{item.title}}
          </span>
            <span class="breadcrumb dash" *ngIf="!last && item.title">&ndash;</span>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <gd-line [color]="lineColor"></gd-line>

</ng-container>
