import { Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnInit, Output } from '@angular/core';
import { scaleInCenter } from '@rz-gud/animations';

@Component({
  selector: 'option-dropdown',
  templateUrl: './option-dropdown.component.html',
  styleUrls: ['./option-dropdown.component.scss'],
  animations: [scaleInCenter]
})
export class OptionDropdownComponent implements OnInit {


  @Input() items: Array<any> = [];
  @Input() description1: string = '';
  @Input() description2: string = '';
  @Input() simpleObject: boolean = true;
  @Input() useAsLabel: string = 'label';
  @Input() useAsIdentifier: string = 'id';
  @Input() label: string = '';

  @Output() activeItemChange: EventEmitter<any> = new EventEmitter<any>();

  @HostBinding('class.is-toggable') @Input() isToggable: boolean = true;
  @HostBinding('class.is-selected') isSelected: boolean = false;
  @HostBinding('class.is-open') isOpen: boolean = false;

  private currentlyActive: any;

  constructor(private readonly el: ElementRef) {
  }

  @Input() set activeItem(item: any) {
    this.currentlyActive = item;
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.isToggable) {
      this.isOpen = this.el.nativeElement.contains(event.target);
    }
  }

  ngOnInit(): void {
    this.isOpen = !this.isToggable;
  }

  setActive(option: any): void {
    this.activeItem = option;
    this.activeItemChange.emit(option);
    if (this.isToggable) {
      this.isOpen = false;
    }
  }

  getIdentifier(option: any): string | number {
    if (option) {
      return this.simpleObject ? option : option[this.useAsIdentifier];
    } else {
      return '';
    }
  }

  getLabel(option: any): string {
    if (option) {
      return this.simpleObject ? option : option[this.useAsLabel];
    } else {
      return '';
    }
  }

  isActive(option: any): boolean {
    if (option && this.currentlyActive) {
      return this.getIdentifier(option) === this.getIdentifier(this.currentlyActive);
    } else {
      return false;
    }
  }
}
