import {Component} from '@angular/core';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {PageSelectors} from '@rz-gud/store';
import {ContentElementModel} from '@conception/ngx-pimcore-connect';
import {CampusEventDetailLinkModel} from './campus-event-detail-link.model';

@Component({
  selector: 'ce-campus-event-detail-link',
  templateUrl: './campus-event-detail-link.component.html',
  styleUrls: ['./campus-event-detail-link.component.scss']
})
export class CampusEventDetailLinkComponent {

  @ContentElementModel(CampusEventDetailLinkModel) content: CampusEventDetailLinkModel;
  eventsOverview$: Observable<string> = this.store.select(PageSelectors.eventOverview);

  constructor(private readonly store: Store) {
  }

}
