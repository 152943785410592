import {createReducer, on} from '@ngrx/store';
import {LanguageNavigationActions} from '../actions';
import {LanguageNavigationItem} from '@rz-gud/interfaces';

export interface LanguageNavigationState {
  items: Array<LanguageNavigationItem>;
  current: LanguageNavigationItem;
}

export const initialState: LanguageNavigationState = {
  items: [],
  current: undefined
};

export const languageNavigationReducer = createReducer(
  initialState,
  on(LanguageNavigationActions.set, (state, {items}) => ({...state, items})),
  on(LanguageNavigationActions.setCurrent, (state, {current}) => ({...state, current}))
);
