import {Component, HostBinding, Input} from '@angular/core';
import {NavigationItem} from '@rz-gud/interfaces';
import {Observable} from 'rxjs';
import {LayoutSelectors, PageMode} from '@rz-gud/store';
import {Store} from '@ngrx/store';

@Component({
  selector: 'flyout-menu',
  templateUrl: './flyout-menu.component.html',
  styleUrls: ['./flyout-menu.component.scss']
})
export class FlyoutMenuComponent {

  @Input() data: NavigationItem;
  @HostBinding('class') @Input() color: string;

  showTextTeaser: boolean = true;
  readonly pageMode$: Observable<PageMode> = this.store.select(LayoutSelectors.mode);

  constructor(private readonly store: Store) {
  }

  toggleTeaser(event: any, hasTeaser: boolean): void {
    if (hasTeaser) {
      this.showTextTeaser = event?.type !== 'mouseenter';
    } else {
      this.showTextTeaser = true;
    }
  }

}
