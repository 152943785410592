<div class="swiper" *ngIf="content.images.length > 1">
  <swiper
    #swiper
    [loop]="true"
    [spaceBetween]="20"
    [navigation]="false"
    [slidesPerView]="'auto'"
    (activeIndexChange)="setCurrentSlide($event)"
  >
    <ng-template swiperSlide *ngFor="let slide of content.images">
      <div class="inner-slide">
        <img
          *ngIf="slide.image.url"
          [src]="slide.image.url"
          [alt]="slide.image.meta?.alt"
          [title]="slide.image.meta?.title"
        />
      </div>
    </ng-template>
  </swiper>
  <gd-slider-navigation
    [maxSlides]="maxSlides"
    [currentSlide]="currentSlide"
    (jumpTo)="swiper.swiperRef.slideTo($event)"
    (navigation)="
      $event === 'next'
        ? swiper.swiperRef.slideNext()
        : swiper.swiperRef.slidePrev()
    "
  ></gd-slider-navigation>
</div>
<img
  *ngIf="content.images.length === 1"
  class="single-image"
  [src]="content.images[0].image?.url"
  [alt]="content.images[0].image?.meta?.alt"
  [title]="content.images[0].image?.meta?.title"
/>

<div class="text">
  <h2 [innerHTML]="content.headline"></h2>
  <p [innerHTML]="content.copy"></p>
</div>
