<div class="back-to-overview">
  <a *ngIf="(eventsOverview$ | async) as eventsOverview" [routerLink]="eventsOverview">
    <gd-simple-button icon="long-arrow-back" [label]="'backToOverview' | translate"></gd-simple-button>
  </a>
</div>

<header>
  <h1 [innerHTML]="content.title"></h1>

  <ul class="details">
    <li class="detail" *ngIf="content.startDate">
      <gd-icon color="azure" icon="calendar" class="detail__icon"></gd-icon>
      <div class="detail__label">
        {{content.startDate}}
        <ng-container *ngIf="content.endDate && content.endDate !== content.startDate"> - {{content.endDate}}</ng-container>
      </div>
    </li>
    <li class="detail" *ngIf="content.startTime && content.startTime !== '00:00'">
      <gd-icon color="azure" icon="clock" class="detail__icon"></gd-icon>
      <div class="detail__label">
        {{content.startTime}}
        <ng-container *ngIf="content.endTime"> - {{content.endTime}}</ng-container>
        <ng-container *ngIf="content.timezone"> ({{content.timezone}})</ng-container>
      </div>
    </li>
    <li class="detail" *ngIf="content.location">
      <gd-icon color="azure" icon="location" class="detail__icon"></gd-icon>
      <div class="detail__label">{{content.location}}</div>
    </li>
  </ul>

  <div class="ics" *ngIf="icsDownload">
    <a [href]="icsDownload" target="_blank">
      <gd-button [icon]="'calendar'" [label]="'icsDownload' | translate" [color]="'azure-outline'"></gd-button>
    </a>
  </div>
</header>
