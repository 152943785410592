<div class="fact-slider--inner">
  <ng-container *ngIf="content.blocks.length > 0">
    <swiper
      #swiper
      (resize)='checkAfterResize($event)'
      [config]="sliderConfig"
      (activeIndexChange)="setCurrentSlide($event)"
    >
      <ng-template swiperSlide *ngFor="let block of content.blocks">
        <div class="simple-teaser">
          <gd-icon-box
            class="simple-teaser__icon"
            [border]="true"
            [icon]="block.icon"
            [color]="gdColors.SILVERLIGHT"
            [borderColor]="gdColors.MAYA"
          ></gd-icon-box>
          <div
            class="simple-teaser__headline"
            [innerHTML]="block.headline"
          ></div>
          <div class="simple-teaser__body" [innerHTML]="block.text"></div>
        </div>
      </ng-template>
    </swiper>

    <gd-slider-navigation
      *ngIf="showNavigation"
      [maxSlides]="content.blocks.length - 3"
      [currentSlide]="currentSlide"
      (jumpTo)="swiper.swiperRef.slideTo($event)"
      (navigation)="
        $event === 'next'
          ? swiper.swiperRef.slideNext()
          : swiper.swiperRef.slidePrev()
      "
    >
    </gd-slider-navigation>
  </ng-container>
</div>
