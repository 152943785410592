import { Deserializable, serialize } from '@conception/ts-class-deserializer';

export class SimpleHeadlineModel extends Deserializable {
  @serialize() headline: string;
  @serialize() headlineHighlight: string;
  @serialize() intro: string;
  @serialize() introHighlight: string;
  @serialize() backgroundColor: string;
  @serialize() withBadge: boolean;
}
