import {Component, OnInit} from '@angular/core';
import {EasyModeSelectorModel} from './easy-mode-selector.model';
import {Store} from '@ngrx/store';
import {EasyModeActions, EasyModeSelectors, EasyModeState, PageActions} from '@rz-gud/store';
import {Observable} from 'rxjs';
import {ButtonSizes, GDColors} from '@conception/ng-gud-ui';
import {ngIfAnimation, slideHorizontal, slideInOutBottom} from '@rz-gud/animations';
import {ContentElementModel} from '@conception/ngx-pimcore-connect';

@Component({
  selector: 'ce-easy-mode-selector',
  templateUrl: './easy-mode-selector.component.html',
  styleUrls: ['./easy-mode-selector.component.scss'],
  animations: [ngIfAnimation, slideHorizontal, slideInOutBottom]
})
export class EasyModeSelectorComponent implements OnInit {
  @ContentElementModel(EasyModeSelectorModel) content: EasyModeSelectorModel;

  buttonSizes = ButtonSizes;
  gdColors = GDColors;

  readonly easymode$: Observable<EasyModeState> = this.store.select(EasyModeSelectors.select);

  constructor(private readonly store: Store) {
  }

  ngOnInit(): void {
    this.store.dispatch(PageActions.clearBreadcrumb());
  }

  previousStage(): void {
    this.store.dispatch(EasyModeActions.previousStage());
  }

  nextStage(): void {
    this.store.dispatch(EasyModeActions.nextStage());
  }
}
