import {Deserializable, serialize, serializeArray} from '@conception/ts-class-deserializer';

export class EasyModeSelectorAnswerModel extends Deserializable {
  @serialize() label: string;
  @serialize() id: number;
}

export class EasyModeSelectorQuestionModel extends Deserializable {
  @serialize() question: string;
  @serialize() questionShortHand: string;
  @serializeArray(EasyModeSelectorAnswerModel) answers: Array<EasyModeSelectorAnswerModel>;
}

export class EasyModeSelectorModel extends Deserializable {
  @serialize() headline: string;
  @serialize() subline: string;
  @serialize() cta: string;
  @serializeArray(EasyModeSelectorQuestionModel) qaBlock: Array<EasyModeSelectorQuestionModel>;
}
