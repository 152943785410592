import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ProductFilterActions,
  ProductFilterSelectors,
  SearchActions,
  SearchSelectors,
} from '@rz-gud/store';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss'],
})
export class SearchbarComponent implements OnInit, OnDestroy {
  @ViewChild('searchInput') inputRef: ElementRef;
  @Input() prefill: boolean = false;
  @Input() productSearch: boolean = false;
  @Input() theme: 'light' | 'dark' = 'light';
  @Input() autofillTheme: 'light' | 'dark' = 'light';
  @Input() placeholder: string = '';
  @Input() productAutofill: boolean = false;
  @Input() disableInText: boolean = false;
  @Input() class: string = '';
  @Input() isProductOverview: boolean = false;

  @Output() exact: EventEmitter<boolean> = new EventEmitter<boolean>();

  showAutoSuggest: boolean = false;
  searchTerm: string = '';
  focusIndex: number = 0;

  private exactSearch: boolean = false;
  private sub: Subscription;
  private searchOverlaySubscription: Subscription;

  private wasInside = false;

  constructor(
    private readonly store: Store,
    private readonly translate: TranslateService
  ) {
    this.searchOverlaySubscription = this.store.select(SearchSelectors.isOverlayOpen).subscribe(overlayOpen => {
      this.showAutoSuggest = overlayOpen;
    });
    this.store
      .select(ProductFilterSelectors.searchTerm)
      .subscribe((currentSearchTerm) => {
        if (this.searchTerm.toLowerCase() !== currentSearchTerm.toLowerCase()) {
          this.searchTerm = currentSearchTerm;
        }
      });
    this.store.select(SearchSelectors.term).subscribe((currentSearchTerm) => {
      if (this.searchTerm.toLowerCase() !== currentSearchTerm.toLowerCase()) {
        this.searchTerm = currentSearchTerm;
      }
    });

    this.translate.onLangChange.subscribe((langChange) => {
      if (this.searchTerm) {
        this.store.dispatch(
          this.productSearch
            ? ProductFilterActions.search()
            : SearchActions.search()
        );
      }
    });
  }

  @HostBinding('attr.class') get hostClasses(): string {
    return [
      this.class,
      this.showAutoSuggest ? 'no-bottom-border' : '',
      this.theme,
    ].join(' ');
  }

  @Input() set setExactSearch(exactSearch: boolean) {
    this.exactSearch = exactSearch;
  }

  @HostListener('click')
  clickInside() {
    this.wasInside = true;
  }

  @HostListener('document:click')
  clickOut() {
    if (!this.wasInside) {
      this.showAutoSuggest = false;
    }
    this.wasInside = false;
  }

  ngOnInit(): void {
    if (this.prefill && !this.productSearch) {
      this.sub = this.store
        .select(SearchSelectors.term)
        .subscribe((result) => (this.searchTerm = result));
    }

    setTimeout(() => {
      if (this.inputRef && !this.prefill && !this.productSearch) {
        const input: HTMLInputElement = this.inputRef
          .nativeElement as HTMLInputElement;
        input.focus();
      }
    }, 100);
    this.showAutoSuggest = false;
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
    this.searchOverlaySubscription.unsubscribe();
    this.showAutoSuggest = false;
  }

  search(): void {
    if (this.searchTerm) {
      this.store.dispatch(
        this.productSearch
          ? ProductFilterActions.exactSearch({ exactSearch: this.exactSearch })
          : SearchActions.exactSearch({ exactSearch: this.exactSearch })
      );
      this.store.dispatch(
        this.productSearch
          ? ProductFilterActions.search()
          : SearchActions.search()
      );
      this.showAutoSuggest = false;
    } else {
      this.onBlur();
      this.store.dispatch(ProductFilterActions.reloadAll());
      this.showAutoSuggest = false;
      this.searchTerm = '';
    }
  }

  reloadAll(): void {
    if (this.isProductOverview) {
      this.onBlur();
      this.store.dispatch(ProductFilterActions.reloadAll());
      this.showAutoSuggest = false;
      this.searchTerm = '';
    }
  }

  autoSuggest(event: KeyboardEvent): void {
    if (this.isProductOverview) {
      this.store.dispatch(
        ProductFilterActions.setProductOverviewSearchFocus({
          productOverviewSearchHasFocus: false,
        })
      );
    }
    if (event.key !== 'Enter') {
      if (this.searchTerm.length !== 0) {
        if (!this.disableInText) {
          this.toggleExactSearch();
        }
        this.store.dispatch(
          this.productSearch
            ? ProductFilterActions.setSearchTerm({ term: this.searchTerm })
            : SearchActions.setTerm({ term: this.searchTerm })
        );
      } else {
        this.clear();
      }
    } else {
      this.toggleAutoSuggest(false);
    }
  }

  clear(): void {
    this.inputRef?.nativeElement.focus();
    this.searchTerm = '';

    this.store.dispatch(
      this.productSearch
        ? ProductFilterActions.clearSearchTerm()
        : SearchActions.clearTerm()
    );
    this.store.dispatch(SearchActions.clearSuggestions());
    this.focusIndex = 0;
  }

  onBlur(): void {
    if (this.isProductOverview) {
      this.store.dispatch(
        ProductFilterActions.setProductOverviewSearchFocus({
          productOverviewSearchHasFocus: true,
        })
      );
    }
  }

  toggleAutoSuggest($val: boolean = true): void {
    if (this.isProductOverview) {
      this.store.dispatch(
        ProductFilterActions.setProductOverviewSearchFocus({
          productOverviewSearchHasFocus: false,
        })
      );
    }
    this.focusIndex = 0;
    this.store
      .select(ProductFilterSelectors.autofillProductNames)
      .subscribe((autofillProductNames) => {
        if (autofillProductNames.length === 1) {
          this.showAutoSuggest =
            this.searchTerm.length > 0
              ? !(
                  autofillProductNames[0].toLowerCase() ===
                  this.searchTerm.toLowerCase()
                )
              : false;
        } else {
          if ($val === false) {
            this.showAutoSuggest = false;
          } else {
            this.showAutoSuggest = autofillProductNames.length > 0;
          }
        }
      });
  }

  focusAutoSuggest(e) {
    if (e.key === 'ArrowDown') {
      if (this.focusIndex <= 19) {
        this.focusIndex = this.focusIndex + 1;
      }
    }
    if (e.key === 'ArrowUp') {
      if (this.focusIndex !== 0) {
        this.focusIndex = this.focusIndex - 1;
      }
    }
  }

  private toggleExactSearch(): void {
    if (this.searchTerm) {
      this.exactSearch =
        this.searchTerm.startsWith('"') && this.searchTerm.endsWith('"');
      this.exact.emit(this.exactSearch);
    }
  }
}
