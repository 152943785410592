import {Component} from '@angular/core';
import {ContentElementModel} from '@conception/ngx-pimcore-connect';
import {ProductDetailDownloadModel} from './product-detail-download.model';

@Component({
  selector: 'ce-product-detail-download',
  templateUrl: './product-detail-download.component.html',
  styleUrls: ['./product-detail-download.component.scss']
})
export class ProductDetailDownloadComponent {
  @ContentElementModel(ProductDetailDownloadModel) content: ProductDetailDownloadModel;
}
