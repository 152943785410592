import {Deserializable, serialize, serializeArray} from '@conception/ts-class-deserializer';

export class BranchTabContent extends Deserializable {
  @serializeArray() content_elements: Array<any>;
}

export class BranchTabpanel extends Deserializable {
  @serialize(BranchTabContent) tabContent: BranchTabContent;
  @serialize() tabTitle: string;
}

export class BranchTabpanelModel extends Deserializable {
  @serializeArray(BranchTabpanel) tabs: Array<BranchTabpanel>;
}
