<div class="flyout">
  <div class="flyout__inner">
    <div class="menu" [class.menu--no-image]="!data.productCategory || !data.productCategory[0]?.teaserImage?.urls">
      <flyout-item *ngFor="let link of data.pages; let i = index"
                   [data]="link.linkBrick"
                   [category]="link.productCategory?.length ? link.productCategory[0] : undefined"
                   [color]="color"
                   (mouseenter)="toggleTeaser($event, link?.linkBrick.copy?.length > 0 || link?.productCategory?.length > 0)"
                   (mouseleave)="toggleTeaser($event, link?.linkBrick.copy?.length > 0 || link?.productCategory?.length > 0)"></flyout-item>
    </div>

    <div class="system-image"
         *ngIf="data.productCategory && data.productCategory.length && data.productCategory[0].teaserImage?.urls">
      <img
        [src]="((pageMode$ | async) === 'easy') ? data.productCategory[0].teaserImage.urls['NavigationTeaserAzure'] : data.productCategory[0].teaserImage.urls['NavigationTeaserSilverlight']"
        [alt]="data.productCategory[0].teaserImage.meta.alt" [title]="data.productCategory[0].teaserImage?.meta?.title">
    </div>

    <flyout-teaser-text [class.hide]="!showTextTeaser"
                        [isCategory]="data.productCategory?.length > 0"
                        [data]="data.productCategory?.length ? data.productCategory[0] : data.linkBrick"
                        [color]="color"></flyout-teaser-text>
  </div>
</div>
