import {Component, Input} from '@angular/core';
import {EasyModeSelectorAnswerModel} from '@rz-gud/content-elements';
import {Store} from '@ngrx/store';
import {EasyModeActions, EasyModeSelectors, EasyModeState} from '@rz-gud/store';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'easy-mode-dropdown',
  templateUrl: './easy-mode-dropdown.component.html',
  styleUrls: ['./easy-mode-dropdown.component.scss']
})
export class EasyModeDropdownComponent {

  @Input() availableAnswers: Array<EasyModeSelectorAnswerModel> = [];

  readonly label$: Observable<string> = this.store.select(EasyModeSelectors.dropdownLabel(this.translate.instant('pleaseChoose')));
  readonly easymode$: Observable<EasyModeState> = this.store.select(EasyModeSelectors.select);

  constructor(private readonly store: Store,
              private readonly translate: TranslateService) {
  }

  setAnswer(answer: EasyModeSelectorAnswerModel) {
    this.store.dispatch(EasyModeActions.setAnswer({answer: answer.toPlainObject()}));
  }
}
