import {createReducer, on} from '@ngrx/store';
import {ComparisonActions} from '../actions';
import {Comparison} from '@rz-gud/models';

export interface ComparisonState {
  onComparisonPage: boolean;
  showOverlay: boolean;
  comparisonListProducts: Array<{ id: number; title: string }>;
  resultList: Comparison;
  isLoading: boolean;
}

export const initialState: ComparisonState = {
  onComparisonPage: false,
  showOverlay: true,
  comparisonListProducts: [],
  resultList: new Comparison(),
  isLoading: false
};

export const comparisonReducer = createReducer(
  initialState,
  on(ComparisonActions.clearList, (state) => ({...state, resultList: initialState.resultList, comparisonListProducts: []})),
  on(ComparisonActions.onPage, (state) => ({...state, onComparisonPage: true})),
  on(ComparisonActions.notOnPage, (state) => ({...state, onComparisonPage: false})),
  on(ComparisonActions.showOverlay, (state, {showOverlay}) => ({...state, showOverlay})),
  on(ComparisonActions.writeList, (state, {resultList}) => ({...state, resultList})),
  on(ComparisonActions.isLoading, (state, {isLoading}) => ({...state, isLoading})),
  on(ComparisonActions.addToList, (state, {id, title}) => {
    const comparisonListProducts = [...state.comparisonListProducts, {id, title}];
    return {...state, comparisonListProducts};
  }),
  on(ComparisonActions.removeFromList, (state, {id}) => ({
    ...state,
    comparisonListProducts: state.comparisonListProducts.filter(e => e.id !== id)
  })),
  on(ComparisonActions.updateList, (state, {comparisonListProducts}) => ({
    ...state,
    comparisonListProducts
  })),
);

