import { createReducer, on } from '@ngrx/store';
import { PageActions } from '../actions';
import { SiteInfo, Breadcrumb } from '@rz-gud/interfaces';

export interface PageState {
  siteInfo: SiteInfo;
  breadcrumb: Array<Breadcrumb>;
}

export const initialState: PageState = {
  siteInfo: {
    id: -1,
    name: '',
    hubspotPortalId: '',
  },
  breadcrumb: [],
};

export const pageReducer = createReducer(
  initialState,
  on(PageActions.setSiteInfo, (state, { siteInfo }) => ({
    ...state,
    siteInfo,
  })),
  on(PageActions.clearSiteInfo, (state) => ({
    ...state,
    siteInfo: initialState.siteInfo,
  })),
  on(PageActions.setBreadcrumb, (state, { breadcrumb }) => ({
    ...state,
    breadcrumb,
  })),
  on(PageActions.clearBreadcrumb, (state) => ({
    ...state,
    breadcrumb: initialState.breadcrumb,
  }))
);
