import {Deserializable, serialize, serializeArray} from '@conception/ts-class-deserializer';
import {PimAsset, PimLink} from '@conception/ngx-pimcore-connect';

export class ImageSlide extends Deserializable {
  @serialize() headline: string;
  @serialize() subline: string;
  @serialize() icon: string;
  @serialize(PimLink) link: PimLink;
  @serialize(PimAsset) image: PimAsset;
}

export class ImageSliderModel extends Deserializable {
  @serialize() note: string;
  @serializeArray(ImageSlide) slides: Array<ImageSlide>;
}

