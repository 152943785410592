import {createAction, props} from '@ngrx/store';
import {SimpleType} from '@rz-gud/interfaces';

export const setHiddenFields = createAction(
  '[Hubspot Form] Set hidden fields',
  props<{ hiddenFields: Array<SimpleType> }>()
);

export const openInquiryForm = createAction(
  '[Hubspot Form] Open product inquiry'
);

export const openQuestionForm = createAction(
  '[Hubspot Form] Open product question'
);

export const closeForm = createAction(
  '[Hubspot Form] Close form'
);
