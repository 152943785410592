<img *ngIf="category && category.teaserImage?.urls"
     [src]="(pageMode$ | async) === 'easy' ?
      category.teaserImage?.urls['NavigationTeaserAzure'] :
      category.teaserImage?.urls['NavigationTeaserSilverlight']"
     [alt]="category.headline">

<img *ngIf="data?.image?.url"
     [src]="data.image.url"
     [alt]="data.image.meta?.alt"
     [title]="data.image.meta?.title">
<div class="no-image"
     *ngIf="!data?.image?.url && !(category && category.teaserImage?.urls)"></div>

<div class="content">
  <h3 *ngIf="data?.headline || category?.headline"
      [innerHTML]="category ? category.headline : data.headline"></h3>
  <p *ngIf="data?.copy || category?.excerpt"
     [innerHTML]="category ? category.excerpt : data.copy"></p>

  <ng-container [ngSwitch]="brickType">
    <a *ngSwitchCase="'navigationLinkInternal' || 'NavigationLinkInternal'"
       [routerLink]="data?.pageLink?.pageLink?.url">
      <gd-button color="maya" [label]="'viewAll' | translate"></gd-button>
    </a>

    <a *ngSwitchCase="'navigationLinkExternal'" [href]="data?.externalLink?.direct"
       [target]="data?.externalLink?.target">
      <gd-button color="maya" [label]="'viewAll' | translate"></gd-button>
    </a>

    <a *ngSwitchCase="'navigationLinkFilter'"
       [routerLink]="productOverviewLink$ | async" [queryParams]="{filter: category?.filterID}">
      <gd-button color="maya" [label]="'viewAll' | translate"></gd-button>
    </a>
  </ng-container>
</div>
