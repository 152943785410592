import {Component, Input} from '@angular/core';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {EasyModeActions, EasyModeSelectors, EasyModeState} from '@rz-gud/store';
import {EasyModeSelectorQuestionModel} from '@rz-gud/content-elements';

@Component({
  selector: 'easy-mode-indicator',
  templateUrl: './easy-mode-indicator.component.html',
  styleUrls: ['./easy-mode-indicator.component.scss']
})
export class EasyModeIndicatorComponent {

  @Input() qaBlock: Array<EasyModeSelectorQuestionModel> = [];
  readonly easymode$: Observable<EasyModeState> = this.store.select(EasyModeSelectors.select);

  constructor(private readonly store: Store) {
  }

  jumpToStage(stage: number): void {
    this.store.dispatch(EasyModeActions.setStage({stage}));
  }
}
