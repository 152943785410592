<overlay-container (closed)="close()">
  <div class="headline-2 color--silver-light slide-in-top">Select your language</div>
  <gd-separator></gd-separator>
  <option-dropdown class="slide-in-bottom"
                   *ngIf="items.length"
                   [simpleObject]="false"
                   [useAsLabel]="'label'"
                   [useAsIdentifier]="'lang'"
                   [items]="items"
                   [activeItem]="activeItem"
                   (activeItemChange)="set($event)"
                   [description1]="'Guntermann & Drunck GmbH'"
                   [description2]="'G&D North America Inc.'"
                   [isToggable]="false"></option-dropdown>
</overlay-container>
