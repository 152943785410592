<ng-container *ngIf="items.length > 0">
  <swiper
    #swiper
    [config]="sliderConfig"
    (activeIndexChange)="setCurrentSlide($event)"
  >
    <ng-template swiperSlide *ngFor="let product of items">
      <div class="inner-slide">
        <product-teaser [product]="product"></product-teaser>
      </div>
    </ng-template>
  </swiper>
  <div class="bottom">
    <gd-slider-navigation
      *ngIf="showNavigation"
      [maxSlides]="maxSlides"
      [currentSlide]="currentSlide"
      (jumpTo)="swiper.swiperRef.slideTo($event)"
      (navigation)="
        $event === 'next'
          ? swiper.swiperRef.slideNext()
          : swiper.swiperRef.slidePrev()
      "
    >
    </gd-slider-navigation>
  </div>
</ng-container>
