import {
  Deserializable,
  serialize,
  serializeArray,
} from '@conception/ts-class-deserializer';
import { PimAsset, PimVideo } from '@conception/ngx-pimcore-connect';

export class AccordionBlock extends Deserializable {
  @serialize() urlParameter?: string;
  @serialize() headline: string;
  @serialize() copy: string;
  @serialize(PimVideo) video: PimVideo;
  @serialize(PimAsset) image: PimAsset;
  @serialize() hubspotFormId: string;
  @serialize() dynamicsWebsiteId: string;
  @serialize() dynamicsHostName: string;
  @serialize() dynamicsFormBlockId: string;
}

export class AccordionModel extends Deserializable {
  @serialize() headline: string;
  @serialize() copy?: string;
  @serialize() theme: 'silver-light' | 'green' | 'yellow';
  @serialize() openFirstElement: boolean;
  @serializeArray(AccordionBlock) blocks: Array<AccordionBlock>;
}
