import {Component, HostBinding, Input} from '@angular/core';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {LayoutSelectors, PageMode, PageSelectors} from '@rz-gud/store';

@Component({
  selector: 'flyout-teaser',
  templateUrl: './flyout-teaser.component.html',
  styleUrls: ['./flyout-teaser.component.scss']
})
export class FlyoutTeaserComponent {

  @Input() category: any;
  @Input() brickType: string = '';
  @Input() data: any;
  @HostBinding('class') @Input() color: string;

  readonly pageMode$: Observable<PageMode> = this.store.select(LayoutSelectors.mode);
  readonly productOverviewLink$: Observable<string> = this.store.select(PageSelectors.productOverview);

  constructor(private readonly store: Store) {
  }
}
