import {ChangeDetectorRef, Component, HostBinding, Inject, Input, OnDestroy, OnInit, PLATFORM_ID, ViewChild,} from '@angular/core';
import SwiperCore, {EffectFade} from 'swiper';
import {GDColors} from '@conception/ng-gud-ui';
import {isPlatformBrowser} from '@angular/common';
import {SwiperComponent} from 'swiper/angular';
import {FadeSlide} from './fade-slider.model';

SwiperCore.use([EffectFade]);

@Component({
  selector: 'fade-slider',
  templateUrl: './fade-slider.component.html',
  styleUrls: ['./fade-slider.component.scss']
})
export class FadeSliderComponent implements OnInit, OnDestroy {

  @ViewChild(SwiperComponent) swiperComp: SwiperComponent;

  @Input() slides: Array<FadeSlide> | Array<any> = [];
  @Input() note: string = '';
  @Input() noteLayout: number = 1;
  @Input() staticLayout: boolean = false;
  @HostBinding('class.fullscreen') @Input() fullscreen: boolean = false;

  gdColors = GDColors;
  currentIndex: number = 1;
  progress: number = 0;

  private start: number = 0;
  private slideTime: number = 5000;
  private readonly isBrowser: boolean = false;
  private animationId: number;

  constructor(private readonly cd: ChangeDetectorRef,
              @Inject(PLATFORM_ID) platformId: Record<string, unknown>) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    if (this.isBrowser && this.slides.length > 1) {
      this.animationId = window.requestAnimationFrame((timestamp) => this.step(timestamp));
    }
  }

  ngOnDestroy(): void {
    if (this.isBrowser) {
      window.cancelAnimationFrame(this.animationId);
    }
  }

  setIndex(e: number): void {
    this.currentIndex = e + 1;
    this.cd.detectChanges();
  }

  /**
   * Animation function based on requestAnimationFrame every time when slideTime is reached,
   * Swiper will be triggered to slide to the next slide. Based upon the cycles remaining time a progress is calculated,
   * which is bound to the <gd-circle-indicator>-component for visual representation.
   *
   * @param timestamp - milliseconds since the initialization of the window
   * @private
   */
  private step(timestamp: number) {
    if (!this.start) {
      this.start = timestamp;
    }
    const elapsed = timestamp - this.start;

    this.progress = elapsed / this.slideTime * 100;

    if (elapsed > this.slideTime) {
      this.start = timestamp;

      if (this.swiperComp) {
        if (this.swiperComp.swiperRef.isEnd) {
          this.swiperComp.swiperRef.slideTo(0, 100);
        } else {
          this.swiperComp.swiperRef.slideNext();
        }
      }
    }

    this.animationId = window.requestAnimationFrame((ts) => this.step(ts));
  }

}
