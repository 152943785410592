<ng-container *ngIf="items.length > 0">
  <swiper
    #swiper
    [config]="sliderConfig"
    (activeIndexChange)="setCurrentSlide($event)"
  >
    <ng-template swiperSlide *ngFor="let branch of items">
      <div class="inner-slide">
        <branch-teaser [branch]="branch" layout="portrait"></branch-teaser>
      </div>
    </ng-template>
  </swiper>
  <div class="branch-block__bottom">
    <gd-slider-navigation
      *ngIf="showNavigation"
      [maxSlides]="maxSlides"
      [class.full-width]="!showOverviewButton"
      [currentSlide]="currentSlide"
      (jumpTo)="swiper.swiperRef.slideTo($event)"
      (navigation)="
        $event === 'next'
          ? swiper.swiperRef.slideNext()
          : swiper.swiperRef.slidePrev()
      "
    >
    </gd-slider-navigation>

    <div
      class="to-overview"
      *ngIf="
        showOverviewButton && (branchesOverview$ | async) as branchesOverview
      "
    >
      <a [routerLink]="branchesOverview">
        <gd-simple-button [label]="'toOverview' | translate"></gd-simple-button>
      </a>
    </div>
  </div>
</ng-container>
